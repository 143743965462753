import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required").matches(/^[^\s]*$/, "Spaces are not allowed in the first name"),
  last_name: Yup.string().required("Last name is required").matches(/^[^\s]*$/, "Spaces are not allowed in the last name"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string().required("Street address is required"),
  country_id: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  zipCode: Yup.string().required("Zip code is required"),
  subject_detail: Yup.string().required("subject detail is required"),
  date_of_birth: Yup.string().required("Date of Birth is required"),
  // identity_picture: Yup.string().required("ID is required"),
  year_group: Yup.string().required("Year Group is required"),
});


export default validationSchema;
