import InnerBannerIgcse from "../inner-banner-Igcse";
import InternationalIgcseSection from "../international-gcse";
import Layout from "../../../layout/frontend/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  IAboutBanner,
  IBestLearningExp,
  HomePageContentInfo,
  Slide,
  SampleCourse,
} from "../type/type";
import { useEffect, useState } from "react";
import { FrontendApi, api, resolveUrl } from "../../../config/apis";
import { CircularProgress } from "@mui/joy";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SampleLessons from "../sample-lessons";

type TmetaData = {
  title: string;
  description: string;
  keywords: string;
  image: string;
};
type IigescContent = {
  paragraph: string;
  description: string;
  title: string;
  image: string;
};
type props = {
  type: string;
};

const CoursesPage = function ({ type }: props) {
  const { REACT_APP_SERVER_BASE } = process.env;
  const [sampleCourse, setSampleCourse] = useState<SampleCourse[]>([]);
  const [banner, setBanner] = useState<IAboutBanner>();

  const [igescContent, setIgescContent] = useState<IigescContent>({
    paragraph: "",
    description: "",
    title: "",
    image: "",
  });

  const [enrollNowInfo, setEnrollNowInfo] = useState<HomePageContentInfo>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(FrontendApi.IGCSE_PAGE, type));
        setBanner(res.data.CoursesPage.Banner);
        setIgescContent(res.data.CoursesPage.Content);
        setLoading(false);
      } catch (error) {
        setBanner({});
        setIgescContent({
          paragraph: "",
          description: "",
          title: "",
          image: "",
        });

        console.error(error);
      }
    };
    fetchData();
  }, [type]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(FrontendApi.HOME_PAGE_CMS);
        setSampleCourse(res.data.HomePageContent.SampleCourse);
        setEnrollNowInfo(res.data.HomePageInfo.EnrollNow);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [metaData, setmetaData] = useState<TmetaData>();

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const res = await api.get(resolveUrl(FrontendApi.META_TAGS_VIEW, type));
        setmetaData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMetaData();
  }, [type]);

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      </>
    );
  }

  // Initialize AOS
  AOS.init();
  const imageUrl = `${REACT_APP_SERVER_BASE}${metaData?.image}`;

  return (
    <>
      <Layout enrollNowInfo={enrollNowInfo}>
        <HelmetProvider>
          <Helmet>
            <title>{metaData && metaData.title}</title>
            <meta
              name="description"
              content={metaData && metaData.description}
            />
            <meta name="keywords" content={metaData && metaData.keywords} />
            <meta property="og:image" content={metaData && imageUrl} />
            <meta property="og:image:width" content="2500" />
            <meta property="og:image:height" content="933" />
            <meta property="og:image:type" content="image/jpeg" />

            <meta
              property="og:image:alt"
              content={metaData && metaData.title}
            />
            <meta
              property="og:description"
              content={metaData && metaData.description}
            />
            <meta property="og:title" content={metaData && metaData.title} />

            {/* <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:site" content="@exampletwitter" />
                        <meta name="twitter:title" content="Mountain Sunset" />
                        <meta name="twitter:description" content="A beautiful sunset over the mountains" />
                        <meta name="twitter:image" content="https://example.com/path/to/image.jpg" /> */}
          </Helmet>
        </HelmetProvider>
        <InnerBannerIgcse bannerData={banner} />
        <InternationalIgcseSection igescContent={igescContent} />
        <SampleLessons sampleCourse={sampleCourse} />
      </Layout>
    </>
  );
};

export default CoursesPage;
