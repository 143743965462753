import { FC } from "react";
import { ContentTypeProp } from "./ContentProps";
import { Grid } from "@mui/material";
import { Button } from "react-bootstrap";
import EditIcon from "../../../../../static/images/edit.jpg";
import DeleteIcon from "../../../../../static/images/trash.jpg";
import VideoIcon from "../../../../../static/images/video-icon.png";

const Video: FC<ContentTypeProp> = function ({
  contentIndex,
  content,
  sectionIndex,
  editContent,
  removeContent,
  editDisabled,
}) {
  return (
    <Grid
      className="single-lesson"
      container
      alignItems="center"
      justifyContent="space-between"
      key={contentIndex}
    >
      <Grid item>
        <h2>
          <span>Part {contentIndex + 1}: </span>
          <img src={VideoIcon} alt="icon" />
          {content.upload_title}
        </h2>
      </Grid>
      <Grid item>
        <Button
          className="course-btn"
          variant="contained"
          disabled={editDisabled}
          style={editDisabled ? { opacity: 0.5 } : {}}
          onClick={(e) =>
            editContent(
              e,
              sectionIndex,
              "video",
              contentIndex,
              true,
              content.upload_title,
              undefined,
              undefined,
              undefined,
              content
            )
          }
        >
          <span className="tooltip">Edit Video</span>
          <img src={EditIcon} alt="icon" />
        </Button>
        <Button
          className="course-btn"
          variant="contained"
          disabled={editDisabled}
          style={editDisabled ? { opacity: 0.5 } : {}}
          onClick={(e) =>
            removeContent(e, "content", contentIndex, sectionIndex)
          }
        >
          <span className="tooltip">Remove Video</span>
          <img src={DeleteIcon} alt="icon" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Video;
