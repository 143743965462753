import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { UserParentDetails } from "../../../../helper/helperFunction";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import Moment from "react-moment";
import styles from "./parents.module.scss";
import DynamicTabs from "../../../../components/tabs/Tabs";
import ViewComplaints from "../../manage-complaints/view";
import UserEnrolledstudents from "./kids-enrolled";
import UserViewComplaints from "./complain";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";

type User = {
  city: string | null;
  country: string | null;
  email: string;
  gender: string | null;
  name: string;
  phoneNumber: string;
  profile: number;
  registrationDate: string;
  role: string;
  state: string | null;
  streetAddress: string;
  students: string;
  enrolledStudents: string;
};

export default function DetailsParent() {
  const params = useParams<{ id?: string }>();
  const [parentInfo, setParentInfo] = useState<User>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchParentDetails = async () => {
      const res = await UserParentDetails(params.id ?? "");
      setParentInfo(res);
    };
    fetchParentDetails();
  }, [params.id]);

  const onEdit = async () => {
    navigate(`/admin/user/parents/edit/${params.id}`);
  };
  const tabs = [
    {
      label: "Students Enrolled",
      content: <UserEnrolledstudents />,
    },
    {
      label: "Complaints",
      content: <UserViewComplaints />,
    },
  ];
  const baseUrl = SERVER_KEY;
  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: "700", marginBottom: "20px" }}>
        Parents
      </Typography>
      <Box className={styles.studentProfileList}>
        <Grid container spacing={2}>
          <Grid item xl={2.5} lg={3} md={12} xs={12}>
            <Card className={styles.studentCardBx}>
              <CardContent className={styles.studentCardContent}>
                <Avatar
                  alt={parentInfo?.name ? parentInfo.name.toUpperCase() : ""}
                  src={`${baseUrl}${parentInfo?.profile}`}
                  className={styles.stuProfile}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  className={styles.stuName}
                >
                  <span className="bold">{parentInfo?.name}</span>
                </Typography>
              </CardContent>

              <div className={styles.stuViewDetailBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onEdit()}
                  className={styles.stuViewBtn}
                >
                  Edit Parent
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid item xl={9.5} lg={9} md={12} xs={12}>
            <Grid container className="personal_info_yi">
              <Grid item xs={12} className={styles.gridItem}>
                <Typography variant="h3">Personal Data</Typography>
                <span>
                  Gender:<span className="bold">{parentInfo?.gender}</span>
                </span>
                {/* <span>
                  Date of Birth:<span className="bold">23/02/1992</span>
                </span> */}
                <span>
                  Phone Number:
                  <span className="bold">{parentInfo?.phoneNumber}</span>
                </span>
                <span>
                  Email:<span className="bold">{parentInfo?.email}</span>
                </span>
                <span>
                  Registeration Date:{" "}
                  <span className="bold">
                    <Moment format="DD-MM-YYYY">
                      {parentInfo?.registrationDate}
                    </Moment>
                  </span>
                </span>
              </Grid>
              <Grid item xs={12} className={styles.gridItem}>
                <Typography variant="h3">Address</Typography>
                <span>
                  Street address:
                  <span className="bold">{parentInfo?.streetAddress}</span>
                </span>
                <span>
                  City:
                  <span className="bold">{parentInfo?.city}</span>
                </span>
                <span>
                  State:<span className="bold">{parentInfo?.state}</span>
                </span>
                <span>
                  Country:<span className="bold">{parentInfo?.country}</span>
                </span>
              </Grid>

              <Grid
                item
                xs={12}
                className={`${styles.gridItem} ${styles.gridItemLast}`}
              >
                <Typography variant="h3">Students Info</Typography>

                <span>
                  Total Students:
                  <span className="bold"> {parentInfo?.students}</span>
                </span>
                <span>
                  Students Enrolled:
                  <span className="bold"> {parentInfo?.enrolledStudents}</span>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Typography
        variant="h5"
        sx={{ fontWeight: "700", marginBottom: "20px", marginTop: "30px" }}
      >
        More Detail
      </Typography>
      <DynamicTabs tabs={tabs} />
    </>
  );
}
