import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import GlobalCMSPopup from "../parent-form-popup";
import TermsAndConditionCms from "./temsAndCondition";

const PopupsCms = () => {
  const tabs = [
    {
      label: "General Terms & Conditions",
      content: (
        <GlobalCMSPopup
          type="terms-conditions"
          heading="Terms & Conditions"
          successPathUrl="/admin/cms/terms-conditions"
        />
      ),
    },
    {
      label: "Parent Signup",
      content: (
        <GlobalCMSPopup
          type="parent-signup-popup"
          heading="Parent Signup Popup"
        />
      ),
    },
    {
      label: "Student Signup",
      content: (
        <GlobalCMSPopup
          type="student-signup-form"
          heading="Student Signup Popup"
        />
      ),
    },
    {
      label: "Parent First Login",
      content: (
        <GlobalCMSPopup
          type="parent-first-login"
          heading="Parent First Login"
        />
      ),
    },
    {
      label: "Parent Second Login",
      content: (
        <GlobalCMSPopup type="parent-2nd-login" heading="Parent Second Login" />
      ),
    },
    {
      label: "Payment Terms & Conditions",
      content: (
        <GlobalCMSPopup
          type="payment terms and conditions"
          heading="Payment Terms & Conditions"
        />
      ),
    },
  ];

  return (
    <>
      <h1>Popup Content</h1>
      <Box className="setting-tabs-container">
        <DynamicTabs tabs={tabs} />
      </Box>
    </>
  );
};
export default PopupsCms;
