// import StudentAssignmentTable from "./Student-assignment-table";
// import StudentAssignmentFilter from "./Student-assignment-filter";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import styles from "../studentAssignments.module.scss";
import { Textarea } from "@mui/joy";
import imgBox from "../../../assets/img/share.svg";
import Form from "../../../../../../components/forms/Form";
import {
  GetUserRole,
  getAssignmentsDetail,
  truncateTextWithEllipsis,
  GetLastPartOfFileName,
  TrackMouse,
} from "../../../../../../helper/helperFunction";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";
import { AdminApi, api, resolveUrl } from "../../../../../../config/apis";
import { useAuth } from "../../../../../../hooks/auth/auth";
import FileViewer from "../../../../../../components/fileViewer";
import BucketFileViewer from "../../../../../../components/buketFileViewer";
import AssignmentEditor from "../../../../teacher-dashbaord/assignments/AssignmentEditor";
import Modal from "@mui/material/Modal";
import PdfFile from "../../../../../../components/pdf-file";
import { useTracking } from "../../../../../../components/TimeTracking";
import { toast } from "react-toastify";
import validationSchema from "./validation";

interface PrivateComment {
  commentId: number;
  comment: string;
  first_name: string;
  last_name: string;
  created_at: string;
  role: string;
  commentCreatedDate: Date;
}

interface StudentAssignmentMeta {
  file_path?: string;
  file_name?: string;
}

export interface Assignment {
  CourseName: string;
  totalMarks: number;
  obtainedMarks: any;
  AssignmentTitle: string;
  AssignmentDescription: string;
  StudentAssignmentId: number;
  studentAssignmentStatus: string;
  AssignmentMedia: string | null;
  StudentAssignmentMeta: StudentAssignmentMeta | null;
  PrivateComments: PrivateComment[];
  upload_path: any;
  upload_title: any;
  studentAssignmentMarkingStatus: any;
  checked_assignment: any;
  courseSlug?: string;
}
interface Comment {
  assignmentId?: number | string;
  userId?: number;
  comment?: string;
}
interface UploadFormData {
  assignmentId?: number | string;
  file_path: File | null;
  submissionDate: Date;
}
const StudentAssignmentsDetail = () => {
  const mouse_track = TrackMouse();

  const { REACT_APP_API_BASE } = process.env;

  const { tracking, setTracking } = useTracking();

  const [isDataLoaded, setIsDataLoaded] = useState(true);

  const auth = useAuth();
  const userId = auth.state?.user.id;
  const params = useParams();
  const [assignment, setAssignment] = useState<Assignment>();
  const [fileName, setFileName] = useState<string>();
  const [formSuccess, setFormSuccess] = useState(false);
  const [marks, setMarks] = useState<any>({
    marks: "",
  });

  const [formData, setFormData] = useState<any>({
    marks: null,
    feedback: "",
  });

  const handleOnChange = (event: any) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };
  const handleMarksOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value =
      target.type === "number" ? parseFloat(target.value) : target.value;

    setFormData({
      ...formData,
      [target.name]: Number.isNaN(value) ? null : value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const currentDate = new Date();
  const [uploadFormData, setUploadFormData] = useState<UploadFormData>({
    assignmentId: params.id,
    file_path: null,
    submissionDate: currentDate,
  });
  const reSumbitFormData = {
    studentId: userId,
    studentAssignmentId: params.id,
  };

  useEffect(() => {
    const getAssignments = async () => {
      try {
        const res = await getAssignmentsDetail(params.id ?? "");
        setAssignment(res.AssignmentData);
        setMarks({ marks: res.AssignmentData.obtainedMarks });
        setFormData({
          marks: res.AssignmentData.obtainedMarks || null,
          feedback: res.AssignmentData.feedback,
        });
        setTracking({
          contentId: res.AssignmentData.contentId ?? "",
          duration: 0,
        });
        setIsDataLoaded(false);
      } catch (error) {
        console.error(error);
      }
    };

    getAssignments();
  }, [params.id, formSuccess]);

  const handleFormSuccess = async (response: any) => {
    if (response) {
      setFormSuccess((prevState) => !prevState);
      setFormData({
        assignmentId: params.id,
        userId: userId,
        comment: "",
      });
    }
  };

  const uploadAssignment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;

    if (selectedFiles && selectedFiles.length > 0) {
      const file = selectedFiles[0];
      setUploadFormData({
        ...uploadFormData,
        [event.target.name]: file,
      });
      setFileName(file.name);
    } else {
      console.error("submissionFile should not be empty");
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 0,
    height: "800px",
    overflow: "auto",
  };
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);

  const nevigate = useNavigate();

  const handleOpen = () => {
    nevigate(`/admin/student-assignments/edit-pdf/${params.id}`);
  };
  const handleClose = () => setOpen(false);

  const fileUrl = getFileUrl(assignment?.StudentAssignmentMeta?.file_path);
  const file_Name = assignment?.StudentAssignmentMeta?.file_name;

  const isPdf = GetLastPartOfFileName(file_Name ?? "");

  const handleReassignCancle = async () => {
    try {
      await api.get(
        resolveUrl(AdminApi.STUDENT_RESUBMIT_ASSIGNMENT_CANCLE, `${params.id}`)
      );
      toast.success("Canceled successfully");
      setFormSuccess((prevState) => !prevState);
    } catch (error) {
      console.error(error);
    }
  };
  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  return (
    <>
      {isDataLoaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 350,
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      ) : (
        <Box className={styles.assignmentDetailPanel}>
          <Grid container className={styles.assignmentDetailPanelTitle}>
            <Grid
              item
              xs={12}
              className={styles.assignmentDetailPanelTitleHead}
            >
              <Typography
                variant="h2"
                className={styles.assignmentDetailPanelTitleHeadContent}
              >
                Assignment Detail
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.assignmentDetailPanelTitleSub}>
              <Typography
                variant="h4"
                className={styles.assignmentDetailPanelTitleSubContent}
              >
                {assignment?.CourseName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={styles.assignmentDetailPanelContent}>
            <Box className={styles.studentAssignmentDetail}>
              {/* Top Head Layout */}
              <Grid container className={styles.ASDHeading}>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  className={styles.ASDHeadingTitleContent}
                >
                  <Typography
                    variant="h2"
                    className={styles.ASDHeadingTitleText}
                  >
                    {assignment?.AssignmentTitle}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={3}
                  className={styles.ASDHeadingPointsContent}
                >
                  {
                    <Typography
                      variant="body1"
                      className={styles.ASDHeadingPointsText}
                    >
                      {assignment?.obtainedMarks === ""
                        ? `${assignment?.totalMarks} Points`
                        : `${assignment?.obtainedMarks}/${assignment?.totalMarks}`}
                    </Typography>
                  }
                </Grid>
              </Grid>
              {/* Upload and Submit Layout */}
              <Grid container className={styles.ASDDetail} spacing={"60px"}>
                <Grid item xs={12} lg={8} className={styles.ASDDetailContent}>
                  <Box
                    className={styles.studentAssignmentBtnView}
                    sx={{ marginBottom: "10px" }}
                  >
                    <BucketFileViewer
                      name={"Download file"}
                      filePath={assignment?.upload_path}
                    />
                  </Box>

                  <Typography
                    variant="body1"
                    className={styles.ASDDetailContentText}
                  >
                    {assignment?.AssignmentDescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} lg={4} className={styles.ASDDetailAction}>
                  <Grid
                    container
                    className={styles.ASDDetailBtn}
                    spacing={"10px"}
                  >
                    {GetUserRole() === "Student" ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          className={styles.ASDDetailBtnUpload}
                        >
                          {assignment?.studentAssignmentStatus === "active" ||
                          assignment?.studentAssignmentStatus === "approved" ? (
                            <>
                              <Form
                                submitUrl={AdminApi.STUDENT_SUBMIT_ASSIGNMENT}
                                formData={uploadFormData}
                                successPath={`/admin/student-courses/view/${assignment?.courseSlug}`}
                                saveText="Submit"
                                onSuccess={handleFormSuccess}
                                multipart={true}
                              >
                                <Box className={styles.boxModuleChange}>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {fileName}
                                  </Typography>

                                  <Button
                                    variant="contained"
                                    component="label"
                                    className={styles.ASDDetailBtnText}
                                  >
                                    Upload your work
                                    <input
                                      type="file"
                                      hidden
                                      name="file_path"
                                      accept=".ppt, .pptx, .doc, .docx, .word, .jpeg, .jpg, .png, .pdf"
                                      onChange={uploadAssignment}
                                    />
                                  </Button>
                                </Box>
                              </Form>
                            </>
                          ) : (
                            <>
                              <Box>
                                {assignment?.StudentAssignmentMeta && (
                                  <Box
                                    className={styles.studentAssignmentBtnView}
                                  >
                                    <Typography
                                      variant="body1"
                                      className="ellipsis"
                                      sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {
                                        assignment?.StudentAssignmentMeta
                                          ?.file_name
                                      }
                                    </Typography>
                                    <FileViewer
                                      name={"view"}
                                      filePath={
                                        assignment?.StudentAssignmentMeta
                                          ?.file_path
                                      }
                                    />
                                  </Box>
                                )}
                                {assignment?.checked_assignment ? (
                                  <>
                                    <Box
                                      className={
                                        styles.studentAssignmentBtnView
                                      }
                                      sx={{ marginTop: "10px" }}
                                    >
                                      <Typography variant="body1">
                                        Checked File
                                      </Typography>

                                      <FileViewer
                                        name={"view"}
                                        filePath={
                                          assignment?.checked_assignment
                                        }
                                      />
                                    </Box>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Box>
                              {assignment?.studentAssignmentMarkingStatus !==
                              "marked" ? (
                                <>
                                  {assignment?.studentAssignmentStatus ===
                                  "pending" ? (
                                    <>
                                      <Button
                                        className="btn"
                                        onClick={() => handleReassignCancle()}
                                      >
                                        Cancel Request
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Form
                                        submitUrl={
                                          AdminApi.STUDENT_RESUBMIT_ASSIGNMENT
                                        }
                                        formData={reSumbitFormData}
                                        successPath={`/admin/student-assignments/view/${params.id}`}
                                        saveText="Request Re assign"
                                        onSuccess={handleFormSuccess}
                                        disable={
                                          assignment?.studentAssignmentStatus ===
                                            "pending" ||
                                          assignment?.studentAssignmentStatus ===
                                            "rejected"
                                        }
                                      ></Form>
                                    </>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </Grid>
                      </>
                    ) : GetUserRole() === "Teacher" ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          className={styles.ASDDetailBtnUpload}
                        >
                          <Form
                            submitUrl={AdminApi.SUBMIT_ASSIGNMENT_MARKS}
                            formData={formData}
                            successPath={`/admin/students/assignments`}
                            saveText="Save"
                            onSuccess={handleFormSuccess}
                            editId={params.id}
                            validate={customValidation}
                          >
                            <Box className="assignment_detail_teacher">
                              <Box
                                className={`${
                                  styles.studentAssignmentBtnView
                                } ${isPdf === "pdf" ? "edited_assin" : ""}`}
                                sx={{ marginBottom: "10px" }}
                              >
                                <span
                                  title={
                                    assignment?.StudentAssignmentMeta?.file_name
                                  }
                                >
                                  <Typography variant="body1">
                                    {assignment?.StudentAssignmentMeta
                                      ?.file_name
                                      ? truncateTextWithEllipsis(
                                          assignment?.StudentAssignmentMeta
                                            ?.file_name,
                                          6
                                        )
                                      : ""}
                                  </Typography>
                                </span>

                                <Box>
                                  <FileViewer
                                    name={"view"}
                                    filePath={
                                      assignment?.StudentAssignmentMeta
                                        ?.file_path
                                    }
                                  />
                                  {isPdf === "pdf" ? (
                                    <>
                                      <a
                                        style={{ marginLeft: "40px" }}
                                        className="btn_edit"
                                        data-apikey={`${REACT_APP_API_BASE}/${AdminApi.Edited_PDF_UPLOAD}/${params.id}`}
                                        id="open_pdf_tool"
                                        data-pdf={fileUrl}
                                        data-assignmentid={params.id}
                                        href={`/admin/student-assignments/edit-pdf/${params.id}`}
                                      >
                                        Edit
                                      </a>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </Box>
                              {assignment?.checked_assignment ? (
                                <Box
                                  className={styles.studentAssignmentBtnView}
                                  sx={{ marginBottom: "10px" }}
                                >
                                  <Typography variant="body1">
                                    Checked File
                                  </Typography>

                                  <FileViewer
                                    name={"view"}
                                    filePath={assignment?.checked_assignment}
                                  />
                                </Box>
                              ) : (
                                ""
                              )}
                              <Box>
                                <Grid item xs={12}>
                                  <InputLabel
                                    sx={{
                                      textAlign: "left",
                                      fontWeight: "700",
                                      marginTop: "20px",
                                    }}
                                  >
                                    Marks
                                  </InputLabel>
                                </Grid>
                                <TextField
                                  fullWidth
                                  type="number"
                                  name="marks"
                                  value={formData.marks}
                                  onChange={handleMarksOnChange}
                                  variant="outlined"
                                  error={errors.marks !== undefined}
                                  helperText={errors.marks}
                                />
                              </Box>
                              <Box>
                                <Grid item xs={12}>
                                  <InputLabel
                                    sx={{
                                      textAlign: "left",
                                      fontWeight: "700",
                                      marginTop: "20px",
                                    }}
                                  >
                                    Feedback
                                  </InputLabel>
                                </Grid>
                                <Textarea
                                  name="feedback"
                                  value={formData.feedback}
                                  minRows={3}
                                  maxRows={3}
                                  onChange={handleOnChange}
                                />
                              </Box>
                            </Box>
                          </Form>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* {(GetUserRole() === "Teacher" || GetUserRole() === "Student") ?

            (<>
              <Box className={styles.ASDCommentHeading}>
                <Typography
                  variant="h2"
                  className={styles.ASDCommentHeadingContent}
                >
                  Private Comments
                </Typography>
              </Box>
              <Grid container>
                {assignment?.PrivateComments &&
                  assignment.PrivateComments.map((comment, index) => (
                    <Grid key={index} container className={styles.ASDMsgBox}>
                      <Box className={styles.ASDMsgImgContainer}>
                        <Avatar
                          sx={{ width: 87, height: 87, mr: 0 }}
                          alt=""
                          src="/static/images/avatar/2.jpg"
                        />
                      </Box>
                      <Box className={styles.ASDMsgTextContainer}>
                        <Typography variant="h4" className={styles.ASDMsgTextTitle}>
                          {comment.first_name} {comment.last_name}
                        </Typography>
                        <Typography variant="body1" className={styles.ASDMsgDate}>
                          <Moment format="MMM DD, YYYY">
                            {comment.created_at}
                          </Moment>
                        </Typography>
                        <Typography
                          variant="body1"
                          className={styles.ASDMsgContent}
                        >
                          {comment.comment}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              <Box className={styles.ASDPostMain}>
                <Form
                  submitUrl={AdminApi.STUDENT_PRIVATE_COMMENT}
                  formData={formData}
                  successPath={`/admin/student-assignments/view/${params.id}`}
                  saveText="Send Message"
                  onSuccess={handleFormSuccess}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3 }}
                    className={styles.aTTDForm}
                  >
                    <Grid item xs={12} className={styles.aTTDFormTextArea}>
                      <Textarea
                        placeholder=""
                        name="comment"
                        minRows={4}
                        maxRows={4}
                        value={formData.comment}
                        onChange={(event) =>
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              comment: event.target.value,
                            };
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Form>
              </Box>

            </>) : ""
          } */}
              {/* Comments Section Layout */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <AssignmentEditor />
                </Box>
              </Modal>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default StudentAssignmentsDetail;

const getFileUrl = (filePath: any) => {
  const { REACT_APP_SERVER_BASE } = process.env;

  // Check if REACT_APP_API_BASE is defined before constructing the URL
  if (!REACT_APP_SERVER_BASE) {
    return null; // or handle the undefined case as needed
  }

  const baseUrl = REACT_APP_SERVER_BASE;

  const fileUrl = filePath && `${baseUrl}${filePath.replace("\\", "/")}`;
  return fileUrl;
};
