import { Link, useNavigate, useParams } from "react-router-dom";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { Box } from "@mui/material";
import { DateView } from "../../../../components/DateView/DateView";
import ProgressCourseCell from "../../../../components/BucketProgressBarSubject";

export default function UserViewCourses() {
  const navigate = useNavigate();

  const params = useParams();
  const tableColumns = [
    {
      cell: (row: { CourseName: string; featureImage?: string }) => (
        <>
          <ProgressCourseCell name={row.CourseName} image={row.featureImage} />
        </>
      ),
      name: "Subject Name",
      sortable: true,
      minWidth: "250px",
    },
    {
      selector: (row: { createdAt: string }) => (
        <DateView date={row.createdAt} />
      ),
      name: "Created At",
      sortable: false,
    },

    {
      selector: (row: { lectures: string }) => row.lectures,
      name: "Lesson",
      sortable: false,
    },
    {
      selector: (row: { courseCompleted: number }) => row.courseCompleted,
      name: "Subjects Completed",
      sortable: false,
    },
    // {
    //     selector: (row: { hours: number, minutes: number }) => ((row.hours * 60) + row.minutes) + " min",
    //     name: "Total Time",
    //     sortable: false,
    // },
    {
      selector: (row: { activeStudents: number }) => row.activeStudents,
      name: "Active Students",
      sortable: false,
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/courses/view/${id}`);
  };

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(AdminApi.USER_TEACHER_COURSES, `${params.id}`)}
        onView={onView}
        actions={{
          edit: false,
          delete: false,
        }}
        module="manage-users"
      />
    </>
  );
}
