import BannerImg from './asset/img/bannerImg.jpg';
import { Typography } from '@mui/material';
import './style/style.css';
import ImageViewer from '../../../components/imageViewer';


type IigescContent = {
    paragraph:string,
    description:string,
    title:string,
    image:string,
}

type props = {
    igescContent:IigescContent
}

const InternationalIgcseSection = function ({igescContent}:props) {
    
    return (
        <div className="interIgcseMain">
            <div className="container">
                <Typography variant='h2' data-aos="fade-up" data-aos-duration="2500">{igescContent && igescContent.title}</Typography>
                <Typography variant='body1' data-aos="fade-up" data-aos-duration="2800">{igescContent && igescContent.description}</Typography>
                <div className="sectionContent">
                    <div className="sectionImg" data-aos="fade-right" data-aos-duration="2500">
                        {igescContent && igescContent.image ?
                            <ImageViewer imagePath={igescContent?.image ?? ""} altText={igescContent?.title} />
                            :
                            <img src={BannerImg} alt="Banner Img" />
                        }
                    </div>
                    <div className="textContent" data-aos="fade-left" data-aos-duration="2500"  dangerouslySetInnerHTML={{ __html: igescContent && igescContent.paragraph }}>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InternationalIgcseSection;