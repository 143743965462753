import Box from "@mui/material/Box";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { getForumView } from "../../../../helper/helperFunction";
import React, { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./cousre-catalog.module.scss";
import ActionButtons from "../../../components/dataTables/ActionButtons";
import { api, resolveUrl, AdminApi } from "../../../config/apis";
import { getForumView } from "../../../helper/helperFunction";
// import FormModal from "./manage-forums/manage-threads/Modal";

const handleStartDateChange = (value: Date | null) => {
  // if (value) {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     start_date: value,
  //   }));
  // }
};

interface Comment {
  // Define the properties of a comment
}

interface Like {
  // Define the properties of a like
}

interface Thread {
  id: number;
  topic: string;
  description: string;
  comments: Comment[];
  likes: Like[];
}

interface ForumData {
  topic: string;
  description: string;
  type: string;
  status: boolean;
  moderate: boolean;
  tags: string[];
  thread: Thread[];
}

export default function CourseEnrolled() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState<string>("");
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const DEFAULT_FORUM_DATA: ForumData = {
    topic: "",
    description: "",
    type: "",
    status: false,
    moderate: false,
    tags: [],
    thread: [],
  };

  const params = useParams<{ id: string }>();
  const [forumViewData, setForumViewData] =
    React.useState<ForumData>(DEFAULT_FORUM_DATA);

  const fetchForumViewData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await getForumView(params.id ?? "");
      setForumViewData({
        topic: "",
        description: "",
        type: "",
        status: false,
        moderate: false,
        tags: [],
        thread: res.forum.thread,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      // Handle error here
    }
  }, [params.id]);

  React.useEffect(() => {
    fetchForumViewData();
  }, [fetchForumViewData]);

  const columns: TableColumn<Thread>[] = [
    {
      selector: (row: Thread) => row.topic,
      name: "Thread",
      cell: (row: Thread) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <Typography sx={{ fontWeight: "bold", lineHeight: "30px" }}>
            {row.topic}
          </Typography>
          <span>{row.description}</span>
        </div>
      ),
      sortable: true,
      width: "700px",
    },
    {
      selector: (row: Thread) => row.comments?.length ?? 0,
      name: "Replies",
      sortable: true,
    },
    {
      selector: (row: Thread) => "1k",
      name: "Views",
      sortable: false,
    },
    {
      selector: (row: Thread) => row.topic,
      name: "Activity",
      cell: (row: { topic: string; description: string }) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <span>Lorem ipsum</span>
          <br />
          <span>07-28-2020, 10:53 AM</span>
          <Typography sx={{ fontWeight: "bold", lineHeight: "30px" }}>
            by John Doe
          </Typography>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row: { id: number }) => (
        <ActionButtons
          id={row.id}
          onView={onView}
          // onEdit={onEdit}
          actions={{
            edit: false,
          }}
          onDelete={onDelete}
          module={"manage-forums"}
        />
      ),
    },
  ];
  const onEdit = async (id: string) => {
    navigate(`forum/edit/${id}`);
  };

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.FORUM_DELETE, id));
  };
  const onView = async (id: string) => {
    navigate(`/admin/forum_threads/view/${id}`);
  };
  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Subject Enrolled</h1>
      </div>

      <Box className="inner-container" sx={{ mb: 4, p: "0" }}>
        <form>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item xs={4.5}>
              <Box sx={{ display: "flex" }} className={styles.searchBy}>
                <Box className={styles.select_filter}>
                  <Select
                    fullWidth
                    name="Teacher"
                    // onChange={handleOnSelectChange}
                    defaultValue="selected"
                  >
                    <MenuItem value="selected"> Teacher</MenuItem>
                  </Select>
                </Box>
                <Box className={styles.select_filter}>
                  <Select
                    fullWidth
                    name="All"
                    // onChange={handleOnSelectChange}
                    defaultValue="selected"
                  >
                    <MenuItem value="selected"> All</MenuItem>
                  </Select>
                </Box>
                <p>Or</p>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    id="coursetitle"
                    variant="outlined"
                    required
                    fullWidth
                    name="name"
                    // value={formData.name}
                    // onChange={handleOnChange}
                  />
                  <Box className="btn-wrapper">
                    <Link to={"#"}>Go</Link>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={1.5}>
              <Box className={styles.select_filter}>
                <Select
                  fullWidth
                  name="Ascending"
                  // onChange={handleOnSelectChange}
                  defaultValue="selected"
                >
                  <MenuItem value="selected">Ascending</MenuItem>
                </Select>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>

      <div className="page-container Forums">
        <DataTable
          columns={columns}
          data={forumViewData.thread}
          progressPending={isLoading}
        />
      </div>
      {/* <FormModal id={viewId} open={isModalOpen} closeModal={toggleModal} /> */}
    </>
  );
}
