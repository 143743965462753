import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import Form from "../../../components/forms/Form";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AdminForm = ({ id, setFormSuccess }: any) => {
  const [formData, setFormData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    gender: "",
    country_id: null,
    role_id: null,
    zipCode: "",
    phone: "+44",
  });

  const [roles, setRoles] = useState([]);

  const [locations, setLocations] = useState([]);

  const [errors, setErrors] = useState<any>({});

  const handleOnChange = (event: any) => {
    const valueTarget = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: valueTarget,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };
  const phoneRegExp = /^\+?[0-9]{6,20}$/; // Example regular expression for numeric phone numbers with optional plus sign
  const minLength = 8; // Minimum allowed length
  const maxLength = 15; // Maximum allowed length

  const schema = yup.object().shape({
    first_name: yup
      .string()
      .required("Fields are required")
      .matches(/^[^\s]*$/, "Spaces are not allowed in the first name"),
    last_name: yup
      .string()
      .required("Fields are required")
      .matches(/^[^\s]*$/, "Spaces are not allowed in the last name"),
    email: yup.string().required("Fields are required"),
    gender: yup.string().required("Fields are required"),
    country_id: yup.string().required("Fields are required"),
    role_id: yup.string().required("Fields are required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .min(minLength, `Phone number must be at least ${minLength} characters`)
      .max(maxLength, `Phone number can't exceed ${maxLength} characters`)
      .required("Phone number is required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      // Additional validation for uploadPreview images
      const newErrors: any = {};
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleFormSuccess = async (response: any) => {
    if (response) {
      setFormSuccess((prevState: any) => !prevState);
    }
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.GET_ROLLS));
        const filteredRoles = res.data.Roles.filter(
          (role: { name: string }) => {
            return (
              role.name.toLowerCase().includes("admin") &&
              role.name !== "Super Admin"
            );
          }
        );

        setRoles(filteredRoles);
      } catch (error) {
        // Error handling
      }
    };

    const fetchLocations = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.COUNTRIES));
        setLocations(res.data.data);
      } catch (error) {
        // Error handling
      }
    };

    fetchRoles();
    fetchLocations();
  }, []);

  useEffect(() => {
    if (id) {
      const fechData = async () => {
        const res = await api.get(resolveUrl(AdminApi.ADMIN_VIEW, id ?? ""));
        setFormData({
          first_name: res.data.Profile.first_name ?? "",
          last_name: res.data.Profile.last_name ?? "",
          email: res.data.Profile.email ?? "",
          gender: res.data.Profile.gender ?? "",
          country_id: res.data.Profile.country_id ?? "",
          role_id: res.data.Profile.role_id ?? "",
          phone: res.data.Profile.phone ?? "+44",
        });
      };
      fechData();
    }
  }, [id]);

  const handlePhoneChange = (value: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      phone: `+${value}`,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      phone: undefined,
    }));
  };
  const submitUrl = id ? AdminApi.EDIT_ADMIN : AdminApi.ADD_ADMIN;

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={submitUrl}
              formData={formData}
              editId={id}
              onSuccess={handleFormSuccess}
              validate={customValidation}
              successPath="/admin/setting"
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    First Name*
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter first name"
                    value={formData.first_name}
                    name="first_name"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.first_name !== undefined}
                    helperText={errors.first_name}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Last Name*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter last name"
                    value={formData.last_name}
                    name="last_name"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.last_name !== undefined}
                    helperText={errors.last_name}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Email*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Enter email"
                    value={formData.email}
                    name="email"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.email !== undefined}
                    helperText={errors.email}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Phone*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                  />
                  {errors.phone && (
                    <FormHelperText error>{errors.phone}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Gender*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    value={formData.gender}
                    name="gender"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.gender !== undefined}
                    helperText={errors.gender}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Location*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    placeholder="Enter location"
                    value={formData.country_id}
                    name="country_id"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.country_id !== undefined}
                    helperText={errors.country_id}
                  >
                    {locations &&
                      locations.map((location: any, index) => (
                        <MenuItem key={index} value={location.id}>
                          {location.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Role*</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    select
                    fullWidth
                    value={formData.role_id}
                    name="role_id"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.role_id !== undefined}
                    helperText={errors.role_id}
                  >
                    {roles &&
                      roles.map((role: any, index) => (
                        <MenuItem key={index} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminForm;
