import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  Box,
} from "@mui/material";
import playvideo from "./../../../../src/static/images/play-img.png";
import styles from "./cousre-catalog.module.scss";
import Rating from "@mui/material/Rating";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";
import Tooltip from "@mui/material/Tooltip";
import { truncateTextWithEllipsis } from "../../../helper/helperFunction";

interface SingleCourseCardProps {
  title: string;
  price: number;
  image?: string;
  handlePurchaseNow: () => void;
}

const SingleCourseCard: React.FC<SingleCourseCardProps> = ({
  title,
  price,
  image,
  handlePurchaseNow,
}) => {
  const [value, setValue] = React.useState<number | null>(2);

  const getFileUrl = (filePath?: string) => {
    const { REACT_APP_SERVER_BASE } = process.env;
    const baseUrl = REACT_APP_SERVER_BASE;
    const fileUrl = filePath && `${filePath.replace("\\", "/")}`;

    return fileUrl;
  };

  const fileUrl = getFileUrl(image);

  return (
    <>
      <Card>
        <Box className={styles.subject_thumbnail}>
          <CardMedia
            sx={{ height: "214px" }}
            component="img"
            image={fileUrl ?? playvideo}
            alt={title}
          />

          <Box className={styles.subject_intro}>
            {/* <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                {title}
              </Typography>
            </Grid> */}
            {/* <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </Grid> */}
          </Box>
        </Box>
        <CardContent>
          <Grid className={styles.videoCourses} xs={12} container>
            <Grid item xs={12} className={styles.subject_intro}>
              <Tooltip title={title} arrow>
                <Typography variant="h5" component="h2">
                  {title && truncateTextWithEllipsis(title ?? "-", 40)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                Subject fee
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {CURRENCY_SYMBOL}
                {price}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Button
                className="dark-btn"
                variant="contained"
                onClick={handlePurchaseNow}
              >
                Purchase now
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SingleCourseCard;
