import { useEffect, useState } from "react";
import styles from "./filter.module.scss";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getTeachersData } from "../../../../../helper/helperFunction";
import { useAuth } from "../../../../../hooks/auth/auth";

interface Teacher {
  id: number;
  first_name: string;
  last_name: string;
}

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

interface FilterProps {
  onFiltersChange: (filters: FilterState) => void;
}

const Filter: React.FC<FilterProps> = ({ onFiltersChange }) => {
  const auth = useAuth();
  const userId = auth.state?.user.id;
  const [teacher, setTeachers] = useState<Teacher[]>([]);

  useEffect(() => {
    const getTeachers = async () => {
      try {
        const res = await getTeachersData(userId);
        setTeachers(res.teachers.items);
      } catch (error) {
        console.error(error);
      }
    };

    getTeachers();

    return () => {};
  }, [userId]);

  const [order, setOrder] = useState("descending");
  const [filters, setFilters] = useState<FilterState>({
    teacherId: null,
    status: null,
    searchQuery: null,
  });

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters, onFiltersChange]);

  const [filterData, setFilterData] = useState<FilterState>({
    teacherId: null,
    status: "all",
    searchQuery: null,
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      order,
    }));
  }, [order]);

  return (
    <>
      <form className={styles.assignmentFilterMain}>
        <Typography variant="body1" className={styles.assignmentFilterTitle}>
          Search By
        </Typography>
        {/* <FormControl className={styles.assignmentFilterUserType}>
          <Select
            className={styles.assignmentFilterUserTypeSelect}
            onChange={(event) =>
              setFilterData((prevState: FilterState) => {
                return {
                  ...prevState,
                  teacherId: event.target.value,
                };
              })
            }
          >
            {teacher.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.first_name} {item.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {/* <FormControl className={styles.assignmentFilterStatus}>
          <Select
            onChange={(event) =>
              setFilterData((prevState: FilterState) => {
                return {
                  ...prevState,
                  status: event.target.value,
                };
              })
            }
            className={styles.assignmentFilterStatusSelect}
            value={filterData.status}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body1" className={styles.assignmentOrText}>
          Or
        </Typography> */}
        <Box className={styles.assignmentSearchComp}>
          <TextField
            id="coursetitle"
            variant="outlined"
            fullWidth
            className={styles.assignmentFilterKeyword}
            placeholder="Name"
            onChange={(event) =>
              setFilterData((prevState: FilterState) => {
                return {
                  ...prevState,
                  searchQuery: event.target.value,
                };
              })
            }
          />
          <Box className={styles.assignmentFilterKeywordButtonWrapper}>
            <Button
              className={styles.assignmentFilterKeywordButton}
              onClick={() =>
                setFilters((prevState: FilterState) => {
                  return {
                    ...prevState,
                    ...filterData,
                  };
                })
              }
            >
              Go
            </Button>
          </Box>
        </Box>
        <FormControl className={styles.assignmentFilterOrder}>
          <Select
            className={styles.assignmentFilterOrderSelect}
            value={order}
            onChange={(event) => setOrder(event.target.value)}
          >
            <MenuItem value="ascending" selected>
              Ascending
            </MenuItem>
            <MenuItem value="descending">Descending</MenuItem>
          </Select>
        </FormControl>
      </form>
    </>
  );
};

export default Filter;
