import React, { useEffect, useState } from "react";
import styles from "./manage-discount.module.scss";
import {
  Typography,
  Button,
  Card,
  CardContent,
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { getStudentData } from "../../../helper/helperFunction";
import { useAuth } from "../../../hooks/auth/auth";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
interface StudentCardProps {
  checkedList: number[];
  onStudentCardClick: (id: number) => void;
}

interface Student {
  id: number;
  first_name: string;
  last_name: string;
  profile_picture?: string;
}

const StudentCard: React.FC<StudentCardProps> = ({
  checkedList,
  onStudentCardClick,
}) => {
  const [studentData, setStudentData] = useState<Student[] | null>(null);

  const Parent = useAuth();
  const id = Parent.state?.user.id;
  useEffect(() => {
    async function fetchStudentData() {
      const data = await getStudentData(id);
      setStudentData(data);
    }

    fetchStudentData();
  }, [id]);

  if (!studentData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 350,
        }}
      >
        <CircularProgress sx={{ color: "#d6c38f" }} />
      </div>
    );
  }
  const baseUrl = SERVER_KEY;

  return (
    <Box className={styles.studentProfileList}>
      {studentData.map((student, index) => (
        <Card
          key={index}
          sx={{ maxWidth: 345, marginRight: 3 }}
          className={styles.studentCardBx}
        >
          <CardContent className={styles.studentCardContent}>
            <Avatar
              alt={student?.first_name ? student.first_name.toUpperCase() : ""}
              src={`${baseUrl}${student?.profile_picture}`}
              className={styles.stuProfile}
            />

            <Typography variant="h4" gutterBottom className={styles.stuName}>
              {student.first_name} {student.last_name}
            </Typography>
            {/* <Typography variant="body1" className={styles.stuLastActive}>
              <b>Last Active</b> 1 hr ago
            </Typography> */}
          </CardContent>
          <div className={styles.stuViewDetailBtn}>
            <Checkbox
              checked={checkedList.includes(student.id)}
              onChange={() => onStudentCardClick(student.id)}
              className={styles.stuViewDetailCheck}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => onStudentCardClick(student.id)}
              className={styles.stuViewBtn}
            >
              {checkedList.includes(student.id) ? "selected" : "select"}
            </Button>
          </div>
        </Card>
      ))}
    </Box>
  );
};

export default StudentCard;
