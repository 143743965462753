import React, { useEffect, useRef, useState } from "react";
import AddComment from "../manage-comments/add";
import styles from "../forum.module.css";
import { Box } from "@mui/material";
import Moment from "react-moment";
import { Button } from "react-bootstrap";
import { makeFullUrl } from "../../../../helper/helperFunction";

interface Props {
  comment: Comment;
  thread_id?: string;
  setFormSuccess: (value: boolean) => void;
  setCommentFormHide: (value: boolean) => void;
}

interface Comment {
  id: number;
  comment: string;
  comment_parent: number | null;
  created_at: string;
  updated_at: string;
  childComments: Comment[];
  creator: Creator;
}
interface Creator {
  id: number;
  first_name: string;
  last_name: string;
  created_at: string;
  updated_at: string;
  role: string;
  totalThreads: string;
  totalForums: string;
  profile_picture: string;
}
const Comment: React.FC<Props> = ({
  comment,
  thread_id,
  setFormSuccess,
  setCommentFormHide,
}) => {
  const [commentId, setCommentId] = useState<number | null>();

  const replyFormRef = useRef<HTMLDivElement>(null);

  const [showReplyForm, setShowReplyForm] = useState(false);
  const [formSuccessReply, setFormSuccessReply] = useState(false);

  useEffect(() => {
    setFormSuccess(formSuccessReply);
    if (formSuccessReply) {
      setShowReplyForm(false);
    }
  }, [formSuccessReply, setFormSuccess]);

  useEffect(() => {
    if (showReplyForm) {
      setCommentFormHide(false);
    }
  }, [setCommentFormHide, showReplyForm]);

  const handleReply = (
    commentId: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setCommentId(commentId);
    setShowReplyForm(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        replyFormRef.current &&
        !replyFormRef.current.contains(event.target as Node)
      ) {
        setCommentId(null);
        setShowReplyForm(false);
        setCommentFormHide(true);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setCommentFormHide]);

  if (!comment || !comment.creator) {
    return null;
  }

  return (
    <>
      <Box>
        <Box className={styles["comment-container"]}>
          <Box className={styles["comment"]}>
            <Box className={styles["left"]}>
              <img
                src={
                  comment.creator.profile_picture
                    ? makeFullUrl(comment.creator.profile_picture)
                    : "/dummy.jpg"
                }
                alt="dummy"
              />
            </Box>
            <Box className={styles["right"]}>
              <Box className={styles["upper"]}>
                <Box>
                  <p className={styles["name"]}>
                    <span>
                      {comment.creator.first_name} {comment.creator.last_name}
                    </span>{" "}
                    {comment.creator.role}
                  </p>
                  <Moment format="MMM DD, YYYY">{comment.created_at}</Moment>
                </Box>
                <Box className={styles["stats"]}>
                  <Box>
                    <p>Posts: {comment.creator.totalForums}</p>
                    <p>Threads: {comment.creator.totalThreads}</p>
                  </Box>
                  <Box>
                    <p>
                      Joined:{" "}
                      <Moment format="MMM, YYYY">
                        {comment.creator.created_at}
                      </Moment>
                    </p>
                    {/* <p>Reputation: 1</p> */}
                  </Box>
                </Box>
              </Box>
              <Box className={styles["bottom"]}>
                <p>{comment.comment}</p>
              </Box>
              <Box className={styles["reply"]}>
                <Button
                  onClick={(event) => handleReply(comment.id, event)}
                ></Button>
              </Box>
            </Box>
          </Box>

          {commentId === comment.id && showReplyForm && (
            <div ref={replyFormRef}>
              <AddComment
                thread={thread_id}
                comment_parent={commentId}
                setFormSuccess={setFormSuccessReply}
                setCommentFormHide={setCommentFormHide}
              />
            </div>
          )}
          {comment.childComments.length > 0 && (
            <Box>
              {comment.childComments.map((childComment, index) => (
                <Box key={index} className={styles["child_comments"]}>
                  <Comment
                    key={index}
                    comment={childComment}
                    thread_id={thread_id}
                    setFormSuccess={setFormSuccess}
                    setCommentFormHide={setCommentFormHide}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Comment;
