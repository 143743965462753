import styles from "./manage-quize.module.scss";
import { useCallback, useEffect, useState } from "react";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormHelperText,
  InputLabel,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import * as React from "react";
import Grid from "@mui/material/Grid";
import { Add } from "@mui/icons-material";
import { getQuizesBank } from "../../../helper/helperFunction";
import * as Yup from "yup";

interface QuizData {
  name: any;
  description: string;
  tags: string[];
  totalMarks: number | null;
  duration: any;
  is_live: boolean;
  content: QuizContent[];
  courseId?: number;
}

interface QuizContent {
  question: string;
  answers: string[];
  answer_key: number[];
  type: string;
  id: any;
}
interface QuizBank {
  question: string;
  answers: string[];
  answer_key: number[];
  type: string;
  id: any;
}

export const QuizContent = ({
  isEdit,
  setFormData,
  formData,
  contentIndex,
  editContentCallback,
  errorMsg,
}: any) => {
  const [quizBank, setQuizBank] = useState<QuizBank[]>([]);

  const defaultQuizContent: QuizContent = {
    question: "",
    answers: [""],
    answer_key: [],
    type: "multiple-choices",
    id: null,
  };

  const [quizContent, setQuizContent] = useState<QuizContent[]>([
    defaultQuizContent,
  ]);

  const [editedQuizIndex, setEditedQuizIndex] = useState<number | null>(null);

  const [open, setOpen] = React.useState(false);

  const [quizBankOpen, setquizBankOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());

  const handleQuizBankClose = () => {
    setquizBankOpen(false);
    setQuizContent([defaultQuizContent]);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 996,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedQuizContent = [...quizContent];
    updatedQuizContent[0].question = event.target.value;
    setQuizContent(updatedQuizContent);
  };

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedQuizContent = [...quizContent];
    updatedQuizContent[0].answers[index] = event.target.value;
    setQuizContent(updatedQuizContent);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionIndex: number,
    optionIndex: number
  ) => {
    const updatedQuizContent = [...quizContent];

    const checked = event.target.checked;

    if (checked) {
      if (!updatedQuizContent[questionIndex].answer_key.includes(optionIndex)) {
        updatedQuizContent[questionIndex].answer_key.push(optionIndex);
      }
    } else {
      updatedQuizContent[questionIndex].answer_key = updatedQuizContent[
        questionIndex
      ].answer_key.filter((idx) => idx !== optionIndex);
    }

    setQuizContent(updatedQuizContent);
  };

  const handleAddOption = () => {
    const updatedQuizContent = [...quizContent];

    updatedQuizContent[0].answers.push("");
    setQuizContent(updatedQuizContent);
  };

  const handleRemoveOption = (index: number) => {
    const updatedQuizContent = [...quizContent];
    updatedQuizContent[0].answers.splice(index, 1);
    updatedQuizContent[0].answer_key.splice(index, 1);
    setQuizContent(updatedQuizContent);
  };

  const handleQuizAdd = () => {
    for (let quiz of quizContent) {
      if (!quiz.question || quiz.question.trim() === "") {
        alert("Each quiz must have a non-empty question.");
        return;
      }
      if (
        !quiz.answers ||
        quiz.answers.length < 2 || // Ensure there are at least 2 answers
        quiz.answers.some((answer) => answer.trim() === "")
      ) {
        alert("All quizzes must have at least two non-empty options.");
        return;
      }
      if (!quiz.answer_key || quiz.answer_key.length === 0) {
        alert("Each quiz must have at least one value in the Answer key.");
        return;
      }
    }

    if (editedQuizIndex !== null) {
      // Your code for handling edited quiz index can go here
    } else {
      const UpdatedQuizContent = [...quizContent];
      const UpdatedQuizContentWithoutId = UpdatedQuizContent.map(
        ({ id, ...rest }) => rest
      );

      setFormData((prevFormData: QuizData) => ({
        ...prevFormData,
        content: [...prevFormData.content, ...UpdatedQuizContentWithoutId],
      }));
    }
    setEditedQuizIndex(null);
    setOpen(false);
    setquizBankOpen(false);
    setQuizContent([defaultQuizContent]);
    setSelectedItems(new Set());
  };

  const handleRemoveQuiz = (index: number) => {
    const updatedFormData = { ...formData };
    updatedFormData.content.splice(index, 1);
    setFormData(updatedFormData);
  };

  const handleEditQuiz = (index: number) => {
    setEditedQuizIndex(index);
    setOpen(true);
    const updatedFormData = { ...formData };
    const selectedQuiz = updatedFormData.content[index];
    setQuizContent([selectedQuiz]);
  };

  const addFromQuizBank = () => {
    setquizBankOpen(true);
    setQuizContent([]);
  };

  useEffect(() => {
    const getQuizes = async () => {
      try {
        const res = await getQuizesBank();
        setQuizBank(
          res.items.map((contentItem: any) => ({
            question: contentItem.question,
            answers: JSON.parse(contentItem.answers),
            answer_key: JSON.parse(contentItem.answer_key),
            type: contentItem.type,
            id: contentItem.id,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    };

    getQuizes();
  }, []);

  const handleToggleQuizBank = (id: number) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(id)) {
        newSelectedItems.delete(id);
        handleRemoveQuizBank(id);
      } else {
        newSelectedItems.add(id);
        handleAddQuizBank(id);
      }
      return newSelectedItems;
    });
  };

  const handleAddQuizBank = (id: number) => {
    const copiedData = { ...quizBank.find((quiz) => quiz.id === id) };
    const { question, answers, answer_key, type, id: quizId } = copiedData;

    const updatedQuizContent: QuizContent = {
      question: question ?? "",
      answers: answers ?? [],
      answer_key: answer_key ?? [],
      type: type ?? "",
      id: quizId ?? -1,
    };

    setQuizContent((prevContent) => [...prevContent, updatedQuizContent]);
  };

  const handleRemoveQuizBank = (id: number) => {
    setQuizContent((prevContent) =>
      prevContent.filter((quiz) => quiz.id !== id)
    );
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        sx={{ mb: 2 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <h2>Quiz Content</h2>
        </Grid>
        <Grid item>
          <Button
            sx={{ mr: 2 }}
            className="light-btn"
            variant="contained"
            endIcon={<Add />}
            onClick={addFromQuizBank}
          >
            Add From Quiz Bank
          </Button>
          <Button
            className="light-btn"
            variant="contained"
            endIcon={<Add />}
            onClick={handleOpen}
          >
            Add Content
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} className={styles.qETableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className={styles.qETableHead}>
            <TableRow className={styles.qETableRow}>
              <TableCell align="left">Order #</TableCell>
              <TableCell align="left">Question</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.qETableBody}>
            <TableRow>
              <TableCell colSpan={3} sx={{ padding: 0 }}>
                {open && (
                  <Box sx={{ padding: 4 }}>
                    <Box className={styles.qEFAddContentModule}>
                      <Box className={styles.qEFACMContainer}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            container
                            sx={{ mb: 3, alignItems: "center" }}
                          >
                            <Grid
                              container
                              className={styles.qEFACMFieldContainer}
                            >
                              <Grid
                                item
                                xs={12}
                                className={styles.qEFACMFieldContent}
                              >
                                <Grid
                                  item
                                  xs={2}
                                  className={styles.qEFACMFieldContentLabel}
                                >
                                  <InputLabel sx={{ textAlign: "left" }}>
                                    Question
                                  </InputLabel>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  className={styles.qEFACMFieldContentInputQue}
                                >
                                  <TextField
                                    fullWidth
                                    name="question"
                                    variant="outlined"
                                    placeholder="Enter Your Question"
                                    value={quizContent[0]?.question}
                                    onChange={handleQuestionChange}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={styles.qEFACMFieldContent}
                              >
                                <Grid
                                  item
                                  xs={2}
                                  className={styles.qEFACMFieldContentLabel}
                                >
                                  <InputLabel sx={{ textAlign: "left" }}>
                                    Options
                                  </InputLabel>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  className={styles.qEFACMFieldContentInputAns}
                                >
                                  {quizContent.map((item, questionIndex) => (
                                    <Grid container key={questionIndex}>
                                      {item.answers.map(
                                        (option, optionIndex) => (
                                          <Grid
                                            container
                                            key={optionIndex}
                                            className={
                                              styles.qEFACMFCIAnsContent
                                            }
                                          >
                                            <Grid
                                              item
                                              className={
                                                styles.qEFACMFCIAnsInput
                                              }
                                            >
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={option}
                                                onChange={(
                                                  event: React.ChangeEvent<HTMLInputElement>
                                                ) =>
                                                  handleOptionChange(
                                                    event,
                                                    optionIndex
                                                  )
                                                }
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              className={
                                                styles.qEFACMFCIAnsCheckBox
                                              }
                                            >
                                              <Checkbox
                                                color="success"
                                                checked={item.answer_key.includes(
                                                  optionIndex
                                                )}
                                                onChange={(event) =>
                                                  handleCheckboxChange(
                                                    event,
                                                    questionIndex,
                                                    optionIndex
                                                  )
                                                }
                                              />
                                            </Grid>
                                            {optionIndex !== 0 && (
                                              <Grid
                                                item
                                                className={
                                                  styles.qEFACMFCIAnsRemove
                                                }
                                              >
                                                <Button
                                                  onClick={() =>
                                                    handleRemoveOption(
                                                      optionIndex
                                                    )
                                                  }
                                                ></Button>
                                              </Grid>
                                            )}
                                          </Grid>
                                        )
                                      )}
                                      <Grid item xs={4}>
                                        <Button
                                          onClick={handleAddOption}
                                          className={styles.qEFACMFCIAddBtn}
                                        >
                                          Add Option
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid container className={styles.qEFACMFCISaveBtn}>
                              <Grid
                                item
                                xs={12}
                                justifyContent={"flex-end"}
                                display={"flex"}
                              >
                                <Button onClick={handleQuizAdd}>save</Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                )}
              </TableCell>
            </TableRow>

            {formData &&
              formData.content.map((content: any, index: number) => (
                <TableRow key={index}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">{content.question}</TableCell>
                  <TableCell align="right">
                    <Button
                      className={styles.qEFBtnRemove}
                      onClick={() => handleRemoveQuiz(index)}
                    ></Button>
                    <Button
                      className={styles.qEFBtnEdit}
                      onClick={() => handleEditQuiz(index)}
                    ></Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {errorMsg && (
        <FormHelperText sx={{ fontSize: "15px", marginTop: 2 }} error>
          {errorMsg}
        </FormHelperText>
      )}
      <Button
        sx={{ marginTop: 3 }}
        className="btn"
        variant="contained"
        onClick={(e) => editContentCallback(e, "addQuiz", contentIndex)}
      >
        {isEdit ? "Edit" : "Save"}
      </Button>

      <Modal
        className={styles.qEFAddContentModule}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={quizBankOpen}
        onClose={handleQuizBankClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={quizBankOpen}>
          <Box sx={style} className={styles.qEFACMContainer}>
            <TableContainer
              component={Paper}
              className={styles.qETableContainer}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className={styles.qETableHead}>
                  <TableRow
                    // className="quiz-heading-row"
                    className={styles.qETableRow}
                  >
                    <TableCell align="left">Order #</TableCell>
                    <TableCell align="left">Question</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles.qETableBody}>
                  {quizBank.map((content, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">{content.question}</TableCell>
                      <TableCell align="right">
                        {/* {quizContent.some((quiz) => quiz.id === content.id) ? (
                          <Button
                            onClick={() => handleRemoveQuizBank(content.id)}
                            className={styles.addFromRemoveBtn}
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleAddQuizBank(content.id)}
                            className={styles.addFromAddBtn}
                          >
                            Add
                          </Button>
                        )} */}
                        <Checkbox
                          checked={selectedItems.has(content.id)}
                          onChange={() => handleToggleQuizBank(content.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container className={styles.qEFACMFCISaveBtn} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Button onClick={handleQuizAdd}>save</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
