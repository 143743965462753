import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Box, FormHelperText, InputLabel, TextField } from "@mui/material";
import { Textarea } from "@mui/joy";
import styles from "./style.module.scss";
import * as Yup from "yup";
import ImageUpload from "../../../components/UploadImage";

export default function AboutServicesHeading() {
  type FormData = {
    title: string;
    description: string;
    image?: File | null;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [onSucces, setOnSucees] = useState<any>();
  const [uploadPreviewProp, setUploadPreviewProp] = useState(null);

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    image: null,
  });

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };
  const handleIDImageChange = (name: any, selectedFile: any) => {
    setFormData({
      ...formData,
      image: selectedFile,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get(resolveUrl(AdminApi.ABOUT_SERVICES_INFO_VIEW));
      try {
        setFormData({
          title: res.data.data.title ?? "",
          description: res.data.data.description ?? "",
        });
        setUploadPreviewProp(res.data.data.image ?? null);

        setOnSucees("");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [onSucces]);

  return (
    <Box className={styles.aSCreateTicketMain}>
      <Box className={styles.aSCTContainer}>
        <Form
          submitUrl={AdminApi.ABOUT_SERVICES_INFO_SAVE}
          formData={formData}
          successPath="/admin/cms/about"
          validate={customValidation}
          onSucces={setOnSucees}
          multipart={true}
        >
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Title
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Title"
              value={formData.title}
              name="title"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.title !== undefined}
              helperText={errors.title}
            />
          </Box>
          <Box className={styles.aSCTContentDesc}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentDescLabel}
            >
              Description
            </InputLabel>
            <Textarea
              placeholder="Description"
              name="description"
              value={formData.description}
              minRows={4}
              maxRows={4}
              onChange={handleOnChange}
              className={styles.aSCTContentDescInput}
            />
            {errors.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Box>

          <Box className={`${styles.aSCTContentType} identity_picture `}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTypeLabel}
            >
              Image
            </InputLabel>
            <ImageUpload
              name="image"
              label="Upload Image"
              previewWidth={200}
              maxFileSize={5242880}
              onChange={handleIDImageChange}
              uploadPreviewProp={uploadPreviewProp}
              isRequired={true}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}
