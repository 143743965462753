import { AdminApi } from "../../../config/apis";
import Datatable from "../../../components/dataTables/Datatable";
import styles from "./enrolled.module.css";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { useState } from "react";
import CustomModal from "../../../components/popups/Modal";
import InstallmentCoursesPopup from "./intallment-courses-popup";

type ItemType = {
  paymentId: number;
  courseNames: string[];
  dueDate: string;
  overDueDate: boolean;
  payable: boolean;
};

type TableColumn = {
  cell: (row: ItemType) => JSX.Element;
  name: string;
  sortable: boolean;
};

export default function CousesGridIntallment({ filters }: any) {
  const navigate = useNavigate();
  const [payId, setPayId] = useState<number>();
  const [open, setOpen] = useState(false);

  const handleCouseList = (id: number) => {
    setPayId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const tableColumns: TableColumn[] = [
    {
      cell: (row) => (
        <>
          <p
            className={styles.course_name}
            onClick={() => handleCouseList(row.paymentId)}
          >
            {row.courseNames &&
              row.courseNames.map((item, index) => {
                return <span key={index}>{item}</span>;
              })}
          </p>
        </>
      ),
      name: "Subject Name",
      sortable: false,
    },
    {
      cell: (row) => (
        <p
          className={`${styles.pay_date} ${
            row.overDueDate ? styles.pay_date_due : ""
          }`}
        >
          {row.overDueDate && <p className={styles.pay_over_due}>Over Due</p>}
          {row.dueDate ? (
            <Moment format="MMM DD, YYYY">{row.dueDate}</Moment>
          ) : (
            "-"
          )}
        </p>
      ),
      name: "Due date",
      sortable: false,
    },
    {
      cell: (row: ItemType) => (
        <>
          <Button
            className={`btn ${styles.pay_now_btn}`}
            onClick={() => navigate(`/admin/course-cart/view/${row.paymentId}`)}
          >
            {row.payable ? "Pay now" : "View"}
          </Button>
        </>
      ),
      name: "Action",
      sortable: false,
    },
  ];

  return (
    <>
      <Box className={styles.install_table}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.INSTALLMENT_PAID_COURSES}
          filters={filters}
          actionColumns={false}
          module="manage-parent-courses"
        />
      </Box>
      <CustomModal
        state={open}
        setState={setOpen}
        width={1100}
        handleClose={handleClose}
      >
        <InstallmentCoursesPopup payId={payId} />
      </CustomModal>
    </>
  );
}
