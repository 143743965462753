import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  GetAuthState,
  GetUserName,
  toTitleCase,
} from "../../../helper/helperFunction";
import Form from "../../../components/forms/Form";
import { Avatar, Box, Grid, InputLabel, Typography } from "@mui/material";
import { Textarea } from "@mui/joy";
import Moment from "react-moment";
import styles from "./manage-complaints.module.scss";
export default function DetailsComplaints() {
  const params = useParams();

  const CURRENT_USER = GetAuthState("USER");

  const [data, setData] = useState<any>({});
  const [msgData, setMsgData] = useState<any>([]);
  const [formData, setFormData] = useState({
    message: "",
    user_id: CURRENT_USER.id,
  });

  const viewData = useCallback(async () => {
    if (params.id) {
      const res = await api.get(
        resolveUrl(AdminApi.COMPLAINTS_VIEW, params.id)
      );

      setData(res.data);
    }
  }, [params.id]);

  useEffect(() => {
    viewData();
  }, [viewData]);

  return (
    <Box className={styles.aTDMain}>
      <Grid container className={styles.aTTDContainer}>
        <Grid item xs={10} className={styles.aTTDContentType}>
          <Grid container className={styles.aTTDContentTypeContainer}>
            <Grid item xs={12} className={styles.aTTDContentTypeTitle}>
              <Typography
                variant="h4"
                className={styles.aTTDContentTypeTitleContent}
              >
                {data.title}
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.aTTDContentTypeName}>
              {data.complainant && (
                <>
                  <Typography
                    variant="body1"
                    className={styles.aTTDContentTypeContentText}
                  >
                    Complaint From
                  </Typography>

                  {/* <Avatar
                            sx={{ width: 27, height: 27, mr: 0 }}
                            className={styles.aTTDContentTypeContentAvator}
                            alt={GetUserName()}
                            src="/static/images/avatar/2.jpg"
                            /> */}
                </>
              )}
              {data.complainant && (
                <Typography
                  variant="body1"
                  className={styles.aTTDContentTypeContentName}
                >
                  {toTitleCase(
                    `${data.complainant.first_name} ${data.complainant.last_name}`
                  )}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              className={styles.aTTDContentTypeName}
              sx={{ marginTop: "20px" }}
            >
              {data.defendant && (
                <>
                  <Typography
                    variant="body1"
                    className={styles.aTTDContentTypeContentText}
                  >
                    Against
                  </Typography>

                  {/* <Avatar
                    sx={{ width: 27, height: 27, mr: 0 }}
                    className={styles.aTTDContentTypeContentAvator}
                    alt={GetUserName()}
                    src="/static/images/avatar/2.jpg"
                  /> */}
                </>
              )}
              {data.defendant && (
                <Typography
                  variant="body1"
                  className={styles.aTTDContentTypeContentName}
                >
                  {toTitleCase(
                    `${data.defendant.first_name} ${data.defendant.last_name}`
                  )}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        className={styles.aTTDContentTypeContentText}
        sx={{ marginTop: "20px", fontWeight: "700" }}
      >
        Description
      </Typography>

      <Grid container className={styles.aTTDDescription}>
        <Grid item xs={12} className={styles.aTTDDescriptionContent}>
          {data.complaint}
        </Grid>
      </Grid>
    </Box>
  );
}
