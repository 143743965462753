import React, { useState, useCallback, useEffect } from "react";
import Form from "../../../../components/forms/Form";
import { FormEvent } from "react";
import { Box, InputLabel, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Textarea from "@mui/joy/Textarea";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { useAuth } from "../../../../hooks/auth/auth";
import { useParams } from "react-router-dom";

interface ForumThread {
  topic: string;
  description: string;
  creatorId?: string;
  forumId?: string;
}
type Props = {
  closeModal: () => void;
  forumId: string;
  setreloadData: any;
  slug: string;
};
const AddThread: React.FC<Props> = ({
  closeModal,
  forumId,
  setreloadData,
  slug,
}) => {
  const user = useAuth();

  const [formData, setFormData] = useState<ForumThread>({
    topic: "",
    description: "",
    creatorId: user?.state?.user?.id,
    forumId: forumId,
  });
  const [ID, setID] = useState<number>();
  const [formSuccess, setFormSuccess] = useState(false);

  const params = useParams<{ forumSlug: string }>();

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    if (formSuccess) {
      closeModal();
      setreloadData((prevState: any) => !prevState);
    }
  }, [formSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.FORUM_THREADS_VIEW_UPDATE, slug)
        );
        const { description, topic, id, creator } = res.data.thread;
        setID(id);
        setFormData({
          topic: topic,
          description: description,
          creatorId: creator.id,
          forumId: forumId,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container className="page-container" sx={{ p: 0 }}>
        <Grid item xs={12} className="inner-container">
          <Form
            submitUrl={AdminApi.FORUM_THREAD_SAVE}
            formData={formData}
            successPath={`/admin/forum/${params.forumSlug}`}
            onSuccess={setFormSuccess}
            editId={ID}
          >
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2.5}>
                <InputLabel sx={{ textAlign: "left" }}>Topic</InputLabel>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  placeholder="Enter Topic"
                  name="topic"
                  variant="outlined"
                  onChange={handleOnChange}
                  value={formData.topic ?? ""}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container sx={{ mb: 3 }}>
              <Grid item xs={2.5}>
                <InputLabel sx={{ pt: 2 }}>Description</InputLabel>
              </Grid>
              <Grid item xs={9.5}>
                <Textarea
                  placeholder="Description"
                  name="description"
                  minRows={4}
                  maxRows={4}
                  onChange={handleOnChange}
                  value={formData.description ?? ""}
                />
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddThread;
