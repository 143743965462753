import { Box, Typography } from "@mui/material";
import styles from './course.module.scss'
import { useState } from "react";
import CourseDetailTable from "./course-detail";
import FilterBase from "../filter/filter-base";

type FilterState = {
    teacherId: any;
    status: string | null;
    searchQuery: string | null;
  };


const CoursePanel = () => {
    const [filters, setFilters] = useState<FilterState>();


    const handleFiltersChange = (filterState: FilterState) => {
      setFilters(filterState);    
    };

  return (
    <Box className={styles.coursePanelMain}>
        <Typography variant="h2" className={styles.coursePanelMainTitle}>Subjects</Typography>
        <FilterBase  onFiltersChange={handleFiltersChange} />
        <Box className={styles.coursePanelMainTable}>
            <CourseDetailTable />
        </Box> 
    </Box>
  );
};

export default CoursePanel;
