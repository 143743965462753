import Card from "../../../layout/admin/partials/Card";
import Degrecap from "../../../../src/static/images/degree-cap.png";
import { Grid } from "@mui/material";
import { GetUserRole } from "../../../helper/helperFunction";
import StudentDashboard from "../student-dashboard/dashboard/Student-dashboard-content";
import ParentDashboard from "../parent-dashbard/dashboard/Parent-dashboard-content";
import { useState, useEffect, useCallback } from "react";
import { AdminApi, api } from "../../../config/apis";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";

import TeacherDashboardContent from "../teacher-dashbaord/dashboard/Teacher-dashbaord";
import AdminDashboard from "../admin-dashboard/admin-dashboard";
import DashboardAdmins from "../other-user-dashboard";

export default function Dashboard<PROPS>({ ...props }: PROPS): JSX.Element {
  type CardType = { heading: any; paragraph: string; imageUrl: string };

  const [cardsData, setCardsData] = useState<null | CardType[]>([]);

  const editData = useCallback(async () => {
    const res = await api.get(AdminApi.DASHBOARD_STATS);
    if (res.data.status) {
      const cardData: CardType[] = [];
      if (res.data.data.courses) {
        cardData.push({
          heading: res.data.data.courses,
          paragraph: "Subjects",
          imageUrl: Degrecap,
        });
      }

      if (res.data.data.studentCourses) {
        cardData.push({
          heading: res.data.data.studentCourses,
          paragraph: "Subjects Enrolled",
          imageUrl: Degrecap,
        });
      }

      if (res.data.data.students) {
        cardData.push({
          heading: res.data.data.students,
          paragraph: "Students",
          imageUrl: Degrecap,
        });
      }

      if (res.data.data.teachers) {
        cardData.push({
          heading: res.data.data.teachers,
          paragraph: "Teachers",
          imageUrl: Degrecap,
        });
      }

      if (res.data.data.monthRevenue) {
        cardData.push({
          heading: `${CURRENCY_SYMBOL}${parseFloat(
            res.data.data.monthRevenue
          ).toLocaleString("en-US")}`,
          paragraph: "Month Revenue",
          imageUrl: Degrecap,
        });
      }

      if (res.data.data.totalRevenue) {
        cardData.push({
          heading: `${CURRENCY_SYMBOL}${parseFloat(
            res.data.data.totalRevenue
          ).toLocaleString("en-US")}`,
          paragraph: "Total Revenue",
          imageUrl: Degrecap,
        });
      }

      setCardsData(cardData);
    }
  }, []);

  useEffect(() => {
    editData();
  }, [editData]);

  if (GetUserRole() === "Super Admin") {
    return (
      <>
        <AdminDashboard />
      </>
    );
  } else if (GetUserRole() === "Parent") {
    return (
      <>
        <ParentDashboard />
      </>
    );
  } else if (GetUserRole() === "Student") {
    return (
      <>
        <StudentDashboard />
      </>
    );
  } else if (GetUserRole() === "Teacher") {
    return (
      <>
        <TeacherDashboardContent />
      </>
    );
  } else {
    return <DashboardAdmins />;
  }
}
