import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface CKEditorProps {
  initialValue: string;
  onReady?: (editor: any) => void;
  onChange?: (event: any, editor: any, data: string) => void;
}

const CKEditorComponent: React.FC<CKEditorProps> = ({
  initialValue,
  onReady,
  onChange,
}) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={initialValue}
      onReady={(editor) => {
        if (onReady) {
          onReady(editor);
        }
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        if (onChange) {
          onChange(event, editor, data);
        }
      }}
      config={{
        // Customize the toolbar items here
        toolbar: [
          "heading", // Include the heading option
          "|", // Add a separator
          "bold", // Include the bold option
          "italic", // Include the italic option
          "link", // Include the link option
          "bulletedList",
          "numberedList",
          "blockQuote",
          "undo",
          "redo",
        ],
      }}
    />
  );
};

export default CKEditorComponent;
