import React, { useEffect, useState } from "react";
import Switch from "@mui/joy/Switch";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { api, resolveUrl } from "../../config/apis";
import { toast } from "react-toastify";

const GlobalSwitchCell = ({
   id, 
   is_active, 
   EndPoint, 
   requestBody, 
   queryparams, 
   setReloadData,
   succesMsg,
   method,
  }:any) => {

  useEffect(() => {
    setChecked(is_active);
  }, [is_active]);

  const [checked, setChecked] = useState<any>(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const toggleConfirmDialog = () => {
    setOpenConfirm(!openConfirm);
  };

  const onActive = async () => {
    try {
      
          if (method === "GET") {
            const response = await api.get(resolveUrl(EndPoint, id));
            
            setChecked(!checked); 
            setOpenConfirm(false);
            
            if (response.status === 201 || response.status === 200) {
              toast.success(succesMsg ? succesMsg : response.data.message);
              setReloadData((prevState: any) => !prevState);
            }
          }else{
            const response = await api.post(
              resolveUrl(EndPoint, id),
              {
                params: {
                  ...queryparams,
                },
                ...requestBody ?? "", 
              }
            );
            
            setChecked(!checked); 
            setOpenConfirm(false);
            
            if (response.status === 201) {
              toast.success(succesMsg ? succesMsg : response.data.message);
              setReloadData((prevState: any) => !prevState);
            }
          }
    } catch (error:any) {
      setOpenConfirm(false);
      toast.error(error);
    }
  };

  return (
    <>
      <Switch
        checked={checked}
        onChange={toggleConfirmDialog} // Open confirmation dialog on switch change
        sx={{
          "--Switch-trackWidth": "40.49px",
          "--Switch-trackHeight": "20.41px",
          "--Switch-thumbSize": "14px",
          "& .MuiSwitch-thumb": {
            marginTop: "0.5px !important", 
          },
        }}
      />

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{ textAlign: "center", padding: "20px" }}
          className="action_btn"
        >
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {checked
                ? "Are you sure you want to inactive?"
                : "Are you sure you want to active?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button className="btn cancel" onClick={toggleConfirmDialog}>
              Cancel
            </Button>
            <Button className="btn save" onClick={onActive} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default GlobalSwitchCell;
