import { Box, Grid, InputLabel, TextField } from "@mui/material";
import styles from "./manage-catagories.module.css";
import { useState, useCallback, useEffect, FormEvent } from "react";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import Form from "../../../../components/forms/Form";
import * as Yup from "yup";

interface Props {
  id: string;
  setFormSuccess: any;
}
type AddCategoriesFormData = {
  name: string;
};

const AddCategoriesForm: React.FC<Props> = ({ id, setFormSuccess }) => {
  const [formData, setFormData] = useState<AddCategoriesFormData>({ name: "" });

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  // Fetch Form data

  const editData = useCallback(async () => {
    if (id) {
      const res = await api.get(resolveUrl(AdminApi.CATEGORY_VIEW, id));

      setFormData({
        name: res.data.category.name,
      });
    }
  }, [id]);

  // Use Effect Hook
  useEffect(() => {
    editData();
  }, [editData]);

  const handleFormSuccess = async (response: any) => {
    if (response) {
      setFormSuccess((prevState: any) => !prevState);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });
  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };
  return (
    <Box className={styles.discount}>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container" sx={{ p: 0 }}>
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.CATEGORY_SAVE}
              formData={formData}
              editId={id}
              successPath="/admin/forum/categories/view"
              onSuccess={handleFormSuccess}
              validate={customValidation}
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2.5}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Add Category
                  </InputLabel>
                </Grid>
                <Grid item xs={9.5}>
                  <TextField
                    fullWidth
                    placeholder="Enter category name"
                    value={formData.name}
                    name="name"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.name !== undefined}
                    helperText={errors.name}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddCategoriesForm;
