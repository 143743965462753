import Box from "@mui/material/Box";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GetUserId, getForumView } from "../../../../helper/helperFunction";
import React, { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import ActionButtons from "../../../../components/dataTables/ActionButtons";
import { api, resolveUrl, AdminApi } from "../../../../config/apis";
import FormModal from "./Modal";
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import styles from "../forum.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import Datatable from "../../../../components/dataTables/Datatable";
import { DateView } from "../../../../components/DateView/DateView";

interface Comment {
  // Define the properties of a comment
}

interface Like {
  // Define the properties of a like
}

interface Thread {
  id: number;
  creatorId: number;
  topic: string;
  slug: string;
  forumSlug: string;
  totalReplies: string;
  totalViews: string;
  description: string;
  comments: Comment[];
  likes: Like[];
  lastPostBy?: {
    first_name: string;
    createdDate: string;
  };
  creatorName: string;
}

interface ForumData {
  topic: string;
  description: string;
  type: string;
  status: boolean;
  moderate: boolean;
  tags: string[];
  thread: Thread[];
}

export default function ViewThreads() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [text, setText] = React.useState("");
  const [sort, setSort] = React.useState("asc");
  const [slug, setSlug] = React.useState<string>("");

  const handleStartDateChange = (value: Date | null) => {
    if (value) {
      const formattedDate = dayjs(value).format("YYYY-MM-DD");
      setFromDate(formattedDate);
    }
  };
  const handleDateToChange = (value: Date | null) => {
    if (value) {
      const formattedDate = dayjs(value).format("YYYY-MM-DD");
      setToDate(formattedDate);
    }
  };
  const [getResData, setResData] = useState<any>();

  const userId = GetUserId();

  const navigate = useNavigate();

  const params = useParams<{ forumSlug: string }>();

  const columns: TableColumn<Thread>[] = [
    {
      cell: (row: Thread) => (
        <div
          style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
          onClick={() => onView(row.slug, row.forumSlug)}
        >
          <Typography sx={{ fontWeight: "bold", lineHeight: "30px" }}>
            {row.topic}
          </Typography>
          <span>{row.creatorName}</span>
        </div>
      ),
      name: "Thread",
      sortable: false,
      width: "700px",
    },
    {
      selector: (row: Thread) => row.totalReplies || "-",
      name: "Replies",
      sortable: true,
    },
    {
      selector: (row: Thread) => row.totalViews || "-",
      name: "Views",
      sortable: false,
    },
    {
      cell: (row: Thread) =>
        row.lastPostBy && (
          <div style={{ whiteSpace: "pre-wrap" }}>
            {/* <span>Lorem ipsum</span> */}

            <span>
              <DateView date={row.lastPostBy.createdDate} />
            </span>
            <Typography sx={{ fontWeight: "bold", lineHeight: "23px" }}>
              by {row.lastPostBy.first_name}
            </Typography>
          </div>
        ),
      name: "Last Comment",
      sortable: false,
      width: "150px",
    },

    {
      name: "Actions",
      width: "100px",
      cell: (row: { id: number; creatorId: number; slug: string }) =>
        row.creatorId === userId ? (
          <ActionButtons
            id={row.id}
            onEdit={() => onEdit(row.slug)}
            actions={{
              view: false,
            }}
            onDelete={onDelete}
            module={"manage-forums"}
          />
        ) : null,
    },
  ];
  const onEdit = async (slug: string) => {
    setSlug(slug);
    toggleModal();
  };

  const onDelete = async (id: string) => {
    const res = await api.delete(resolveUrl(AdminApi.FORUM_THREAD_DELETE, id));
    if (res.data.status) {
      refresh();
    }
    return res;
  };

  const onView = async (slug: string, forumSlug: string) => {
    navigate(`/admin/forum/${forumSlug}/${slug}`);
  };
  const [reloadData, setReloadData] = useState(false);

  const refresh = () => {
    setReloadData((prevReloadData) => !prevReloadData);
  };

  return (
    <>
      <Box
        className="course-content"
        display={"flex"}
        justifyContent={"space-between"}
      >
        <h1 className="main-heading">Threads</h1>
        <Box
          sx={{ p: 0 }}
          className={` ${styles.searchBy} inner-container btn-wrapper`}
          justifyContent={"flex-end"}
        >
          <Link className={styles.Post_a_thread} to={"#"} onClick={toggleModal}>
            Post A thread
          </Link>
        </Box>
      </Box>

      <Box className="inner-container search_filter_yi" sx={{ mb: 4, p: "0" }}>
        <form>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item xs={8}>
              <Box className={`${styles.searchBy} ${styles.searchBy_left_box}`}>
                <Box className={styles.search_by}>
                  <InputLabel>Search By</InputLabel>
                </Box>
                <Box className={`${styles.searchBy}`}>
                  <Box className={styles.select_filter}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // label="Date from"

                        onChange={handleStartDateChange}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box className={styles.select_filter}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // label="Date to"
                        // placeholder="Date to"
                        onChange={handleDateToChange}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box className={styles.select_filter}>
                    <Select
                      fullWidth
                      name="sort"
                      value={sort}
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <MenuItem value="asc">Ascending</MenuItem>
                      <MenuItem value="desc">Descending</MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                sx={{ p: 0 }}
                className={` ${styles.searchBy} ${styles.searchByRight} inner-container btn-wrapper`}
              >
                <TextField
                  id="coursetitle"
                  variant="outlined"
                  required
                  fullWidth
                  name="search"
                  onChange={(e) => setText(e.target.value)}
                />
                <Link to={"#"}>Go</Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>

      <div className="page-container Forums">
        <Datatable
          tableColumns={columns}
          dataUrl={`${AdminApi.FORUM_THREADS_VIEW}/${params.forumSlug}`}
          module="manage-forums"
          actionColumns={false}
          getResData={setResData}
          refreshHandler={reloadData}
        />
      </div>
      <FormModal
        forumId={getResData?.forumId}
        open={isModalOpen}
        closeModal={toggleModal}
        setreloadData={setReloadData}
        slug={slug}
      />
    </>
  );
}
