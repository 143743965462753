import { Box, Button, Grid } from "@mui/material";
import { StudentProgress } from "./Student-progress";
import StudentDetails from "./students-details/details";
import { LecturesChart } from "./lecture-chart";
import { StudentActivityChart } from "./student-activity-log";
import { useLocation, useParams } from "react-router-dom";
import StudentQuizesTable from "./student-quizes";
import StudentAssignmentTable from "./student-assignment";
import StudentCourseList from "./student-courses";
import Strength from "./feedback/strength";
import AreasOfImprovements from "./feedback/areasOfimprovements";
import TeacherAssignmentFeedback from "./feedback/teachersFeedback";
import { Margin, usePDF } from "react-to-pdf";
import {
  GetAuthState,
  GetUserRole,
  toTitleCase,
} from "../../../helper/helperFunction";
import { SingleCourseProgress } from "./Student-progress/single-subjet-progress";
import { useEffect, useState } from "react";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AllCoursesReports = () => {
  const CURRENT_USER = GetAuthState("USER");
  const isStudent = GetUserRole() === "Student";
  const { id } = useParams();

  const paramId = isStudent ? CURRENT_USER : id;

  const { toPDF, targetRef } = usePDF({
    filename: "Report.pdf",
    page: { margin: Margin.SMALL },
  });

  const [triggerDownload, setTriggerDownload] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTriggerDownload(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (triggerDownload) {
      toPDF();
    }
  }, [triggerDownload]);

  const query = useQuery();

  const courseId = query.get("courseId") ? +query.get("courseId")! : null;
  const courseName = query.get("courseName");

  useEffect(() => {
    document.body.classList.add("student-report-pdf");
    return () => {
      document.body.classList.remove("student-report-pdf");
    };
  }, []);

  return (
    <>
      <Button
        onClick={() => toPDF()}
        className={`btn ${courseId ? "btn_single_subject" : ""}`}
        variant="contained"
      >
        Download Report
      </Button>
      <Box ref={targetRef} sx={{ backgroundColor: "#faf6ec" }} p={2}>
        <Box
          className="course-content"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: courseId ? 3 : "",
          }}
        >
          <h1 style={{ margin: "0 auto 0 0" }} className="main-heading">
            {courseName && toTitleCase(courseName)}
          </h1>
        </Box>
        <Grid container spacing={2} className="student_report_pdf">
          <Grid item xs={12}>
            {courseId ? (
              <SingleCourseProgress courseId={courseId} paramId={paramId} />
            ) : (
              <StudentProgress paramId={paramId} />
            )}
          </Grid>
          <Grid item xs={12}>
            <StudentDetails paramId={paramId} />
          </Grid>
          <Grid item xs={6}>
            <LecturesChart courseId={courseId} paramId={paramId} />
          </Grid>
          <Grid item xs={6}>
            <StudentActivityChart courseId={courseId} paramId={paramId} />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Strength courseId={courseId} paramId={paramId} />
          </Grid>
          {/* <Grid item xs={12} style={{ paddingTop: 0 }}>
            <AreasOfImprovements courseId={courseId} paramId={paramId} />
          </Grid> */}
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <TeacherAssignmentFeedback courseId={courseId} paramId={paramId} />
          </Grid>

          {!courseId && (
            <Grid item xs={12}>
              <Box>
                <StudentCourseList courseId={courseId} paramId={paramId} />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <StudentAssignmentTable courseId={courseId} paramId={paramId} />
          </Grid>
          <Grid item xs={12}>
            <StudentQuizesTable courseId={courseId} paramId={paramId} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AllCoursesReports;
