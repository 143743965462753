import * as Yup from "yup";
const phoneRegExp = /^\+?[0-9]{6,20}$/; // Example regular expression for numeric phone numbers with optional plus sign
const minLength = 8; // Minimum allowed length
const maxLength = 15; // Maximum allowed length
const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[^\s]*$/, "Spaces are not allowed in the first name"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[^\s]*$/, "Spaces are not allowed in the last name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  address: Yup.string().required("Street address is required"),
  country_id: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string()
    .matches(/^(?![0-9]+$)[a-zA-Z0-9\s]+$/, "City cannot be only numbers")
    .required("City is required"),
  zipCode: Yup.string().required("Zip code is required"),
  about_user: Yup.string().required("About Teacher is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .min(minLength, `Phone number must be at least ${minLength} characters`)
    .max(maxLength, `Phone number can't exceed ${maxLength} characters`)
    .required("Phone number is required"),
});

export default validationSchema;
