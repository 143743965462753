import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { DateView } from "../../../components/DateView/DateView";
import { truncateTextWithEllipsis } from "../../../helper/helperFunction";

export default function ViewQuiz() {
  const navigate = useNavigate();

  const tableColumns = [
    {
      selector: (row: { name: string }) => row.name,
      name: "Quiz Title",
      sortable: true,
      width: "200px",
    },
    {
      selector: (row: { created_at: string }) => (
        <DateView date={row.created_at} />
      ),
      name: "Created At",
      sortable: false,
      width: "150px",
    },
    {
      selector: (row: { description: string }) =>
        truncateTextWithEllipsis(row.description, 100),
      name: "Description",
      sortable: false,
    },
  ];

  const onEdit = async (id: string) => {
    navigate(`/admin/quiz/edit/${id}`);
  };

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.QUIZ_DELETE, id));
  };

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Quiz</h1>
        <Box className="btn-wrapper">
          <Link to={"edit"}>Add a Quiz</Link>
        </Box>
      </div>

      <div className="page-container">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.QUIZ_PAGINATE}
          onEdit={onEdit}
          onDelete={onDelete}
          actions={{
            view: false,
          }}
          module="manage-quizes"
        />
      </div>
    </>
  );
}
