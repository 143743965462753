import { useEffect, useState } from "react";
import styles from "./filter.module.scss";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

interface FilterProps {
  onFiltersChange: (filters: FilterState) => void;
}

const Filter: React.FC<FilterProps> = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState<FilterState>({
    teacherId: null,
    status: null,
    searchQuery: null,
  });

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters, onFiltersChange]);

  // Level
  const [filterData, setFilterData] = useState<FilterState>({
    teacherId: null,
    status: "lvl1",
    searchQuery: null,
  });

  // Place
  const [filterDataPlace, setFilterDataPlace] = useState<FilterState>({
    teacherId: null,
    status: "Place1",
    searchQuery: null,
  });

  const [order, setOrder] = useState("descending");
  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      order,
    }));
  }, [order]);

  return (
    <>
      <form className={styles.studentTopFilterMain}>
        <Typography
          variant="body1"
          className={styles.studentTopFilterMainTitle}
        >
          Search By
        </Typography>
        <FormControl className={styles.studentTopFilterId}>
          <TextField
            id="coursetitle"
            variant="outlined"
            fullWidth
            placeholder="ID"
            className={styles.studentTopFilterIdInput}
          />
        </FormControl>
        <FormControl className={styles.studentTopFilterLvl}>
          <Select
            onChange={(event) =>
              setFilterData((prevState: FilterState) => {
                return {
                  ...prevState,
                  status: event.target.value,
                };
              })
            }
            className={styles.studentTopFilterLvlInput}
            value={filterData.status}
          >
            <MenuItem value="lvl1">Level 1</MenuItem>
            <MenuItem value="lvl2">Level 2</MenuItem>
            <MenuItem value="lvl3">Level 3</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={styles.studentTopFilterPlace}>
          <Select
            onChange={(event) =>
              setFilterDataPlace((prevState: FilterState) => {
                return {
                  ...prevState,
                  status: event.target.value,
                };
              })
            }
            className={styles.studentTopFilterPlaceInput}
            value={filterDataPlace.status}
          >
            <MenuItem value="Place1">Place</MenuItem>
            <MenuItem value="Place2">Place</MenuItem>
            <MenuItem value="Place3">Place</MenuItem>
          </Select>
        </FormControl>
        <Box className={styles.studentTopFilterKeyword}>
          <TextField
            id="coursetitle"
            variant="outlined"
            fullWidth
            className={styles.studentTopFilterKeywordInput}
            onChange={(event) =>
              setFilterData((prevState: FilterState) => {
                return {
                  ...prevState,
                  searchQuery: event.target.value,
                };
              })
            }
          />
          <Box className={styles.studentTopFilterBtn}>
            <Button
              className={styles.studentTopFilterBtnContent}
              onClick={() =>
                setFilters((prevState: FilterState) => {
                  return {
                    ...prevState,
                    ...filterData,
                  };
                })
              }
            >
              Go
            </Button>
          </Box>
        </Box>
        <FormControl className={styles.studentTopFilterAccen}>
          <Select
            className={styles.studentTopFilterAccenInput}
            value={order}
            onChange={(event) => setOrder(event.target.value)}
          >
            <MenuItem value="ascending" selected>
              Ascending
            </MenuItem>
            <MenuItem value="descending">Descending</MenuItem>
          </Select>
        </FormControl>
      </form>
    </>
  );
};

export default Filter;
