import DynamicTabs from "../../../components/tabs/Tabs";
import CourseCard from "./CourseCard";
import CourseInfo from "./CourseInfo";
import Content from "./content/Content";
import { Typography } from "@mui/material";
import { getCourseView } from "../../../helper/helperFunction";
import { CourseData } from "../../../Type/courseTypes";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import Videoplay from "../../../../src/static/images/videoplay.png";
import Enrolledstudents from "./Enrolledstudents/Enrolledstudents";
import ViewQuizes from "./Quizes/Quizes";
import ViewAssignment from "./Assignment/Assignment";
import FormLoader from "../../../components/loader/FormLoader";

const DEFAULT_COURSE_VIEW = {
  name: "",
  have_live_quiz: false,
  price: "",
  year: "",
  description: "",
  sections: [],
  teacherId: "",
  featured_image: "",
  teacher: {},
};

function VeiwDetail() {
  const params = useParams<{ id: string }>();
  const [courseViewData, setCourseViewData] =
    React.useState<any>(DEFAULT_COURSE_VIEW);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const fetchCourseViewData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getCourseView(params.id ?? "");

      setCourseViewData({
        name: res.course.name,
        description: res.course.description,
        have_live_quiz: res.course.have_live_quiz,
        price: res.course.price,
        year: res.course.year,
        sections: res.course.section,
        teacher: res.course.teacher,
        featured_image: res.course.featured_image,
        status: res.course.status,
        teacherProfile: res.course.teacherProfile,
      });
      setIsDataFetched(true);
    } catch (error) {
      // Handle error here
    } finally {
      setIsLoading(false);
    }
  }, [params.id]);

  useEffect(() => {
    if (!isDataFetched) {
      fetchCourseViewData();
    }
  }, [fetchCourseViewData, isDataFetched]);

  const getFileUrl = (filePath?: string) => {
    const { REACT_APP_SERVER_BASE } = process.env;
    const baseUrl = REACT_APP_SERVER_BASE;
    const fileUrl = filePath && `${filePath.replace("\\", "/")}`;

    return fileUrl;
  };

  const fileUrl = getFileUrl(courseViewData.featured_image);

  const tabs = [
    {
      label: "Content",
      content: <Content courseData={courseViewData} />,
    },
    {
      label: "Enrolled Students",
      content: <Enrolledstudents />,
    },
    {
      label: "Quizzes",
      content: <ViewQuizes />,
    },
    {
      label: "Assignment",
      content: <ViewAssignment />,
    },
  ];

  if (isLoading) {
    return isLoading && <FormLoader />;
  }

  return (
    <>
      <div className="subject_detail_page">
        <h1 className="main-heading">Subjects</h1>
        <CourseInfo
          video={fileUrl ?? Videoplay}
          title={courseViewData.name}
          content={courseViewData.description}
          instructor={`${courseViewData.teacher.first_name} ${courseViewData.teacher.last_name}`}
          status={courseViewData.status}
          profile_image={courseViewData.teacherProfile}
        />
        <CourseCard />
        <DynamicTabs tabs={tabs} />
      </div>
    </>
  );
}

export default VeiwDetail;
