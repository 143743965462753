import { FormEvent, useCallback, useEffect, useState } from "react";
import { GetAuthState, toTitleCase } from "../../../helper/helperFunction";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import { useParams } from "react-router-dom";
import styles from "./adminSupport.module.scss";
import * as Yup from "yup";

export default function EditAdminSupport() {
  const params = useParams();

  type FormData = {
    title: string;
    description: string;
    type: string;
    priority: string;
    user_id: string;
    status: string;
  };

  const CURRENT_USER = GetAuthState("USER");

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    type: "general",
    priority: "low",
    user_id: CURRENT_USER.id,
    status: "active",
  });

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const selectedValue = event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: selectedValue,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };
  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const viewData = useCallback(async () => {
    if (params.id) {
      const res = await api.get(resolveUrl(AdminApi.SUPPORT_VIEW, params.id));

      const { priority, status, title, description, user } =
        res.data.adminSupport;

      setFormData({
        title: title,
        description: description,
        type: "general",
        priority: priority,
        user_id: user.id,
        status: status,
      });
    }
  }, [params.id]);

  useEffect(() => {
    viewData();
  }, [viewData]);

  return (
    <Box className={styles.aSCreateTicketMain}>
      <Typography variant="h2" className={styles.aSCTTitle}>
        {!params.id ? "Create New Ticket" : "Update Ticket"}
      </Typography>
      <Box className={styles.aSCTContainer}>
        <Form
          submitUrl={AdminApi.SUPPORT_EDIT}
          formData={formData}
          editId={params.id}
          successPath="/admin/support"
          validate={customValidation}
        >
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Title
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Title"
              value={formData.title}
              name="title"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.title !== undefined}
              helperText={errors.title}
            />
          </Box>
          <Box className={styles.aSCTContentDesc}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentDescLabel}
            >
              Description
            </InputLabel>
            <Textarea
              placeholder="Description"
              name="description"
              value={formData.description}
              minRows={4}
              maxRows={4}
              onChange={handleOnChange}
              className={styles.aSCTContentDescInput}
            />
            {errors.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Box>
          <Box className={styles.aSCTContentType} marginBottom={2}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTypeLabel}
            >
              Type
            </InputLabel>
            <Select
              fullWidth
              name="type"
              sx={{ Width: "100%" }}
              onChange={handleSelectChange}
              defaultValue="slected"
              value={formData.type}
              className={styles.aSCTContentTypeInput}
            >
              {["general", "Subjects", "payment"].map((items: any, i) => {
                return (
                  <MenuItem key={i} value={items}>
                    {toTitleCase(items)}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box className={styles.aSCTContentType}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTypeLabel}
            >
              Priority
            </InputLabel>
            <Select
              fullWidth
              name="priority"
              sx={{ Width: "100%" }}
              onChange={handleSelectChange}
              defaultValue="slected"
              value={formData.priority}
              className={styles.aSCTContentTypeInput}
            >
              {["low", "medium", "high"].map((items: any, i) => {
                return (
                  <MenuItem key={i} value={items}>
                    {toTitleCase(items)}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          {params.id && (
            <Box className={styles.aSCTContentType} mt={2}>
              <InputLabel
                sx={{ textAlign: "left" }}
                className={styles.aSCTContentTypeLabel}
              >
                Status
              </InputLabel>
              <Select
                fullWidth
                name="status"
                sx={{ Width: "100%" }}
                onChange={handleSelectChange}
                defaultValue="slected"
                value={formData.status}
                className={styles.aSCTContentTypeInput}
              >
                {["active", "closed", "completed"].map((items: any, i) => {
                  return (
                    <MenuItem key={i} value={items}>
                      {toTitleCase(items)}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          )}
        </Form>
      </Box>
    </Box>
  );
}
