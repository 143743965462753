import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import DynamicMeta from "../manage-meta-tag/dynamic-meta";
import BannerCms from "../Courses-banner";
import CourseHeading from "../manage-IGCSE-content/AddSectionTitle";

const SecondaryKS3 = (() => {
    const tabs = [
        {
            label: 'Secondary-KS3 Banner',
            content: <BannerCms heading={"Secondary-KS3 Banner"} type="Secondary-KS3"/>,
        },
        {
            label: 'Secondary-KS3 Content',
            content: <CourseHeading type="Secondary-KS3"/>,
        },
        {
            label: "Secondary-KS3 Meta Tag",
            content: <DynamicMeta type="Secondary-KS3" heding="Secondary-KS3 Meta"/>,
        },
    ];

    return (
        <>
            <h1>Secondary-KS3</h1>
            <Box className="setting-tabs-container">
                <DynamicTabs tabs={tabs} />
            </Box>
        </>
    )
})
export default SecondaryKS3