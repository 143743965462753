import React, { useEffect, useState } from "react";
import styles from "./manage-kids.module.scss";
import {
  Typography,
  Button,
  Card,
  CardContent,
  Avatar,
  Box,
  Checkbox,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../../../../hooks/auth/auth";
import { getStudentData } from "../../../../../helper/helperFunction";
import { useNavigate } from "react-router-dom";
import { SERVER_KEY } from "../../../../../config/constants/EnviormentVariables";

interface Student {
  profile_picture?: string;
  id: number;
  first_name: string;
  last_name: string;
}

const StudentCard: React.FC = () => {
  const [studentData, setStudentData] = useState<Student[] | null>(null);

  const navigate = useNavigate();

  const Parent = useAuth();
  const id = Parent.state?.user.id;
  useEffect(() => {
    async function fetchStudentData() {
      const data = await getStudentData(id);
      setStudentData(data);
    }

    fetchStudentData();
  }, [id]);

  const onView = async (id: number) => {
    navigate(`/admin/student/view/${id}`);
  };

  if (!studentData) {
    return (
      <Box className="loader" sx={{ display: "flex", position: "static" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={styles.studentProfileList}>
      {studentData.map((student) => (
        <Card
          key={student.id}
          sx={{ maxWidth: 345, marginRight: 3, marginBottom: 3 }}
          className={styles.studentCardBx}
        >
          <CardContent className={styles.studentCardContent}>
            <Avatar
              src={`${SERVER_KEY}${student.profile_picture}`}
              className={styles.stuProfile}
            />
            <Typography variant="h4" gutterBottom className={styles.stuName}>
              {student.first_name} {student.last_name}
            </Typography>
            {/* <Typography variant="body1" className={styles.stuLastActive}>
              <b>Last Active</b> 1 hr ago
            </Typography> */}
          </CardContent>
          <div className={styles.stuViewDetailBtn}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onView(student.id)}
              className={styles.stuViewBtn}
            >
              View details
            </Button>
          </div>
        </Card>
      ))}
    </Box>
  );
};

export default StudentCard;
