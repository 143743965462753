import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("This field is required"),
  name: Yup.string().required("This field is required"),
  start_date: Yup.string().required("This field is required"),
  end_date: Yup.string().required("This field is required"),
  code: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),

  discount: Yup.number()
    .required("This field is required")
    .test(
      "is-valid-discount",
      "Discount must be between 1 and 100 for percentage type",
      function (value) {
        const { discount_type } = this.parent;
        if (discount_type === "percent") {
          return value > 0 && value <= 100;
        }
        return true;
      }
    ),
  use_type: Yup.string().required("This field is required"),

  count: Yup.string().test({
    test: function (value) {
      return this.parent.use_type === "multiple" ? !!value : true;
    },
    message: "This field is required",
  }),
  // discount_for_value: Yup.array()
  //   .min(1, "At least one item is required")
  //   .test({
  //     test: function (value) {
  //       return this.parent.discount_for === "selected_course" ? !!value : true;
  //     },
  //     message: "This field is required",
  //   }),

  discount_for_value: Yup.array().test({
    test: function (value: any) {
      return this.parent.discount_for === "selected_course"
        ? !!value.length
        : true;
    },
    message: "Subject is required",
  }),
});

export default validationSchema;
