import style from "../../teacherDashboard.module.scss";
import {Card, CardContent, Grid, Paper, Typography } from "@mui/material";


const CourseScoreAvgComp = () => {
  return (
    <Card className={style.scoreMain}>
      <CardContent className={style.scoreMainContent}>
        <Grid container spacing={"30px"} className={style.scoreGrid}>
          <Grid item xs={12} sm={12} md={12} lg={4} className={style.scoreCard}>
            <Paper className={style.scoreCardContent}>
              <Typography variant="h6" className={style.scoreTitle}>Learner Enrolled</Typography>
              <Typography variant="body1" className={style.scoreScore}>50</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} className={style.scoreCard}>
            <Paper className={style.scoreCardContent}>
              <Typography variant="h6" className={style.scoreTitle}>Active Learners</Typography>
              <Typography variant="body1" className={style.scoreScore}>10</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} className={style.scoreCard}>
            <Paper className={style.scoreCardContent}>
              <Typography variant="h6" className={style.scoreTitle}>completed</Typography>
              <Typography variant="body1" className={style.scoreScore}>150</Typography>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CourseScoreAvgComp;

