import { Box, Modal } from "@mui/material";
import styles from "../forum.module.css";
import AddThread from "./add";

type Props = {
  open: boolean;
  closeModal: () => void;
  forumId: string;
  slug: string;
  setreloadData: any;
};

const style = {
  width: 719,
};

const FormModal: React.FC<Props> = ({
  open,
  closeModal,
  forumId,
  setreloadData,
  slug,
}) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={style} className={styles.popup}>
        <button onClick={closeModal} style={{ opacity: 0 }}>
          Close
        </button>
        <AddThread
          closeModal={closeModal}
          forumId={forumId}
          setreloadData={setreloadData}
          slug={slug}
        />
      </Box>
    </Modal>
  );
};

export default FormModal;
