import { Grid } from "@mui/material";
import { truncateTextWithEllipsis } from "../../../../../helper/helperFunction";
import { FC } from "react";
import { Button } from "react-bootstrap";
import AssignIcon from "../../../../../static/images/summary.png";
import EditIcon from "../../../../../static/images/edit.jpg";
import DeleteIcon from "../../../../../static/images/trash.jpg";
import { ContentTypeProp } from "./ContentProps";

const Assignment: FC<ContentTypeProp> = function ({
  contentIndex,
  content,
  sectionIndex,
  editContent,
  removeContent,
  editDisabled,
}) {
  return (
    <Grid
      className="single-lesson"
      container
      alignItems="center"
      justifyContent="space-between"
      key={contentIndex}
    >
      <Grid item>
        <h2>
          <span>Part {contentIndex + 1}: </span>
          <img src={AssignIcon} alt="icon" />
          <p>{truncateTextWithEllipsis(content.textData, 50)}</p>
        </h2>
      </Grid>
      <Grid item>
        <Button
          className="course-btn"
          variant="contained"
          disabled={editDisabled}
          style={editDisabled ? { opacity: 0.5 } : {}}
          onClick={(e) =>
            editContent(
              e,
              sectionIndex,
              "assignment",
              contentIndex,
              true,
              content.textData,
              content.textDataContent,
              content.points,
              undefined,
              content
            )
          }
        >
          <span className="tooltip">Edit Assignment</span>
          <img src={EditIcon} alt="icon" />
        </Button>
        <Button
          className="course-btn"
          variant="contained"
          disabled={editDisabled}
          style={editDisabled ? { opacity: 0.5 } : {}}
          onClick={(e) =>
            removeContent(e, "content", contentIndex, sectionIndex)
          }
        >
          <span className="tooltip">Remove Assignment</span>
          <img src={DeleteIcon} alt="icon" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Assignment;
