import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import styles from "./studentAssignments.module.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BucketFileViewer from "../../../../components/buketFileViewer";
import { AdminApi } from "../../../../config/apis/admin";
import { api, resolveUrl } from "../../../../config/apis";

interface PrivateComment {
  commentId: number;
  comment: string;
  first_name: string;
  last_name: string;
  created_at: string;
  role: string;
  commentCreatedDate: Date;
}

interface StudentAssignmentMeta {
  file_path?: string;
  file_name?: string;
}

export interface Assignment {
  description: string;
  title: string;
  points: string;
  file: string;
  courseName: string;
}
const AssignmentsDetail = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(true);

  const params = useParams();
  const [assignment, setAssignment] = useState<Assignment>();
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    const getAssignments = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.COUSES_VIEW_ASSIGNMENT, `${params.id}`)
        );

        setAssignment(res.data);

        setIsDataLoaded(false);
      } catch (error) {
        console.error(error);
      }
    };

    getAssignments();
  }, [params.id, formSuccess]);

  return (
    <>
      {isDataLoaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 350,
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      ) : (
        <Box className={styles.assignmentDetailPanel}>
          <Grid container className={styles.assignmentDetailPanelTitle}>
            <Grid
              item
              xs={12}
              className={styles.assignmentDetailPanelTitleHead}
            >
              <Typography
                variant="h2"
                className={styles.assignmentDetailPanelTitleHeadContent}
              >
                Assignment Detail
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.assignmentDetailPanelTitleSub}>
              <Typography
                variant="h4"
                className={styles.assignmentDetailPanelTitleSubContent}
              >
                {assignment?.courseName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={styles.assignmentDetailPanelContent}>
            <Box className={styles.studentAssignmentDetail}>
              {/* Top Head Layout */}
              <Grid container className={styles.ASDHeading}>
                <Grid
                  item
                  xs={12}
                  lg={9}
                  className={styles.ASDHeadingTitleContent}
                >
                  <Typography
                    variant="h2"
                    className={styles.ASDHeadingTitleText}
                  >
                    {assignment?.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={3}
                  className={styles.ASDHeadingPointsContent}
                >
                  {
                    <Typography
                      variant="body1"
                      className={styles.ASDHeadingPointsText}
                    >
                      {assignment?.points} Points
                    </Typography>
                  }
                </Grid>
              </Grid>
              <Grid container className={styles.ASDDetail} spacing={"60px"}>
                <Grid item xs={12} lg={8} className={styles.ASDDetailContent}>
                  <Box
                    className={styles.studentAssignmentBtnView}
                    sx={{ marginBottom: "10px" }}
                  >
                    <BucketFileViewer
                      name={"Download file"}
                      filePath={assignment?.file}
                    />
                  </Box>

                  <Typography
                    variant="body1"
                    className={styles.ASDDetailContentText}
                  >
                    {assignment?.description}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AssignmentsDetail;
