import {
  Box,
  FormControl,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { AdminApi, api, resolveUrl } from "../../../../../config/apis";
import { Link, useParams } from "react-router-dom";

const StudentQuizesAnswer = () => {
  const { id } = useParams();
  const [quizData, setQuizData] = useState([]);
  const [courseSlug, setCourseSlug] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(
          resolveUrl(AdminApi.GET_QUIZ_RESULT, `${id}`)
        );
        setQuizData(response.data.afterResult);
        setCourseSlug(response.data.courseSlug);
        setLoading(false);
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      }
    };

    getData();
  }, [id]);

  if (loading) {
    return (
      <Box
        className="loader"
        sx={{
          display: "flex",
          height: "50vh",
          position: "static",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Box className="quiz_result_detail">
        <Grid item container xs={12}>
          {quizData.map((questionData: any, index: any) => (
            <Question key={index} questionData={questionData} count={index} />
          ))}

          <Box className="btn-wrapper" width={"100%"} marginY={4}>
            <Link to={`/admin/student-courses/view/${courseSlug}`}>Back</Link>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default StudentQuizesAnswer;

const Question = ({ questionData, count }: any) => {
  const { question, answer, quiz_index, student_index, markable } =
    questionData;

  const answerOptions = answer.replace(/^\[|\]$/g, "").split(",");

  return (
    <>
      <Grid item xs={12} className="question_wrap">
        <Typography variant="h2">
          {count + 1 + "."} {question}
          <span className="slected" style={{ color: "red", fontSize: "15px" }}>
            {markable ? "" : " (Not marked due to late submission)"}
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ padding: "20px 0" }}>
        {answerOptions.map((option: string, index: number) => (
          <Box key={index}>
            <FormControl component="fieldset">
              <FormControlLabel
                className={quiz_index.includes(index) ? "corect" : "incorrect"}
                key={index}
                label={option}
                control={<Checkbox checked={quiz_index.includes(index)} />}
              />
              {student_index.includes(index) && (
                <span className="slected"> (Your answer)</span>
              )}
              {/* {quiz_index.includes(index) && <span> (Correct)</span>} */}
            </FormControl>
          </Box>
        ))}
      </Grid>
    </>
  );
};
