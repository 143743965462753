import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { toTitleCase } from "../../../../helper/helperFunction";
import { DateView } from "../../../../components/DateView/DateView";
import { Box } from "@mui/material";
import { useState } from "react";

type props = {
  courseId?: number | null;
  paramId?: number | null;
};

const StudentAssignmentTable = ({ courseId, paramId }: props) => {
  const [dataAvailable, setDataAvailable] = useState(true);

  const tableColumns = [
    {
      selector: (row: any) => toTitleCase(row.assignmentTitle ?? "-"),
      name: "Assignment Title",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row: any) => toTitleCase(row.courseName ?? "-"),
      name: "Course Name",
      sortable: true,
      minWidth: "200px",
    },
    {
      cell: (row: { createdAt: string }) =>
        row.createdAt && <DateView date={row.createdAt} />,
      name: "Date",
    },
    {
      cell: (row: { isSubmitted: boolean }) => (
        <>
          {row.isSubmitted === !true && toTitleCase("Active")}
          {row.isSubmitted === true && toTitleCase("Submitted")}
        </>
      ),
      name: "Status",
    },
    {
      cell: (row: {
        totalMarks: string | null;
        obtainedMarks: string | null;
      }) => (
        <>{`${row.obtainedMarks ? row.obtainedMarks : "-"}/${
          row.totalMarks ? row.totalMarks : "0"
        }`}</>
      ),
      name: "Score",
    },
  ];

  const param = {
    courseId: courseId,
  };
  // Callback function to handle data availability
  const handleDataAvailable = (isAvailable: boolean) => {
    setDataAvailable(isAvailable);
  };

  return (
    <>
      {dataAvailable && (
        <Box className="page-container" style={{ marginBottom: 0 }} mt={2}>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={resolveUrl(
              AdminApi.STUDENT_ASSIGN_ASSIGNMENTS_PDF,
              `${paramId}`
            )}
            actionColumns={false}
            actions={{
              view: false,
              edit: false,
              delete: false,
            }}
            dataFilter={param}
            pagination={false}
            onDataAvailable={handleDataAvailable}
            module="manage-kids"
          />
        </Box>
      )}
    </>
  );
};

export default StudentAssignmentTable;
