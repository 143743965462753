import styles from "./studnetCourses.module.scss";
import { Grid, Typography, Avatar } from "@mui/material";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi } from "../../../../config/apis/admin";
import { resolveUrl } from "../../../../config/apis";
import { GetUserName } from "../../../../helper/helperFunction";
import ProgressCourseCell from "../../../../components/ProgressBarSubject";
import { useState } from "react";

interface Course {
  name: string;
  status: string;
  version: number;
  progress: number;
  totalLessons: number;
  completedLesson: number;
  teacherName: string;
  teacherProfile: string;
  courseImage: string;
}

type props = {
  courseId?: number | null;
  paramId?: number | null;
};

const StudentCourseList = ({ courseId, paramId }: props) => {
  const [dataAvailable, setDataAvailable] = useState(true);
  const { REACT_APP_SERVER_BASE } = process.env;
  const tableColumns = [
    {
      cell: (row: any) => (
        <>
          <ProgressCourseCell
            name={row.name}
            progress={row.progress != null ? row.progress : 0}
            image={row.courseImage}
          />
        </>
      ),
      name: "Subject Name",
      sortable: false,
      minWidth: "350px",
    },

    {
      cell: (row: Course) => (
        <Grid container className={styles.courseTableColAssignTeacher}>
          <Grid
            item
            className={styles.courseTableColAssignTeacherContentAvatarContent}
          >
            <Avatar
              className={styles.courseTableColAssignTeacherContentAvatar}
              sx={{ width: 34, height: 34, mr: 0 }}
              alt={GetUserName()}
              src={`${REACT_APP_SERVER_BASE}${row.teacherProfile}`}
            />
          </Grid>
          <Grid
            item
            className={styles.courseTableColAssignTeacherContentNameContent}
          >
            <Typography
              variant="h4"
              className={styles.courseTableColAssignTeacherContentName}
            >
              {row.teacherName}
            </Typography>
          </Grid>
        </Grid>
      ),

      name: "Assigned Teacher",
      minWidth: "250px",
      sortable: false,
    },
    {
      cell: (row: Course) => (
        <Grid container className={styles.courseTableColTotalLessons}>
          <Grid item className={styles.courseTableColTotalLessonsContent}>
            <Typography
              variant="h4"
              className={styles.courseTableColTotalLessonsContentText}
            >
              {row.completedLesson}
            </Typography>
          </Grid>
        </Grid>
      ),

      name: "Completed Lessons",
      minWidth: "180px",
      sortable: false,
      center: "yes",
    },
    {
      cell: (row: Course) => (
        <Grid container className={styles.courseTableColTotalLessons}>
          <Grid item className={styles.courseTableColTotalLessonsContent}>
            <Typography
              variant="h4"
              className={styles.courseTableColTotalLessonsContentText}
            >
              {row.totalLessons}
            </Typography>
          </Grid>
        </Grid>
      ),

      name: "Total Lessons",
      minWidth: "150px",
      sortable: false,
      center: "yes",
    },
  ];
  const param = {
    courseId: courseId,
  };
  // Callback function to handle data availability
  const handleDataAvailable = (isAvailable: boolean) => {
    setDataAvailable(isAvailable);
  };

  return (
    <>
      {dataAvailable && (
        <div
          className={`page-container courseDataTable`}
          style={{ marginBottom: 0 }}
        >
          <Datatable
            className={styles.courseDataTable}
            tableColumns={tableColumns}
            dataUrl={resolveUrl(AdminApi.STUDENT_COURSE_LIST_PDF, `${paramId}`)}
            actions={{
              view: true,
              edit: false,
              delete: false,
            }}
            dataFilter={param}
            actionColumns={false}
            pagination={false}
            onDataAvailable={handleDataAvailable}
            module="manage-kids"
          />
        </div>
      )}
    </>
  );
};

export default StudentCourseList;
