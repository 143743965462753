import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import FaqsBannerCms from "../manage-Faqs-banner"
import {FAQS} from "../manage-faqs"
import DynamicMeta from "../manage-meta-tag/dynamic-meta";

const FaqsPageCms = (() => {
    const tabs = [
        {
            label: "FAQ'S Banner",
            content: <FaqsBannerCms heading={"FAQ'S Banner"}/>,
        },
        {
            label: "FAQ'S",
            content: <FAQS heading={"FAQ'S"}/>,
        },
        {
            label: "FAQ'S  Meta Tag",
            content: <DynamicMeta type="faqs-page" heding="FAQ'S  Meta"/>,
        },
    ];

    return (
        <>
            <h1>FAQ'S</h1>
            <Box className="setting-tabs-container">
                <DynamicTabs tabs={tabs} />
            </Box>
        </>
    )
})
export default FaqsPageCms