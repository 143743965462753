import { Outlet } from 'react-router-dom';
import { UnAuthorized } from '../../components/errorPages/UnAuthorize';
import { useAuth } from '../auth/auth';

type Props = {
    permission?: any;
 }
 

export const HasPermission = <PROPS extends Props>({permission, ...props}: PROPS): JSX.Element => {

    const { state } = useAuth();    
    // return state && (state.userRolePermissions.includes(permission) || state.userRole === 'Super Admin') ? <Outlet/> : <UnAuthorized />
    return state && (
        (Array.isArray(permission) && permission.some(p => state.userRolePermissions.includes(p))) ||
        (typeof permission === 'string' && state.userRolePermissions.includes(permission)) ||
        state.userRole === 'Super Admin'
      ) ? <Outlet/> : <UnAuthorized />;
      
};