import { Box, Grid, Popover } from "@mui/material";
import {
  FC,
  HTMLAttributes,
  MouseEvent,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import EditIcon from "../../../../static/images/edit.jpg";
import DeleteIcon from "../../../../static/images/trash.jpg";
import Assignment from "./_ContentType/Assignment";
import Quiz from "./_ContentType/Quiz";
import Summary from "./_ContentType/Summary";
import { Quiz as QuizType } from "../../../../Type/courseTypes";
import Video from "./_ContentType/Video";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { EditQuizInSubject } from "../../manage-quiz-in-subject/edit";
import AddQuiz from "./_ContentType/AddQuiz";
import AddFile from "./_ContentType/addFile";

export type SectionData = {
  title: string;
  courseContent: any[];
  sectionSchedule?: boolean | undefined;
};

export interface CourseSectionProps {
  sectionIndex: number;
  paramsId?: string;
  section: SectionData;
  elementID: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClick: (
    event: MouseEvent<HTMLButtonElement>,
    sectionIndex: number
  ) => void;
  editSection: (
    event: MouseEvent<HTMLButtonElement>,
    sectionTitle: string,
    sectionIndex: number
  ) => void;
  removeContent: (
    event: MouseEvent<HTMLButtonElement>,
    type: "section" | "content",
    sectionIndex: number
  ) => void;
  handleClose: (event: MouseEvent<HTMLButtonElement>) => void;
  editContent: (
    event: MouseEvent<HTMLButtonElement>,
    sectionIndex: number,
    type: "addQuiz" | "quiz" | "assignment" | "video" | "summary" | "addFile"
  ) => void;
  quiz: QuizType;
  onDragEnd: (result: any, sectionIndex: number) => void;
  setSecIndex?: any;
  secIndex?: any;
  editDisabled: any;
}

const CourseSectionItem = forwardRef<HTMLDivElement, CourseSectionProps>(
  (
    {
      sectionIndex,
      section,
      elementID,
      open,
      quiz,
      anchorEl,
      paramsId,
      handleClick,
      editSection,
      removeContent,
      handleClose,
      editContent,
      onDragEnd,
      setSecIndex,
      secIndex,
      editDisabled,
    },
    ref
  ) => {
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
      userSelect: "none",
      width: "100%",
      background: isDragging ? "rgb(39 58 92 / 5%)" : "none",
      ...draggableStyle,
    });

    return (
      <div ref={ref}>
        <Grid className="course-section-wrap" container key={sectionIndex}>
          <Grid
            className="course-section"
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 2 }}
          >
            <Grid item>
              <h3>
                <span>Section {sectionIndex + 1}:</span>
                {section.title}
              </h3>
            </Grid>
            <Grid item className="content-modal">
              <Box className="add-cont-btn-wrap">
                <Button
                  className="light-btn"
                  aria-describedby={elementID}
                  variant="contained"
                  disabled={editDisabled}
                  style={editDisabled ? { opacity: 0.5 } : {}}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    handleClick(event, sectionIndex);
                    setSecIndex(sectionIndex);
                  }}
                >
                  {" "}
                  Add Content{" "}
                </Button>
                <Button
                  className="course-btn edit-section"
                  onClick={(e) => editSection(e, section.title, sectionIndex)}
                  disabled={editDisabled}
                  style={editDisabled ? { opacity: 0.5 } : {}}
                >
                  <span className="tooltip">Edit Section</span>
                  <img src={EditIcon} alt="icon" />
                </Button>
                <Button
                  className="light-btn add_quiz_btn"
                  aria-describedby={elementID}
                  variant="contained"
                  onClick={(e) => editContent(e, sectionIndex, "addQuiz")}
                  disabled={editDisabled}
                  style={editDisabled ? { opacity: 0.5 } : {}}
                >
                  Create Quiz
                </Button>

                {paramsId && paramsId ? (
                  section.sectionSchedule === true ||
                  section.sectionSchedule === undefined ? (
                    <Button
                      className="course-btn delete-section"
                      onClick={(e) => removeContent(e, "section", sectionIndex)}
                      disabled={editDisabled}
                      style={editDisabled ? { opacity: 0.5 } : {}}
                    >
                      <span className="tooltip">Remove Section</span>
                      <img src={DeleteIcon} alt="icon" />
                    </Button>
                  ) : (
                    <Button
                      style={{ opacity: "0.5" }}
                      className="course-btn delete-section"
                    >
                      <span className="tooltip tooltip_section">
                        This section cannot be removed as it has been purchased.
                      </span>
                      <img src={DeleteIcon} alt="icon" />
                    </Button>
                  )
                ) : (
                  <Button
                    className="course-btn delete-section"
                    onClick={(e) => removeContent(e, "section", sectionIndex)}
                  >
                    <span className="tooltip">Remove Section</span>
                    <img src={DeleteIcon} alt="icon" />
                  </Button>
                )}
              </Box>
              <Popover
                id={elementID}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                className="content-popover"
              >
                <Box className="contents-popup">
                  <Button
                    className="course-btn add-assignment"
                    onClick={(e) => editContent(e, secIndex, "assignment")}
                  >
                    Add Assignment
                  </Button>
                  <Button
                    className="course-btn add-quiz"
                    onClick={(e) => editContent(e, secIndex, "quiz")}
                  >
                    Add Quiz
                  </Button>
                  <Button
                    className="course-btn add-summary"
                    onClick={(e) => editContent(e, secIndex, "summary")}
                  >
                    Add Summary
                  </Button>
                  <Button
                    className="course-btn add-video"
                    onClick={(e) => editContent(e, secIndex, "video")}
                  >
                    Add Video
                  </Button>
                  <Button
                    className="course-btn add-reffile"
                    onClick={(e) => editContent(e, secIndex, "addFile")}
                  >
                    Add File
                  </Button>
                </Box>
              </Popover>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="quiz-section"
            sx={{ px: 2 }}
          >
            <DragDropContext
              onDragEnd={(result: any) => onDragEnd(result, sectionIndex)}
              style={{ width: "100%" }}
            >
              <Droppable
                droppableId="section-dropable"
                style={{ width: "100%" }}
              >
                {(provided: any) => {
                  return (
                    <div
                      style={{ width: "100%" }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {section.courseContent &&
                        section.courseContent.map(
                          (content: any, contentIndex: number) => {
                            if (content.isDelete) return false;
                            // Conditionally set isDragDisabled based on paramsId
                            const isDragDisabled = !!paramsId;

                            if (content.type === "assignment") {
                              return (
                                <Draggable
                                  key={contentIndex}
                                  draggableId={`section-${contentIndex}`}
                                  index={contentIndex}
                                  style={{ width: "100%" }}
                                  isDragDisabled={isDragDisabled}
                                >
                                  {(provided: any, snapshot: any) => {
                                    return (
                                      <div
                                        className="content_item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Assignment
                                          contentIndex={contentIndex}
                                          sectionIndex={sectionIndex}
                                          content={content}
                                          editContent={editContent}
                                          removeContent={removeContent}
                                          editDisabled={editDisabled}
                                        />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            } else if (content.type === "quiz") {
                              return (
                                <Draggable
                                  key={contentIndex}
                                  draggableId={`section-${contentIndex}`}
                                  index={contentIndex}
                                  isDragDisabled={isDragDisabled}
                                >
                                  {(provided: any, snapshot: any) => {
                                    return (
                                      <div
                                        className="content_item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Quiz
                                          contentIndex={contentIndex}
                                          sectionIndex={sectionIndex}
                                          content={content}
                                          editContent={editContent}
                                          removeContent={removeContent}
                                          quiz={quiz}
                                          editDisabled={editDisabled}
                                        />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            } else if (content.type === "summary") {
                              return (
                                <Draggable
                                  key={contentIndex}
                                  draggableId={`section-${contentIndex}`}
                                  index={contentIndex}
                                  isDragDisabled={isDragDisabled}
                                >
                                  {(provided: any, snapshot: any) => {
                                    return (
                                      <div
                                        className="content_item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Summary
                                          contentIndex={contentIndex}
                                          sectionIndex={sectionIndex}
                                          content={content}
                                          editContent={editContent}
                                          removeContent={removeContent}
                                          editDisabled={editDisabled}
                                        />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            } else if (content.type === "video") {
                              return (
                                <Draggable
                                  key={contentIndex}
                                  draggableId={`section-${contentIndex}`}
                                  index={contentIndex}
                                  isDragDisabled={isDragDisabled}
                                >
                                  {(provided: any, snapshot: any) => {
                                    return (
                                      <div
                                        className="content_item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Video
                                          contentIndex={contentIndex}
                                          sectionIndex={sectionIndex}
                                          content={content}
                                          editContent={editContent}
                                          removeContent={removeContent}
                                          editDisabled={editDisabled}
                                        />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            } else if (content.type === "addQuiz") {
                              return (
                                <Draggable
                                  key={contentIndex}
                                  draggableId={`section-${contentIndex}`}
                                  index={contentIndex}
                                  isDragDisabled={isDragDisabled}
                                >
                                  {(provided: any, snapshot: any) => {
                                    return (
                                      <div
                                        className="content_item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <AddQuiz
                                          contentIndex={contentIndex}
                                          sectionIndex={sectionIndex}
                                          content={content}
                                          editContent={editContent}
                                          removeContent={removeContent}
                                          quiz={quiz}
                                          editDisabled={editDisabled}
                                        />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            } else if (content.type === "addFile") {
                              return (
                                <Draggable
                                  key={contentIndex}
                                  draggableId={`section-${contentIndex}`}
                                  index={contentIndex}
                                  style={{ width: "100%" }}
                                  isDragDisabled={isDragDisabled}
                                >
                                  {(provided: any, snapshot: any) => {
                                    return (
                                      <div
                                        className="content_item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <AddFile
                                          contentIndex={contentIndex}
                                          sectionIndex={sectionIndex}
                                          content={content}
                                          editContent={editContent}
                                          removeContent={removeContent}
                                          editDisabled={editDisabled}
                                        />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            }
                            return false;
                          }
                        )}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </div>
    );
  }
);

export default CourseSectionItem;
