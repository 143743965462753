import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import DynamicMeta from "../manage-meta-tag/dynamic-meta";
import SuperCurriculumBannerCms from "../manage-super-curriculum-banner";
import ViewCompetence from "../manage-competence";

const TheSuperCurriculumCms = (() => {
    const tabs = [
        {
            label: 'Super Curriculum Banner',
            content: <SuperCurriculumBannerCms heading={"Super Curriculum Banner"}/>,
        },
        {
            label: 'knowledge and skills',
            content: <ViewCompetence/>,
        },
        {
            label: "Super Curriculum Meta Tag",
            content: <DynamicMeta type="curriculum" heding="Super Curriculum"/>,
        },
    ];

    return (
        <>
            <h1>The Super Curriculum</h1>
            <Box className="setting-tabs-container">
                <DynamicTabs tabs={tabs} />
            </Box>
        </>
    )
})
export default TheSuperCurriculumCms