import { Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { DateView } from "../../../components/DateView/DateView";

export default function ViewQuestionBank() {
  const navigate = useNavigate();

  const tableColumns = [
    {
      selector: (row: { question: string }) => row.question,
      name: "Question",
      sortable: true,
    },
    {
      selector: (row: { created_at: string }) => (
        <DateView date={row.created_at} />
      ),
      name: "Created At",
      sortable: false,
    },
  ];

  const onEdit = async (id: string) => {
    navigate(`/admin/question-bank/edit/${id}`);
  };

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.QUESTIONBANK_DEL, id));
  };

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading fullwith">Question Bank</h1>
        <Box className="btn-wrapper">
          <Link to={"edit"}>Add Question</Link>
        </Box>
      </div>

      <div className="page-container">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.QUESTIONBANK_PAGINATE}
          onEdit={onEdit}
          onDelete={onDelete}
          actions={{
            view: false,
          }}
          module="manage-question-bank"
        />
      </div>
    </>
  );
}
