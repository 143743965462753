import Moment from "react-moment";

export function DateView (params: {date: string}) {
        
    if(params.date !== "")
    {
        return (
            <Moment format="MMM DD, YYYY">{params.date ?? ""}</Moment>
        )
    }
    else{
        return (
           <></>
        )
    }
    
}