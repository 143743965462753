import React, { useState, useEffect } from "react";
import { FormHelperText, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { SERVER_KEY } from "../../config/constants/EnviormentVariables";
import upload from "../../static/images/upload.svg";


const ImageUploadInput = styled("input")({
  display: "none",
});

const ImageUpload = ({
  name,
  label,
  previewWidth,
  previewHeight,
  maxFileSize,
  onChange,
  uploadPreviewProp,
  isRequired, // New prop indicating whether upload is required

}: any) => {
  const [formData, setFormData] = useState<any>(null);
  const [uploadPreview, setUploadPreview] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (formData) {
      validateFile(formData);
    }
  }, [formData]);
  

  useEffect(() => {
    // Set the prop as the uploadPreview if it exists and formData is not set
    if (uploadPreviewProp && !formData) {
      setUploadPreview(`${SERVER_KEY}${uploadPreviewProp}`);
    }
  }, [uploadPreviewProp, formData]);

  useEffect(() => {
    // Trigger validation if isRequired prop is set and uploadPreview is empty
    if (isRequired && !uploadPreviewProp) {
      // setErrors({ uploadRequired: "Upload is required." });
    }
  }, [isRequired, uploadPreviewProp, formData]);

  const handleChange = (e: any) => {
    const { files } = e.target;
    const selectedFile = files ? files[0] : null;

    setFormData(selectedFile);
    onChange(name, selectedFile);


    if (selectedFile) {
      setUploadPreview(URL.createObjectURL(selectedFile));
      setErrors({});
    } else {
      setUploadPreview(null);
    }
  };

  const validateFile = (file: any) => {
    const newErrors: any = {};
    const fileType = file.type.split("/")[0];

    if (fileType !== "image") {
      newErrors.fileType = "Only image files are allowed.";
    }

    if (file.size > maxFileSize) {
      newErrors.fileSize = "File size exceeds the maximum limit.";
    }

    setErrors(newErrors);
  };

  return (
    <div>
      <Button
        variant="contained"
        component="label"
      >
        <Box component="img" alt="Logo" src={upload} style={{width:"47px",height:"47px"}}/>

        {label}
        <ImageUploadInput
          type="file"
          name={name}
          accept="image/*"
          onChange={handleChange}
        />
      </Button>
      {uploadPreview && (
        <>
        <img
          src={uploadPreview}
          alt={`${label} Preview`}
          width={previewWidth}
          height={previewHeight}
          className="Preview_image"
          />
        {/* <Button onClick={()=>setFormData(null)}>x</Button> */}
          </>
      )}
      {uploadPreview ? null : ( // Conditionally render error messages
        <>
          {errors.fileType && <div>{errors.fileType}</div>}
          {errors.fileSize && <div>{errors.fileSize}</div>}
          {errors.uploadRequired && (
            <FormHelperText error>{errors.uploadRequired}</FormHelperText>
          )}
        </>
      )}
    </div>
  );
};

export default ImageUpload;
