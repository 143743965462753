import { FC, FormEvent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  Alert,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import mainLogo from "../../static/images/logo.png";
import { resolveUrl, AuthApi, api } from "../../config/apis";
import { useAuth } from "../../hooks/auth/auth";
import BootstrapInput from "../../layout/admin/partials/BootstrapInput";
import { signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth, requestForToken } from "../../services/firebase";

interface Props {
  title?: string;
}
const Login: FC<Props> = ({ title }) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([""]);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAuth();
  const [saveLoginDetails, setSaveLoginDetails] = useState(false);

  const navigate = useNavigate();

  const populateSavedLoginDetails = () => {
    const savedDetails = localStorage.getItem("loggedInDetails");

    if (savedDetails) {
      const { email, password } = JSON.parse(savedDetails);
      const emailField = document.querySelector("#email") as HTMLInputElement;
      const passwordField = document.querySelector(
        "#password"
      ) as HTMLInputElement;

      if (emailField && passwordField) {
        emailField.value = email;
        passwordField.value = password;
      }
    }
  };
  useEffect(() => {
    populateSavedLoginDetails();
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const payload = {
      email: data.get("email") as string,
      password: data.get("password") as string,
    };
    if (saveLoginDetails) {
      const loginDetails = {
        email: payload.email,
        password: payload.password,
      };
      localStorage.setItem("loggedInDetails", JSON.stringify(loginDetails));
    }
    api
      .post(resolveUrl(AuthApi.LOGIN), payload)
      .then((res) => {
        if (res.data) {
          localStorage.setItem("auth", JSON.stringify(res.data));
          const currentTime = new Date().getTime();
          localStorage.setItem("loginTime", `${currentTime}`);
          setIsError(false);
          if (payload.email && payload.password) {
            signInWithEmailAndPassword(
              firebaseAuth,
              payload.email,
              payload.password
            )
              .then((userCredential) => {
                localStorage.setItem(
                  "firebase_auth",
                  JSON.stringify(userCredential.user)
                );
                requestForToken(res.data.user.id);
                dispatch({ type: "LOGIN", payload: res.data });
                setLoading(false);
                navigate("/admin");
              })
              .catch((error) => {
                console.log(error, "error");

                const errorMessage = error.message;
                setIsError(true);
                setErrorMsg(errorMessage);
                setLoading(false);
              });
          }
        }
      })
      .catch((err) => {
        setIsError(true);
        setLoading(false);

        let message = ["Server Error"];
        if (err.response) {
          message = err.response.data.message;
          if (typeof message != "object") message = [err.response.data.message];
        }

        setErrorMsg(message);
      });
  };

  <BootstrapInput />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "88vh",
        justifyContent: "center",
      }}
    >
      <Box className="login-wrap">
        <Box className="logo-wrap">
          <Box
            component="img"
            sx={{
              height: 40,
              width: 232,
              mb: 2,
            }}
            alt="Logo"
            src={mainLogo}
          />
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
        </Box>
        <Box
          className="login-form"
          component="form"
          onSubmit={handleSubmit}
          noValidate
        >
          {isError && (
            <Alert severity="error">
              {/* <ul style={{ padding: "0", margin: "0" }}>
                {errorMsg && errorMsg.map((value: string, index: number) => {
                  return <li key={index}>{value}</li>;
                })}
              </ul> */}
              <ul style={{ padding: "0", margin: "0" }}>
                {Array.isArray(errorMsg) ? (
                  errorMsg.map((value: string, index: number) => (
                    <li key={index}>{value}</li>
                  ))
                ) : (
                  <li>{errorMsg}</li>
                )}
              </ul>
            </Alert>
          )}
          <FormControl variant="standard" sx={{ width: "100%", mb: 2, mt: 1 }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Email Address:
            </InputLabel>
            <BootstrapInput
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Password:
            </InputLabel>
            <BootstrapInput
              fullWidth={true}
              required
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                name="saveLoginDetails" // This name should match with the payload key
                color="primary"
                checked={saveLoginDetails}
                onChange={(e) => setSaveLoginDetails(e.target.checked)}
              />
            }
            label="Save Login Details"
          />

          <Button
            className="darkbeige"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            Login
            {loading && (
              <CircularProgress
                size={15}
                sx={{
                  color: "#f3f3f3",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-7px",
                  marginLeft: "25px",
                }}
              />
            )}
          </Button>
          <Box sx={{ m: 1, position: "relative" }}></Box>
          <Box className="forget-link">
            <Link to="/auth/forget-password">Forgot password?</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
