import { Typography } from "@mui/material";
import BannerImg from './asset/img/bannerImg.jpg';
import './style/style.css';
import { Link } from "react-router-dom";
import ImageViewer from "../../../components/imageViewer";

interface IInnerBanner {
    bannerData?: TBannerData
}

type TBannerData = {
    heading?: string,
    description?: string
    image?: string
}

const InnerBanner = ({ bannerData }: IInnerBanner) => {
    


    function getSecondToLastWord(inputString: string): string {
        const words = inputString.trim().split(' ');
        return words.length >= 4 ? words[words.length - 1] : '';
    }


    const heading = bannerData?.heading || "";
    const secondToLastWord = getSecondToLastWord(heading);

    const styledHeading = heading.replace(secondToLastWord, `<span class='textGradian'> ${secondToLastWord} </span>`);

    return (
        <div className="innerBanner">
            <div className="container">
                <div className="contain">
                    <div className="textContain">
                        <div className="bradcrumbs">
                            <Link to="/">Home</Link> \ <Link to="/about" className="active">About</Link>
                        </div>
                        <div className="pageTitle">
                            <Typography variant="h1" dangerouslySetInnerHTML={{ __html: styledHeading }} />

                        </div>
                        <div className="pageDesc">
                            <Typography variant="body1">{bannerData && bannerData.description}</Typography>
                        </div>
                    </div>
                    <div className="bannerImg">
                        {bannerData && bannerData.image ?
                            <ImageViewer imagePath={bannerData?.image ?? ""} altText={bannerData?.heading} />
                            :
                            <img src={BannerImg} alt="Banner Img" />
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InnerBanner;