import { useState, useEffect } from "react";
import { AdminApi, api } from "../../../config/apis";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { Grid } from "@mui/joy";

const StudentsActiveChart = () => {
  const [inActiveStudents, setInActiveStudents] = useState(0);
  const [activeStudents, setActiveStudents] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const ActiveOrInactiveStu = await api.get(
          AdminApi.ACTIVE_OR_INACTIVE_STUDENT
        );

        setActiveStudents(ActiveOrInactiveStu.data.Active);
        setInActiveStudents(ActiveOrInactiveStu.data.Inactive);
        setIsDataLoaded(true);
      } catch (error) {
        setIsDataLoaded(true);
        console.error("Error fetching data:", error);
      }
    };

    fetchChartData();
  }, []);

  const data = {
    labels: ["Active", "InActive"],
    datasets: [
      {
        label: "",
        data: [activeStudents, inActiveStudents],
        backgroundColor: [
          "rgba(163, 145, 94, 1), rgba(214, 195, 143, 1)",
          "rgba(7, 24, 54, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = data.labels[context.dataIndex];
            const value = data.datasets[0].data[context.dataIndex];
            return `${label}: ${value}%`;
          },
        },
      },
    },
    legend: {
      display: true,
      position: "bottom",
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <>
      {isDataLoaded ? (
        <Grid
          sx={{ height: "400px", display: "flex", justifyContent: "center" }}
        >
          <Pie data={data} options={options} />
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      )}
    </>
  );
};

export default StudentsActiveChart;
