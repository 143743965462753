import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, Tooltip } from "@mui/material";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, resolveUrl } from "../../../../config/apis";
import { DateView } from "../../../../components/DateView/DateView";
import {
  convertMinutesToTime,
  toTitleCase,
} from "../../../../helper/helperFunction";
import AvatarImage from "../../../../components/Avatar";

export default function Enrolledstudents() {
  const [filters, setFilters] = useState({});

  const params = useParams();

  const navigate = useNavigate();

  const onView = async (id: string) => {
    navigate(`/admin/student/view/${id}`);
  };

  const tableColumns = [
    {
      selector: (row: { id: any }) => `#${row.id}`,
      name: "ID",
      sortable: false,
      minWidth: "100px",
    },
    {
      cell: (row: { Name: string; studentProfile: string }) => (
        <>
          <AvatarImage imageName={row.studentProfile} name={row.Name} />
          {toTitleCase(`${row.Name}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row: { Email: string }) => (
        <Tooltip title={row.Email} arrow>
          <span>{row.Email}</span>
        </Tooltip>
      ),
      name: "Email",
      sortable: false,
      minWidth: "250px",
    },
    {
      selector: (row: { Place: any }) => (
        <Tooltip title={row.Place} arrow>
          <span>{row.Place}</span>
        </Tooltip>
      ),
      minWidth: "150px",
      name: "Place",
    },
    {
      selector: (row: { AddedOn: string }) => <DateView date={row.AddedOn} />,
      name: "Added on",
      sortable: false,
    },
    {
      selector: (row: { parentName: string }) => toTitleCase(row.parentName),
      name: "Parent Name",
      sortable: false,
      minWidth: "150px",
    },
    {
      selector: (row: { courses: number }) => row.courses,
      name: "Subjects",
      sortable: false,
      minWidth: "120px",
    },
    {
      selector: (row: { avgTime: number }) =>
        row.avgTime ? convertMinutesToTime(row.avgTime) : "-",
      name: "Avg time",
      sortable: false,
      minWidth: "200px",
    },
    {
      selector: (row: { avgScore: any }) =>
        row.avgScore ? row.avgScore.toFixed(2) : "-",
      name: "Avg score",
      sortable: false,
      minWidth: "150px",
    },
  ];
  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(
          AdminApi.FETCH_ENROLL_STUDENTS_COURSES,
          `${params.id}`
        )}
        filters={filters}
        onView={onView}
        actions={{
          edit: false,
          delete: false,
        }}
        module="manage-courses"
      />
    </>
  );
}
