import { useEffect, useState } from "react";
import styles from "./filter.module.scss";
import { Box, Button, TextField, Typography } from "@mui/material";

type FilterState = {
  teacherId: any;
  status: string | null;
  text: string | null;
};

interface FilterProps {
  onFiltersChange: (filters: FilterState) => void;
  placeholder?: any;
}

const FilterBase: React.FC<FilterProps> = ({
  onFiltersChange,
  placeholder,
}) => {
  const [filters, setFilters] = useState<FilterState>({
    teacherId: null,
    status: null,
    text: null,
  });

  // useEffect(() => {
  //   onFiltersChange(filters);
  // }, [filters, onFiltersChange]);

  // Level
  const [filterData, setFilterData] = useState<FilterState>({
    teacherId: null,
    status: null,
    text: null,
  });
  const handleApplyFilter = () => {
    onFiltersChange(filterData);
  };

  return (
    <Box className={styles.baseFilterMain}>
      <form className={styles.studentTopFilterMain}>
        <Typography
          variant="body1"
          className={styles.studentTopFilterMainTitle}
        >
          Search By
        </Typography>
        <Box className={styles.studentTopFilterKeyword}>
          <TextField
            id="coursetitle"
            variant="outlined"
            placeholder={placeholder ?? ""}
            fullWidth
            className={styles.studentTopFilterKeywordInput}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleApplyFilter();
              }
            }}
            onChange={(event) =>
              setFilterData((prevState: FilterState) => {
                return {
                  ...prevState,
                  text: event.target.value,
                };
              })
            }
          />
          <Box className={styles.studentTopFilterBtn}>
            <Button
              className={styles.studentTopFilterBtnContent}
              onClick={handleApplyFilter}
            >
              Go
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FilterBase;
