import DynamicTabs from "../../../../../../components/tabs/Tabs";
import StudentDetails from "../../../../manage-users/students/details";
import AreasOfImprovements from "../view/feedback/areasOfimprovements";
import Strength from "../view/feedback/strength";
import TeacherAssignmentFeedback from "../view/feedback/teachersFeedback";

export const ActivityTab = (props: any) => {
  const tabs = [
    {
      label: "Personal Data",
      content: <StudentDetails setStudentName={props.setStudentName} />,
    },
    {
      label: "Strength",
      content: <Strength courseId={props?.courseId} />,
    },
    // {
    //   label: "Areas Of Improvements",
    //   content: <AreasOfImprovements courseId={props?.courseId} />,
    // },
    {
      label: "Feedback",
      content: <TeacherAssignmentFeedback courseId={props?.courseId} />,
    },
  ];
  return (
    <>
      <DynamicTabs tabs={tabs} />
    </>
  );
};
