import { Link, useNavigate, useParams } from "react-router-dom";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { Box } from "@mui/material";
import { DateView } from "../../../../components/DateView/DateView";

export default function UserViewQuizzes() {
  const navigate = useNavigate();

  const params = useParams();
  // const tableColumns = [
  //     {
  //         selector: (row: { name: string }) => row.name,
  //         name: "Subject Name",
  //         sortable: true,
  //     },
  //     {
  //         selector: (row: { created_at: string }) => <DateView date={row.created_at} />,
  //         name: "Created At",
  //         sortable: false,
  //     },

  //     {
  //         selector: (row: { lessons: string }) => row.lessons,
  //         name: "Lesson",
  //         sortable: false,
  //     },
  //     {
  //         selector: (row: { completedCourses: number }) => row.completedCourses,
  //         name: "Subjects Completed",
  //         sortable: false,
  //     },
  //     {
  //         selector: (row: { hours: number, minutes: number }) => ((row.hours * 60) + row.minutes) + " min",
  //         name: "Total Time",
  //         sortable: false,
  //     },
  //     {
  //         selector: (row: { activeStudents: number }) => row.activeStudents,
  //         name: "Active Students",
  //         sortable: false,
  //     },
  // ];

  const tableColumns = [
    {
      selector: (row: { quizTitle: string }) => row.quizTitle,
      name: "Quiz Title",
      sortable: true,
    },
    {
      selector: (row: { courseTitle: string }) => row.courseTitle,
      name: "Subject Name",
      sortable: false,
    },
    {
      selector: (row: { courseSection: string }) => row.courseSection,
      name: "Subject section",
      sortable: false,
    },
    {
      selector: (row: { assignedStudents: string }) => row.assignedStudents,
      name: "Assigned Student",
      sortable: false,
    },
    {
      selector: (row: { completed: string }) => row.completed,
      name: "Completed",
      sortable: false,
    },
    {
      selector: (row: { time: string }) => row.time,
      name: "Time",
      sortable: false,
    },
    {
      selector: (row: { totalMarks: string }) => row.totalMarks,
      name: "Avg Marks",
      sortable: false,
    },
    {
      selector: (row: { avgMarks: string }) => row.avgMarks,
      name: "Total Score",
      sortable: false,
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/courses/view/${id}`);
  };

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(AdminApi.USER_TEACHER_QUIZZES, `${params.id}`)}
        onView={onView}
        actions={{
          edit: false,
          delete: false,
          view: false,
        }}
        module="manage-users"
        actionColumns={false}
      />
    </>
  );
}
