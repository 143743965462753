import { Grid } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import styles from "./assin-cousre.module.scss";
import AvatarImage from "../../../../../components/Avatar";

type InstructorDetailProps = {
  video?: string;
  title?: string;
  content?: string;
  instructor?: string;
  profile_image?: string;
  name?: string;
};

const LABEL = { inputProps: { "aria-label": "Switch demo" } };

export default function InstructorDetail({
  video,
  title,
  content,
  instructor,
  profile_image,
  name,
}: InstructorDetailProps): JSX.Element {
  const [value, setValue] = React.useState<number | null>(2);

  const params = useParams<{ id: string }>();

  return (
    <Grid
      className="page-container course-info"
      alignItems={"center"}
      container
      sx={{ mb: 5 }}
    >
      <Grid item xl={10} lg={10} xs={12} justifyContent={"space-between"}>
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item xl={1} lg={1.5} xs={12}>
            <AvatarImage
              width={"65px"}
              height={"65px"}
              imageName={profile_image ?? ""}
              name={name ?? ""}
            />
          </Grid>

          <Grid
            className={styles.aboutTeacher}
            item
            xl={11}
            lg={10.5}
            xs={12}
            paddingRight={2}
          >
            <h2>{title}</h2>
            <p> {content} </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={2} lg={2} xs={12}>
        <Box className="btn-wrapper" textAlign={"left"}>
          <Link to={"/admin/messages"}>Chat Now</Link>
        </Box>
      </Grid>
    </Grid>
  );
}
