import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import Logo from "./asset/img/logo.png";
import Icon1 from "./asset/img/secondaryks3.svg";
import Icon2 from "./asset/img/igcse.svg";
import "./style/style.css";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/auth/auth";
import { AdminApi, api } from "../../../config/apis";
import { GetUserId } from "../../../helper/helperFunction";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import "./js/customHeader.js";

const HeaderMain = ({ setHeaderHeight }: any) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname);

  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.getBoundingClientRect().height;
      setHeaderHeight(height);
    }
  }, []);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { dispatch } = useAuth();

  const logout = function () {
    dispatch({ type: "LOGOUT" });
    activityLog();
    localStorage.removeItem("auth");
  };

  const activityLog = async () => {
    return await api.get(AdminApi.TIME_TRACKING);
  };

  return (
    <header
      ref={headerRef}
      className={`HeaderMain ${isScrolled ? "fixedHeader" : ""}`}
    >
      <div className="header_wrap">
        <div className="logoContainer">
          <Link to="/">
            <LazyLoadImage
              src={Logo}
              width={303}
              height={53}
              alt="EdexOnline"
            />
          </Link>
        </div>
        <div className="menuNav">
          <nav className="nav">
            <ul className="menu">
              <li className={`menuItem ${currentPath === "/" ? "active" : ""}`}>
                <Link to="/">Home</Link>
              </li>
              <li
                className={`menuItem ${
                  currentPath === "/about" ? "active" : ""
                }`}
              >
                <Link to="/about">About</Link>
              </li>
              <li
                className={`menuItem ${
                  currentPath === "/curriculum" ? "active" : ""
                }`}
              >
                <Link to="/curriculum">Curriculum</Link>
              </li>
              <li className="menuItem dropDownMenu">
                <Link to="#">Courses</Link>
                <div className="subMenu">
                  <ul className="subMenuList">
                    <li className="subItem">
                      <Link to="/Secondary-KS3">
                        <img src={Icon1} alt="Secondary/KS3" />
                        <Typography variant="body1">Secondary/KS3</Typography>
                      </Link>
                    </li>
                    <li className="subItem">
                      <Link to="/igcse">
                        <img src={Icon2} alt="IGCSE" />
                        <Typography variant="body1">IGCSE</Typography>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={`menuItem ${currentPath === "/faq" ? "active" : ""}`}
              >
                <Link to="/faq">FAQs</Link>
              </li>
              <li
                className={`menuItem ${currentPath === "/contact-us" ? "active" : ""}`}
              >
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="loginMenu">
          {/* {!GetUserId() && ( */}
            <Link to="/parents/auth/signup" className="registerBtn">
              Register
            </Link>
          {/* )} */}
          {/* {GetUserId() ? (
            <Link to="/auth/login" className="loginBtn" onClick={logout}>
              Sign Out
            </Link>
          ) : (
            <Link to="/auth/login" className="loginBtn">
              Login
            </Link>
          )} */}
            <Link to="/auth/login" className="loginBtn">
              Login
            </Link>
        </div>
        <div className="hamburger-menu" id="hamburger-menu">
          <div className="menu-bar1"></div>
          <div className="menu-bar2"></div>
          <div className="menu-bar3"></div>
        </div>
        <div className="mobileMenu overlay overlay-slide-left" id="overlay">
          <nav className="mbMenuMain">
            <ul>
              <li id="nav-1" className="slide-out-1 center">
                <Link to="/" className="center">
                  Home
                </Link>
              </li>
              <li id="nav-2" className="slide-out-2 center">
                <Link to="/about" className="center">
                  About
                </Link>
              </li>
              <li id="nav-3" className="slide-out-3 center">
                <Link to="#" className="center">
                  Curriculum
                </Link>
              </li>
              <li id="nav-4" className="slide-out-4 center">
                <Link to="#projects" className="center">
                  Courses
                </Link>
              </li>
              <li id="nav-5" className="slide-out-5 center">
                <Link to="#" className="center">
                  FAQs
                </Link>
              </li>
              <li id="nav-6" className="slide-out-6 center">
                <Link to="/contact-us" className="center">
                Contact Us
                </Link>
              </li>
              
              <li id="nav-7" className="slide-out-7 center">
                <Link to="/parents/auth/signup" className="register">
                  Register
                </Link>
              </li>
              <li id="nav-8" className="slide-out-8 center">
                {GetUserId() ? (
                  <Link to="#" className="login" onClick={logout}>
                    Sign Out
                  </Link>
                ) : (
                  <Link to="/auth/login" className="login">
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default HeaderMain;
