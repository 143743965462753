// import React, { useState } from "react";
import { useState } from "react";
import styles from "./studentQuizes.module.scss";
import { Badge, Typography } from "@mui/material";
import Datatable from "../../../../../../../components/dataTables/Datatable";
import { AdminApi, resolveUrl } from "../../../../../../../config/apis";
import { toTitleCase } from "../../../../../../../helper/helperFunction";

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

const StudentQuizesTable = (params: {
  filterState?: FilterState;
  student: string;
  course: string;
  courseId?: any;
}) => {
  const [filters] = useState({
    courseSlug: params.course,
    ...params.filterState,
  });

  const byCourseId = {
    courseId: params?.courseId,
  };

  const tableColumns = [
    {
      cell: (row: { quiz_name: string }) => row.quiz_name,
      selector: "quiz_name",
      name: "Quiz Title",
      sortable: true,
    },
    {
      cell: (row: { course_name: string }) => row.course_name,
      name: "Subject Name",
    },
    // {
    //   cell: (row: {studentQuizStatus: string}) => toTitleCase(row.studentQuizStatus),
    //   name: "Status",
    // },
    {
      cell: (row: { total_marks: string; obtainedMarks: string }) =>
        `${row.obtainedMarks ?? "-"}/${row.total_marks}`,
      name: "Marks",
    },
  ];

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(AdminApi.STUDENT_ASSIGN_QUIZZES, params.student)}
        filters={filters}
        actionColumns={false}
        actions={{
          edit: false,
          delete: false,
        }}
        dataFilter={byCourseId}
        module="manage-kids"
        // rowsPerPage={5}
      />
    </>
  );
};

export default StudentQuizesTable;
