import { Box, Button, Grid,Typography } from "@mui/material";
import styles from './studentQuizes.module.scss';


const StudentQuizesDetailTable = () => {

  
    return (
    <Box className={styles.quizesFinalPanel}>
        <Grid item xs={12} lg={8} className={styles.qFPTitle}>
            <Typography variant="h2" className={styles.qFPTitleText}>Final Quiz</Typography>
        </Grid>

        <Grid container className={styles.qFPTableContainer}>
            <Grid item xs={12} className={styles.qFPTableCourseTitle}>
                <Typography variant="h2" className={styles.qFPTableCourseTitleText}>Subject Title</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={8} className={styles.qFPTableCourseInfo}>
                <Typography variant="body1" className={styles.qFPTableTime}>0:10:22:33</Typography>
                <Typography variant="body1" className={styles.qFPTableQues}>6 correct questions.</Typography>
                <Typography variant="body1" className={styles.qFPTableScore}>Out of 10</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={styles.qFPTableScoreTotal}>
                <Box className={styles.qFPTableScoreTotalText}>
                    <Typography variant="body1">60 / 100</Typography>
                </Box>
                <Box className={styles.qFPTableScoreAction}>
                    <Button>Re Assign Quiz</Button>
                </Box>
            </Grid>
        </Grid>
    </Box>
  );
};

export default StudentQuizesDetailTable;
