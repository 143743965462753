import { Avatar, Box, Button, Typography } from "@mui/material";
import styles from "./quize.module.scss";
import Datatable from "../../../../../components/dataTables/Datatable";
import { useNavigate } from "react-router-dom";
import React from "react";
import { AdminApi } from "../../../../../config/apis";

const QuizeTableView = () => {
  const navigate = useNavigate();

  const tableColumns = [
    {
      name: "order #",
      width: "90px",
      cell: () => (
        <>
          <Typography
            variant="h4"
            className={styles.quizeTableInnerContentBold}
          >
            1
          </Typography>
        </>
      ),
    },
    {
      name: "Question",
      minWidth: "340px",
      maxWidth: "40%",
      cell: () => (
        <>
          <Typography
            variant="body1"
            className={styles.quizeTableInnerContentBold}
          >
            Lorem ipsum dolor sit amet, consectetur
          </Typography>
        </>
      ),
    },
    {
      name: "Question Type",
      minWidth: "220px",
      maxWidth: "40%",
      cell: () => (
        <>
          <Typography variant="h4" className={styles.quizeTableInnerContent}>
            Multiple choice
          </Typography>
        </>
      ),
    },
    {
      name: "Marks",
      width: "100px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizeTableInnerContent}>
            10
          </Typography>
        </>
      ),
    },
  ];
  const onViewStudent = async (id: string) => {
    navigate(`/admin/courses-panel/${id}`);
  };

  return (
    <Box className={styles.quizeTableMain}>
      <Typography variant="h2" className={styles.quizeTableTitle}>
        Quizes
      </Typography>
      <Box className={styles.quizeTableContainer}>
        <Box className={styles.quizeTableContent}>
          <div className="page-container quizeTableMainInner">
            <Datatable
              tableColumns={tableColumns}
              dataUrl={AdminApi.COURSES_PAGINATE}
              onView={onViewStudent}
              actions={{
                view: true,
              }}
              module="student-view"
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default QuizeTableView;
