import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { RouterRoutes } from "./routes";
import AppProvider from "./hooks";
import TagManager from "react-gtm-module";
import { FrontendApi, api, resolveUrl } from "./config/apis";
import { TrackingProvider } from "./components/TimeTracking";

const App = () => {
  const [gtmCode, setGtmCode] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const res = await api.get(
          resolveUrl(FrontendApi.META_TAGS_VIEW, "gtm-code")
        );
        setGtmCode(res.data.keywords);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMetaData();
  }, []);

  useEffect(() => {
    if (gtmCode) {
      TagManager.initialize({ gtmId: gtmCode });
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: window.location.pathname + window.location.search,
        },
      });
    }
  }, [gtmCode]);

  useEffect(() => {
    const unsubscribe = RouterRoutes.subscribe((location: any) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: location.location.pathname + location.location.search,
        },
      });
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <AppProvider>
        <TrackingProvider>
          <RouterProvider router={RouterRoutes} />
        </TrackingProvider>
      </AppProvider>
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
