import { FormHelperText, TextareaAutosize, TextField, Typography } from "@mui/material";
import BannerImg from "./asset/img/tablet_and_laptop_banner.png";
import "./style/style.css";
import { HomePageContentInfo } from "../type/type";
import { FrontendApi } from "../../../config/apis";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FetchPopupData, useReadMore } from "../../../helper/helperFunction";
import Form from "./EnrollForm";
import useEnrollForm from "./useEnrollForm";
import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImageViewer from "../../../components/imageViewer";
import { Textarea } from "@mui/joy";

interface EnrollNowProps {
  EnrollNowInfo?: any;
}

const EnrollNow = function ({ EnrollNowInfo }: EnrollNowProps) {
  const {
    formData,
    formSuccess,
    errors,
    handleOnChange,
    validateForm,
    handleFormSuccess,
    handlePhoneChange,
  } = useEnrollForm();
  const [thankyouData, setThankyouData] = useState<string>("");
  const type = "enroll-form-thanks";
  useEffect(() => {
    const fetchData = async () => {
      await FetchPopupData(type, setThankyouData);
    };
    fetchData();
  }, []);
  const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={`${REACT_APP_RECAPTCHA_SITE_KEY}`}>
      <div className="enrollMain" id="contact-us">
        <div className="container">
          <div className="enrollContain">
            <div
              className="enrollBanner"
              data-aos="fade-right"
              data-aos-duration="2500"
            >
              
               {EnrollNowInfo && EnrollNowInfo.image ?
                    <ImageViewer imagePath={EnrollNowInfo?.image ?? ""} altText={EnrollNowInfo?.heading} />
                            :
                    <LazyLoadImage
                src={BannerImg}
                alt="Enroll Now"
                className="image-viewer"
              />
                        }
            </div>
            <div
              className="enrollFrom"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <Typography variant="h2">
                {EnrollNowInfo && EnrollNowInfo.title}
              </Typography>
              <Typography variant="body1" className="enrollheadingfour">
                {EnrollNowInfo && EnrollNowInfo?.description}
              </Typography>

              {formSuccess ? (
                <div
                  className="thank-you-message"
                  dangerouslySetInnerHTML={{ __html: thankyouData }}
                ></div>
              ) : (
                <Form
                  classname="enrollFromMain"
                  btnClass="submitBtn"
                  saveText="Submit"
                  submitUrl={FrontendApi.FRONTEND_CONTACT_FORM}
                  formData={formData}
                  validate={validateForm}
                  onSuccess={handleFormSuccess}
                >
                  <TextField
                    className="field"
                    variant="outlined"
                    placeholder="Name"
                    type="text"
                    name="name"
                    onChange={handleOnChange}
                    error={errors.name !== undefined}
                    helperText={errors.name}
                  />

                  <div className="field">
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: false,
                      }}
                      value={formData.phone}
                      onChange={handlePhoneChange}
                    />
                    {errors.phone && (
                      <FormHelperText error>{errors.phone}</FormHelperText>
                    )}
                  </div>
                  <TextField
                    className="field textareaField"
                    variant="outlined"
                    placeholder="Email Address"
                    type="email"
                    name="email"
                    onChange={handleOnChange}
                    error={errors.email !== undefined}
                    helperText={errors.email}
                  />
                  
                   <TextareaAutosize
                    name="description"
                    placeholder="Message"
                    value={formData.description}
                    minRows={4}
                    maxRows={4}
                    onChange={handleOnChange}
                    className="textarea-custom"
                 
                  />
                  {errors.description && (
                    <FormHelperText error>
                      {errors.description}
                    </FormHelperText>
                  )}
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default EnrollNow;
