import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import styles from "./style.module.scss";
import ImageUpload from "../../../components/UploadImage";
import * as Yup from "yup";
import Modal from "@mui/material/Modal";
import { PopupStyle } from "../../../helper/helperFunction";

export default function EditSampleSubject({
  open,
  handleClose,
  width,
  setFormSuccess,
  editId,
}: any) {
  type FormData = {
    title?: string;
    subTitle?: string;
    description?: string;
    buttonText?: string;
    buttonURL?: string;
    image?: File | null;
    id?: number;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    // subTitle: Yup.string().required("This field is required"),
    // description: Yup.string().required("This field is required"),
    buttonText: Yup.string().required("This field is required"),
    buttonURL: Yup.string().required("This field is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    subTitle: "",
    description: "test",
    buttonText: "",
    buttonURL: "",
    id: editId ?? null,
    image: null,
  });
  const [uploadPreviewProp, setUploadPreviewProp] = useState(null);

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const handleIDImageChange = (name: any, selectedFile: any) => {
    setFormData({
      ...formData,
      image: selectedFile,
    });
  };

  useEffect(() => {
    if (editId) {
      const fetchData = async () => {
        try {
          const res = await api.get(
            resolveUrl(AdminApi.SAMPLE_SUBJECT_TABLE_VIEW, editId)
          );
          setFormData({
            title: res.data.sampleCourse.title ?? "",
            // subTitle: res.data.viewHomeBanner.subTitle ?? "",
            description: res.data.sampleCourse.description ?? "",
            buttonText: res.data.sampleCourse.buttonText ?? "",
            buttonURL: res.data.sampleCourse.buttonURL ?? "",
            id: editId ?? null,
          });
          setUploadPreviewProp(res.data.sampleCourse.image);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, [editId]);

  useEffect(() => {
    if (!open) {
      setFormData({
        title: "",
        subTitle: "",
        description: "test",
        buttonText: "",
        buttonURL: "",
        image: null,
      });
      setUploadPreviewProp(null);
    }
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={PopupStyle} maxWidth={width}>
          <Box className={styles.aSCreateTicketMain}>
            <Typography
              variant="h2"
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                paddingTop: "20px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              Add Sample Subjects
            </Typography>
            <Box className={styles.aSCTContainer}>
              <Form
                submitUrl={AdminApi.SAMPLE_SUBJECT_TABLE_EDIT}
                formData={formData}
                successPath="/admin/cms/home"
                setFormSuccess={setFormSuccess}
                validate={customValidation}
                multipart={true}
              >
                {/* <Box className={styles.aSCTContentTitle}>
                                    <InputLabel sx={{ textAlign: "left" }} className={styles.aSCTContentTitleLabel} >Sub Title</InputLabel>
                                    <TextField
                                        fullWidth
                                        placeholder="Enter Subtitle"
                                        value={formData.subTitle}
                                        name="subTitle"
                                        variant="outlined"
                                        onChange={handleOnChange}
                                        className={styles.aSCTContentTitleInput}
                                        error={errors.subTitle !== undefined}
                                        helperText={errors.subTitle}
                                    />
                                </Box> */}
                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Title{" "}
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter Title"
                    value={formData.title}
                    name="title"
                    variant="outlined"
                    onChange={handleOnChange}
                    className={styles.aSCTContentTitleInput}
                    error={errors.title !== undefined}
                    helperText={errors.title}
                  />
                </Box>
                {/* <Box className={styles.aSCTContentDesc}>
                                    <InputLabel sx={{ textAlign: "left" }} className={styles.aSCTContentDescLabel}>Description</InputLabel>
                                    <Textarea
                                        placeholder="Description"
                                        name="description"
                                        value={formData.description}
                                        minRows={4}
                                        maxRows={4}
                                        onChange={handleOnChange}
                                        className={styles.aSCTContentDescInput}
                                    />
                                    {errors.description && (
                                        <FormHelperText error>{errors.description}</FormHelperText>
                                    )}
                                </Box> */}
                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Button text
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter Button Text"
                    value={formData.buttonText}
                    name="buttonText"
                    variant="outlined"
                    onChange={handleOnChange}
                    className={styles.aSCTContentTitleInput}
                    error={errors.buttonText !== undefined}
                    helperText={errors.buttonText}
                  />
                </Box>
                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Button Url
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter Button Url"
                    value={formData.buttonURL}
                    name="buttonURL"
                    variant="outlined"
                    onChange={handleOnChange}
                    className={styles.aSCTContentTitleInput}
                    error={errors.buttonURL !== undefined}
                    helperText={errors.buttonURL}
                  />
                </Box>
                <Box className={`${styles.aSCTContentType} identity_picture `}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTypeLabel}
                  >
                    Image
                  </InputLabel>
                  <ImageUpload
                    name="image"
                    label="Upload Image"
                    previewWidth={200}
                    maxFileSize={5242880}
                    onChange={handleIDImageChange}
                    uploadPreviewProp={uploadPreviewProp}
                    isRequired={true}
                  />
                </Box>
              </Form>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
