import React, { createContext, useContext, useState, ReactNode } from "react";

export interface PaymentData {
    data: {
      paymentScheduleId: number;
      amount: number;
    }[];
    totalAmount?: number;
    paymentId?: number;
  }

// Define the shape of the context data
interface SelectedDataContextType {
  selectedIntall: PaymentData; 
  setSelectedInstall: React.Dispatch<React.SetStateAction<PaymentData>>;
}

const SelectedDataContext = createContext<SelectedDataContextType | undefined>(undefined);

export function SelectedDataProvider({ children }: { children: ReactNode }) {
  const [selectedIntall, setSelectedInstall] = useState<PaymentData>({ data: [] });

  return (
    <SelectedDataContext.Provider value={{ selectedIntall, setSelectedInstall }}>
      {children}
    </SelectedDataContext.Provider>
  );
}

export function useSelectedData(): SelectedDataContextType {
  const context = useContext(SelectedDataContext);
  if (context === undefined) {
    throw new Error("useSelectedData must be used within a SelectedDataProvider");
  }
  return context;
}
