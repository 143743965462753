import React from "react";
import { SERVER_KEY } from "../../config/constants/EnviormentVariables";
import { Avatar } from "@mui/material";

const ImageView = (params:any) => {
  const baseUrl = SERVER_KEY;
  const imageUrl = `${baseUrl}${params.imageName}`;



  const style = {
    width: "40px",
    height: "40px",
    marginRight: "20px",
    padding:"0!important",
    background:"#f3eace",
      '& .MuiAvatar-img': {
        objectFit: "contain",
        padding: "5px"
      }
  };

  return (
    <>
      <Avatar
       variant="square"
        sx={style}
        alt={params.name ? params.name.toUpperCase() : ""}
        src={imageUrl ?? ""}
      />
    </>
  );
};

export default ImageView;
