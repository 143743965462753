import { Box, Typography } from "@mui/material";
import styles from "./Kids.module.scss";
import { useState } from "react";
import KisdsList from "./kidsList";
import { Link } from "react-router-dom";
import Filter from "../filters/filters";
import {
  GetUserRole,
  HasPermission,
} from "../../../../../helper/helperFunction";

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

const KidsListView = () => {
  const [filters, setFilters] = useState<FilterState>();

  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);
  };

  return (
    <Box className={styles.assignmentPagePanel}>
      <Box className="course-content parent_student_list" p={0}>
        <h1 className="main-heading">Students</h1>
        <Box className="btn-wrapper">
          <HasPermission permission="create.manage-kids">
            <Link to={"edit"}>Add Student</Link>
          </HasPermission>
        </Box>
      </Box>
      <Filter onFiltersChange={handleFiltersChange} />
      <KisdsList filterState={filters} />
    </Box>
  );
};

export default KidsListView;
