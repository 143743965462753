import React, { RefObject, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { useCartContext } from "../../../../context/CartContext";
import CustomModal from "../../../../components/popups/Modal";
import styles from "./style.module.css";
import { CURRENCY_SYMBOL } from "../../../../config/constants/constants";

interface Installment {
  installmentNumber: number;
  dueDate: string;
  amount: string;
}

interface Tdata {
  installments: Installment[];
  totalInstallments: number | null;
  totalAmount: string;
  firstAmountOfInstallment: string;
}
interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleShow: (show: boolean) => void;
  setCurrentPay: (pay: number | null) => void;
}

const InstallmentsTable: React.FC<ModalProps> = ({
  open,
  handleClose,
  handleShow,
  setCurrentPay,
}) => {
  const { subTotalPrice, SectionForInstallment, cartId } = useCartContext();

  const defaultData = {
    installments: [],
    totalInstallments: null,
    totalAmount: "",
    firstAmountOfInstallment: "",
  };
  const [installmentData, setInstallmentData] = useState<Tdata>(defaultData);

  useEffect(() => {
    if (cartId) {
      const fetchData = async () => {
        const res = await api.get(
          resolveUrl(AdminApi.CART_ITEM_INSTALLMENT, `${cartId}`)
        );
        setInstallmentData(res.data);
        setCurrentPay(res.data.firstAmountOfInstallment);
      };
      fetchData();
    } else {
      setInstallmentData(defaultData);
      setCurrentPay(null);
    }
  }, [cartId, SectionForInstallment, subTotalPrice]);

  return (
    <CustomModal state={open} setState={handleShow} width={800}>
      <Box className={`${styles.installment_count}`}>
        <p>
          {installmentData && installmentData.totalInstallments} Installments
        </p>
      </Box>
      <TableContainer
        component={Paper}
        className={styles.installment_popup_wrap}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Installment</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installmentData &&
              installmentData.installments.map((installment: any) => (
                <TableRow key={installment.installmentNumber}>
                  <TableCell>{`Installment ${installment.installmentNumber}`}</TableCell>
                  <TableCell>{installment.dueDate}</TableCell>
                  <TableCell>
                    {CURRENCY_SYMBOL && CURRENCY_SYMBOL}
                    {installment.amount}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={styles.amount_box}>
        <p>
          Total Course Amount:{" "}
          <span className={styles.amount}>
            {CURRENCY_SYMBOL && CURRENCY_SYMBOL}
            {installmentData && installmentData.totalAmount}{" "}
          </span>
        </p>
        <p>
          Current Pay Amount:{" "}
          <span className={styles.amount}>
            {CURRENCY_SYMBOL && CURRENCY_SYMBOL}
            {installmentData && installmentData.firstAmountOfInstallment}
          </span>
        </p>
      </Box>
      <button className="closeBtn" onClick={handleClose}>
        +
      </button>
    </CustomModal>
  );
};

export default InstallmentsTable;
