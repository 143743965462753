import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import HomeSchooling from "../manage-home-schooling";
import HomeBannerCms from "../manage-home-banner";
import OurPrograms from "../manage-our-programs";
import WhystudyOnline from "../manage-why-study";
import EnrollNow from "../manage-enroll-now";
import SampleSubjects from "../manage-sample-course";
import DynamicMeta from "../manage-meta-tag/dynamic-meta";
import GlobalCMSPopup from "../parent-form-popup";

const HomePageCms = () => {
  const tabs = [
    {
      label: "Home Banner",
      content: <HomeBannerCms />,
    },
    {
      label: "Home Schooling",
      content: <HomeSchooling />,
    },
    {
      label: "Our programs",
      content: <OurPrograms />,
    },
    {
      label: "Why Study",
      content: <WhystudyOnline />,
    },
    // {
    //   label: "Enroll Now",
    //   content: <EnrollNow />,
    // },
    // {
    //   label: "Enroll Form Thanks",

    //   content: (
    //     <GlobalCMSPopup
    //       type="enroll-form-thanks"
    //       heading="Enroll Form Thanks"
    //       successPathUrl="/admin/cms/home"
    //     />
    //   ),
    // },
    {
      label: "Sample Subjects",
      content: <SampleSubjects />,
    },
    {
      label: "Home  Meta Tag",
      content: <DynamicMeta type="home-page" heding="Home Meta" />,
    },
  ];

  return (
    <>
      <h1>Home</h1>
      <Box className="setting-tabs-container">
        <DynamicTabs tabs={tabs} />
      </Box>
    </>
  );
};
export default HomePageCms;
