import { useState, useEffect, useCallback } from "react";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import {
  Alert,
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import ImageUpload from "../../../../components/UploadImage";
import SelectOptionsApi from "../../../../components/SelectApi";
import { useParams } from "react-router-dom";
import validationSchema from "./validation";
import Form from "../../../../components/forms/Form";
import SelectOptions from "../../../../components/Select";
import { GetUserRole } from "../../../../helper/helperFunction";
import { useAuth } from "../../../../hooks/auth/auth";
import DatePicker from "react-date-picker";
import PhoneInput from "react-phone-input-2";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-phone-input-2/lib/style.css";
import { Textarea } from "@mui/joy";

export default function EditStudentProfile() {
  const [formData, setFormData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "+44",
    gender: "",
    address: "",
    country_id: "",
    state: "",
    city: "",
    zipCode: "",
    profile_picture: "",
    parent_id: "",
    year_group: "",
    date_of_birth: "",
    identity_picture: "",
    subject_detail: "",
  });

  const [uploadIdentity, setUploadIdentity] = useState<any>(null);

  const ukLocale = "en-GB"; // UK locale
  const handleDateChange = (date: any) => {
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    setFormData({
      ...formData,
      date_of_birth: localDate.toISOString().split("T")[0],
    });
  };

  const [uploadPreviewProp, setUploadPreviewProp] = useState<any>(null);

  const genderOptions = [
    { id: "Male", options: "Male" },
    { id: "Female", options: "Female" },
    { id: "Others", options: "Other" },
  ];

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const handleImageChange = (name: any, selectedFile: any) => {
    setFormData({
      ...formData,
      profile_picture: selectedFile,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const handleIDImageChange = (name: any, selectedFile: any) => {
    setFormData({
      ...formData,
      identity_picture: selectedFile,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      phone: `+${value}`,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      phone: undefined,
    }));
  };

  const handleUpdate = (name: any, value: any) => {
    setFormData((prevFormData: any) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const auth = useAuth();
  const userID = auth.state?.user.id;

  const params = useParams();
  const isSuperAdmin = GetUserRole() === "Super Admin";
  const ID = isSuperAdmin ? params.id : userID;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (ID) {
          const res = await api.get(
            resolveUrl(AdminApi.USER_MODULE_STUDENT_VIEW, ID)
          );
          const identity_picture = res.data.Profile.identity_picture || null;
          const shouldSkipValidation = !!identity_picture;

          setFormData({
            first_name: res.data.Profile.first_name || "",
            last_name: res.data.Profile.last_name || "",
            email: res.data.Profile.email || "",
            phone: res.data.Profile.phone || "",
            gender: res.data.Profile.gender || "",
            address: res.data.Profile.address || "",
            country_id: res.data.Profile.country_id || "",
            state: res.data.Profile.state_id || "",
            city: res.data.Profile.city_id || "",
            zipCode: res.data.Profile.zipcode || "",
            parent_id: res.data.Profile.parent_id || "",
            year_group: res.data.Profile.year_group || "",
            date_of_birth: res.data.Profile.date_of_birth || "",
            subject_detail: res.data.Profile.subject_detail || "",
            identity_picture: shouldSkipValidation ? null : identity_picture,
          });

          setUploadPreviewProp(res.data.Profile.profile_picture);
          setUploadIdentity(res.data.Profile.identity_picture);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [ID]);

  return (
    <>
      <div className="container global-user">
        <Typography variant="h1" className="main-heading">
          {GetUserRole() === "Super Admin" ? "Edit Student" : "Edit Profile"}
        </Typography>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              successPath={
                GetUserRole() === "Super Admin" ? "/admin/user/student" : ""
              }
              submitUrl={AdminApi.USER_MODULE_STUDENT_EDIT}
              formData={formData}
              editId={GetUserRole() === "Super Admin" ? params.id : userID}
              validate={customValidation}
              multipart={true}
            >
              <h2 className="content-title">Personal Info</h2>
              <Grid container justifyContent={"space-between"}>
                <Grid
                  item
                  xl={9}
                  lg={9}
                  xs={12}
                  sx={{ order: { lg: "inherit", md: 2 } }}
                >
                  {isSuperAdmin ? (
                    <Grid
                      item
                      xs={12}
                      container
                      sx={{ mb: 3, alignItems: "center" }}
                    >
                      <Grid item xl={2} lg={3} xs={12}>
                        <InputLabel sx={{ textAlign: "left" }}>
                          Select Parent*
                        </InputLabel>
                      </Grid>
                      <Grid item xl={6.5} lg={9} xs={12}>
                        <SelectOptionsApi
                          apiEndpoint={AdminApi.FETCH_ALL_PARENT}
                          formData={formData}
                          onUpdate={handleUpdate}
                          labelKey="name"
                          name="parent_id"
                          valueKey="id"
                          errorText={errors.parent_id}
                        />
                        {errors.parent_id && (
                          <Alert severity="error">
                            {errors.parent_id.message}
                          </Alert>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} lg={3} xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        First Name*
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6.5} lg={9} xs={12}>
                      <TextField
                        fullWidth
                        name="first_name"
                        variant="outlined"
                        placeholder="Enter First Name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        error={errors.first_name !== undefined}
                        helperText={errors.first_name}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} lg={3} xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Last Name*
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6.5} lg={9} xs={12}>
                      <TextField
                        fullWidth
                        name="last_name"
                        variant="outlined"
                        placeholder="Enter Last Name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        error={errors.last_name !== undefined}
                        helperText={errors.last_name}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} lg={3} xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>Email*</InputLabel>
                    </Grid>
                    <Grid item xl={6.5} lg={9} xs={12}>
                      <TextField
                        fullWidth
                        name="email"
                        variant="outlined"
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={errors.email !== undefined}
                        helperText={errors.email}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xl={2}
                  lg={2}
                  xs={12}
                  className="circle upload-image profile"
                  style={{ marginRight: "0" }}
                  sx={{ order: { lg: "inherit", md: 1 } }}
                  container
                >
                  <ImageUpload
                    name="profile_picture"
                    label="Upload Photo"
                    previewWidth={200}
                    maxFileSize={5242880}
                    onChange={handleImageChange}
                    uploadPreviewProp={uploadPreviewProp}
                    isRequired={false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>Phone*</InputLabel>
                </Grid>
                <Grid item xl={6.5} lg={9} xs={12}>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    disabled
                    value={formData.phone}
                    onChange={handlePhoneChange}
                  />
                  {errors.phone && (
                    <FormHelperText error>{errors.phone}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>Gender*</InputLabel>
                </Grid>
                <Grid item xl={6.5} lg={9} xs={12}>
                  <SelectOptions
                    options={genderOptions}
                    formData={formData}
                    onUpdate={handleUpdate}
                    name="gender"
                    labelKey="options"
                    valueKey="id"
                    errorText={errors.gender}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel> Date of Birth* </InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <DatePicker
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date"
                    onChange={handleDateChange}
                    value={
                      formData.date_of_birth
                        ? new Date(formData.date_of_birth)
                        : null
                    }
                    yearAriaLabel="Year"
                    maxDate={new Date()} // Disable future dates
                    locale={ukLocale}
                  />
                  {errors.date_of_birth && (
                    <FormHelperText error>
                      {errors.date_of_birth}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  item
                  xl={2.5}
                  lg={3}
                  md={12}
                  sx={{
                    textAlign: { lg: "center" },
                    marginTop: { lg: 0, xs: 3 },
                  }}
                >
                  <InputLabel sx={{ paddingLeft: { lg: 2 } }}>
                    Year Group*
                  </InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <Select
                    sx={{ maxWidth: "100%" }}
                    required
                    id="outlined-required"
                    fullWidth
                    name="year_group"
                    value={formData.year_group}
                    onChange={handleInputChange}
                    error={errors.year_group !== undefined}
                    size="small"
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      <em>{"Select Option"}</em>
                    </MenuItem>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.year_group && (
                    <FormHelperText error>{errors.year_group}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Street Address*
                  </InputLabel>
                </Grid>
                <Grid item xl={6.5} lg={9} xs={12}>
                  <TextField
                    fullWidth
                    name="address"
                    variant="outlined"
                    placeholder="Street Address"
                    value={formData.address}
                    onChange={handleInputChange}
                    error={errors.address !== undefined}
                    helperText={errors.address}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} md={12}>
                  <InputLabel> Country*</InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <SelectOptionsApi
                    apiEndpoint={AdminApi.COUNTRIES}
                    formData={formData}
                    onUpdate={handleUpdate}
                    labelKey="name"
                    name="country_id"
                    valueKey="id"
                    defaultText="Select Option"
                    errorText={true}
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  xl={2.5}
                  lg={3}
                  md={12}
                  sx={{
                    textAlign: { lg: "center" },
                    marginTop: { lg: 0, xs: 3 },
                  }}
                >
                  <InputLabel sx={{ paddingLeft: { lg: 2 } }}>
                    State/County*
                  </InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <TextField
                    fullWidth
                    name="state"
                    variant="outlined"
                    value={formData.state}
                    onChange={handleInputChange}
                    error={errors.state !== undefined}
                    helperText={errors.state}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} md={12}>
                  <InputLabel> City*</InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <TextField
                    fullWidth
                    name="city"
                    variant="outlined"
                    value={formData.city}
                    onChange={handleInputChange}
                    error={errors.city !== undefined}
                    helperText={errors.city}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xl={2.5}
                  lg={3}
                  md={12}
                  sx={{
                    textAlign: { lg: "center" },
                    marginTop: { lg: 0, xs: 3 },
                  }}
                >
                  <InputLabel sx={{ paddingLeft: { lg: 2 } }}>
                    Zip/Postal Code*{" "}
                  </InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <TextField
                    fullWidth
                    name="zipCode"
                    variant="outlined"
                    placeholder="Zip/Postal Code "
                    value={formData.zipCode}
                    onChange={handleInputChange}
                    error={errors.zipCode !== undefined}
                    helperText={errors.zipCode}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mt: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Subject details*
                  </InputLabel>
                </Grid>
                <Grid item xl={6.5} lg={9} xs={12}>
                  <Textarea
                    name="subject_detail"
                    value={formData.subject_detail}
                    minRows={4}
                    maxRows={4}
                    onChange={handleInputChange}
                  />
                  {errors.subject_detail && (
                    <FormHelperText error>
                      {errors.subject_detail}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel
                    sx={{
                      maxWidth: { lg: "135px", xs: "auto" },
                      marginTop: { lg: "0", xs: 3 },
                      textAlign: "left",
                      display: "inline-block!important",
                      textWrap: "inherit",
                    }}
                  >
                    Upload Passport Copy*
                  </InputLabel>
                </Grid>
                <Grid
                  item
                  xl={6.5}
                  lg={9}
                  xs={12}
                  className="identity_picture  Passport_Copy"
                >
                  <ImageUpload
                    name="identity_picture"
                    label="Upload Passport Copy"
                    previewWidth={200}
                    maxFileSize={5242880}
                    onChange={handleIDImageChange}
                    uploadPreviewProp={uploadIdentity}
                    isRequired={false}
                  />
                  {/* {errors.identity_picture && (
                    <FormHelperText error>
                      {errors.identity_picture}
                    </FormHelperText>
                  )} */}
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
