import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import "./style/style.css";
import { FrontendApi, api, resolveUrl } from "../../../config/apis";

type FAQItem = {
  id: number;
  categoryId: number;
  categoryTitle: string;
  question: string;
  answer: string;
  addedOn: string; // You might want to use a date type here
  modifiedOn: string; // You might want to use a date type here
};

type Category = {
  id?: number;
  title?: string;
};

const FaqAccordian = function () {
  const [expanded, setExpanded] = useState<string | null>(null);
  const [limit, setLimit] = useState(5);
  const [totalItem, setTotalItem] = useState();

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null);
    };

  const [accordionData, setAccordionData] = useState<FAQItem[]>();

  const [catList, setCatList] = useState<Category[]>();

  const [catId, setCatId] = useState<Category>({
    id: -1,
    title: "All",
  });

  const handleCatId = (id: number | undefined, title: string | undefined) => {
    setCatId({
      id: id,
      title: title,
    });
    setLimit(5);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (catId?.id === -1) {
          const res = await api.get(resolveUrl(FrontendApi.FAQS_DATA), {
            params: {
              limit: limit,
            },
          });
          setAccordionData(res.data.items);
          setTotalItem(res.data.meta.totalItems);
        } else {
          const res = await api.get(resolveUrl(FrontendApi.FAQS_DATA), {
            params: {
              categoryId: catId?.id ?? "",
              limit: limit,
            },
          });
          setTotalItem(res.data.meta.totalItems);
          setAccordionData(res.data.items);
        }
      } catch (error) {
        console.error(error);
        setAccordionData([]);
      }
    };
    fetchData();
  }, [catId, limit]);

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const res = await api.get(FrontendApi.FAQS_CATLIST);
        setCatList(res.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchListData();
  }, []);

  const handleLoadMore = () => {
    setLimit((prev) => prev + 5);
  };
  return (
    <div className="faqMain">
      <div className="container">
        <div className="faqContain">
          <div
            className="faqAccordianMain"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            <Typography variant="h2">{catId?.title ?? "All"}</Typography>

            {accordionData && accordionData.length !== 0 ? (
              <>
                {accordionData &&
                  accordionData.map((item, index) => {
                    return (
                      <>
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                        >
                          <AccordionSummary
                            key={index}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                          >
                            <Typography variant="h6">
                              {item.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div
                              className="text_color_white"
                              dangerouslySetInnerHTML={{
                                __html: item?.answer && item?.answer,
                              }}
                            ></div>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    );
                  })}
              </>
            ) : (
              <Typography sx={{ marginLeft: "15px" }}>
                No FAQ'S for this category.
              </Typography>
            )}
            <Box sx={{ textAlign: "center" }}>
              {totalItem && limit < totalItem ? (
                <Button className="btn" onClick={handleLoadMore}>
                  Load More
                </Button>
              ) : (
                ""
              )}
            </Box>
          </div>
          <div
            className="faqTabs"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <div className="faqs_scroll">
              <div className={`tabItem ${catId?.id === -1 ? "active" : ""} `}>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCatId(-1, "All");
                  }}
                >
                  All
                </Link>
              </div>
              {catList &&
                catList.map((item, index) => {
                  return (
                    <>
                      <div
                        className={`tabItem ${
                          catId?.id === item.id ? "active" : ""
                        } `}
                        key={index}
                      >
                        <Link
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCatId(item.id, item.title);
                          }}
                        >
                          {item.title}
                        </Link>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAccordian;
