import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import FtLogo from "./asset/images/edexlogo.svg";
import MapIcon from "./asset/images/map-marker-alt.svg";
import EnvelopIcon from "./asset/images/Envelope.svg";
import PhoneIcone from "./asset/images/Phone.svg";
import FbIcon from "./asset/images/fb-icon.svg";
import LinkedIcon from "./asset/images/linkedin-icon.svg";
import TwtIcon from "./asset/images/twt-icon.svg";
import YtIcon from "./asset/images/yt-icon.svg";
import "./style/style.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FrontendApi, api } from "../../../config/apis";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface HomePageFooter {
  id: number;
  title: string;
  description: string;
  type: string;
  phone: string;
  email: string;
  address: string;
  addressUrl: string;
  copyRightText: string;
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
  youtubeLink: string;
  linkedinLink: string;
  created_at: string;
  updated_at: string;
}

const FooterMain = () => {
  const [footerData, setFooterData] = useState<HomePageFooter>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(FrontendApi.FRONTEND_FOOTER);
        setFooterData(res.data.homePageFooter);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="FooterMain">
      <div className="ftContainer">
        <div className="ft-Contain">
          <div className="infoContain">
            <Link to="/" className="ftLogo">
              <img src={FtLogo} alt="Exdex Online" />
            </Link>
            <div className="textContent">
              <h4>{footerData && footerData.title}</h4>
              <p>{footerData && footerData.description}</p>
            </div>
          </div>
          <div className="navigationContain">
            <div className="siteLinkContent">
              <div className="infoLink">
                <h4 className="title">Information</h4>
                <ul className="ftMenu">
                  {/* <li className="ftItem">
                    <a href="#contact-us">Contact</a>
                  </li>
                  <li className="ftItem">
                    <a href="#">Resources</a>
                  </li>
                  <li className="ftItem">
                    <a href="#">Site Map</a>
                  </li> */}
                  <li className="ftItem">
                    <Link to="/terms-conditions">Terms of Use</Link>
                  </li>
                  <li className="ftItem">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="contactLink">
                <h4 className="title">Contact Us</h4>
                <ul className="contactDetails">
                  <li className="contactItem">
                    <Link
                      to={(footerData && footerData.addressUrl) ?? "#"}
                      target="_blank"
                    >
                      <div className="icon">
                        <img src={MapIcon} alt="map" />
                      </div>
                      <p>{footerData && footerData.address}</p>
                    </Link>
                  </li>
                  <li className="contactItem">
                    <Link to={`mailto:${footerData && footerData.email}`}>
                      <div className="icon">
                        <img src={EnvelopIcon} alt="envelop" />
                      </div>
                      <p>{footerData && footerData.email}</p>
                    </Link>
                  </li>
                  <li className="contactItem">
                    <Link to={`tel:${footerData && footerData.phone}`}>
                      <div className="icon">
                        <img src={PhoneIcone} alt="Phone" />
                      </div>
                      <p>{footerData && footerData.phone}</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Mobile Accordian */}
            <div className="mobileAccordian">
              <Accordion className="AccordianFt">
                <AccordionSummary
                  className="aTitle"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-content"
                  id="panel-header"
                >
                  <Typography variant="h6">Information</Typography>
                </AccordionSummary>
                <AccordionDetails className="acContain">
                  <ul className="ftMenu">
                    {/* <li className="ftItem">
                      <a href="#contact-us">Contact</a>
                    </li>
                    <li className="ftItem">
                      <a href="#">Resources</a>
                    </li>
                    <li className="ftItem">
                      <a href="#">Site Map</a>
                    </li> */}
                    <li className="ftItem">
                      <Link to="/terms-conditions">Terms of Use</Link>
                    </li>
                    <li className="ftItem">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion className="AccordianFt">
                <AccordionSummary
                  className="aTitle"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-content"
                  id="panel-header"
                >
                  <Typography variant="h6">Contact Us</Typography>
                </AccordionSummary>
                <AccordionDetails className="acContain">
                  <ul className="contactDetails">
                    <li className="contactItem">
                      <Link
                        to={(footerData && footerData.addressUrl) ?? "#"}
                        target="_blank"
                      >
                        <div className="icon">
                          <img src={MapIcon} alt="map" />
                        </div>
                        <p>{footerData && footerData.address}</p>
                      </Link>
                    </li>
                    <li className="contactItem">
                      <Link to={`mailto:${footerData && footerData.email}`}>
                        <div className="icon">
                          <img src={PhoneIcone} alt="Phone" />
                        </div>
                        <p>{footerData && footerData.email}</p>
                      </Link>
                    </li>
                    <li className="contactItem">
                      <Link to={`tel:${footerData && footerData.phone}`}>
                        <div className="icon">
                          <img src={EnvelopIcon} alt="envelop" />
                        </div>
                        <p>{footerData && footerData.phone}</p>
                      </Link>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* Mobile Accordian End */}
            <div className="bottomFt">
              <div className="copyText">
                {/* <p>Copyright © 2022 <Link to="#">EdEx Online</Link>. All rights reserved..</p> */}
                <p>{footerData && footerData.copyRightText}</p>
              </div>
              <div className="socialLinks">
                <ul className="socialIcons">
                  <li className="icon fb">
                    <Link
                      to={(footerData && footerData.facebookLink) ?? "#"}
                      target="_blank"
                    >
                      <img src={FbIcon} alt="FaceBook" />
                    </Link>
                  </li>
                  <li className="icon linkin">
                    <Link
                      to={(footerData && footerData.linkedinLink) ?? "#"}
                      target="_blank"
                    >
                      <img src={LinkedIcon} alt="LinkedIn" />
                    </Link>
                  </li>
                  <li className="icon twt">
                    <Link
                      to={(footerData && footerData.twitterLink) ?? "#"}
                      target="_blank"
                    >
                      <img src={TwtIcon} alt="X" />
                    </Link>
                  </li>
                  <li className="icon yt">
                    <Link
                      to={(footerData && footerData.youtubeLink) ?? "#"}
                      target="_blank"
                    >
                      <img src={YtIcon} alt="YouTube" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMain;
