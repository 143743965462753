import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import styles from "./style.module.scss";
import ImageUpload from "../../../components/UploadImage";
import * as Yup from "yup";
import Modal from "@mui/material/Modal";
import { PopupStyle } from "../../../helper/helperFunction";

export default function EditWhyStudy({
  open,
  handleClose,
  width,
  setFormSuccess,
  editId,
}: any) {
  type FormData = {
    title?: string;
    description?: string;
    image?: File | null;
    id?: number | null;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const [uploadPreview, setUploadPreview] = useState(null);

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    image: null,
  });

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const handleIDImageChange = (name: any, selectedFile: any) => {
    setFormData({
      ...formData,
      image: selectedFile,
    });
  };

  useEffect(() => {
    if (editId) {
      const fetchData = async () => {
        try {
          const res = await api.get(
            resolveUrl(AdminApi.WHY_STUDY_ONLINE_TABLE_VIEW, editId)
          );
          setFormData({
            title: res.data.homePageStudyOnlineContent.title ?? "",
            description: res.data.homePageStudyOnlineContent.description ?? "",
            id: res.data.homePageStudyOnlineContent.id,
          });
          setUploadPreview(res.data.homePageStudyOnlineContent.image ?? "");
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, [editId]);

  useEffect(() => {
    if (!open) {
      setFormData({
        title: "",
        description: "",
        image: null,
      });
      setUploadPreview(null);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={PopupStyle} maxWidth={width}>
        <Box className={styles.aSCreateTicketMain}>
          <Typography
            variant="h2"
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              paddingTop: "20px",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            Why Study Online
          </Typography>
          <Box className={styles.aSCTContainer}>
            <Form
              submitUrl={AdminApi.WHY_STUDY_ONLINE_TABLE_EDIT}
              formData={formData}
              successPath="/admin/cms/home"
              validate={customValidation}
              multipart={true}
              setFormSuccess={setFormSuccess}
            >
              <Box className={styles.aSCTContentTitle}>
                <InputLabel
                  sx={{ textAlign: "left" }}
                  className={styles.aSCTContentTitleLabel}
                >
                  Title
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder="Enter Title"
                  value={formData.title}
                  name="title"
                  variant="outlined"
                  onChange={handleOnChange}
                  className={styles.aSCTContentTitleInput}
                  error={errors.title !== undefined}
                  helperText={errors.title}
                />
              </Box>
              <Box className={styles.aSCTContentDesc}>
                <InputLabel
                  sx={{ textAlign: "left" }}
                  className={styles.aSCTContentDescLabel}
                >
                  Description
                </InputLabel>
                <Textarea
                  placeholder="Description"
                  name="description"
                  value={formData.description}
                  minRows={4}
                  maxRows={4}
                  onChange={handleOnChange}
                  className={styles.aSCTContentDescInput}
                />
                {errors.description && (
                  <FormHelperText error>{errors.description}</FormHelperText>
                )}
              </Box>
              <Box className={`${styles.aSCTContentType} identity_picture `}>
                <InputLabel
                  sx={{ textAlign: "left" }}
                  className={styles.aSCTContentTypeLabel}
                >
                  Image
                </InputLabel>
                <ImageUpload
                  name="upload_file"
                  label="Upload Image"
                  previewWidth={200}
                  maxFileSize={5242880}
                  onChange={handleIDImageChange}
                  uploadPreviewProp={uploadPreview}
                  isRequired={true}
                />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
