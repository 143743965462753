import { Box, CircularProgress, Grid } from "@mui/material";
import styles from "../studentAssignments.module.scss";
import { getAssignmentsDetail } from "../../../../../../helper/helperFunction";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Moment from "react-moment";
import { AdminApi, api, resolveUrl } from "../../../../../../config/apis";
import { useAuth } from "../../../../../../hooks/auth/auth";
import AssignmentEditor from "../../../../teacher-dashbaord/assignments/AssignmentEditor";

interface PrivateComment {
  commentId: number;
  comment: string;
  first_name: string;
  last_name: string;
  created_at: string;
  role: string;
  commentCreatedDate: Date;
}

interface StudentAssignmentMeta {
  file_path?: string;
  file_name?: string;
}

export interface Assignment {
  CourseName: string;
  totalMarks: number;
  obtainedMarks: any;
  AssignmentTitle: string;
  AssignmentDescription: string;
  StudentAssignmentId: number;
  studentAssignmentStatus: string;
  AssignmentMedia: string | null;
  StudentAssignmentMeta: StudentAssignmentMeta | null;
  PrivateComments: PrivateComment[];
  upload_path: any;
  upload_title: any;
  studentAssignmentMarkingStatus: any;
  checked_assignment: any;
}

const StudentAssignmentsPDFEdit = () => {
  const { REACT_APP_API_BASE, REACT_APP_ENV } = process.env;

  const [isDataLoaded, setIsDataLoaded] = useState(true);

  const params = useParams();
  const [assignment, setAssignment] = useState<Assignment>();

  useEffect(() => {
    const getAssignments = async () => {
      try {
        const res = await getAssignmentsDetail(params.id ?? "");
        setAssignment(res.AssignmentData);

        setIsDataLoaded(false);
      } catch (error) {
        console.error(error);
      }
    };

    getAssignments();
  }, [params.id]);

  const fileUrl = getFileUrl(assignment?.StudentAssignmentMeta?.file_path);

  const apiKey =
    REACT_APP_ENV == "development"
      ? `${REACT_APP_API_BASE}/`
      : REACT_APP_API_BASE;

  return (
    <>
      {isDataLoaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 350,
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      ) : (
        <Box className={styles.assignmentDetailPanel}>
          <Grid container className={styles.assignmentDetailPanelTitle}>
            <Box className="assignment_editor_wraper">
              <span
                style={{ display: "none" }}
                className="btn"
                data-apikey={`${apiKey}${AdminApi.Edited_PDF_UPLOAD}/${params.id}`}
                id="open_pdf_tool"
                data-pdf={fileUrl}
                data-assignmentid={params.id}
              ></span>
              {fileUrl && <AssignmentEditor />}
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default StudentAssignmentsPDFEdit;

const getFileUrl = (filePath: any) => {
  const { REACT_APP_SERVER_BASE } = process.env;

  // Check if REACT_APP_API_BASE is defined before constructing the URL
  if (!REACT_APP_SERVER_BASE) {
    return null; // or handle the undefined case as needed
  }

  const baseUrl = REACT_APP_SERVER_BASE;

  const fileUrl = filePath && `${baseUrl}${filePath.replace("\\", "/")}`;
  return fileUrl;
};
