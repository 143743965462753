import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdminApi, api, resolveUrl } from "../../../../../../config/apis";
import { Box } from "@mui/material";
import { useStudentId } from "../../../../../../context/getStudentId";

export default function CourseSearch({ setCourseId, setCourseName }: any) {
  const { studentId } = useStudentId();

  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(
          resolveUrl(AdminApi.STUDENT_COURSE_LIST, `${studentId}`)
        );
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [studentId]);

  const handleCourseChange = (event: any, value: any) => {
    setCourseId(value && value.id);
    setCourseName(value && value.name);
  };

  return (
    <Box sx={{ maxWidth: "300px" }}>
      <Autocomplete
        disablePortal
        id="course_search"
        options={data}
        fullWidth
        getOptionLabel={(option: { name: string }) => option.name}
        onChange={handleCourseChange}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select Course" />
        )}
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            padding: "4px",
          },
        }}
      />
    </Box>
  );
}
