import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import DynamicTabs from "../../../components/tabs/Tabs";
import { QuizDescription } from "./QuizDescription";
import { QuizContent } from "./QuizContent";
import { useEffect, useState } from "react";

export const EditQuizInSubject = ({
  open,
  handleClose,
  setQuizDto,
  isEdit,
  editContentCallback,
  quizDto,
  contentIndex,
  errorMsg,
}: any) => {
  interface QuizData {
    name: any;
    description: string;
    tags: string[];
    totalMarks: number | null;
    duration: any;
    is_live: boolean;
    content: QuizContent[];
    courseId?: number;
  }

  interface QuizContent {
    question: string;
    answers: string[];
    answer_key: number[];
    type: string;
    id: any;
  }

  const initalData = {
    name: "",
    description: "",
    tags: [],
    totalMarks: null,
    duration: "",
    is_live: false,
    content: [],
  };

  const [formData, setFormData] = useState<QuizData>(initalData);

  const [customValue, setCustomValue] = useState<number>();

  useEffect(() => {
    if (!open) {
      setFormData(initalData);
      setCustomValue(0);
    }
  }, [open]);

  useEffect(() => {
    if (isEdit) {
      setFormData(quizDto);
    }
  }, [isEdit]);

  useEffect(() => {
    setQuizDto(formData);
  }, [formData]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "80vh",
    overflowY: "auto",
  };

  // Effect to add/remove class based on formData changes
  useEffect(() => {
    const buttons = document.querySelectorAll(
      ".subject_create_quiz .MuiTabs-root button:last-child"
    );

    if (buttons && buttons.length !== 0) {
      const lastButton = buttons[0];

      if (
        formData.name !== "" &&
        formData.totalMarks !== null &&
        formData.duration !== ""
      ) {
        lastButton.classList.add("is_active");
      } else {
        lastButton.classList.remove("is_active");
      }
    }
  }, [formData, customValue, open]);

  const tabs = [
    {
      label: "Quiz Description",
      content: (
        <QuizDescription
          setFormData={setFormData}
          formData={formData}
          setCustomValue={setCustomValue}
        />
      ),
    },
    {
      label: "Quiz Content",
      content: (
        <QuizContent
          errorMsg={errorMsg}
          setFormData={setFormData}
          formData={formData}
          editContentCallback={editContentCallback}
          contentIndex={contentIndex}
          isEdit={isEdit}
        />
      ),
    },
  ];

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} className="subject_create_quiz">
          <DynamicTabs
            tabs={tabs}
            customValue={customValue}
            setCustomValue={setCustomValue}
          />
        </Box>
      </Modal>
    </>
  );
};
