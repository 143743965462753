import React, { useState } from "react";
import Switch from "@mui/joy/Switch";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { toast } from "react-toastify";

interface SwitchCellProps {
  id: string;
  is_active: boolean;
  refresh: any;
}

const SwitchCell: React.FC<SwitchCellProps> = ({ id, is_active, refresh }) => {
  const [checked, setChecked] = useState<boolean>(is_active);
  const [openConfirm, setOpenConfirm] = useState(false);

  const toggleConfirmDialog = () => {
    setOpenConfirm(!openConfirm);
  };

  const onActive = async () => {
    try {
      const response = await api.get(resolveUrl(AdminApi.FORUM_ARCHIVE, id));
      // Assuming response.status indicates success
      if (response.status === 200) {
        setChecked((prevChecked) => !prevChecked); // Toggle the checked state
        setOpenConfirm(false);
        refresh();
        toast.success("Post archived successfully");
      } else {
        throw new Error("Failed to archive post"); // Throw an error for non-successful responses
      }
    } catch (error) {
      console.error("Error archiving post:", error);
      setOpenConfirm(false);
      toast.error("Error archiving post. Please try again later.");
    }
  };

  return (
    <>
      <Switch
        checked={checked}
        onChange={toggleConfirmDialog} // Open confirmation dialog on switch change
        sx={{
          "--Switch-trackWidth": "33.49px",
          "--Switch-trackHeight": "18.41px",
          "--Switch-thumbSize": "12px",
        }}
      />

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ textAlign: "center", padding: "20px" }}>
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you Archive this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button className="btn" onClick={toggleConfirmDialog}>
              Disagree
            </Button>
            <Button className="btn" onClick={onActive} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default SwitchCell;
