import React, { useState, useEffect, SetStateAction } from "react";
import { Alert, Box, FormHelperText, Grid } from "@mui/material";
import styles from "./manage-discount.module.scss";
import { AdminApi } from "../../../config/apis";
import { useAuth } from "../../../hooks/auth/auth";
import Form from "../../../components/forms/Form";
import StudentCard from "./StudentCard";
import { useParams } from "react-router-dom";
import { useCartContext } from "../../../context/CartContext";
import * as yup from "yup";

interface CourseItem {
  studentIds: number[];
  coursesId?: string;
}

interface PurchaseCourse {
  parentId: string;
  courseItems: CourseItem[];
  id?: number;
}

interface PurchaseCourseProps {
  id: string;
  closeModal: any;
}

const PurchaseCourseForm: React.FC<PurchaseCourseProps> = ({
  id,
  closeModal,
}) => {
  const { updateCartData, cartId } = useCartContext();

  const params = useParams<{ id: string }>();

  const parent = useAuth();
  const defaultFormData: PurchaseCourse = {
    parentId: parent.state?.user.id || "",
    id: cartId,
    courseItems: [],
  };

  const [formData, setFormData] = useState<PurchaseCourse>(defaultFormData);
  const [errors, setErrors] = useState<any>({});

  const schema = yup.object().shape({
    courseItems: yup
      .array()
      .of(
        yup.object().shape({
          studentIds: yup.array().min(1, "At least one Student is required"),
        })
      )
      .test(
        "at-least-one-student",
        "At least one Student is required",
        function (value: any) {
          return value.some((item: any) => item.studentIds.length > 0);
        }
      )
      .required("Course Items are required"),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [checkedList, setCheckedList] = useState<number[]>([]);

  const handleStudentCardClick = (studentId: number) => {
    const isChecked = checkedList.includes(studentId);
    if (isChecked) {
      setCheckedList((prevCheckedList) =>
        prevCheckedList.filter((id) => id !== studentId)
      );
    } else {
      setCheckedList((prevCheckedList) => [...prevCheckedList, studentId]);
    }
    setErrors({});
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      courseItems: [
        {
          studentIds: checkedList,
          coursesId: params.id,
        },
      ],
    }));
  }, [checkedList, params.id]);

  const handleFormSuccess = (response: any) => {
    if (response) {
      updateCartData();
      closeModal();
    }
  };

  return (
    <Box className={styles.discount}>
      <Box sx={{ width: "100%" }}>
        <Grid container className="page-container">
          {errors.courseItems && (
            <Alert severity="error" sx={{ width: "100%" }}>
              <ul style={{ padding: "0", margin: "0" }}>
                <li>{errors.courseItems}</li>
              </ul>
            </Alert>
          )}
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.ADD_TO_CART}
              formData={formData}
              editId={id}
              onSuccess={handleFormSuccess}
              successPath="/admin/course-cart"
              validate={customValidation}
            >
              <StudentCard
                checkedList={checkedList}
                onStudentCardClick={handleStudentCardClick}
              />
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PurchaseCourseForm;
