import AboutPage from "../pages/frontend/about-page";
import ContactPage from "../pages/frontend/contact-page";
import CurriculumPage from "../pages/frontend/curriculum-page";
import NotFound from "../pages/frontend/error/NotFound";
import FaqPage from "../pages/frontend/faq-page";
import HomePage from "../pages/frontend/home-page";
import CoursesPage from "../pages/frontend/igcse-page";
import PrivacyPolicy from "../pages/frontend/privacy-policy";
import TermsConditions from "../pages/frontend/terms-conditions";

export const FrontendRoutes = {
  path: "/",
  children: [
    {
      path: "",
      element: <HomePage />,
    },
    {
      path: "about",
      element: <AboutPage />,
    },
    {
      path: "contact-us",
      element: <ContactPage/>,
    },
    {
      path: "curriculum",
      element: <CurriculumPage />,
    },
    {
      path: "faq",
      element: <FaqPage />,
    },
    {
      path: "igcse",
      element: <CoursesPage type="IGCSE" />,
    },
    {
      path: "secondary-KS3",
      element: <CoursesPage type="Secondary-KS3" />,
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "terms-conditions",
      element: <TermsConditions />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};
