import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { api, resolveUrl } from "../../config/apis";

const SelectOptionsApi = ({
  disabled = "",
  apiEndpoint = "",
  formData = {},
  onUpdate = () => {},
  labelKey = "",
  name = "",
  valueKey = "",
  errorText = "",
  paramId = "",
  defaultText = "Select Option",
  ...params
}:any) => {
  const [options, setOptions] = useState<any>([]);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (apiEndpoint) {
          const res = await api.get(resolveUrl(apiEndpoint, paramId || ""));          
          setOptions(res.data.data || res.data.Courses);
          setError(null);
        } else {
          setError("API endpoint not provided.");
        }
      } catch (error) {
        setError("Error fetching options.");
      }
    };

    fetchData();
  }, [apiEndpoint, paramId, formData[name]]);

  const handleChange = (event:any) => {
    const { name, value } = event.target;
    onUpdate(name, value);
  };

  if (!options || options.length === 0) {
    // Handle the case when options are not available yet or are empty
    return (
      <>
        <Select
          sx={{ maxWidth: "100%" }}
          required
          fullWidth
          name={name}
          value={formData[name] || ""}
          onChange={handleChange}
          error={!!errorText}
          size="small"
          disabled={disabled}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>{defaultText}</em>;
            }
            return "";
          }}
        >
          <MenuItem key="" value="">
            <em>{defaultText}</em>
          </MenuItem>
        </Select>
        {errorText && <FormHelperText error>{errorText}</FormHelperText>}
      </>
    );
  }

  return (
    <>
      <Select
        sx={{ maxWidth: "100%" }}
        required
        fullWidth
        name={name}
        value={formData[name] || ""}
        onChange={handleChange}
        error={!!errorText}
        size="small"
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <em>{defaultText}</em>;
          }
          const selectedOption = options.find(
            (option:any) => option[valueKey] === selected
          );
          return selectedOption ? selectedOption[labelKey] : "";
        }}
      >
        <MenuItem key="" value="">
          <em>{defaultText}</em>
        </MenuItem>
        {options.map((option:any) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </>
  );
};

export default SelectOptionsApi;
