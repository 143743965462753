import { Box, Typography } from "@mui/material";
import styles from './studentAssignments.module.scss'
import StudentAssignmentTable from "./Student-assignment-table";
import { useState } from "react";
import Filter from "../filters/filters";


type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};


const StudentAssignments = () => {
  const [filters, setFilters] = useState<FilterState>();


  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);    
  };

  return (
    <Box className={styles.assignmentPagePanel}>
    <Typography variant="h2" className={styles.assignmentPanelTitle}>My Assignments</Typography>
    <Filter  onFiltersChange={handleFiltersChange} />
    <StudentAssignmentTable filterState={filters}/>
    
    </Box>
  );
};

export default StudentAssignments;
