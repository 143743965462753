import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
} from "@mui/material";
import styles from "./studentQuizes.module.scss";
import { useState, useEffect, useCallback } from "react";
import { AdminApi, api, resolveUrl } from "../../../../../config/apis";
import { useAuth } from "../../../../../hooks/auth/auth";
import { useNavigate, useParams } from "react-router-dom";
import { useQuizResContext } from "../../../../../context/QuizResponseContext";
import { GetAuthState } from "../../../../../helper/helperFunction";

const StudentQuizesDetailQue = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();

  const auth = useAuth();

  let userId: number | null = null;
  if (auth.state) userId = auth.state.user.id;

  const [quizData, setQuizData] = useState<any>(null);
  const [selectedAnswers, setSelectedAnswers] = useState<any>([]);
  const [studentQuizId, setStudentQuizId] = useState<number | null>(null);
  const { quizRes, setQuizRes, completion_duration, duration, setDuration } =
    useQuizResContext();

  const getQuizData = useCallback(async () => {
    const currentDate = new Date();
    const startingTime = currentDate.toISOString();

    const response = await api.post(resolveUrl(AdminApi.STUDENT_START_QUIZ), {
      quizId: params.id,
      startingTime: startingTime,
      studentId: userId,
    });
    if (response.data.status) {
      setQuizData(response.data.quiz);
      setStudentQuizId(response.data.studentQuizId);
      setClickCount(response.data.totalAttempts + 1);
      setDuration(response.data.duration);
    } else if (!response.data.status && response.data.isCompleted) {
      navigate(
        `/admin/student-quizes/quiz-result/${response.data.studentQuizId}`
      );
    }
  }, [params.id, navigate, userId]);

  useEffect(() => {
    getQuizData();
  }, [getQuizData]);

  const completionDurationInSeconds: any = completion_duration;
  const completionDurationInMinutes = completionDurationInSeconds / 60;

  let timeSpend: number;
  if (duration !== null && duration !== undefined) {
    timeSpend = duration - completionDurationInMinutes;
  } else {
    timeSpend = 0;
  }

  // const [noQuizLeft, setNoQuizLeft] = useState(false);
  // const [isReload, setIsReload] = useState(true);
  const [clickCount, setClickCount] = useState(1);

  // const quiz = quizRes as QuizResponse;

  const handleAnswerSelect = (answer: string) => {
    const isSelected = selectedAnswers.includes(answer);

    if (isSelected) {
      setSelectedAnswers(
        selectedAnswers.filter((answers: any) => answers !== answer)
      );
    } else {
      setSelectedAnswers([...selectedAnswers, answer]);
    }
  };

  const [btnDisabled, setBtnDisabled] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const courseId: string | null = urlParams.get("id");

  // Initialize selectedQuiz as null
  let selectedQuiz: string | null = null;
  let updatedJsonString: string | null = null;
  const CURRENT_USER = GetAuthState("USER");

  // If courseId is truthy, fetch the item from localStorage
  if (courseId) {
    selectedQuiz = localStorage.getItem(
      `selectedItem_${CURRENT_USER.id}${courseId}`
    );
  }

  // If selectedQuiz is not null, update the studentQuizStatus
  if (selectedQuiz) {
    try {
      // Parse the JSON string into a JavaScript object
      const jsonObject: any = JSON.parse(selectedQuiz);

      // Update the studentQuizStatus field
      if (jsonObject && jsonObject.content) {
        jsonObject.content.studentQuizStatus = "complete";
        jsonObject.content.studentQuizId = studentQuizId;

        // Convert the JavaScript object back to a JSON string
        updatedJsonString = JSON.stringify(jsonObject);
      } else {
        console.error("Invalid JSON structure:", jsonObject);
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  } else {
    console.error("No selected quiz found for courseId:", courseId);
  }

  // Now, you can proceed with the handleNext function
  const handleNext = async () => {
    setBtnDisabled(true);
    try {
      const response = await api.post(resolveUrl(AdminApi.STUDENT_SAVE_QUIZ), {
        quizId: params.id,
        studentQuizId: studentQuizId,
        quizContentId: quizData.id,
        answer: selectedAnswers,
        completion_duration: completionDurationInMinutes,
      });

      if (response.data.status) {
        setQuizData(response.data.quiz);
        setBtnDisabled(false);
        setClickCount((prevCount) => prevCount + 1);
      } else if (!response.data.status && response.data.isCompleted) {
        navigate(`/admin/student-quizes/quiz-result/${studentQuizId}`);
        if (updatedJsonString) {
          localStorage.setItem(
            `selectedItem_${CURRENT_USER.id}${courseId}`,
            updatedJsonString
          );
        }
      } else if (!response.data.status && response.data.duration === 0) {
        navigate(`/admin/student-quizes/quiz-result/${studentQuizId}`);
      }
    } catch (error) {
      console.error(error);
    }
    setSelectedAnswers([]);
  };

  const handleEndApiPost = async () => {
    try {
      const currentDate = new Date();
      const endingTime = currentDate.toISOString();
      await api.post(resolveUrl(AdminApi.STUDENT_QUIT_QUIZ), {
        quizId: params.id,
        endingTime: endingTime,
        studentId: userId,
        completion_duration: timeSpend,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Cleanup function
    return () => {
      handleEndApiPost();
    };
  }, []);

  // const handleEndApiPost = async () => {
  //   try {
  //     const currentDate = new Date();
  //     const endingTime = currentDate.toISOString();
  //     const response = await api.post(resolveUrl(AdminApi.STUDENT_QUIT_QUIZ), {
  //       quizId: params.id,
  //       endingTime: endingTime,
  //       studentId: userId,
  //       completion_duration: timeSpend,
  //     });

  //     if (response.data.quizStatus === "complete") {
  //       navigate(`/admin/student-quizes/quiz-result/${studentQuizId}`);
  //       console.log("Quiz completed");
  //     } else if (response.data.quizStatus === "resume") {
  //       navigate("/admin/student-quizes");
  //       console.log("Quiz resumed");
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log("Testing error");
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   if (completionDurationInMinutes !== null && completion_duration !== undefined && completion_duration <= 0) {
  //     handleEndApiPost();
  //   }
  // }, [completionDurationInMinutes, completion_duration]);

  // useEffect(() => {
  //   console.log(noQuizLeft);
  //   const handleEffect = async () => {
  //     if (noQuizLeft) {
  //       await handleEndApiPost();
  //     }
  //   };

  //   handleEffect();
  // }, [noQuizLeft]);

  // useEffect(() => {
  //   if (isReload) {
  //     console.log(isReload, "isReload");
  //     if (!quiz) {
  //       navigate("/admin/student-quizes");
  //     }
  //   }
  // }, [navigate, quiz]);

  // if (!quiz) {
  //   return null;
  // }

  return (
    <>
      {quizData && (
        <Box className={styles.quizesDetailPanelQues}>
          <Grid container className={styles.qDPMid}>
            <Grid item xs={12} lg={8} className={styles.qDPMidText}>
              <Typography
                variant="h4"
                className={styles.qDPMidTextContent}
                sx={{}}
              >
                Total Quiz: {props.totalQuiz}
              </Typography>
              <Typography
                variant="h4"
                className={styles.qDPMidTextContent}
                sx={{}}
              >
                Total Duration: {props.quizDuration} Minutes.
              </Typography>
              <Typography variant="h4" className={styles.qDPMidTextContent}>
                Your currently answered {clickCount - 1} questions.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4} className={styles.qDPMidAction}>
              {/* <Button
          className={styles.qDPMidActionBtn}
          onClick={() => {
            setNoQuizLeft(true);
          }}
        >
          Quit the Test
        </Button> */}
            </Grid>
          </Grid>

          <Box className={styles.qDPContainer}>
            <Grid container className={styles.qDPContent}>
              <Grid item xs={12} className={styles.qDPContentHead}>
                <Typography
                  variant="h2"
                  className={styles.qDPContentHeadNumber}
                >
                  #{clickCount}
                </Typography>
                <Typography variant="h2" className={styles.qDPContentHeadQue}>
                  {quizData && quizData.question}
                </Typography>
              </Grid>
              <Grid item xs={12} className={styles.qDPContentMidContainer}>
                <Box className={styles.qDPContentMidContent}>
                  <FormControl
                    component="fieldset"
                    className={styles.qDPContentMidAns}
                  >
                    {quizData &&
                      quizData.answers &&
                      JSON.parse(quizData.answers).map(
                        (answer: string, index: any) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={selectedAnswers.includes(index)}
                                onClick={() => handleAnswerSelect(index)}
                              />
                            }
                            label={answer}
                          />
                        )
                      )}
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} className={styles.qDPContentBottomActionSkip}>
                {/* <Button>Skip</Button> */}
              </Grid>
              <Grid item xs={6} className={styles.qDPContentBottomActionNext}>
                <Button disabled={btnDisabled} onClick={handleNext}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default StudentQuizesDetailQue;
