import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, resolveUrl } from "../../../../config/apis";
import { useState } from "react";
import { Box } from "@mui/material";

type props = {
  courseId?: number | null;
  paramId?: number | null;
};

const StudentQuizesTable = ({ courseId, paramId }: props) => {
  const [dataAvailable, setDataAvailable] = useState(true);

  const tableColumns = [
    {
      cell: (row: { quizTitle: string }) => row.quizTitle,
      selector: "quiz_name",
      name: "Quiz Title",
    },
    {
      cell: (row: { courseName: string }) => row.courseName,
      name: "Subject Name",
    },

    {
      cell: (row: { totalMarks: string; obtainedMarks: string }) =>
        `${row.obtainedMarks ?? "-"}/${row.totalMarks}`,
      name: "Marks",
    },
  ];

  const param = {
    courseId: courseId,
  };
  // Callback function to handle data availability
  const handleDataAvailable = (isAvailable: boolean) => {
    setDataAvailable(isAvailable);
  };
  return (
    <>
      {dataAvailable && (
        <Box className="page-container">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={resolveUrl(
              AdminApi.STUDENT_ASSIGN_QUIZZES_PDF,
              `${paramId}`
            )}
            actionColumns={false}
            actions={{
              edit: false,
              delete: false,
            }}
            dataFilter={param}
            pagination={false}
            onDataAvailable={handleDataAvailable}
            module="manage-kids"
          />
        </Box>
      )}
    </>
  );
};

export default StudentQuizesTable;
