import { Link, useNavigate, useParams } from "react-router-dom";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { Box } from "@mui/material";
import { DateView } from "../../../../components/DateView/DateView";

export default function UserViewAssignments() {
  const navigate = useNavigate();

  const params = useParams();

  const tableColumns = [
    {
      selector: (row: { courseName: string }) => row.courseName,
      name: "Title",
      sortable: true,
    },
    {
      selector: (row: { sectionTitle: string }) => row.sectionTitle,
      name: "Name",
      sortable: false,
    },
    // {
    //   selector: (row: { courseSection: string }) => row.courseSection,
    //   name: "Type",
    //   sortable: false,
    // },
    {
      selector: (row: { submittedOn: string }) => (
        <DateView date={row.submittedOn} />
      ),

      name: "Submitted on",
      sortable: false,
    },
    {
      selector: (row: { submittedBy: string }) => row.submittedBy,
      name: "Submitted By",
      sortable: false,
      width: "250px",
    },
    {
      selector: (row: { totalMarks: string }) => row.totalMarks,
      name: "Total Marks",
      sortable: false,
    },
    {
      selector: (row: { obtainedMarks: string }) => row.obtainedMarks,
      name: "Marks Obtained",
      sortable: false,
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/courses/view/${id}`);
  };

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(AdminApi.USER_TEACHER_ASSIGNMENT, `${params.id}`)}
        onView={onView}
        actions={{
          edit: false,
          delete: false,
          view: false,
        }}
        module="manage-users"
        actionColumns={false}
      />
    </>
  );
}
