import { useState, useEffect } from "react";
import * as yup from "yup";

interface FormData {
  name: string;
  email: string;
  phone: string;
  description: string;
  captchaToken?: string; // Add captchaToken to the form data
}

const useEnrollForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "+44",
    description:""
  });

  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {}
  );

  useEffect(() => {
    setFormData({
      name: "",
      email: "",
      phone: "+44",
      description:""
    });
  }, [formSuccess]);

  const handleOnChange = (event:any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const phoneRegExp = /^\+?[0-9]{6,20}$/;
  const minLength = 8;
  const maxLength = 15;

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    description: yup.string().required("Message is required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .min(minLength, `Phone number must be at least ${minLength} characters`)
      .max(maxLength, `Phone number can't exceed ${maxLength} characters`)
      .required("Phone number is required"),
  });

  const validateForm = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});

      return true;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleFormSuccess = (response: any) => {
    if (response) {
      setFormSuccess(response);
    }
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: `+${value}`,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: undefined,
    }));
  };

  return {
    formData,
    formSuccess,
    errors,
    handleOnChange,
    validateForm,
    handleFormSuccess,
    handlePhoneChange,
  };
};

export default useEnrollForm;
