import { Button, Grid, Typography, Card, Box, CardMedia } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import {
  GetUserRole,
  getObjectKeyValue,
  truncateTextWithEllipsis,
} from "../../../../helper/helperFunction";
import Videoicon from "../../../../static/images/video-icon.png";
import QuizIcon from "../../../../static/images/quiz-icon.png";
import AssignIcon from "../../../../static/images/summary.png";
import Summary from "../../../../static/images/ep_reading.svg";
import FileIcon from "../../../../static/images/assign-icon.png";

import { CourseData, Quiz } from "../../../../Type/courseTypes";
import { Link } from "react-router-dom";
import CustomModal from "../../../../components/popups/Modal";
import ReactPlayer from "react-player";
import BucketFileViewer from "../../../../components/buketFileViewer";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router";

interface CourseContentProps {
  courseData: CourseData;
  onSelectVideo?: (video: string, contentId: string) => void;
  current_Lesson?: any;
  // getFromLocal?: any;
  course_id?: any;
  userId?: any;
  currentSecIndex?: any;
}
export default function CourseContent({
  courseData,
  onSelectVideo,
  current_Lesson,
  // getFromLocal,
  course_id,
  userId,
  currentSecIndex,
}: CourseContentProps) {
  const [quiz, setQuiz] = useState<Quiz>({
    quiz: [],
  });

  const navigate = useNavigate();

  const isStudent = GetUserRole() === "Student";

  const loadActiveVideo = (video: string, contentId: string) => {
    onSelectVideo && onSelectVideo(video, contentId);
  };

  // const loaclData = getFromLocal;

  // Callback Hook

  // const getQuizData = useCallback(async () => {
  //   const quiz = await api.get(resolveUrl(AdminApi.QUIZ));

  //   setQuiz({
  //     quiz: quiz.data.quiz,
  //   });
  // }, []);

  // Use Effect Hook
  // useEffect(() => {
  //   getQuizData();
  // }, [getQuizData]);

  const [showPopup, setShowPopup] = useState<boolean>(false);

  const handleOpenModal = () => {
    setShowPopup(true);
  };

  const handleCloseModal = () => {
    setShowPopup(false);
  };
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const [selectedContent, setSelectedContent] = useState<any>();
  const [currentLesson, setCurrentLesson] = useState<any>(null);

  const { REACT_APP_SERVER_BASE } = process.env;
  const baseUrl = REACT_APP_SERVER_BASE;

  let videoURL = "";
  if (typeof selectedVideo === "string") {
    const videoPath = selectedVideo;
    // videoURL = `${baseUrl}${videoPath}`;
    videoURL = videoPath;
  }

  // const [openIndex, setOpenIndex] = useState<number | null>(0);
  // const toggle = (index: number) => {
  //   setOpenIndex(openIndex === index ? null : index);
  // };

  const [openIndex, setOpenIndex] = useState<number | null>(() => {
    return currentSecIndex ? currentSecIndex : 0;
  });

  const toggle = (index: number) => {
    const newIndex = openIndex === index ? null : index;
    setOpenIndex(newIndex);

    // localStorage.setItem(
    //   `course_${userId?.id}_${course_id}_index`,
    //   index.toString()
    // );
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        sx={{ mb: 2 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container className="course-content">
          {courseData.sections &&
            courseData.sections.map((section: any, sectionIndex: number) => {
              return (
                <Grid
                  className="course-section-wrap "
                  container
                  key={sectionIndex}
                >
                  <Grid
                    style={
                      section.sectionScheduleStatus === "lock"
                        ? { pointerEvents: "none", opacity: 0.5 }
                        : {}
                    }
                    className={
                      section.hasOwnProperty("isCompleted")
                        ? section.isCompleted === "active" ||
                          section.isCompleted === "completed"
                          ? "unlocked course-section"
                          : "locked course-section"
                        : "locked course-section course-section-catalog"
                    }
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 2 }}
                  >
                    <Grid
                      item
                      width={"100%"}
                      onClick={() => toggle(sectionIndex)}
                    >
                      <h3>
                        <span className="sec_title">
                          Section {sectionIndex + 1}:
                        </span>
                        {section.title}
                        {section.sectionScheduleStatus !== "lock" && (
                          <span className="accordian_section">
                            {openIndex === sectionIndex ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </span>
                        )}
                      </h3>
                    </Grid>
                  </Grid>
                  {section.sectionScheduleStatus === "lock" && (
                    <span className="lock_icon_visible">
                      <LockIcon />
                    </span>
                  )}

                  {openIndex === sectionIndex && (
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      className="quiz-section"
                      sx={{ px: 2 }}
                      style={
                        section.sectionScheduleStatus === "lock"
                          ? { pointerEvents: "none", opacity: 0.5 }
                          : {}
                      }
                    >
                      {section.courseContent &&
                        section.courseContent.map(
                          (content: any, contentIndex: number) => {
                            if (content.type === "assignment") {
                              return (
                                <Grid
                                  className={
                                    content.hasOwnProperty("isCompleted")
                                      ? content.isCompleted === "active" ||
                                        content.isCompleted === "completed"
                                        ? "unlocked single-lesson"
                                        : "locked single-lesson"
                                      : "locked single-lesson"
                                  }
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  key={contentIndex}
                                >
                                  <Grid
                                    item
                                    style={!isStudent ? { width: "100%" } : {}}
                                    className={`${
                                      content.hasOwnProperty(
                                        "Last_completed_item"
                                      )
                                        ? "Last_completed_Active"
                                        : ""
                                    } ${
                                      current_Lesson &&
                                      content.id === current_Lesson.id
                                        ? "active_conten"
                                        : ""
                                    }`}
                                  >
                                    {isStudent ? (
                                      <>
                                        <h2
                                          onClick={() => {
                                            content.hasOwnProperty(
                                              "isCompleted"
                                            ) &&
                                              onSelectVideo &&
                                              onSelectVideo(
                                                content.assignment,
                                                content
                                              );
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span>Part {contentIndex + 1}: </span>
                                          <img src={AssignIcon} alt="icon" />
                                          <p>
                                            {truncateTextWithEllipsis(
                                              content.textData,
                                              50
                                            )}
                                          </p>
                                        </h2>
                                      </>
                                    ) : (
                                      <>
                                        <h2
                                          onClick={() => {
                                            setShowPopup(true);
                                            setSelectedContent(content);
                                            setCurrentLesson(content.type);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            pointerEvents: "all",
                                          }}
                                        >
                                          <span>Part {contentIndex + 1}: </span>
                                          <img src={AssignIcon} alt="icon" />
                                          <p>
                                            {truncateTextWithEllipsis(
                                              content.textData,
                                              50
                                            )}
                                          </p>
                                        </h2>
                                      </>
                                    )}
                                    {content.hasOwnProperty("isCompleted") &&
                                      content.isCompleted === "completed" && (
                                        <span className="Completed">
                                          Completed
                                        </span>
                                      )}
                                  </Grid>
                                </Grid>
                              );
                            } else if (content.type === "addFile") {
                              return (
                                <Grid
                                  className={
                                    content.hasOwnProperty("isCompleted")
                                      ? content.isCompleted === "active" ||
                                        content.isCompleted === "completed"
                                        ? "unlocked single-lesson"
                                        : "locked single-lesson"
                                      : "locked single-lesson"
                                  }
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  key={contentIndex}
                                >
                                  <Grid
                                    style={!isStudent ? { width: "100%" } : {}}
                                    item
                                    className={`${
                                      content.hasOwnProperty(
                                        "Last_completed_item"
                                      )
                                        ? "Last_completed_Active"
                                        : ""
                                    } ${
                                      current_Lesson &&
                                      content.id === current_Lesson.id
                                        ? "active_conten"
                                        : ""
                                    } `}
                                  >
                                    {isStudent ? (
                                      <>
                                        <h2
                                          onClick={() => {
                                            content.hasOwnProperty(
                                              "isCompleted"
                                            ) &&
                                              onSelectVideo &&
                                              onSelectVideo(
                                                content.assignment,
                                                content
                                              );
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span>Part {contentIndex + 1}: </span>
                                          <img src={FileIcon} alt="icon" />
                                          <p>
                                            {truncateTextWithEllipsis(
                                              content.textData,
                                              50
                                            )}
                                          </p>
                                        </h2>
                                      </>
                                    ) : (
                                      <>
                                        <h2
                                          onClick={() => {
                                            setShowPopup(true);
                                            setSelectedContent(content);
                                            setCurrentLesson(content.type);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            pointerEvents: "all",
                                          }}
                                        >
                                          <span>Part {contentIndex + 1}: </span>
                                          <img src={FileIcon} alt="icon" />
                                          <p>
                                            {truncateTextWithEllipsis(
                                              content.textData,
                                              50
                                            )}
                                          </p>
                                        </h2>
                                      </>
                                    )}
                                    {content.hasOwnProperty("isCompleted") &&
                                      content.isCompleted === "completed" && (
                                        <span className="Completed">
                                          Completed
                                        </span>
                                      )}
                                  </Grid>
                                </Grid>
                              );
                            } else if (
                              content.type === "quiz" ||
                              content.type === "addQuiz"
                            ) {
                              return (
                                <Grid
                                  className={
                                    content.hasOwnProperty("isCompleted")
                                      ? content.isCompleted === "active" ||
                                        content.isCompleted === "completed"
                                        ? "unlocked single-lesson"
                                        : "locked single-lesson"
                                      : "locked single-lesson"
                                  }
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  key={contentIndex}
                                >
                                  <Grid
                                    style={!isStudent ? { width: "100%" } : {}}
                                    item
                                    className={`${
                                      content.hasOwnProperty(
                                        "Last_completed_item"
                                      )
                                        ? "Last_completed_Active"
                                        : ""
                                    } ${
                                      current_Lesson &&
                                      content.id === current_Lesson.id
                                        ? "active_conten"
                                        : ""
                                    }`}
                                  >
                                    {isStudent ? (
                                      <>
                                        <h2
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            content.hasOwnProperty(
                                              "isCompleted"
                                            ) &&
                                            onSelectVideo &&
                                            onSelectVideo(content.quiz, content)
                                          }
                                        >
                                          <span>Part {contentIndex + 1}: </span>
                                          <img src={QuizIcon} alt="icon" />
                                          {content.textData}
                                          {getObjectKeyValue(
                                            [],
                                            "name",
                                            "id",
                                            content.quiz
                                          )}
                                        </h2>
                                      </>
                                    ) : (
                                      <>
                                        <h2
                                          style={{
                                            cursor: "pointer",
                                            pointerEvents: "all",
                                          }}
                                          onClick={() =>
                                            navigate(
                                              `/admin/courses/quiz/view/${content.quiz}`
                                            )
                                          }
                                        >
                                          <span>Part {contentIndex + 1}: </span>
                                          <img src={QuizIcon} alt="icon" />
                                          {content.textData}
                                        </h2>
                                      </>
                                    )}
                                    {content.hasOwnProperty("isCompleted") &&
                                      content.isCompleted === "completed" && (
                                        <span className="Completed">
                                          Completed
                                        </span>
                                      )}
                                  </Grid>
                                </Grid>
                              );
                            } else if (content.type === "summary") {
                              return (
                                <Grid
                                  className={
                                    content.hasOwnProperty("isCompleted")
                                      ? content.isCompleted === "active" ||
                                        content.isCompleted === "completed"
                                        ? "unlocked single-lesson"
                                        : "locked single-lesson"
                                      : "locked single-lesson"
                                  }
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  key={contentIndex}
                                >
                                  <Grid
                                    style={!isStudent ? { width: "100%" } : {}}
                                    item
                                    className={`${
                                      content.hasOwnProperty(
                                        "Last_completed_item"
                                      )
                                        ? "Last_completed_Active"
                                        : ""
                                    } ${
                                      current_Lesson &&
                                      content.id === current_Lesson.id
                                        ? "active_conten"
                                        : ""
                                    }`}
                                  >
                                    {isStudent ? (
                                      <>
                                        <h2
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            content.hasOwnProperty(
                                              "isCompleted"
                                            ) &&
                                            onSelectVideo &&
                                            onSelectVideo(
                                              content.summary,
                                              content
                                            )
                                          }
                                        >
                                          <span>Part {contentIndex + 1}: </span>
                                          <img src={Summary} alt="icon" />
                                          Summary
                                        </h2>
                                      </>
                                    ) : (
                                      <>
                                        <h2
                                          onClick={() => {
                                            setShowPopup(true);
                                            setSelectedContent(
                                              content.textDataContent
                                            );
                                            setCurrentLesson(content.type);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            pointerEvents: "all",
                                          }}
                                        >
                                          <span>Part {contentIndex + 1}: </span>
                                          <img src={Summary} alt="icon" />
                                          Summary
                                        </h2>
                                      </>
                                    )}
                                    {content.hasOwnProperty("isCompleted") &&
                                      content.isCompleted === "completed" && (
                                        <span className="Completed">
                                          Completed
                                        </span>
                                      )}
                                  </Grid>
                                </Grid>
                              );
                            } else if (content.type === "video") {
                              return (
                                <Grid
                                  className={
                                    content.hasOwnProperty("isCompleted")
                                      ? content.isCompleted === "active" ||
                                        content.isCompleted === "completed"
                                        ? "unlocked single-lesson"
                                        : "locked single-lesson"
                                      : "locked single-lesson"
                                  }
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  key={contentIndex}
                                >
                                  <Grid
                                    style={!isStudent ? { width: "100%" } : {}}
                                    item
                                    className={`${
                                      content.hasOwnProperty(
                                        "Last_completed_item"
                                      )
                                        ? "Last_completed_Active"
                                        : ""
                                    } ${
                                      current_Lesson &&
                                      content.id === current_Lesson.id
                                        ? "active_conten"
                                        : ""
                                    }`}
                                  >
                                    {isStudent ? (
                                      <>
                                        <h2
                                          onClick={() => {
                                            content.hasOwnProperty(
                                              "isCompleted"
                                            ) &&
                                              onSelectVideo &&
                                              onSelectVideo(
                                                content.upload_file,
                                                content
                                              );
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span>Part {contentIndex + 1}: </span>

                                          <img src={Videoicon} alt="icon" />
                                          {content.upload_title}
                                        </h2>
                                      </>
                                    ) : (
                                      <>
                                        <h2
                                          onClick={() => {
                                            setShowPopup(true);
                                            setSelectedVideo(
                                              content.upload_file
                                            );
                                            setCurrentLesson(content.type);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            pointerEvents: "all",
                                          }}
                                        >
                                          <span>Part {contentIndex + 1}: </span>

                                          <img src={Videoicon} alt="icon" />
                                          {content.upload_title}
                                        </h2>
                                      </>
                                    )}
                                    {content.hasOwnProperty("isCompleted") &&
                                      content.isCompleted === "completed" && (
                                        <span className="Completed">
                                          Completed
                                        </span>
                                      )}
                                  </Grid>
                                </Grid>
                              );
                            }
                            return false;
                          }
                        )}
                    </Grid>
                  )}
                </Grid>
              );
            })}
        </Grid>

        <CustomModal state={showPopup} setState={""} width={650}>
          <button className="closeBtn" onClick={handleCloseModal}>
            +
          </button>

          <Grid item xs={12}>
            {currentLesson && currentLesson === "video" && (
              <>
                <CardMedia
                  component="video"
                  image={videoURL}
                  controls
                  controlsList="nodownload"
                />
              </>
            )}
            {currentLesson && currentLesson === "summary" && (
              <>
                <Box>
                  <h2>Summary</h2>
                  <p>{selectedContent}</p>
                </Box>
              </>
            )}

            {currentLesson && currentLesson === "assignment" && (
              <>
                <Box>
                  <h2>Assignment</h2>
                  <h3>{selectedContent.textData}</h3>
                  <BucketFileViewer
                    name={"Download File"}
                    filePath={selectedContent.upload_file}
                  />
                  <p>{selectedContent.textDataContent}</p>
                </Box>
              </>
            )}
            {currentLesson && currentLesson === "addFile" && (
              <>
                <Box>
                  <h2>File</h2>
                  <h3>{selectedContent.textData}</h3>
                  <BucketFileViewer
                    name={"Download File"}
                    filePath={selectedContent.upload_file}
                  />
                  <p>{selectedContent.textDataContent}</p>
                </Box>
              </>
            )}
          </Grid>
        </CustomModal>
      </Grid>
    </>
  );
}
