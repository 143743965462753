import {
  Avatar,
  Box,
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import styles from "./topStudent.module.scss";
import { Button } from "react-bootstrap";
import Content from "../../../manage-courses/content/Content";
import Enrolledstudents from "../../../manage-courses/Enrolledstudents/Enrolledstudents";
import ViewQuizes from "../../../manage-courses/Quizes/Quizes";
import ViewAssignment from "../../../manage-courses/Assignment/Assignment";
import DynamicTabs from "../../../../../components/tabs/Tabs";
import React from "react";
import { CourseData } from "../../../../../Type/courseTypes";

const DEFAULT_COURSE_VIEW = {
  name: "",
  have_live_quiz: false,
  price: "",
  year: "",
  description: "",
  sections: [],
  teacherId: "",
  secID: [],
};

const ViewStudentPanel = () => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#071836" : "#308fe8",
    },
  }));
  const [courseViewData] = React.useState<CourseData>(DEFAULT_COURSE_VIEW);

  const tabs = [
    {
      label: "Courses",
      content: <Enrolledstudents />,
    },
    {
      label: "Assigments",
      content: <ViewQuizes />,
    },
    {
      label: "Quiz",
      content: <ViewAssignment />,
    },
    {
      label: "Complaints",
      content: <ViewAssignment />,
    },
  ];

  return (
    <Box className={styles.studentViewPanel}>
      <Typography variant="h2" className={styles.studentViewPanelTitle}>
        Students
      </Typography>
      <Box className={styles.studentViewPanelInfo}>
        <Grid container className={styles.studentViewPanelContainer}>
          <Grid item xs={12} md={12} lg={5} xl={4} className={styles.sVPDtail}>
            <Grid container className={styles.sVPDtailContainer}>
              <Grid item xs={12} className={styles.sVPDtailImg}>
                <Avatar
                  className={styles.studentNameAvatar}
                  sx={{ width: 142, height: 142, mr: 0 }}
                  alt="Jhon Doe"
                  src="/static/images/avatar/2.jpg"
                />
              </Grid>
              <Grid item xs={12} className={styles.sVPDtailName}>
                <Typography variant="h4" className={styles.sVPDtailNameText}>
                  Jhon Doe
                </Typography>
                <Typography variant="body1" className={styles.sVPDtailNameSub}>
                  S/o Mark Doe
                </Typography>
              </Grid>
              <Grid item xs={12} className={styles.sVPDtailScore}>
                <BorderLinearProgress
                  variant="determinate"
                  value={80}
                  className={styles.sVPDtailScoreBar}
                />
                <Typography
                  variant="body1"
                  className={styles.sVPDtailScoreText}
                >
                  Avg Score <span>80</span>
                </Typography>
              </Grid>
              <Grid item xs={6} className={styles.sVPDtailBtnMsg}>
                <Button>Message</Button>
              </Grid>
              <Grid item xs={6} className={styles.sVPDtailBtnReport}>
                <Button>Report</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={7} xl={8} className={styles.sVPInfo}>
            <Grid container className={styles.sVPInfoContainer}>
              <Grid item xs={12} className={styles.sVPInfoTextContainer}>
                <Typography variant="h6">Personal Data</Typography>
                <Typography variant="body1">
                  Gender: <span>Male</span>
                </Typography>
                <Typography variant="body1">
                  Date of Birth: <span>23/02/1992</span>
                </Typography>
                <Typography variant="body1">
                  Phone Number: <span>123 456 7890</span>
                </Typography>
                <Typography variant="body1">
                  Email: <span>jhondoe@gmail.com</span>
                </Typography>
                {/* <Typography variant="body1">Last Active: <span>1 hr ago</span></Typography> */}
              </Grid>
              <Grid item xs={12} className={styles.sVPInfoTextContainer}>
                <Typography variant="h6">Address</Typography>
                <Typography variant="body1">
                  Street address: <span>1289 ABC Avenue</span>
                </Typography>
                <Typography variant="body1">
                  City: <span>Tampa</span>
                </Typography>
                <Typography variant="body1">
                  State: <span>Florida</span>
                </Typography>
                <Typography variant="body1">
                  Zip Code: <span>12345</span>
                </Typography>
              </Grid>
              <Grid item xs={12} className={styles.sVPInfoTextContainer}>
                <Typography variant="h6">Educational Info</Typography>
                <Typography variant="body1">
                  IGSCE Year: <span>2020</span>
                </Typography>
                <Typography variant="body1">
                  IGSCE Subjects selected: <span>10</span>
                </Typography>
                <Typography variant="body1">
                  Subjects Completed: <span>6</span>
                </Typography>
              </Grid>
              <Grid item xs={12} className={styles.sVPInfoTextContainer}>
                <Typography variant="h6">Registration</Typography>
                <Typography variant="body1">
                  Date: <span>12/12/2021</span>
                </Typography>
                <Typography variant="body1">
                  Expiry: <span>12/12/2022</span>
                </Typography>
                <Typography variant="body1">
                  Subjects Package: <span>Package 1 Yearly</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h4" className={styles.studentViewPanelTitleSub}>
        More Detail
      </Typography>
      <Box className={styles.studentViewPanelTabs}>
        <DynamicTabs tabs={tabs} />
      </Box>
    </Box>
  );
};

export default ViewStudentPanel;
