import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { UserTeacherDetails } from "../../../../helper/helperFunction";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import Moment from "react-moment";
import styles from "./style.module.scss";
import DynamicTabs from "../../../../components/tabs/Tabs";
import ViewComplaints from "../../manage-complaints/view";
import UserViewCourses from "./courses";
import UserEnrolledstudents from "./kids-enrolled";
import UserViewQuizzes from "./quizz";
import UserViewAssignments from "./assignments";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";
import ViewComplaintsUsers from "../../manage-complaints/ComplaintsOtherUsers";

type User = {
  city: string | null;
  country: string | null;
  email: string;
  gender: string | null;
  name: string;
  phoneNumber: string;
  profile: number;
  registrationDate: string;
  role: string;
  state: string | null;
  streetAddress: string;
  students: string;
  enrolledStudents?: string;
};

export default function DetailsTeacher() {
  const params = useParams<{ id?: string }>();
  const [teacherInfo, setTeacherInfo] = useState<User>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeacherDetails = async () => {
      const res = await UserTeacherDetails(params.id ?? "");
      setTeacherInfo(res);
    };
    fetchTeacherDetails();
  }, [params.id]);

  const onEdit = async () => {
    navigate(`/admin/user/teachers/edit/${params.id}`);
  };
  const tabs = [
    {
      label: "Subjects",
      content: <UserViewCourses />,
    },
    {
      label: "Complaints",
      content: <ViewComplaintsUsers params={params.id} />,
    },
    {
      label: "Students",
      content: <UserEnrolledstudents />,
    },
    {
      label: "Quiz",
      content: <UserViewQuizzes />,
    },
    {
      label: "Assignments",
      content: <UserViewAssignments />,
    },
  ];
  const baseUrl = SERVER_KEY;

  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: "700", marginBottom: "20px" }}>
        Teacher
      </Typography>
      <Box className={styles.studentProfileList}>
        <Grid container spacing={2}>
          <Grid item xl={2.5} lg={3} md={12} xs={12}>
            <Card className={styles.studentCardBx}>
              <CardContent className={styles.studentCardContent}>
                <Avatar
                  alt={teacherInfo?.name ? teacherInfo.name.toUpperCase() : ""}
                  src={`${baseUrl}${teacherInfo?.profile}`}
                  className={styles.stuProfile}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  className={styles.stuName}
                >
                  <span className="bold">{teacherInfo?.name}</span>
                </Typography>
              </CardContent>

              <div className={styles.stuViewDetailBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onEdit()}
                  className={styles.stuViewBtn}
                >
                  Edit Teacher
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid item xl={9.5} lg={9} md={12} xs={12}>
            <Grid container className="teachers_add_yi">
              <Grid item xs={12} className={styles.gridItem}>
                <Typography variant="h3">Personal Data</Typography>
                <span>
                  Gender:<span className="bold">{teacherInfo?.gender}</span>
                </span>
                {/* <span>
                  Date of Birth:<span className="bold">23/02/1992</span>
                </span> */}
                <span>
                  Phone Number:
                  <span className="bold">{teacherInfo?.phoneNumber}</span>
                </span>
                <span>
                  Email:<span className="bold">{teacherInfo?.email}</span>
                </span>
              </Grid>
              <Grid item xs={12} className={styles.gridItem}>
                <Typography variant="h3">Address</Typography>
                <span>
                  Street address:
                  <span className="bold">{teacherInfo?.streetAddress}</span>
                </span>
                <span>
                  City:
                  <span className="bold">{teacherInfo?.city}</span>
                </span>
                <span>
                  State:<span className="bold">{teacherInfo?.state}</span>
                </span>
              </Grid>

              <Grid
                item
                xs={12}
                className={`${styles.gridItem} ${styles.gridItemLast}`}
              >
                <Typography variant="h3">Student Info</Typography>

                <span>
                  Student Enrolled:
                  <span className="bold"> {teacherInfo?.enrolledStudents}</span>
                </span>
                <span>
                  Date:{" "}
                  <span className="bold">
                    <Moment format="DD-MM-YYYY">
                      {teacherInfo?.registrationDate}
                    </Moment>
                  </span>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Typography
        variant="h5"
        sx={{ fontWeight: "700", marginBottom: "20px", marginTop: "30px" }}
      >
        More Detail
      </Typography>
      <DynamicTabs tabs={tabs} />
    </>
  );
}
