import { Box, Grid, Typography } from "@mui/material";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Link, useNavigate } from "react-router-dom";
import { DateView } from "../../../components/DateView/DateView";
import CustomModal from "../../../components/popups/Modal";
import { useState } from "react";
import { HasPermission } from "../../../helper/helperFunction";

export default function ViewNoticeBoard() {
  const navigate = useNavigate();
  const [viewNotice, setViewNotice] = useState<string>("");

  const tableColumns = [
    {
      selector: (row: { title: string }) => row.title,
      name: "Title",
      sortable: false,
    },
    {
      selector: (row: { noticeFor: string }) => row.noticeFor,
      name: "Notice For",
      sortable: false,
    },
    {
      selector: (row: { created_at: string }) => (
        <DateView date={row.created_at} />
      ),
      name: "Added On",
      sortable: false,
    },
  ];

  const ViewData = async (id: any) => {
    try {
      if (id) {
        const res = await api.get(resolveUrl(AdminApi.NOTICE_BOARD_EDIT, id));
        setViewNotice(res.data.data.notice);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onView = async (id: string) => {
    ViewData(id);
    setModalOpen(true);
  };
  const onEdit = async (id: string) => {
    navigate(`/admin/notice-board/edit/${id}`);
  };
  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.NOTICE_BOARD_DEL, id));
  };
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="course-content">
        <HasPermission permission="create.manage-notice-board">
          <Box className="btn-wrapper">
            <Link to={"edit"}>Add Notice</Link>
          </Box>
        </HasPermission>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <h1 className="main-heading">Notice Board</h1>
        </Grid>
      </Grid>

      <div className="page-container">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.NOTICE_BOARD_PAGINATION}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
          module="manage-notice-board"
        />
      </div>

      <CustomModal state={modalOpen} setState={setModalOpen}>
        <button className="closeBtn" onClick={handleCloseModal}>
          +
        </button>
        <div
          dangerouslySetInnerHTML={{ __html: viewNotice && viewNotice }}
        ></div>
      </CustomModal>
    </>
  );
}
