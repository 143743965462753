import { Typography } from "@mui/material";
import Icon1 from "./asset/img/Icon1 .svg";
// import Icon2 from './asset/img/Icon2.svg';
// import Icon3 from './asset/img/Icon3.svg';
// import Icon4 from './asset/img/Icon4.svg';
import "./style/style.css";
import { HomePageContentInfo, Slide } from "../type/type";
import { useReadMorePost } from "../../../helper/helperFunction";

interface StudyEdexOnlineProps {
  studyOnlineInfo?: HomePageContentInfo;
  studyOnline?: Slide[];
}

const StudyEdexOnlineInner = function ({
  studyOnlineInfo,
  studyOnline,
}: StudyEdexOnlineProps) {
  const { isExpanded, toggleReadMore, truncateText } = useReadMorePost(250);

  return (
    <div className="studyEdexInnerMain">
      <div className="container">
        <Typography variant="h2" data-aos="fade-left" data-aos-duration="2000">
          {studyOnlineInfo?.title ?? ""}
        </Typography>
        <div
          className="cardContain"
          data-aos="fade-right"
          data-aos-duration="2500"
        >
          {studyOnline &&
            studyOnline.slice(0, 5).map((item, index) => {
              return (
                <>
                  <div className="card" key={index}>
                    <img src={Icon1} alt="Icon1" />
                    <div className="textContain">
                      <Typography variant="h4">{item.title}</Typography>
                      {/* <Typography variant="body1">
                        {item.description}
                      </Typography> */}
                      <Typography variant="body1">
                        {item.description &&
                          (isExpanded(index)
                            ? item.description
                            : truncateText(item.description))}
                        {item.description && item.description.length > 250 && (
                          <button
                            className="read_more_btn"
                            onClick={() => toggleReadMore(index)}
                          >
                            {isExpanded(index) ? "Read Less" : "Read More"}
                          </button>
                        )}
                      </Typography>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default StudyEdexOnlineInner;
