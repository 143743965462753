import { Avatar, Box } from "@mui/material";
import {
  GetUserRole,
  HasPermission,
  isAdminUser,
  toTitleCase,
} from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi } from "../../../config/apis";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AvatarImage from "../../../components/Avatar";

export default function ViewAdminSupport() {
  const navigate = useNavigate();

  const isAdmin = isAdminUser();

  const [filterData, setFilterData] = useState<null | {}>({});
  const [filters, setFilters] = useState<null | {}>({});

  const tableColumns = [
    {
      selector: (row: { title: string }) => row.title,
      name: "Title",
      sortable: false,
    },
    {
      cell: (row: { userName: string; userProfile: string }) => (
        <>
          <AvatarImage imageName={row.userProfile} name={row.userName} />
          {row.userName && toTitleCase(`${row.userName}`)}
        </>
      ),
      name: "Name",
      sortable: true,
    },
    {
      selector: (row: { userEmail: any }) => row.userEmail,
      name: "Email",
      sortable: false,
    },
    {
      selector: (row: { status: string }) => toTitleCase(row.status),
      name: "Status",
      sortable: false,
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/support/${id}`);
  };
  const onEdit = async (id: string) => {
    navigate(`/admin/support/edit/${id}`);
  };

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading fullwith">Support Tickets</h1>

        <HasPermission permission="create.manage-admin-support">
          {!isAdmin && (
            <Box className="btn-wrapper">
              <Link to={"edit"}>Add Ticket</Link>
            </Box>
          )}
        </HasPermission>
      </div>

      <div className="page-container">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.SUPPORT_PAGINATE}
          filters={filters}
          onView={onView}
          onEdit={onEdit}
          actions={{
            view: true,
            // edit: false,
            delete: false,
          }}
          module="manage-admin-support"
        />
      </div>
    </>
  );
}
