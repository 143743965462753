import React, { useState } from "react";
import { getThreads } from "../../../../helper/helperFunction";
import { Link, useParams } from "react-router-dom";
import styles from "../forum.module.css";
import AddComment from "../manage-comments/add";
import Comment from "../manage-comments/comment";
import { Box, InputLabel, TextField } from "@mui/material";

interface Creator {
  id: number;
  first_name: string;
  last_name: string;
}

interface Thread {
  id: number;
  topic: string;
  description: string;
  created_at: string;
  updated_at: string;
  creator: Creator;
  comment: Comment[];
  like: any[];
  favourite: any[];
  view: View[];
}

interface View {
  id: number;
  type: string;
}

const ThreadViewDetail: React.FC = () => {
  const paramslug = useParams<{ slug: string }>();
  const params = useParams();
  const [thread, setThread] = useState<Thread>();
  const [formSuccess, setFormSuccess] = useState(false);
  const [commentFormHide, setCommentFormHide] = useState(true);

  const fetchThreadViewData = React.useCallback(async () => {
    try {
      const res = await getThreads(paramslug.slug ?? "");

      setThread(res.thread);
    } catch (error) {
      console.error(error);
    }
  }, [paramslug.slug]);

  React.useEffect(() => {
    fetchThreadViewData();
    setCommentFormHide(true);
  }, [fetchThreadViewData, formSuccess]);

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Comments</h1>
      </div>

      <Box
        className="inner-container"
        sx={{ mb: 4, display: "flex", justifyContent: "space-between", p: "0" }}
      >
        <form>
          <Box sx={{ display: "flex" }} className={styles.searchBy}>
            <InputLabel>Search By</InputLabel>
            <TextField
              id="coursetitle"
              variant="outlined"
              required
              fullWidth
              name="name"
              // value={formData.name}
              // onChange={handleOnChange}
            />
            <Box className="btn-wrapper">
              <Link to={"#"}>Go</Link>
            </Box>
          </Box>
        </form>
      </Box>
      <div className={styles["discussion-container"]}>
        {thread && (
          <>
            <div className={styles.bar}>
              {/* <h2>{thread.topic}</h2> */}
              <p>{thread.description}</p>
            </div>

            {thread.comment.map((comment, index) => (
              <Comment
                key={index}
                comment={comment}
                thread_id={`${thread.id}`}
                setFormSuccess={setFormSuccess}
                setCommentFormHide={setCommentFormHide}
              />
            ))}
            {commentFormHide && (
              <AddComment
                thread={`${thread.id}`}
                comment_parent={null}
                setFormSuccess={setFormSuccess}
                formSuccess={formSuccess}
                setCommentFormHide={setCommentFormHide}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ThreadViewDetail;
