import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import DynamicMeta from "../manage-meta-tag/dynamic-meta";
import BannerCms from "../Courses-banner";
import CourseHeading from "../manage-IGCSE-content/AddSectionTitle";

const IGCSE = (() => {
    const tabs = [
        {
            label: 'IGCSE Banner',
            content: <BannerCms heading={"IGCSE Banner"} type="IGCSE"/>,
        },
        {
            label: 'IGCSE Content',
            content: <CourseHeading type="IGCSE"/>,
        },
        {
            label: "IGCSE Meta Tag",
            content: <DynamicMeta type="IGCSE" heding="IGCSE Meta"/>,
        },
    ];

    return (
        <>
            <h1>IGCSE</h1>
            <Box className="setting-tabs-container">
                <DynamicTabs tabs={tabs} />
            </Box>
        </>
    )
})
export default IGCSE