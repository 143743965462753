import { Avatar, Box } from "@mui/material";
import {
  HasPermission,
  isAdminUser,
  toTitleCase,
  truncateTextWithEllipsis,
} from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi } from "../../../config/apis";
import { useReducer, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DateView } from "../../../components/DateView/DateView";
import AvatarImage from "../../../components/Avatar";

export default function ViewComplaintsAdmin() {
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState<null | {}>({});
  const [filters, setFilters] = useState<null | {}>({});
  const location = useLocation();

  const tableColumns = [
    {
      cell: (row: { complainant: any }) => (
        <>
          <AvatarImage
            imageName={row.complainant.userMeta.profile_picture}
            name={`${row.complainant.first_name} ${row.complainant.last_name}`}
          />
          {toTitleCase(
            `${row.complainant.first_name} ${row.complainant.last_name}`
          )}
        </>
      ),
      name: "Complaint From",
      sortable: true,
    },

    {
      selector: (row: { complaint: string }) =>
        truncateTextWithEllipsis(row.complaint, 50),
      name: "Description",
      sortable: false,
    },
    {
      cell: (row: any) => (
        <>
          <AvatarImage
            imageName={row.defendant.userMeta.profile_picture}
            name={`${row.defendant.first_name} ${row.defendant.last_name}`}
          />
          {toTitleCase(
            `${row.defendant.first_name} ${row.defendant.last_name}`
          )}
        </>
      ),
      name: "Against",
      sortable: false,
    },
    {
      selector: (row: { created_at: string }) => (
        <DateView date={row.created_at} />
      ),
      name: "Date / Time",
      sortable: false,
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/complaint/${id}`);
  };

  return (
    <>
      {location.pathname === "/admin/complaints" && (
        <div className="course-content">
          <h1 className="main-heading">Complaint</h1>
          <HasPermission permission="edit.manage-complaints">
            {!isAdminUser() && (
              <Box className="btn-wrapper">
                <Link to={"/admin/complaint/edit"}>Add Complaint</Link>
              </Box>
            )}
          </HasPermission>
        </div>
      )}

      <div
        className={`${
          location.pathname === "/admin/complaints" ? "page-container" : ""
        } `}
      >
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.COMPLAINTS_PAGINATE}
          filters={filters}
          onView={onView}
          actions={{
            view: true,
            edit: false,
            delete: false,
          }}
          module="manage-complaints"
        />
      </div>
    </>
  );
}
