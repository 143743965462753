import { useNavigate, useParams } from "react-router-dom";
import Datatable from "../../../../components/dataTables/Datatable";
import { resolveUrl, AdminApi } from "../../../../config/apis";
import { DateView } from "../../../../components/DateView/DateView";
import { Button } from "@mui/material";
import { GetUserRole } from "../../../../helper/helperFunction";

export default function ViewAssignment() {
  const navigate = useNavigate();

  const params = useParams();

  const isTeacher = GetUserRole();

  const onView = async (studentAssignmentId: string) => {
    isTeacher === "Teacher"
      ? navigate(`/admin/assignments/view/${studentAssignmentId}`)
      : navigate(`/admin/student-assignments/view/${studentAssignmentId}`);
  };
  const onAssignmentView = async (id: string) => {
    navigate(`/admin/courses/assignment/view/${id}`);
  };

  const tableColumns = [
    {
      selector: (row: { assignmentTitle: string }) => row.assignmentTitle,
      name: "Title",
      sortable: true,
    },
    {
      selector: (row: { submittedOn: string }) => (
        <DateView date={row.submittedOn} />
      ),
      name: "Submitted on",
      sortable: false,
    },
    {
      selector: (row: { submittedBy: string }) => row.submittedBy,
      name: "Submitted By",
      sortable: false,
    },
    {
      selector: (row: { totalMarks: string }) => row.totalMarks,
      name: "Total Marks",
      sortable: false,
      minWidth: "200px",
    },
    {
      selector: (row: { obtainedMarks: string }) => row.obtainedMarks,
      name: "Obtained Marks",
      sortable: false,
      minWidth: "200px",
    },
    {
      cell: (row: {
        studentAssignmentId: any;
        studentAssignmentSchedule: any;
        id: any;
      }) => (
        <>
          {row.studentAssignmentSchedule === true ? (
            <>
              <Button
                className="view"
                onClick={() => onView(row.studentAssignmentId)}
              >
                View
              </Button>
            </>
          ) : (
            <>
              <Button className="view" onClick={() => onAssignmentView(row.id)}>
                View
              </Button>
            </>
          )}
        </>
      ),
      name: "Action",
      sortable: false,
    },
  ];

  return (
    <>
      <div className="page-container" style={{ padding: 0 }}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(
            AdminApi.FETCH_ASSIGNMENT_COURSES,
            `${params.id}`
          )}
          actionColumns={false}
          actions={{
            edit: false,
            delete: false,
            view: false,
          }}
          module="manage-courses"
        />
      </div>
    </>
  );
}
