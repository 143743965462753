import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import playvideo from "../../static/images/play-img.png";
import styles from "./style.module.scss";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { truncateTextWithEllipsis } from "../../helper/helperFunction";

type props = {
  name?: string;
  progress?: number;
  image?: string;
};

const ProgressCourseCell = ({ name, progress, image }: props) => {
  const { REACT_APP_SERVER_BASE } = process.env;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#071836" : "#308fe8",
    },
  }));
  return (
    <Grid container className={styles.courseTableColCourseNameContainer}>
      <Grid item className={styles.courseTableColCourseNameImgGrid}>
        <CardMedia
          component="img"
          className={styles.courseTableColCourseNameImg}
          image={`${REACT_APP_SERVER_BASE}${image}` ?? playvideo}
          alt="Course Image"
        />
      </Grid>
      <Grid item className={styles.courseTableColCourseNameTextGrid}>
        <Grid container className={styles.courseTableColCourseNameTextContent}>
          <Grid
            item
            xs={12}
            className={styles.courseTableColCourseNameTextTitle}
          >
            <Tooltip title={name} arrow>
              <Typography
                variant="h5"
                component="h2"
                className={styles.courseTableColCourseNameTitle}
              >
                {name && truncateTextWithEllipsis(name ?? "-", 20)}
              </Typography>
            </Tooltip>
          </Grid>

          {progress ? (
            <>
              <Grid
                item
                xs={6}
                className={styles.courseTableColCourseNameTextContentProgress}
              >
                <Typography
                  variant="body1"
                  className={styles.courseTableColCourseNameProgress}
                >
                  Progress
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={styles.courseTableColCourseNameTextContentPercent}
              >
                <Typography
                  variant="body1"
                  className={styles.courseTableColCourseNamePercent}
                >
                  {progress && progress}%
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={styles.courseTableColCourseNameTextContentBar}
              >
                <BorderLinearProgress
                  variant="determinate"
                  value={progress && progress}
                  className={styles.courseTableColCourseNameBar}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProgressCourseCell;
