import { Avatar, Box } from "@mui/material";
import {
  toTitleCase,
  truncateTextWithEllipsis,
} from "../../../../helper/helperFunction";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, resolveUrl } from "../../../../config/apis";
import { useNavigate, useParams } from "react-router-dom";
import { DateView } from "../../../../components/DateView/DateView";
import AvatarImage from "../../../../components/Avatar";

export default function UserViewComplaints() {
  const navigate = useNavigate();
  const params = useParams();

  const tableColumns = [
    {
      cell: (row: any) => (
        <>
          <AvatarImage
            imageName={row.complainantProfile}
            name={row.complaintFrom}
          />
          {toTitleCase(`${row.complaintFrom}`)}
        </>
      ),
      name: "Complaint From",
      sortable: true,
    },

    {
      selector: (row: { description: string }) =>
        truncateTextWithEllipsis(row.description, 50),
      name: "Description",
      sortable: false,
    },
    {
      cell: (row: any) => (
        <>
          <AvatarImage imageName={row.defendantProfile} name={row.against} />
          {toTitleCase(`${row.against}`)}
        </>
      ),
      name: "Against",
      sortable: false,
    },
    {
      selector: (row: { dateTime: string }) => <DateView date={row.dateTime} />,
      name: "Date / Time",
      sortable: false,
    },
  ];
  const onView = async (id: string) => {
    navigate(`/admin/complaint/${id}`);
  };

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(AdminApi.PARENT_STUDENTS_COMPLAINTS)}
        paramId={params.id}
        onView={onView}
        actions={{
          edit: false,
          delete: false,
        }}
        module="manage-complaints"
      />
    </>
  );
}
