import React, { useState, useRef, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { api, resolveUrl, AdminApi } from "../../../../config/apis";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import styles from "../manage-discount.module.scss";
import CourseDiscount from "../CouseCartComponent/CourseDiscount";
import { useCartContext } from "../../../../context/CartContext";
import { toast } from "react-toastify";
import FormLoader from "../../../../components/loader/FormLoader";
import { useNavigate, useParams } from "react-router-dom";
import { CURRENCY_SYMBOL } from "../../../../config/constants/constants";
import InstallmentsTable from "../installment-popup/intallment-popup";
import InstallmentIcon from "../../../../static/images/installment_icon.png";
import { useSelectedData } from "../../../../context/SelectedDataContext";
import CustomModal from "../../../../components/popups/Modal";
import { FetchPopupData } from "../../../../helper/helperFunction";

const PaymentForm = () => {
  const { selectedIntall } = useSelectedData();

  const params = useParams();

  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState<string | null>(null);
  const {
    SectionForInstallment,
    message,
    subTotalPrice,
    totalDiscount,
    totalPrice,
    cartId,
    discountId,
    isInstallmentAllowed,
    updateCartData,
  } = useCartContext();

  const navigate = useNavigate();

  const [currentPay, setCurrentPay] = useState<number | null>();

  const cardHolderNameRef = useRef<HTMLInputElement>(null);
  const cardHolderEmailRef = useRef<HTMLInputElement>(null);

  const [paymentMethodType, setPaymentMethodType] =
    useState<string>("full Payment");

  const payType = "Payment on Installment";
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    setValidationError("");
  }, [selectedIntall]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements || !cardHolderNameRef.current) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: cardHolderNameRef.current.value,
        email: cardHolderEmailRef.current?.value,
      },
    });

    if (error) {
      setIsLoading(false);

      setPaymentError(error.message || null);
      setPaymentSuccess(null);
    } else {
      const paymentData = {
        subTotalAmount: subTotalPrice,
        discount: totalDiscount,
        totalAmount: paymentMethodType === payType ? currentPay : totalPrice,
        paymentMethod: paymentMethod?.id,
        cart_id: cartId,
        discount_id: discountId,
        paymentType: paymentMethodType,
      };
      const paymentDataIntall = {
        paymentMethod: paymentMethod?.id,
        paymentId: params.id ?? "",
        data: selectedIntall.data ?? [],
        totalAmount: selectedIntall.totalAmount ?? "",
      };
      const ApiUrl = params.id
        ? AdminApi.INSTALLMENT_PAID_PROCCES
        : AdminApi.STRIPE_PROCCES;

      if (selectedIntall.totalAmount === 0) {
        setIsLoading(false);
        setValidationError(
          "Total amount cannot be zero please select the installment plan"
        );
        return; // Stop further execution
      }

      try {
        const response = await api.post(
          resolveUrl(ApiUrl),
          params.id ? paymentDataIntall : paymentData,
          {}
        );

        if (response.data.status === true) {
          setIsLoading(false);
          setPaymentSuccess("Payment successful!");
          setPaymentError(null);
          toast.success("Payment successful!");
          updateCartData();
          navigate(`/admin/student`);
        } else {
          setIsLoading(false);
          setPaymentError("Payment failed. Please try again.");
          setPaymentSuccess(null);
        }
      } catch (error) {
        setIsLoading(false);
        setPaymentError(
          "An error occurred while processing the payment. Please try again."
        );
        setPaymentSuccess(null);
      }
    }
  };

  const handleCancel = () => {
    navigate(`/admin/course-catalog`);
  };

  const handlePaymentMethodChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setPaymentMethodType(value);
    if (value === payType) {
      setIsModalOpen(true);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModule = () => {
    setShowModal(false);
    setIsAlertOpen(false);
  };
  const handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;

    setAcceptTerms(isChecked);
  };
  const [poupData, setPopupData] = useState<string>("");
  const type = "payment terms and conditions";
  useEffect(() => {
    const fetchData = async () => {
      await FetchPopupData(type, setPopupData);
    };
    fetchData();
  }, []);
  const shouldShowButton =
    !isInstallmentAllowed ||
    (SectionForInstallment && SectionForInstallment < 2);

  return (
    <>
      {isLoading && <FormLoader />}
      {params && !params.id && (
        <Grid item xs={12} className={styles.payment_Type}>
          <Select
            value={paymentMethodType}
            onChange={handlePaymentMethodChange}
            disabled={!cartId || !!shouldShowButton}
          >
            <MenuItem value="full Payment">Full Payment</MenuItem>
            <MenuItem value="Payment on Installment">
              Payment On Installment
            </MenuItem>
          </Select>
          {paymentMethodType === "Payment on Installment" ? (
            <button
              style={cartId ? {} : { opacity: "0.5" }}
              disabled={!cartId}
              onClick={() => setIsModalOpen(true)}
              className={styles.InstallmentIcon_btn}
            >
              <img src={InstallmentIcon} alt="installment button" />
            </button>
          ) : (
            ""
          )}

          {shouldShowButton && (
            <button
              onClick={() => setIsAlertOpen(true)}
              className={styles.Installment_I_btn}
            >
              i
            </button>
          )}
        </Grid>
      )}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={"16px"} className={styles.paymentStripeForm}>
          <Grid item xs={12} className={styles.paymentStripeCardHolder}>
            <label>
              Cardholder Name:
              <TextField
                inputRef={cardHolderNameRef}
                className={styles.paymentStripeCardHolderInput}
              />
            </label>
          </Grid>
          <Grid item xs={12} className={styles.paymentStripeCardHolder}>
            <label>
              Cardholder Email:
              <TextField
                inputRef={cardHolderEmailRef}
                className={styles.paymentStripeCardHolderInput}
              />
            </label>
          </Grid>
          <Grid item xs={6} className={styles.paymentStripeCardNumber}>
            <label className={styles.paymentStripeCardNumberContent}>
              Card Number:
              <CardNumberElement
                options={{ style: { base: { fontSize: "16px" } } }}
                onChange={() => setPaymentError(null)}
              />
            </label>
          </Grid>
          <Grid item xs={4} className={styles.paymentStripeExpiry}>
            <label className={styles.paymentStripeExpiryContent}>
              Expiry:
              <CardExpiryElement />
            </label>
          </Grid>
          <Grid item xs={2} className={styles.paymentStripeCvc}>
            <label className={styles.paymentStripeCvcContent}>
              CVC:
              <CardCvcElement />
            </label>
          </Grid>
        </Grid>
        <Grid container className={styles.paymentGrandTotal}>
          <Grid item xs={12} className={styles.paymentGrandTotalContainer}>
            {paymentMethodType !== payType && (
              <Grid container className={styles.paymentGrandTotalContent}>
                <Grid
                  item
                  xs={6}
                  className={styles.paymentGrandTotalContentTitle}
                >
                  <Typography
                    variant="h4"
                    className={styles.paymentGrandTotalContentTitleContent}
                  >
                    Subtotal
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={styles.paymentGrandTotalContentPrice}
                >
                  <Typography
                    variant="h4"
                    className={styles.paymentGrandTotalContentPriceContent}
                  >
                    {CURRENCY_SYMBOL}
                    {subTotalPrice}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {params.id ? (
              ""
            ) : paymentMethodType !== payType ? (
              <Grid container className={styles.paymentGrandTotalContent}>
                <Grid
                  item
                  xs={6}
                  className={styles.paymentGrandTotalContentTitle}
                >
                  <Typography
                    variant="h4"
                    className={styles.paymentGrandTotalContentTitleContent}
                  >
                    Discount
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={styles.paymentGrandTotalContentPrice}
                >
                  {/* <Button variant="contained" className={styles.paymentGrandTotalContentPriceRemoveBtn}>
        Remove
      </Button> */}
                  <Typography
                    variant="h4"
                    className={styles.paymentGrandTotalContentPriceContent}
                  >
                    {CURRENCY_SYMBOL}
                    {totalDiscount}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )}

            <Grid container className={styles.paymentGrandTotalContent}>
              <Grid
                item
                xs={6}
                className={styles.paymentGrandTotalContentTitle}
              >
                <Typography
                  variant="h4"
                  className={styles.paymentGrandTotalContentTitleContent}
                >
                  Total
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={styles.paymentGrandTotalContentPrice}
              >
                {!params.id && (
                  <Typography
                    variant="h4"
                    className={styles.paymentGrandTotalContentPriceContent}
                  >
                    {CURRENCY_SYMBOL}
                    {paymentMethodType === payType ? currentPay : totalPrice}
                  </Typography>
                )}

                {params && params.id && (
                  <Typography
                    variant="h4"
                    className={styles.paymentGrandTotalContentPriceContent}
                  >
                    {CURRENCY_SYMBOL}
                    {selectedIntall && selectedIntall.totalAmount}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {params.id ? (
          ""
        ) : paymentMethodType !== payType ? (
          <CourseDiscount />
        ) : (
          ""
        )}

        <Box className={styles.paymentBtnDetail}>
          <Button
            variant="contained"
            color="primary"
            className={styles.paymentBtnCancel}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!stripe || !acceptTerms}
            variant="contained"
            color="primary"
            className={styles.paymentBtnOrder}
          >
            Confirm Order
          </Button>
        </Box>

        <Box className={styles.error_or_succes_wrap}>
          {validationError && (
            <Typography variant="body2" color="error" component="div">
              {validationError}
            </Typography>
          )}

          {paymentError && (
            <Typography variant="body2" color="error" component="div">
              {paymentError}
            </Typography>
          )}
          {paymentSuccess && (
            <Typography variant="body2" color="success" component="div">
              {paymentSuccess}
            </Typography>
          )}
        </Box>
        <Grid item xs={12} container sx={{ mt: 2, alignItems: "center" }}>
          <Grid item container alignItems={"center"}>
            <Checkbox
              checked={acceptTerms}
              name="acceptTerms"
              onChange={handleCheckboxChange}
            />
            <InputLabel
              sx={{
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "700",
                color: "#0F203F",
                fontFamily: "Poppins",
              }}
            >
              I agree to the{" "}
              <span
                onClick={() => setShowModal(true)}
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                Terms and Conditions
              </span>
            </InputLabel>
          </Grid>
        </Grid>
      </form>

      <InstallmentsTable
        open={isModalOpen}
        handleClose={closeModal}
        handleShow={setIsModalOpen}
        setCurrentPay={setCurrentPay}
      />
      <CustomModal state={showModal} setState={setShowModal} width={800}>
        <button className="closeBtn" onClick={closeModule}>
          +
        </button>
        <div dangerouslySetInnerHTML={{ __html: poupData }}></div>
      </CustomModal>
      <CustomModal state={isAlertOpen} setState={setShowModal} width={800}>
        <button className="closeBtn" onClick={closeModule}>
          +
        </button>
        <p>{message && message}</p>
      </CustomModal>
    </>
  );
};

export default PaymentForm;
