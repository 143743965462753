import { Box, Typography } from "@mui/material";
import styles from './topStudent.module.scss'
import Filter from '../filter/filterTopStudent'
// import StudentAssignmentTable from "./Student-assignment-table";
import { useState } from "react";
import TopStudentTable from "./top-student-detail";
// import Filter from "../filters/filters";

type FilterState = {
    teacherId: any;
    status: string | null;
    searchQuery: string | null;
  };


const TopStudentPanel = () => {
    const [filters, setFilters] = useState<FilterState>();


    const handleFiltersChange = (filterState: FilterState) => {
      setFilters(filterState);    
    };

  return (
    <Box className={styles.TopStudentPanel}>
      <Typography variant="h2" className={styles.TopStudentPanelTitle}>Students</Typography>
      <Filter  onFiltersChange={handleFiltersChange} />
      <Box className={styles.TopStudentPanelTable}>
        <TopStudentTable />
      </Box>
    </Box>
  );
};

export default TopStudentPanel;
