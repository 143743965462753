import { Avatar, Box, Grid, Rating, Typography } from "@mui/material";
import styles from './course.module.scss'
import ImgBase from '../../assets/play-img.png'
import React from "react";
import Enrolledstudents from "../../../manage-courses/Enrolledstudents/Enrolledstudents";
import ViewQuizes from "../../../manage-courses/Quizes/Quizes";
import ViewAssignment from "../../../manage-courses/Assignment/Assignment";
import DynamicTabs from "../../../../../components/tabs/Tabs";
import CourseScoreAvgComp from "../../dashboard/dashboard-components/Course-score-element";

const CourseViewPanel = () => {
    const [value, setValue] = React.useState<number | null>(2);

    const tabs = [
        {
          label: "Content",
          content: <Enrolledstudents/>,
        },
        {
          label: "Enrolled students",
          content: <ViewQuizes/>,
        },
        {
          label: "Quizes",
          content: <ViewAssignment/>,
        },
        {
          label: "Assignment",
          content: <ViewAssignment/>,
        },
        {
          label: "Reviews",
          content: <ViewAssignment/>,
        },
      ];

  return (
    <Box className={styles.courseViewPanel}>
      <Typography variant="h4" className={styles.courseViewPanelTitle}>Subject</Typography>
      <Grid container className={styles.courseViewDetailContainer}>
          <Grid item className={styles.courseViewDetailImage}>
              <Box
                className="togglebutton"
                component="img"
                src={ImgBase}
                alt="img"
              />
          </Grid>
          <Grid item className={styles.courseViewDetailText}>
              <Typography variant="h2" className={styles.courseViewDetailTitle}>Subject Title</Typography>
              <Typography variant="body1" className={styles.courseViewDetailSub}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint, doloribus.</Typography>
              <Box className={styles.courseViewDetailAuthor}>
                  <Avatar className={styles.courseViewDetailAuthorImg}></Avatar>
                  <Typography variant="body1" className={styles.courseViewDetailAuthorName}>Jhon Doe</Typography>
              </Box>
          </Grid>
          <Grid item className={styles.courseViewDetailReview}>
              <Typography variant="body1"><span>2</span> Reviews</Typography>
              <Rating
              name="simple-controlled"
              className={styles.courseRating}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              />
          </Grid>
      </Grid>
      <CourseScoreAvgComp />
      <Box className={styles.studentViewPanelTabs}>
          <DynamicTabs tabs={tabs} />
      </Box>
    </Box>
  );
};

export default CourseViewPanel;
