import { Box, Typography } from "@mui/material";
import styles from "./studentAssignments.module.scss";
import StudentAssignmentTable from "./Student-assignment-table";
import { useState } from "react";
import Filter from "../filters/filters";

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

const StudentAssignments = (props: any) => {
  const [filters, setFilters] = useState<FilterState>();

  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);
  };

  return (
    <Box className={styles.assignmentPagePanel}>
      {/* <Filter  onFiltersChange={handleFiltersChange} /> */}
      <StudentAssignmentTable
        filterState={filters}
        courseId={props?.courseId}
      />
    </Box>
  );
};

export default StudentAssignments;
