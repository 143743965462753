import React from "react";
interface CourseDescriptionProps {
  description: string;
}

const CourseDescription: React.FC<CourseDescriptionProps> = ({
  description,
}) => {
  return (
    <div>
      <h2>Subject Description</h2>
      {/* <p>{description}</p> */}
      <div
        dangerouslySetInnerHTML={{ __html: description && description }}
      ></div>
    </div>
  );
};

export default CourseDescription;
