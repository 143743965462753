import { useState, useEffect } from "react";
import { AdminApi, api } from "../../../config/apis";
import { CircularProgress, Typography } from "@mui/material";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";

import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { Grid } from "@mui/joy";

const RevenueVsRefunds = () => {
  const [revenue, setRevenue] = useState<number[]>([]);
  const [refunds, setRefunds] = useState<number[]>([]);
  const [isBarChartLoaded, setIsBarChartLoaded] = useState(false);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const Refund_Vs_Revenue_Report = await api.get(
          AdminApi.REFUND_VS_REVEVUE_REPORT
        );
        const { refund, revenue } = Refund_Vs_Revenue_Report.data;

        const revenueValues = Object.values(revenue).map(Number);
        const refundValues = Object.values(refund).map(Number);

        setRevenue(revenueValues);
        setRefunds(refundValues);
        setIsBarChartLoaded(true);
      } catch (error) {
        setIsBarChartLoaded(true);
        console.error("Error fetching data:", error);
      }
    };

    fetchChartData();
  }, []);

  const year_count = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data: any = {
    labels: year_count,
    datasets: [
      {
        label: "Refund",
        data: refunds ?? [],
        backgroundColor: "rgba(163, 145, 94, 1)", // Set the background color for the "Refund" bars
        barThickness: 20, // Adjust the width of the "Refund" bars (in pixels)
      },
      {
        label: "Revenue",
        data: revenue ?? [],
        backgroundColor: "rgba(7, 24, 54, 1)", // Set the background color for the "Revenue" bars
        barThickness: 20, // Adjust the width of the "Revenue" bars (in pixels)
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += CURRENCY_SYMBOL + context.parsed.y.toLocaleString();
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 31,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      {isBarChartLoaded ? (
        <Grid sx={{ padding: "20px" }}>
          <Chart type="bar" data={data} options={options} />
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 350,
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      )}
      {/* {!isBarChartLoaded && (
        <Typography sx={{ padding: "30px" }}>
          There are no records to display
        </Typography>
      )} */}
    </>
  );
};

export default RevenueVsRefunds;
