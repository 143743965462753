import React from "react";
import { Box, Modal } from "@mui/material";
import styles from "./manage-adminUser.module.css";
import AdminForm from "./edit";

const AdminFormModal = ({ open, id, closeModal , setFormSuccess}:any) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box className={styles.popup}>
        <AdminForm id={id} setFormSuccess={setFormSuccess}/>
      </Box>
    </Modal>
  );
};

export default AdminFormModal;
