import {
  Avatar,
  Box,
  Fade,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Button as Btn,
  FormHelperText,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Button, Modal } from "react-bootstrap";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, resolveUrl } from "../../../config/apis";
import dayjs from "dayjs";
import { FormEvent, useState } from "react";
import styles from "../transactions/transations.module.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toTitleCase } from "../../../helper/helperFunction";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";
import { DateView } from "../../../components/DateView/DateView";
import Form from "../../../components/forms/Form";
import { Textarea } from "@mui/joy";
import CustomModal from "../../../components/popups/Modal";

export default function ViewRefundRequests() {
  type FiltersData = {
    username: string | null;
    email: string | null;
    start_date: dayjs.Dayjs | null;
    end_date: dayjs.Dayjs | null;
  };

  const [filterData, setFilterData] = useState<FiltersData | {}>({});
  const [filters, setFilters] = useState<FiltersData | {}>({});
  const [showRefundMoal, setShowRefundMoal] = useState(false);
  const [refundObject, setRefundObject] = useState<any>({});

  const [formData, setFormData] = useState<{
    refundResponse: string;
    refundStatus: string;
  }>({
    refundResponse: "",
    refundStatus: "active",
  });

  const [formSuccess, setFormSuccess] = useState(false);
  const { REACT_APP_SERVER_BASE } = process.env;

  const tableColumns = [
    {
      cell: (row: {
        user: {
          first_name: string;
          last_name: string;
          userMeta: { profile_picture: string };
        };
      }) => (
        <>
          <Avatar
            sx={{ width: 40, height: 40, mr: 2 }}
            alt={row.user.first_name.toUpperCase()}
            src={`${REACT_APP_SERVER_BASE}${row.user.userMeta?.profile_picture}`}
          />
          {toTitleCase(`${row.user.first_name} ${row.user.last_name}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row: { user: { email: string } }) => row.user.email,
      name: "Email",
      sortable: false,
    },
    {
      selector: (row: { payment: { amount: string } }) => {
        return `${CURRENCY_SYMBOL}${parseFloat(row.payment.amount).toFixed(2)}`;
      },
      name: "Amount",
      sortable: false,
    },
    {
      selector: (row: { created_at: string }) => {
        return <DateView date={row.created_at} />;
      },
      name: "Date",
      sortable: true,
    },
    {
      selector: (row: { type: string }) => {
        return `${toTitleCase(row.type)}`;
      },
      name: "Refund Status",
      sortable: false,
    },
    {
      selector: (row: any) => {
        return (
          <>
            <Btn
              className="edit"
              onClick={() => updateRefundStatus(row)}
              disabled={row.type === "refunded"}
            >
              Update Status
            </Btn>
          </>
        );
      },
      name: "Refund Status",
      sortable: false,
    },
  ];

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleOnSelectChange = (event: SelectChangeEvent<string | boolean>) => {
    const target = event.target.value as string;

    setFormData({
      ...formData,
      refundStatus: target,
    });
  };

  const closeRefundMoal = () => {
    setShowRefundMoal(false);
    setRefundObject({});
  };

  const updateRefundStatus = (obj: {
    refund_response: string;
    type: string;
  }) => {
    setShowRefundMoal(true);
    setRefundObject(obj);
    if (obj) {
      setFormData({
        refundResponse: obj.refund_response,
        refundStatus: obj.type,
      });
    }
  };

  const handleFormSuccess = async (response: any) => {
    if (response) {
      setShowRefundMoal(false);
      setFormSuccess((prevState: any) => !prevState);
    }
  };

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    const errors: any = {};

    setErrors(errors);

    if (!formData.refundResponse) {
      errors.refundResponse = "This field is required";
    }

    const hasErrors = Object.keys(errors).length > 0;

    return !hasErrors;
  };

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Refund Request</h1>
      </div>

      <Box className={styles.innerContainer}>
        <Typography variant="body1" className={styles.transationsBodyText}>
          Search By
        </Typography>
        <Box className={styles.transationsFieldContainer}>
          <TextField
            className={styles.transationsFieldEmail}
            id="email"
            variant="outlined"
            fullWidth
            placeholder="Email"
            onChange={(event) =>
              setFilterData((prevState) => {
                return {
                  ...prevState,
                  email: event.target.value,
                };
              })
            }
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date from"
              className={styles.transationsFieldDateTo}
              maxDate={dayjs()}
              format="YYYY-MM-DD"
              onChange={(value) =>
                setFilterData((prevState) => {
                  return {
                    ...prevState,
                    start_date: value,
                  };
                })
              }
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date to"
              className={styles.transationsFieldDateFrom}
              maxDate={dayjs()}
              format="YYYY-MM-DD"
              onChange={(value) =>
                setFilterData((prevState) => {
                  return {
                    ...prevState,
                    end_date: value,
                  };
                })
              }
            />
          </LocalizationProvider>
          <Typography variant="body1" className={styles.transationsBodyTextTwo}>
            Or
          </Typography>
          <TextField
            id="username"
            className={styles.transationsFieldName}
            variant="outlined"
            fullWidth
            placeholder="Name"
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  username: event.target.value,
                };
              })
            }
          />
        </Box>
        <Box
          className={styles.transationsBtn}
          sx={{ marginTop: "0px !important" }}
        >
          <Button className="btn mar-t0" onClick={() => setFilters(filterData)}>
            Go
          </Button>
        </Box>
      </Box>

      <div className="page-container">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.REFUND_REQUEST_PAGINATE}
          filters={filters}
          onView={() => setShowRefundMoal(true)}
          actionColumns={false}
          module="manage-transactions"
          refreshHandler={formSuccess}
        />
      </div>
      {/* <Modal

        className="modal"
        show={showRefundMoal}
        open={showRefundMoal}
      >
        <Fade in={showRefundMoal}>
          <Box sx={style}>
            <button onClick={closeRefundMoal}>Close</button>
            {refundObject && <Form
              submitUrl={resolveUrl(AdminApi.UPDATE_REFUND_STATUS, refundObject.id)}
              formData={formData}
              editId={refundObject.id}
              successPath='/admin/refund-request'
            >
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Refund Reason
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  {refundObject.refund_reason}
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Response
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <Textarea
                    value={formData.refundResponse}
                    name="refundResponse"
                    variant="outlined"
                    minRows={4}
                    maxRows={4}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Status
                  </InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    name="refundStatus"
                    sx={{ minWidth: 100 }}
                    onChange={handleOnSelectChange}
                    value={formData.refundStatus}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="refunded">Refund</MenuItem>
                    <MenuItem value="rejected">Reject</MenuItem>
                  </Select>
                </Grid>
              </Grid>

            </Form>}
          </Box>
        </Fade>
      </Modal> */}

      <CustomModal state={showRefundMoal} setState={setShowRefundMoal}>
        <button className="closeBtn" onClick={closeRefundMoal}>
          +
        </button>

        {refundObject && (
          <Form
            submitUrl={resolveUrl(
              AdminApi.UPDATE_REFUND_STATUS,
              refundObject.id
            )}
            formData={formData}
            editId={refundObject.id}
            successPath="/admin/refund-request"
            onSuccess={handleFormSuccess}
            validate={customValidation}
          >
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left", whiteSpace: "inherit" }}>
                  Refund Reason
                </InputLabel>
              </Grid>
              <Grid item xs={10}>
                {refundObject.refund_reason}
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Response</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <Textarea
                  value={formData.refundResponse}
                  name="refundResponse"
                  variant="outlined"
                  minRows={4}
                  maxRows={4}
                  onChange={handleOnChange}
                />
                {errors.refundResponse && (
                  <FormHelperText error>{errors.refundResponse}</FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Status</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <Select
                  name="refundStatus"
                  sx={{ minWidth: 100 }}
                  onChange={handleOnSelectChange}
                  value={formData.refundStatus}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="refunded">Refund</MenuItem>
                  <MenuItem value="rejected">Reject</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Form>
        )}
      </CustomModal>
    </>
  );
}
