import styles from "./studentAssignments.module.scss";
import { useParams } from "react-router-dom";

import {
  Badge,
  Typography,
  Avatar,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import Datatable from "../../../../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../../../../config/apis";
import {
  GetUserRole,
  toTitleCase,
} from "../../../../../../../helper/helperFunction";
import { DateView } from "../../../../../../../components/DateView/DateView";
import { FormEvent, useState } from "react";
import { useAuth } from "../../../../../../../hooks/auth/auth";
import { useStudentId } from "../../../../../../../context/getStudentId";

interface Assignments {
  id: string;
  textData: string;
  subjectTitle: string;
  timeContent: string;
  assignmentStatus: string;
  assignmentScore: string;
  assignmentScoreStatus: string;
  assignmentAction: string;
}

const StudentAssignmentTable = ({
  courseId = null,
  filterState,
  limit = 1000,
}: any) => {
  const { studentId } = useStudentId();

  const [isModalOpen, setModalOpen] = useState(false);
  const [assignmentId, setAssignmentId] = useState<string | null>(null);
  const [assignmentMarks, setAssignmentMarks] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);

  const CURRENT_ROLE = GetUserRole();

  const tableColumns = [
    {
      selector: (row: any) => toTitleCase(row.AssignemntTitle ?? "-"),
      name: "Assignment Title",
      sortable: true,
      width: "200px",
    },
    {
      cell: (row: { assingmentCreatedAt: string }) => (
        <DateView date={row.assingmentCreatedAt} />
      ),
      name: "Date",
      selector: "assingmentCreatedAt",
      sortable: true,
    },
    {
      cell: (row: { studentAssignmentStatus: string }) => (
        <>
          {row.studentAssignmentStatus === "closed" && toTitleCase("submitted")}
          {row.studentAssignmentStatus === "pending" &&
            toTitleCase("Re-Assign Request")}
          {row.studentAssignmentStatus === "active" && toTitleCase("active")}
          {row.studentAssignmentStatus === "rejected" &&
            toTitleCase("rejected")}
          {row.studentAssignmentStatus === "approved" &&
            toTitleCase("re-assigned")}
        </>
      ),
      name: "Status",
    },
    {
      cell: (row: {
        totalMarks: string | null;
        ObtainedMarks: string | null;
      }) => (
        <>{`${row.ObtainedMarks ? row.ObtainedMarks : "-"}/${
          row.totalMarks ? row.totalMarks : "0"
        }`}</>
      ),
      name: "Score",
    },
    // CURRENT_ROLE === 'Teacher' && {
    //   cell: (row: { isSubmitted: boolean, file_path: string|null, studentAssignmentStatus: string, studentAssignmentId: string }) => (
    //     <>
    //       {row.studentAssignmentStatus === 'closed' && <Button onClick={(e) => downloadAssignment(e, row.file_path)}>Download</Button>}
    //       {row.studentAssignmentStatus === 'pending' && <Button onClick={(e) => reassignRequest(e, row.studentAssignmentId)}>Re-Assign</Button>}
    //       {row.studentAssignmentStatus === 'closed' && <Button onClick={(e) => {
    //         setModalOpen(true)
    //         setAssignmentId(row.studentAssignmentId)
    //       }}>Marks</Button>}
    //     </>
    //   ),
    //   name: "Actions",
    // },
  ];

  const downloadAssignment = (
    event: FormEvent,
    assignmentFile: string | null
  ) => {
    const link = document.createElement("a");
    if (assignmentFile) {
      link.href = `http://127.0.0.1:7000/student-assignment/098499f63c1a91f612186dc56525afca`;
      link.setAttribute("download", `Student Assignment.pdf`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    }
  };

  const reassignRequest = async (
    event: FormEvent,
    assignmentId: number | string
  ) => {
    const res = await api.post(
      resolveUrl(AdminApi.REASSIGN_ASSIGNMENT_REQUEST),
      {
        id: assignmentId,
        status: "approved",
      }
    );
  };

  const submitMarks = async (event: FormEvent) => {
    if (assignmentId) {
      try {
        const res = await api.post(
          resolveUrl(AdminApi.SUBMIT_ASSIGNMENT_MARKS, assignmentId),
          {
            marks: assignmentMarks,
          }
        );
        if (res.data.status) {
          setModalOpen(false);
          setAssignmentId(null);
          setAssignmentMarks(null);
        }
      } catch (err: any) {
        setErrMsg(err.response.data.message);
      }
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setAssignmentId(null);
    setAssignmentMarks(null);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 996,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const byCourseId = {
    courseId: courseId,
  };
  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(
          AdminApi.STUDENT_ASSIGN_ASSIGNMENTS,
          `${studentId}`
        )}
        actionColumns={false}
        actions={{
          view: false,
          edit: false,
          delete: false,
        }}
        module="manage-kids"
        filterState={filterState}
        dataFilter={byCourseId}
      />
      <Modal
        className={styles.qEFAddContentModule}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box sx={style} className={styles.qEFACMContainer}>
            <Grid container>
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid container className={styles.qEFACMFieldContainer}>
                  <Grid item xs={12} className={styles.qEFACMFieldContent}>
                    <Grid
                      item
                      xs={2}
                      className={styles.qEFACMFieldContentLabel}
                    >
                      <InputLabel sx={{ textAlign: "left" }}>Marks</InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      className={styles.qEFACMFieldContentInputQue}
                    >
                      <TextField
                        fullWidth
                        name="marks"
                        variant="outlined"
                        placeholder="Enter Marks"
                        value={assignmentMarks ? assignmentMarks : ""}
                        onChange={(e) => setAssignmentMarks(e.target.value)}
                      />
                      {errMsg && <p>{errMsg}</p>}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className={styles.qEFACMFCISaveBtn}>
                  <Grid item xs={12}>
                    <Button onClick={submitMarks}>Submit</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default StudentAssignmentTable;
