import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, Tooltip } from "@mui/material";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, resolveUrl } from "../../../../config/apis";
import { DateView } from "../../../../components/DateView/DateView";
import {
  convertMinutesToTime,
  toTitleCase,
} from "../../../../helper/helperFunction";
import AvatarImage from "../../../../components/Avatar";

export default function UserEnrolledstudents() {
  const params = useParams();

  const navigate = useNavigate();

  const onView = async (id: string) => {
    navigate(`/admin/student/view/${id}`);
  };

  const tableColumns = [
    {
      selector: (row: { id: any }) => `#${row.id}`,
      name: "ID",
      sortable: false,
      width: "100px",
    },

    {
      cell: (row: { Name: string; profile: string }) => (
        <>
          <AvatarImage imageName={row.profile} name={row.Name} />
          {toTitleCase(`${row.Name}`)}
        </>
      ),
      selector: "Name",
      name: "Name",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row: { Email: string }) => row.Email,
      name: "Email",
      sortable: false,
      minWidth: "250px",
    },
    {
      selector: (row: { Place: any }) => (
        <Tooltip title={row.Place}>
          <div>{row.Place}</div>
        </Tooltip>
      ),
      name: "City",
      sortable: false,
    },
    {
      selector: (row: { addedOn: string }) => <DateView date={row.addedOn} />,
      name: "Added on",
      sortable: false,
    },

    {
      selector: (row: { courses: number }) => row.courses,
      name: "Subjects",
      sortable: false,
    },
    {
      selector: (row: { avgTime: number }) =>
        row.avgTime ? convertMinutesToTime(row.avgTime) : "-",
      name: "Avg time",
      sortable: false,
      width: "200px",
    },
    {
      selector: (row: { avgScore: any }) =>
        row.avgScore && row.avgScore.score
          ? row.avgScore.score.toFixed(2)
          : "-",
      name: "Avg score",
      sortable: false,
      width: "130px",
    },
  ];

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(AdminApi.PARENT_ENROLLED_STUDENTS, `${params.id}`)}
        onView={onView}
        actions={{
          edit: false,
          delete: false,
        }}
        module="manage-users"
      />
    </>
  );
}
