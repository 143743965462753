import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { api, AdminApi, resolveUrl } from "../../../../../../../config/apis";
// import { LineChart } from "@mui/x-charts";
import style from "../kisds.module.css";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { useStudentId } from "../../../../../../../context/getStudentId";

export const LecturesChart = ({ courseId = null }: any) => {
  const { studentId } = useStudentId();
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
    return currentMonth;
  };
  const currentMonth = getCurrentMonth();
  const [months, setMonths] = useState<number[]>([]);
  const [lectures, setLectures] = useState<number[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<any>(currentMonth);
  const [selectedOption, setSelectedOption] = useState<string>("monthly");
  const currentYear = new Date().getFullYear();

  const searchParams = new URLSearchParams(location.search);

  const handleMonthChange = (event: SelectChangeEvent<number | string>) => {
    const month: any = event.target.value;
    setSelectedMonth(month);
    // searchParams.set("lecture-month", month);

    // navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const handleOptionChange = (event: SelectChangeEvent<number | string>) => {
    const selectedValue = event.target.value as string;
    setSelectedOption(selectedValue);

    // Remove the lecture-month parameter if it exists
    // if (searchParams.has("lecture-month") && selectedValue === "yearly") {
    //   searchParams.delete("lecture-month");
    // } else {
    //   searchParams.set("lecture-month", selectedMonth);
    // }

    // searchParams.set("lecture-year", selectedValue);
    // navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        let paramsApi: any = {
          year: currentYear,
        };

        if (selectedOption === "monthly") {
          paramsApi.month = selectedMonth;
        }
        const StudentEngagement = await api.get(
          resolveUrl(AdminApi.LECTURES_GRAPH, `${studentId}`),
          {
            params: {
              ...paramsApi,
              courseId: courseId,
            },
          }
        );

        setMonths(Object.keys(StudentEngagement.data.data).map(Number));
        setLectures(Object.values(StudentEngagement.data.data).map(Number));
        setIsDataLoaded(true); // Mark the data as loaded
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchGraphData();
  }, [currentYear, studentId, selectedMonth, selectedOption, courseId]);

  const year_count = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data: any = {
    labels: selectedOption === "yearly" ? year_count : months,
    datasets: [
      {
        label: "Lectures", // Empty string as the label
        data: lectures ?? [],
        borderColor: "rgba(164, 146, 95, 1)",
        backgroundColor: "rgba(163, 145, 94, 1), rgba(214, 195, 143, 1)",
        borderWidth: 2,
        pointRadius: 2,
        pointBackgroundColor: "rgba(163, 145, 94, 1), rgba(214, 195, 143, 1)",
        borderJoinStyle: "round",
        tension: 0.5,
      },
    ],
  };

  // const options = {
  //   scales: {
  //     x: {
  //       beginAtZero: true,
  //       ticks: {
  //         autoSkip: true,
  //         maxTicksLimit: 31,
  //       },
  //     },
  //     y: {
  //       beginAtZero: true,
  //     },
  //   },
  // };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 31,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y} lectures attended`; // Add custom text
            }
            return label;
          },
          title: function (context: any) {
            if (selectedOption === "monthly") {
              return `Date: ${context[0].label} ${
                year_count[selectedMonth - 1]
              } `; // Show date in monthly view
            } else {
              return `Month: ${year_count[context[0].dataIndex]}`; // Show month in yearly view
            }
          },
        },
      },
    },
  };
  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Box
            className={style.chart_wrap}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                className={style.chart_title}
                variant="h5"
                gutterBottom
              >
                Lectures
              </Typography>
            </Box>
            <Box className={style.chart_select_wrap}>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                sx={{
                  "& .MuiSelect-select": {
                    padding: "10.5px 14px",
                  },
                }}
              >
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
              {selectedOption === "yearly" ? (
                <Select
                  value={currentYear}
                  disabled
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10.5px 14px",
                    },
                  }}
                >
                  <MenuItem value={currentYear}>{currentYear}</MenuItem>
                </Select>
              ) : (
                <Select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10.5px 14px",
                    },
                  }}
                >
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              )}
            </Box>
          </Box>

          {isDataLoaded ? (
            lectures.length > 0 ? (
              <Chart type="line" data={data} options={options} />
            ) : (
              <Typography>There are no records to display</Typography>
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 350,
              }}
            >
              <CircularProgress sx={{ color: "#d6c38f" }} />
            </div>
          )}
        </Box>
      </Grid>
    </>
  );
};
