import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import DynamicMeta from "../manage-meta-tag/dynamic-meta";
import ContactBannerCms from "../manage-contact-banner";
import EnrollNow from "../manage-enroll-now"
import GlobalCMSPopup from "../parent-form-popup";

const ConatactPageCms = (() => {
    const tabs = [
        {
            label: 'Contact Banner',
            content: <ContactBannerCms heading={"Contact Us Banner"}/>,
        },
        {
            label: 'Enroll Now',
            content: <EnrollNow/>,
        },
        {
            label: "Enroll Form Thanks",
      
            content: (
              <GlobalCMSPopup
                type="enroll-form-thanks"
                heading="Enroll Form Thanks"
                successPathUrl="/admin/cms/contact-us"
              />
            ),
          },
        {
            label: "Contact Us Meta Tag",
            content: <DynamicMeta type="contac-page" heding="Contact Meta"/>,
        },
    ];

    return (
        <>
            <h1>Contact Us</h1>
            <Box className="setting-tabs-container">
                <DynamicTabs tabs={tabs} />
            </Box>
        </>
    )
})
export default ConatactPageCms