import React, { useState, useEffect, useRef } from "react";
import SingleCourseCard from "./single-course-card";
import { CourseData, Quiz } from "../../../Type/courseTypes";
import DynamicTabs from "../../../components/tabs/Tabs";
import Content from "../manage-courses/content/Content";
import { useParams } from "react-router-dom";
import {
  getCourseView,
  getCourseDemoVideo,
  getStudentData,
} from "../../../helper/helperFunction";
import InstructorDetail from "./instructor-detail";
import Rating from "@mui/material/Rating";
import CourseDescription from "./course-description";
import CourseReview from "./course-reviews";
import styles from "./cousre-catalog.module.scss";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import Playvideo from "../../../../src/static/videos/learning.mp4";
import PurchaseCourseFormModal from "../manage-purchase-courses/Modal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth } from "../../../hooks/auth/auth";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";

const CourseDetail: React.FC = () => {
  const Parent = useAuth();
  const id = Parent.state?.user.id;

  const { REACT_APP_SERVER_BASE } = process.env;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState<string>("");
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [open, setOpen] = React.useState(false);
  const [hasKid, setHasKid] = useState(false);

  useEffect(() => {
    const fetchAllKids = async () => {
      const res = await getStudentData(id);
      const kidsIds = res.map((item: { id: number }) => item.id);

      setHasKid(kidsIds.length !== 0);
    };

    fetchAllKids();
  }, [id]);

  const handleClose = () => {
    setOpen(false);
  };

  const [demovideo, setDemoVideo] = React.useState<string | null>("");

  const DEFAULT_COURSE_VIEW = {
    name: "",
    have_live_quiz: false,
    price: "",
    year: "",
    description: "",
    sections: [],
    teacherId: "",
    secID: [],
  };
  const [loading, setLoading] = useState(false);

  const params = useParams<{ id: string }>();
  const [courseViewData, setCourseViewData] =
    React.useState<CourseData>(DEFAULT_COURSE_VIEW);

  const fetchCourseViewData = React.useCallback(async () => {
    try {
      setLoading(true);

      const res = await getCourseView(params.id ?? "");

      setCourseViewData({
        name: res.course.name,
        description: res.course.description,
        have_live_quiz: res.course.have_live_quiz,
        price: res.course.price,
        teacherId: res.course.teacher.id ?? "",
        year: res.course.year,
        sections: res.course.section,
        secID: [],
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  React.useEffect(() => {
    fetchCourseViewData();
  }, [fetchCourseViewData]);

  const fetchCourseDemovideo = React.useCallback(async () => {
    try {
      const path = await getCourseDemoVideo(params.id ?? "");
      const baseUrl = REACT_APP_SERVER_BASE;
      const videoUrl = path && `${path.replace("\\", "/")}`;
      setDemoVideo(videoUrl);
    } catch (error) {
      console.error(error);
    }
  }, [REACT_APP_SERVER_BASE, params.id]);

  React.useEffect(() => {
    fetchCourseDemovideo();
  }, [fetchCourseDemovideo]);

  const tabs = [
    {
      label: "Subject Description",
      content: (
        <CourseDescription description={courseViewData.description ?? ""} />
      ),
    },
  ];

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: "#d6c38f" }} />
      </div>
    );
  }

  if (
    !courseViewData ||
    (courseViewData.name === "" &&
      courseViewData.description === "" &&
      courseViewData.teacherId === "")
  ) {
    return <h2>No course found.</h2>;
  }

  return (
    <>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={12}>
          <h2 className="main-heading">{courseViewData.name}</h2>
        </Grid>
      </Grid>

      <Grid container justifyContent={"space-between"}>
        {/* <CardMedia component="iframe" src={video} allow="autoPlay"/> */}

        <Grid
          item
          xs={7}
          sx={{ pr: 2 }}
          className="courese_single_col_wapper_yi"
        >
          <Card>
            <Box className={styles.subject_thumbnail}>
              {demovideo ? (
                <CardMedia
                  component="video"
                  image={demovideo}
                  controls
                  controlsList="nodownload"
                />
              ) : (
                <CardMedia
                  component="video"
                  image={Playvideo}
                  controls
                  controlsList="nodownload"
                />
              )}
            </Box>
            <CardContent>
              <Grid className={styles.videoCourses} xs={12} container>
                <Grid item xs={6}>
                  <Typography variant="h5" component="h2">
                    Subject fee
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {CURRENCY_SYMBOL}
                    {courseViewData.price}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Button
                    className="dark-btn"
                    variant="contained"
                    onClick={toggleModal}
                    disabled={hasKid ? false : true}
                  >
                    Purchase now
                  </Button>
                </Grid>
                <div>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Use Google's location service?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Subject title
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Disagree</Button>
                      <Button onClick={handleClose} autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={5}
          className="course_catalog_parent courese_single_col_wapper_yi"
        >
          <Box className={`${styles.subject_detail} page-container`}>
            <h2>Lessons</h2>
            <Box className={styles.subject_detail_single}>
              <Content courseData={courseViewData} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* <Box sx={{ mt: 5 }}>
                <InstructorDetail
                    title="About the Teacher"
                    content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                />
            </Box> */}

      <DynamicTabs tabs={tabs} />

      <PurchaseCourseFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
      />
    </>
  );
};

export default CourseDetail;
