import Datatable from "../../../../../components/dataTables/Datatable";
import { Avatar, Box, Typography } from "@mui/material";
import { AdminApi } from "../../../../../config/apis";
import { useNavigate } from "react-router-dom";
import styles from "./topStudent.module.scss";
import { toTitleCase } from "../../../../../helper/helperFunction";
import AvatarImage from "../../../../../components/Avatar";

export default function TopStudentTable() {
  const navigate = useNavigate();

  const tableColumns = [
    {
      name: "Student Name",
      width: "200px",
      cell: (row: { studentName: string; studentProfile: string }) => (
        <>
          <AvatarImage imageName={row.studentProfile} name={row.studentName} />
          {toTitleCase(`${row.studentName}`)}
        </>
      ),
    },

    {
      name: "Course",
      width: "250px",
      cell: (row: { courseName: string }) => <>{row.courseName}</>,
    },
    {
      name: "Avg score",
      cell: (row: { totalMarks: string }) => (
        <>
          <Typography
            variant="body1"
            className={styles.studentIndexContentScore}
          >
            {row.totalMarks}
          </Typography>
        </>
      ),
    },
    {
      name: "Lesson Completed",
      cell: (row: { totalProgress: string }) => (
        <>
          <Typography
            variant="body1"
            className={styles.studentIndexContentScore}
          >
            {row.totalProgress}%
          </Typography>
        </>
      ),
    },
  ];
  const onViewStudent = async (id: string) => {
    navigate(`/admin/top-student/${id}`);
  };

  return (
    <>
      <div className="page-container" style={{ padding: 0 }}>
        <Box className={styles.studentPageContainer}>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.TOP_STUDENTS}
            onView={onViewStudent}
            actionColumns={false}
            module="student-view"
          />
        </Box>
      </div>
    </>
  );
}
