import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./quize.module.scss";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { Textarea } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import Datatable from "../../../../../components/dataTables/Datatable";
import { AdminApi } from "../../../../../config/apis";

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

interface FilterProps {
  onFiltersChange: (filters: FilterState) => void;
}

const QuizePanel: React.FC<FilterProps> = ({ onFiltersChange }) => {
  // Select Course
  const [filterData, setFilterData] = useState<FilterState>({
    teacherId: null,
    status: "off",
    searchQuery: null,
  });

  // Quiz Content Table
  const navigate = useNavigate();

  const tableColumns = [
    {
      name: "order #",
      width: "120px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizIndexContentIndex}>
            1
          </Typography>
        </>
      ),
    },
    {
      name: "Question",
      width: "400px",
      cell: () => (
        <div>
          <Typography variant="h4" className={styles.quizIndexContentQue}>
            Lorem ipsum dolor sit amet, consectetur
          </Typography>
        </div>
      ),
    },
    {
      name: "Quiz Type",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizIndexContent}>
            Multiple choice
          </Typography>
        </>
      ),
    },
    {
      name: "Makrs",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizIndexContent}>
            10
          </Typography>
        </>
      ),
    },
  ];
  const onViewStudent = async (id: string) => {
    navigate(`/admin/top-student/${id}`);
  };

  return (
    <Box className={styles.quizPanelMain}>
      <Typography variant="h2" className={styles.quizPanelMainTitle}>
        Add A Quiz
      </Typography>
      <Grid container className={styles.quizPanelContainer}>
        <Grid item xs={12} className={styles.quizPanelFrom}>
          {/* Form */}
          <Form className={styles.quizPanelFormContainer}>
            <Box className={styles.quizPanelFormRow}>
              <Typography variant="body1" className={styles.quizPanelFormLabel}>
                Select Subject
              </Typography>
              <Select
                onChange={(event) =>
                  setFilterData((prevState: FilterState) => {
                    return {
                      ...prevState,
                      status: event.target.value,
                    };
                  })
                }
                value={filterData.status}
                className={styles.quizPanelFormSelect}
              >
                <MenuItem value="off">Select Any</MenuItem>
                <MenuItem value="math">Maths</MenuItem>
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="urdu">Urdu</MenuItem>
              </Select>
            </Box>
            <Box className={styles.quizPanelFormRow}>
              <Typography variant="body1" className={styles.quizPanelFormLabel}>
                Quiz title
              </Typography>
              <TextField
                fullWidth
                placeholder="Title"
                className={styles.quizPanelFormInput}
              />
            </Box>
            <Box className={styles.quizPanelFormRow}>
              <Typography variant="body1" className={styles.quizPanelFormLabel}>
                Description
              </Typography>
              <Textarea
                placeholder="Description"
                minRows={3}
                className={styles.quizPanelFormTextArea}
              />
            </Box>
            <Box className={styles.quizPanelFormRow}>
              <Typography variant="body1" className={styles.quizPanelFormLabel}>
                Tags
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter Tags"
                className={styles.quizPanelFormInput}
              />
            </Box>
            <Box className={styles.quizPanelFormRow}>
              <Typography variant="body1" className={styles.quizPanelFormLabel}>
                Duration
              </Typography>
              <Box className={styles.quizPanelFormDate}>
                <TextField
                  fullWidth
                  placeholder="0"
                  className={styles.quizPanelFormDateHours}
                  type="number"
                />
                <Typography
                  variant="body1"
                  className={styles.quizPanelFormDateLabel}
                >
                  Hours
                </Typography>
              </Box>
              <Box className={styles.quizPanelFormDate}>
                <TextField
                  fullWidth
                  placeholder="0"
                  type="number"
                  className={styles.quizPanelFormDateMins}
                />
                <Typography
                  variant="body1"
                  className={styles.quizPanelFormDateLabel}
                >
                  Mins
                </Typography>
              </Box>
            </Box>
          </Form>
          {/* End Form */}
        </Grid>
        <Grid item xs={6} className={styles.quizPanelSubTitle}>
          <Typography variant="h4">Quiz Content</Typography>
        </Grid>
        <Grid item xs={6} className={styles.quizPanelAddBtn}>
          <Button className={styles.quizPanelAddBtnContent}>add Content</Button>
        </Grid>
        <Grid item xs={12} className="quizPanelTabel">
          <Box className="page-container">
            <Datatable
              tableColumns={tableColumns}
              dataUrl={AdminApi.COURSES_PAGINATE}
              onView={onViewStudent}
              actions={{
                view: true,
              }}
              module="student-view"
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={styles.quizPanelActionBtn}>
          <Button className={styles.quizPanelActionBtnContent}>Save</Button>
          <Button className={styles.quizPanelActionBtnContent}>Cancel</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuizePanel;
