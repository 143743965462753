import React, { useEffect, useState } from "react";
import styles from "./enrolled.module.css";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { api, resolveUrl, AdminApi } from "../../../config/apis";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";
import { useSelectedData } from "../../../context/SelectedDataContext";
import { useParams } from "react-router-dom";
import playvideo from "../../../static/images/play-img.png";
import Moment from "react-moment";
import { toast } from "react-toastify";

interface DataItem {
  id: number;
  installment: string;
  paidAmount: number;
  remainingAmount: number;
  installmentAmount: number;
  dueDate: string;
  payable: boolean;
  overDueDate: boolean;
}

interface CourseData {
  assignedStudent: string;
  assignedTeacher: string;
  courseFeatureImage?: string;
  courseName: string;
  lessons: number;
  progress: number;
}

const CourseListContent = () => {
  const { REACT_APP_SERVER_BASE } = process.env;

  const params = useParams();
  const { setSelectedInstall } = useSelectedData();
  const [intallmentData, setInstallmenData] = useState<DataItem[] | null>([]);

  const [cardData, setCardData] = useState<CourseData[] | null>(null);
  const [paidAmount, setPaidAmount] = useState<any>();
  const [installAmount, setInstallAmount] = useState<any>();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const [selectedData, setSelectedData] = useState<
    {
      paymentScheduleId: number;
      amount: number;
    }[]
  >([]);

  // const handleCheckboxChange = (id: number, item: DataItem) => {
  //   if (selectedIds.includes(id)) {
  //     setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
  //     setSelectedData(
  //       selectedData.filter(
  //         (selectedItem) => selectedItem.paymentScheduleId !== id
  //       )
  //     );
  //   } else {
  //     setSelectedIds([...selectedIds, id]);
  //     setSelectedData([
  //       ...selectedData,
  //       { paymentScheduleId: id, amount: item.installmentAmount },
  //     ]);
  //   }
  // };

  const handleCheckboxChange = (id: number, item: DataItem) => {
    const previousInstallmentsPaidOrSelected = intallmentData
      ?.filter((installment) => installment.id < id)
      .every(
        (installment) =>
          installment.paidAmount > 0 || selectedIds.includes(installment.id)
      );

    if (!previousInstallmentsPaidOrSelected) {
      toast.error("Please pay previous installments first.");
      return;
    }

    if (selectedIds.includes(id)) {
      // Unselect the checkbox and all subsequent checkboxes
      setSelectedIds(selectedIds.filter((selectedId) => selectedId < id));
      setSelectedData(
        selectedData.filter(
          (selectedItem) => selectedItem.paymentScheduleId < id
        )
      );
    } else {
      setSelectedIds([...selectedIds, id]);
      setSelectedData([
        ...selectedData,
        { paymentScheduleId: id, amount: item.installmentAmount },
      ]);
    }
  };

  function sumAllAmounts(selectedData: any[]) {
    const sum = selectedData.reduce((total, item) => total + item.amount, 0);
    return sum;
  }

  const totalSum = sumAllAmounts(selectedData);

  const remainingTotalAmount = installAmount - paidAmount;

  const remainingAmount = remainingTotalAmount - totalSum;

  useEffect(() => {
    setSelectedInstall({
      data: selectedData,
      totalAmount: totalSum,
    });
  }, [selectedData, setSelectedInstall]);

  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        const res = await api.get(
          resolveUrl(AdminApi.INSTALLMENT_SCHEDULES, params.id ?? "")
        );
        setInstallmenData(res.data);
        const sumOfPaidAmount = res.data
          .filter((item: { paidAmount: number }) => item.paidAmount > 0)
          .reduce(
            (total: number, item: { paidAmount: number }) =>
              total + item.paidAmount,
            0
          );
        const sumOfInstallment = res.data
          .filter(
            (item: { installmentAmount: number }) => item.installmentAmount > 0
          )
          .reduce(
            (total: number, item: { installmentAmount: number }) =>
              total + item.installmentAmount,
            0
          );
        setPaidAmount(sumOfPaidAmount);
        setInstallAmount(sumOfInstallment);
      };
      fetchData();
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        const res = await api.get(
          resolveUrl(AdminApi.INSTALLMENT_PAID_COURSES_LIST, params.id ?? "")
        );
        setCardData(res.data.items);
      };
      fetchData();
    }
  }, [params.id]);

  return (
    <>
      <Box sx={{ width: "100%" }} className={styles.couserList}>
        {cardData &&
          cardData.map((items, index) => (
            <Grid
              key={index}
              container
              spacing={2}
              className={styles.couserListContent}
            >
              <Box className={styles.couserThumbnailContent}>
                <img
                  src={`${items.courseFeatureImage}` ?? playvideo}
                  alt={items.courseName}
                />
              </Box>
              <Box className={styles.couserTextContent}>
                <Box className={styles.couser_wrape}>
                  <Typography
                    variant="h3"
                    sx={{ mt: 2 }}
                    className={styles.couserTitle}
                  >
                    Course title
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ mt: 2, fontWeight: "400!important" }}
                    className={styles.couserTitle}
                  >
                    {items.courseName}
                  </Typography>
                </Box>
                <Box className={styles.couser_wrape}>
                  <Typography
                    variant="h3"
                    sx={{ mt: 2 }}
                    className={styles.couserTitle}
                  >
                    Assigned Student
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ mt: 2, fontWeight: "400!important" }}
                    className={styles.couserTitle}
                  >
                    {items.assignedStudent}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
      </Box>
      <Paper elevation={3} className={styles.installment_popup_wrap}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Installment</TableCell>
              <TableCell>Due On</TableCell>
              <TableCell>Installment Amount</TableCell>
              <TableCell>Paid Amount</TableCell>
              <TableCell>Remaining Amount</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {intallmentData &&
              intallmentData.map((item) => (
                <TableRow key={item.id}>
                  <Checkbox
                    checked={!item.payable || selectedIds.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id, item)}
                    disabled={!item.payable}
                  />
                  <TableCell>{item.installment}</TableCell>
                  <TableCell>
                    <Moment format="MMM DD, YYYY">{item.dueDate}</Moment>
                  </TableCell>
                  <TableCell>
                    {CURRENCY_SYMBOL}
                    {item.installmentAmount}
                  </TableCell>
                  <TableCell>
                    {CURRENCY_SYMBOL}
                    {item.paidAmount}
                  </TableCell>
                  <TableCell>
                    {item.remainingAmount === 0
                      ? "-"
                      : `${CURRENCY_SYMBOL} ${item.remainingAmount}`}{" "}
                  </TableCell>
                  <TableCell>
                    {!item.payable ? (
                      <span className={`${styles.Paid} ${styles.status_pay}`}>
                        Paid
                      </span>
                    ) : item.overDueDate ? (
                      <span
                        className={`${styles.Overdue} ${styles.status_pay}`}
                      >
                        Overdue
                      </span>
                    ) : (
                      <span
                        className={`${styles.Pending} ${styles.status_pay}`}
                      >
                        Pending
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <Grid className={styles.purchaseListBottom}>
        <Box className={styles.purchaseListDetail}>
          <Grid className={styles.purchaseTotal}>
            <Typography variant="body1" className={styles.purchaseTotalStatic}>
              Total Course Amount
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={styles.purchaseTotalDynamic}
            >
              {CURRENCY_SYMBOL}
              {installAmount && installAmount}
            </Typography>
          </Grid>
          <Grid className={styles.purchaseTotal}>
            <Typography variant="body1" className={styles.purchaseTotalStatic}>
              Remaining Amount
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={styles.purchaseTotalDynamic}
            >
              {CURRENCY_SYMBOL}
              {remainingAmount && remainingAmount}
            </Typography>
          </Grid>
          <Grid className={styles.purchaseTotal}>
            <Typography variant="body1" className={styles.purchaseTotalStatic}>
              Amount to pay
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={styles.purchaseTotalDynamic}
            >
              {CURRENCY_SYMBOL}
              {totalSum && totalSum}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default CourseListContent;
