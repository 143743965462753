const NotFound = () => {
  return (
    <div
      style={{
        textAlign: "center",
        height: "95vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <h1>404 - Not Found</h1>
        <p>The page you are looking for doesn't exist.</p>
        {/* You can add a link to go back to the home page or any other relevant page */}
        <a href="/">Go back to Home</a>
      </div>
    </div>
  );
};

export default NotFound;
