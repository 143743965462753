import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import Form from "../../../components/forms/Form";
import { useParams } from "react-router-dom";
import styles from "./m-questions-bank.module.scss";
import validationSchema from "./validation";

export default function EditQuestionBank() {
  type QuestionBankType = {
    question: string;
    answer: string[];
    answerKey: number[];
  };

  const params = useParams();

  const [formData, setFormData] = useState<QuestionBankType>({
    question: "",
    answer: [""],
    answerKey: [],
  });

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const handleAnswers = (event: FormEvent, index: number) => {
    const target = event.target as HTMLInputElement;
    const answers = formData.answer;

    answers[index] = target.value;

    setFormData({
      ...formData,
      answer: answers,
    });

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      answer: undefined,
    }));
  };

  const handleAnswersActions = (
    event: FormEvent,
    type: string,
    index?: number
  ) => {
    const answers = formData.answer;
    const answerKeys = formData.answerKey;

    switch (type) {
      case "add":
        answers.push("");
        break;
      case "remove":
      default:
        if (index && answerKeys) {
          answers.splice(index, 1);
          const answerKeyIndex = answerKeys.indexOf(index);
          answerKeys.splice(answerKeyIndex, 1);
        }
        break;
    }

    setFormData({
      ...formData,
      answer: answers,
      answerKey: answerKeys,
    });
  };

  const checkAnswerKey = (event: FormEvent, index: number) => {
    const target = event.target as HTMLInputElement;

    let answerKeys = formData.answerKey;

    if (!answerKeys) {
      answerKeys = [];
    }
    if (target.checked) answerKeys.push(index);
    else {
      const answerKeyIndex = answerKeys.indexOf(index);
      answerKeys.splice(answerKeyIndex, 1);
    }

    setFormData({
      ...formData,
      answerKey: answerKeys,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      answerKey: undefined,
    }));
  };

  const editData = useCallback(async () => {
    if (params.id) {
      const res = await api.get(
        resolveUrl(AdminApi.QUESTIONBANK_VIEW, params.id)
      );
      setFormData({
        question: res.data.questionBank.question,
        answer: JSON.parse(res.data.questionBank.answers),
        answerKey: JSON.parse(res.data.questionBank.answer_key),
      });
    }
  }, [params.id]);

  useEffect(() => {
    editData();
  }, [editData]);

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const newErrors: any = {};

      // Custom validation for assessmentWeightage
      for (var i = 0; i < formData.answer.length; i++) {
        if (formData.answer[i] === "" || formData.answer[i] === undefined) {
          newErrors[`answer[${i}]`] = "Answer is required.";
        }
      }

      setErrors(newErrors); // Set Yup validation errors and custom errors in the state

      // Check if there are any errors (Yup or custom)
      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors); // Set Yup validation errors in the state
      return false; // Validation failed
    }
  };

  return (
    <Box className={styles.mQbankMain}>
      <Box sx={{ width: "100%" }} className={styles.mQbankContainer}>
        <Box sx={{ width: "100%" }} className={styles.mQbankTitle}>
          <Typography variant="h2">Edit Question</Typography>
        </Box>
        <Grid container className={styles.mQbankFromContainer}>
          <Grid item xs={12} className={styles.mQbankFromInnerContainer}>
            <Form
              submitUrl={AdminApi.QUESTIONBANK_EDIT}
              formData={formData}
              editId={params.id}
              successPath="/admin/question-bank"
              validate={customValidation}
            >
              <Grid
                item
                xs={12}
                container
                sx={{ mb: 3, alignItems: "flex-start" }}
                className={styles.mQbankFromContent}
              >
                <Grid
                  item
                  sx={{ width: "160px" }}
                  className={styles.mQbankFromLabel}
                >
                  <InputLabel sx={{ textAlign: "left" }}>Question</InputLabel>
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 160px)" }}
                  className={styles.mQFAContentInput}
                >
                  <TextField
                    fullWidth
                    value={formData.question}
                    name="question"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.question !== undefined}
                    helperText={errors.question}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                sx={{ mb: 3, alignItems: "flex-start" }}
                className={styles.mQbankFromContent}
              >
                <Grid
                  item
                  sx={{ width: "160px" }}
                  className={styles.mQbankFromLabel}
                >
                  <InputLabel sx={{ textAlign: "left" }}>Answers</InputLabel>
                </Grid>

                <Grid
                  item
                  sx={{ width: "calc(100% - 160px)" }}
                  className={styles.mQbankFromAnsContent}
                >
                  {errors.answerKey && (
                    <Alert severity="error" sx={{ width: "100%" }}>
                      {errors.answerKey}
                    </Alert>
                  )}
                  {errors.answer && (
                    <Alert severity="error" sx={{ width: "100%" }}>
                      {errors.answer}
                    </Alert>
                  )}
                  {formData.answer &&
                    formData.answer.map((answer, index) => {
                      return (
                        <Grid
                          container
                          sx={{ mb: 1, alignItems: "center" }}
                          key={index}
                          className={styles.mQFAContent}
                        >
                          <Grid
                            item
                            sx={{ width: "calc(100% - 80px)" }}
                            className={styles.mQFAContentInput}
                          >
                            <TextField
                              fullWidth
                              value={answer}
                              name="question"
                              variant="outlined"
                              onChange={(e) => handleAnswers(e, index)}
                              helperText={errors[`answer[${index}]`] || ""}
                              error={Boolean(errors[`answer[${index}]`])}
                            />
                          </Grid>
                          <Grid
                            item
                            sx={{ width: "30px" }}
                            className={styles.mQFAContentCheckBox}
                          >
                            <Checkbox
                              name="answerKey"
                              value={true}
                              color="success"
                              checked={
                                formData.answerKey
                                  ? formData.answerKey.indexOf(index) >= 0
                                  : false
                              }
                              onChange={(e) => checkAnswerKey(e, index)}
                            />
                          </Grid>
                          {index !== 0 && (
                            <Grid
                              item
                              sx={{ width: "30px" }}
                              className={styles.mQFAContentRemoveBtn}
                            >
                              <Button
                                onClick={(e) =>
                                  handleAnswersActions(e, "remove", index)
                                }
                              ></Button>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}

                  <Grid container className={styles.mQAddBtn}>
                    <Grid item xs={4}>
                      <Button onClick={(e) => handleAnswersActions(e, "add")}>
                        Add Answer
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
