import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { AdminApi, api } from "../../../config/apis";
import style from "./dashboard.module.css";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { Grid } from "@mui/joy";

const EnrolmentsChart = () => {
  const getCurrentMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
    return currentMonth;
  };
  const currentMonth = getCurrentMonth();
  const [selectedMonth, setSelectedMonth] = useState<number | string>(
    currentMonth
  );
  const [selectedOption, setSelectedOption] = useState<string>("monthly");
  const currentYear = new Date().getFullYear();
  const [isDataLoaded, setIsEnrolments] = useState(false);
  const [months, setEnroMonths] = useState<number[]>([]);
  const [lectures, setEnrolments] = useState<number[]>([]);

  const handleMonthChange = (event: SelectChangeEvent<number | string>) => {
    setSelectedMonth(event.target.value);
  };

  const handleOptionChange = (event: SelectChangeEvent<number | string>) => {
    const selectedValue = event.target.value as string;
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        let paramsApi: any = {
          year: currentYear,
        };

        if (selectedOption === "monthly") {
          paramsApi.month = selectedMonth;
        }
        const Enrolments = await api.get(AdminApi.STUDENT_ENROLMENTS, {
          params: paramsApi,
        });

        setEnroMonths(Object.keys(Enrolments.data.data).map(Number));
        setEnrolments(Object.values(Enrolments.data.data).map(Number));
        setIsEnrolments(true);
      } catch (error) {
        setIsEnrolments(true);
        console.error("Error fetching data:", error);
      }
    };

    fetchGraphData();
  }, [currentYear, selectedMonth, selectedOption]);

  const year_count = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data: any = {
    labels: selectedOption === "yearly" ? year_count : months,
    datasets: [
      {
        label: "Enrolments", // Empty string as the label
        data: lectures ?? [],
        borderColor: "rgba(164, 146, 95, 1)",
        backgroundColor: "rgba(163, 145, 94, 1), rgba(214, 195, 143, 1)",
        borderWidth: 2,
        pointRadius: 2,
        pointBackgroundColor: "rgba(163, 145, 94, 1), rgba(214, 195, 143, 1)",
        borderJoinStyle: "round",
        tension: 0.5,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 31,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Box
          className={style.chart_wrap}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography className={style.chart_title} variant="h5" gutterBottom>
              Enrolments
            </Typography>
          </Box>
          <Box className={style.chart_select_wrap}>
            <Select value={selectedOption} onChange={handleOptionChange}>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
            {selectedOption === "yearly" ? (
              <Select value={currentYear} disabled>
                <MenuItem value={currentYear}>{currentYear}</MenuItem>
              </Select>
            ) : (
              <Select value={selectedMonth} onChange={handleMonthChange}>
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </Select>
            )}
          </Box>
        </Box>

        {isDataLoaded ? (
          <Grid sx={{ padding: "20px" }}>
            <Chart type="line" data={data} options={options} />
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 350,
            }}
          >
            <CircularProgress sx={{ color: "#d6c38f" }} />
          </div>
        )}
      </Box>
    </>
  );
};

export default EnrolmentsChart;
