import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi } from "../../../../config/apis/admin";
import { resolveUrl } from "../../../../config/apis";
import CircularProgressWithLabel from "../../../../components/ProgressCircle";
import moment from "moment";
import { useState } from "react";
import { Box } from "@mui/material";

type props = {
  courseId?: number | null;
  paramId?: number | null;
};

const Strength = ({ courseId, paramId }: props) => {
  const [dataAvailable, setDataAvailable] = useState(true);

  const tableColumns = [
    {
      selector: (row: { feedback?: string; date: any }) => (
        <>
          <p style={{ fontWeight: 700 }}>{row.feedback}</p>
          <p>{moment(row.date).format("MMMM DD, YYYY")}</p>
        </>
      ),
      name: "Strength",
      sortable: false,
      width: "90%",
      wrap: "yes",
      compact: "yes",
    },

    {
      selector: (row: any) => (
        <CircularProgressWithLabel value={row.percentage} />
      ),
      name: "",
      sortable: false,
      width: "10%",
      right: "yes",
      compact: "yes",
      
    },
  ];
  const param = {
    courseId: courseId,
  };
  // Callback function to handle data availability
  const handleDataAvailable = (isAvailable: boolean) => {
    setDataAvailable(isAvailable);
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: "26px",
        fontWeight: 700,
        paddingBottom: "20px!important",
      },
    },
  };

  return (
    <>
      {dataAvailable && (
        <Box className="courseDataTable" mt={2} p={4} bgcolor={"#fff"}>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={resolveUrl(
              AdminApi.TEACHER_FEEDBACK_PDF,
              `${paramId}/Strength`
            )}
            actions={{
              view: true,
              edit: false,
              delete: false,
            }}
            actionColumns={false}
            pagination={false}
            dataFilter={param}
            onDataAvailable={handleDataAvailable}
            module="manage-kids"
            customStyles={customStyles}
          />
        </Box>
      )}
    </>
  );
};

export default Strength;
