import { Box, Grid, Typography } from "@mui/material";
import style from "./dashboard.module.css";
import TopStudents from "../top-sudents/view";
import TopTeachers from "../top-teachers/view";
import TopCourses from "../top-courses/view";
import ViewTransactions from "../transactions/view";
import StudentsActiveChart from "./StudentsActiveChart";
import EnrolmentsChart from "./EnrolmentsChart";
import StudentEngagement from "./StudentEngagement";
import RevenueVsRefunds from "./RevenueVsRefunds";
import AdminCardData from "./CardData";
import { useCallback, useEffect, useState } from "react";
import { AdminApi, api } from "../../../config/apis";

const AdminDashboard = () => {
  return (
    <>
      <Box className="admin_dash_board">
        <Grid container spacing={3} className="col_6_yi_main">
          <AdminCardData />
        </Grid>
        <Grid container item xs={12} spacing={3} sx={{ marginTop: "0" }}>
          <Grid item xs={6} className="col_2_yi">
            <Box
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <Typography
                className={style.chart_title}
                variant="h5"
                gutterBottom
              >
                Revenue Vs Refunds
              </Typography>
              <RevenueVsRefunds />
            </Box>
          </Grid>
          <Grid item xs={6} className="col_2_yi">
            <EnrolmentsChart />
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ marginTop: "0" }}>
          <Grid item xs={7} className="col_2_yi">
            <StudentEngagement />
          </Grid>
          <Grid item xs={5} className="col_2_yi">
            <Box className={style.student_chart}>
              <Typography
                className={style.chart_title}
                variant="h5"
                gutterBottom
              >
                Students
              </Typography>
              <StudentsActiveChart />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "0" }}>
          <Grid item xs={6} className="col_2_yi">
            <Box
              sx={{
                backgroundColor: "#fff",
              }}
              className={style.student_chart}
            >
              <Typography
                className={style.chart_title}
                variant="h5"
                gutterBottom
              >
                Top Students
              </Typography>
              <TopStudents />
            </Box>
          </Grid>
          <Grid item xs={6} className="col_2_yi">
            <Box
              className={style.student_chart}
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <Typography
                className={style.chart_title}
                variant="h5"
                gutterBottom
              >
                Top Teachers
              </Typography>
              <TopTeachers />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "0" }}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <Typography
                className={style.chart_title}
                variant="h5"
                gutterBottom
              >
                Top Subjects
              </Typography>
              <TopCourses />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "0" }}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <div className={style.viewTransactionsContainer}>
                <ViewTransactions />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdminDashboard;
