import { useState } from "react";
import { AdminApi } from "../../../config/apis";
import Datatable from "../../../components/dataTables/Datatable";
import { toTitleCase } from "../../../helper/helperFunction";
import {
  Avatar,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Button, FormControl } from "react-bootstrap";
import styles from "./enrolled.module.scss";
import { useNavigate } from "react-router-dom";

export default function ViewEnrolledStudents() {
  const navigate = useNavigate();

  type FiltersData = {
    student_name?: string | null;
    course_name?: string | null;
    teacher_name?: string | null;
    order?: string | null;
  };
  const { REACT_APP_SERVER_BASE } = process.env;
  const [filters, setFilters] = useState<FiltersData>({});
  const [filterData, setFilterData] = useState<FiltersData>({});

  const tableColumns = [
    {
      cell: (row: { student: any; studentProfile: string }) => {
        return (
          <>
            <Avatar
              sx={{ width: 40, height: 40, mr: 2 }}
              alt={row.student.first_name.toUpperCase()}
              src={`${REACT_APP_SERVER_BASE}${row.studentProfile}`}
            />
            {toTitleCase(`${row.student.first_name} ${row.student.last_name}`)}
          </>
        );
      },
      name: "Student",
      sortable: false,
    },
    {
      selector: (row: { course: any }) => row.course.name,
      name: "Course",
      sortable: false,
    },
    {
      selector: (row: { lessons: number }) => row.lessons,
      name: "Lessons",
      sortable: false,
    },
    {
      cell: (row: { course: any; teacherProfile: string }) => {
        return (
          <>
            <Avatar
              sx={{ width: 40, height: 40, mr: 2 }}
              alt={row.course.teacher.first_name.toUpperCase()}
              src={`${REACT_APP_SERVER_BASE}${row.teacherProfile}`}
            />
            {toTitleCase(
              `${row.course.teacher.first_name} ${row.course.teacher.last_name}`
            )}
          </>
        );
      },
      name: "Assigned Teacher",
      sortable: false,
    },
    {
      cell: (row: { student: any }) => (
        <>
          <button
            style={{ border: 0, cursor: "pointer" }}
            className="view"
            onClick={() => onView(row.student.id)}
          >
            view
          </button>
        </>
      ),
      name: "Action",
      sortable: false,
      width: "80px",
    },
  ];
  const onView = async (id: string) => {
    navigate(`/admin/student/view/${id}`);
  };
  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Enrolled Students</h1>
      </div>
      <Grid container className={styles.enrolledStudnetFilter}>
        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          className={styles.enrolledStudnetFilterContainer}
        >
          <Typography
            variant="body1"
            className={styles.enrolledStudnetFilterTitle}
          >
            Search By
          </Typography>
          <TextField
            id="student"
            variant="outlined"
            fullWidth
            placeholder="Student Name"
            className={styles.enrolledStudnetFilterStudentName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setFilters(filterData);
              }
            }}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  student_name: event.target.value,
                };
              })
            }
          />
          <TextField
            id="course"
            variant="outlined"
            fullWidth
            placeholder="Subject Name"
            className={styles.enrolledStudnetFilterCourseName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setFilters(filterData);
              }
            }}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  course_name: event.target.value,
                };
              })
            }
          />
          <TextField
            id="teacher"
            variant="outlined"
            fullWidth
            placeholder="Teacher Name"
            className={styles.enrolledStudnetFilterTeacherName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setFilters(filterData);
              }
            }}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  teacher_name: event.target.value,
                };
              })
            }
          />
          <Button
            className={styles.enrolledStudnetFilterBtn}
            onClick={() => setFilters(filterData)}
          >
            Go
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={2}
          className={styles.enrolledStudnetFilterOrderContainer}
        >
          <Select
            value={(filters && filters?.order) ?? "descending"}
            onChange={(event) =>
              setFilters((prevFilters) => ({
                ...prevFilters,
                order: event.target.value,
              }))
            }
            className={styles.enrolledStudnetFilterOrderContent}
          >
            <MenuItem value="ascending" selected>
              Ascending
            </MenuItem>
            <MenuItem value="descending">Descending</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <div className="page-container">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.ENROLLED_STUDENTS_PAGINATE}
          filters={filters}
          actionColumns={false}
          module="manage-my-students-subjects"
        />
      </div>
    </>
  );
}
