import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import styles from "./style.module.scss";
import ImageUpload from "../../../components/UploadImage";
import * as Yup from "yup";

export default function ContactBannerCms({ heading }: any) {
  type FormData = {
    heading?: string;
    subTitle?: string;
    description?: string;
    buttonText?: string;
    buttonURL?: string;
    image?: File | null;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    heading: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [formData, setFormData] = useState<FormData>({
    heading: "",
    description: "",
    image: null,
  });

console.log(formData,"formData");


  const [onSuccess, setOnSuccess] = useState<any>();
  const [uploadPreviewProp, setUploadPreviewProp] = useState(null);

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const handleIDImageChange = (name: any, selectedFile: any) => {
    setFormData({
      ...formData,
      image: selectedFile,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.CONTACT_BANNER_INFO_VIEW));
        const { heading, description, image } = res.data.viewContactBanner ?? "";

        setFormData({
          heading: heading ?? "",
          description: description ?? "",
        });
        setUploadPreviewProp(image ?? null);
        setOnSuccess("");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [onSuccess]);

  return (
    <Box className={styles.aSCreateTicketMain}>
      <Typography
        variant="h2"
        className={styles.aSCTTitle}
        sx={{
          padding: "20px 30px",
          textAlign: "center",
          marginBottom: "0!important",
        }}
      >
        {heading ?? ""}
      </Typography>
      <Box className={styles.aSCTContainer}>
        <Form
          submitUrl={AdminApi.CONTACT_BANNER_INFO_SAVE}
          formData={formData}
          successPath="/admin/cms/contact-us"
          validate={customValidation}
          multipart={true}
          onSuccess={setOnSuccess}
        >
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Heading
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Title"
              value={formData.heading}
              name="heading"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.heading !== undefined}
              helperText={errors.heading}
            />
          </Box>
          <Box className={styles.aSCTContentDesc}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentDescLabel}
            >
              Description
            </InputLabel>
            <Textarea
              placeholder="Description"
              name="description"
              value={formData.description}
              minRows={4}
              maxRows={4}
              onChange={handleOnChange}
              className={styles.aSCTContentDescInput}
            />
            {errors.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Box>

          <Box className={`${styles.aSCTContentType} identity_picture `}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTypeLabel}
            >
              Image
            </InputLabel>
            <ImageUpload
              name="image"
              label="Upload Image"
              previewWidth={200}
              maxFileSize={5242880}
              onChange={handleIDImageChange}
              uploadPreviewProp={uploadPreviewProp}
              isRequired={true}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}
