// import React, { useState } from "react";
// import styles from '../manage-discount.module.scss'
import styles from "../studentDashboard.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import CourseAvgComp from "./Student-dashboard-components/Course-score";
import NoticeBoard from "../../manage-notice-board/noticeBoard";
import StudentAssignmentTable from "../courses/student-assignments/Student-assignment-table";
import StudentQuizesTable from "../courses/student-quizes/Student-quizes-table";
import { Link } from "react-router-dom";
import ViewCourse from "../courses/student-coursedetail/view";

const StudentDashboard = () => {
  return (
    <Box className={styles.studentDashboardMain}>
      <Grid container spacing={"30px"} className={styles.studentDashboardGrid}>
        <Grid item xs={12} className={styles.studentDashboardScore}>
          <CourseAvgComp />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={8}
          className={styles.studentDashboardCourse}
        >
          <Grid
            container
            className={styles.studentDashboardCourseGrid}
            justifyContent={"space-between"}
            marginBottom={2}
          >
            <Grid item className={styles.studentDashboardCourseTitle}>
              <Typography
                variant="h2"
                className={styles.studentDashboardCourseTitleContent}
              >
                My Subjects
              </Typography>
            </Grid>
            <Grid item className={styles.studentDashboardCourseLink}>
              <Typography
                variant="h5"
                className={styles.studentDashboardCourseLinkContent}
              >
                <Link to={"student-courses"}>See All</Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="student_dash_subjects">
            <ViewCourse />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          className={styles.studentDashboardNoticeBoard}
        >
          <Box>
            <NoticeBoard />
          </Box>
        </Grid>
        <Grid item xs={12} className={styles.studentDashboardBottomGrid}>
          <Grid container spacing={"30px"}>
            <Grid
              item
              lg={12}
              xl={6}
              className={styles.studentDashboardAssignment}
            >
              <Grid
                container
                className={styles.studentDashboardAssignmentTitle}
              >
                <Grid
                  item
                  xs={6}
                  className={styles.studentDashboardAssignmentTitleText}
                >
                  <Typography
                    className={
                      styles.studentDashboardAssignmentTitleTextContent
                    }
                    variant="h2"
                  >
                    My Assigments
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={styles.studentDashboardAssignmentLink}
                >
                  <Typography
                    variant="h5"
                    className={styles.studentDashboardAssignmentLinkContent}
                  >
                    <Link to={"student-assignments"}>See All</Link>
                  </Typography>
                </Grid>
              </Grid>
              <StudentAssignmentTable limit={4} />
            </Grid>
            <Grid item lg={12} xl={6} className={styles.studentDashboardQuizes}>
              <Grid container className={styles.studentDashboardQuizesTitle}>
                <Grid
                  item
                  xs={6}
                  className={styles.studentDashboardQuizesTitleText}
                >
                  <Typography
                    className={styles.studentDashboardQuizesTitleTextContent}
                    variant="h2"
                  >
                    My Quizes
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.studentDashboardQuizesLink}>
                  <Typography
                    variant="h5"
                    className={styles.studentDashboardQuizesLinkContent}
                  >
                    <Link to={"student-quizes"}>See All</Link>
                  </Typography>
                </Grid>
              </Grid>
              <StudentQuizesTable limit={4} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StudentDashboard;
