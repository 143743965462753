import { Grid } from "@mui/material";
import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import { GetUserName } from "../../../helper/helperFunction";

const DashboardAdmins = () => {
  return (
    <>
      <Box className="container">
        <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={2}>
            <Typography variant="h2" className={styles.main_heading}>
              Dashboard
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={{ xl: 4, lg: 3 }}>
          <Grid item xl={12}>
            <Typography variant="h2" className={styles.wlcm_heading}>
              welcome <span>{GetUserName()},</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardAdmins;
