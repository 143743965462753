import { Alert, Box, Button } from "@mui/material";
import { FormEvent, useState } from "react";
import { api, resolveUrl } from "../../../config/apis";
import FormLoader from "./loader/FormLoader";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface FormProps {
  validate?: () => Promise<boolean>;
  formData: any; // Update with a more specific type if possible
  submitUrl: string;
  editId?: string;
  onSuccess?: (data: any) => void; // Update with a more specific type if possible
  setFormSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  multipart?: boolean;
  classname?: string;
  btnClass?: string;
  disable?: boolean;
  saveText?: string;
  children?: React.ReactNode;
}

export default function Form({
  validate,
  formData,
  submitUrl,
  editId,
  onSuccess,
  setFormSuccess,
  multipart,
  classname,
  btnClass,
  disable,
  saveText,
  children,
}: FormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string[]>([]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    setIsError(false);

    const isValid = validate ? await validate() : true;

    if (isValid) {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        setIsLoading(false);
        return;
      }

      try {
        const token = await executeRecaptcha("enroll_form");
        const response = await api.post(
          resolveUrl(submitUrl, editId),
          {
            ...formData,
            recaptchaToken: token,
          },
          {
            headers: {
              "Content-Type": multipart
                ? "multipart/form-data"
                : "application/json",
            },
          }
        );

        if (onSuccess) {
          onSuccess(response.data);
        }

        if (setFormSuccess) {
          setFormSuccess((prevState) => !prevState);
        }
      } catch (error: any) {
        setIsError(true);

        if (error.response) {
          const status = error.response.status;
          const errorMessage =
            error.response.data.message || error.response.statusText;
          setErrorMsg([errorMessage]);
        } else {
          setErrorMsg(["Something went wrong. Please try again."]);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setErrorMsg(["Form validation failed. Please try again."]);
    }
  };

  return (
    <>
      {isLoading && <FormLoader />}
      <Box
        component="form"
        className={classname}
        onSubmit={handleFormSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        {isError && (
          <Alert severity="error" sx={{ width: "100%" }}>
            <ul style={{ padding: "0", margin: "0" }}>
              {errorMsg.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </Alert>
        )}
        {children}

        <Button
          className={`${btnClass ? btnClass : ""} btn`}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={disable}
        >
          {saveText || "Save"}
        </Button>
      </Box>
    </>
  );
}
