import { Grid, Typography } from "@mui/material";

interface cardData {
  heading: string;
  paragraph: string;
  imageUrl: string;
}
interface cardProps {
  cardItem: cardData[]|null;
}

export default function Card(props: cardProps) {
  return (
    <>
      {props.cardItem && props.cardItem.map((card, index) => (
        <CardItem
          key={index}
          heading={card.heading}
          paragraph={card.paragraph}
          imageUrl={card.imageUrl}
        />
      ))}
    </>
  );
}
const CardItem: React.FC<cardData> = ({ heading, paragraph, imageUrl }) => {
  return (
    <>
      <Grid item xs={4}>
        <Grid
          container
          sx={{ backgroundColor: "white" }}
          className="dashbaord-card"
          style={{ borderRadius: "5px" }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="body1">{paragraph}</Typography>
            <Typography variant="h3">{heading}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ justifyContent: "end", display: "flex" }}
          >
            <img
              src={imageUrl}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
