import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAssignmentsDetail } from "../../../../helper/helperFunction";
import "./pdfannotate.css";

const AssignmentEditor = function (url: any) {
  //   const params = useParams();

  //   const [assignmentPDF, setAssignmentPDF] = useState<string>();

  //   const { REACT_APP_SERVER_BASE } = process.env;

  //   const getAssignmentPDF = useCallback(async () => {
  //     try {
  //       const res = await getAssignmentsDetail(params.id ?? "");
  //       const pdfFile = `${REACT_APP_SERVER_BASE}${res.AssignmentData.upload_path.replace(
  //         "\\",
  //         "/"
  //       )}`;
  //       setAssignmentPDF(pdfFile);
  //     } catch (error) {
  //      console.error(error);
  //     }
  //   }, [params, REACT_APP_SERVER_BASE]);

  //   useEffect(() => {
  //     getAssignmentPDF();
  //   }, [getAssignmentPDF]);

  return (
    <>
      <div className="toolbar">
        <div className="tool">
          <label htmlFor="">Brush size</label>
          <input
            type="number"
            className="form-control text-right"
            value="1"
            id="brush-size"
            max="50"
          />
        </div>
        <div className="tool">
          <label htmlFor="">Font size</label>
          <select id="font-size" className="form-control">
            <option value="10">10</option>
            <option value="12">12</option>
            <option value="16" selected>
              16
            </option>
            <option value="18">18</option>
            <option value="24">24</option>
            <option value="32">32</option>
            <option value="48">48</option>
            <option value="64">64</option>
            <option value="72">72</option>
            <option value="108">108</option>
          </select>
        </div>
        <div className="tool">
          <button
            className="color-tool active"
            style={{ backgroundColor: "#212121" }}
          ></button>
          <button
            className="color-tool"
            style={{ backgroundColor: "red" }}
          ></button>
          <button
            className="color-tool"
            style={{ backgroundColor: "blue" }}
          ></button>
          <button
            className="color-tool"
            style={{ backgroundColor: "green" }}
          ></button>
          <button
            className="color-tool"
            style={{ backgroundColor: "yellow" }}
          ></button>
        </div>
        <div className="tool">
          <button className="tool-button active" id="pdfEditorSelector">
            <i className="fa fa-hand-paper-o" title="Free Hand"></i>
          </button>
        </div>
        <div className="tool">
          <button className="tool-button" id="pdfEditorPencil">
            <i className="fa fa-pencil" title="Pencil"></i>
          </button>
        </div>
        <div className="tool">
          <button className="tool-button" id="pdfEditorAddText">
            <i className="fa fa-font" title="Add Text"></i>
          </button>
        </div>
        <div className="tool">
          <button className="tool-button" id="pdfEditorAddArrow">
            <i className="fa fa-long-arrow-right" title="Add Arrow"></i>
          </button>
        </div>
        <div className="tool">
          <button className="tool-button" id="pdfEditorRectangle">
            <i className="fa fa-square-o" title="Add rectangle"></i>
          </button>
        </div>

        <div className="tool">
          <button className="btn-danger btn-sm" id="pdfEditorDeleteObj">
            <i className="fa fa-trash"></i>
          </button>
        </div>
        <div className="tool">
          <button className="btn-danger btn-sm" id="pdfEditorClear">
            Clear Page
          </button>
        </div>
        <div className="tool">
          <button className="btn-light btn-sm" id="pdfEditorSave">
            <i className="fa fa-save"></i> Save
            <span id="loader">
              <span className="cirlcle"></span>
            </span>
          </button>
        </div>
      </div>

      <div id="pdf-editor"></div>
    </>
  );
};

export default AssignmentEditor;
