import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../auth/auth';

type Props = {
    permission?: string;
 }

export const ProtectRoutes = <PROPS extends Props>({permission, ...props}: PROPS): JSX.Element => {
    const { state } = useAuth();    
    return state ? <Outlet/> : <Navigate to='/auth/login' />
};