import { Alert, CircularProgress, Typography } from "@mui/material";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import { api } from "../../config/apis";
import ActionButtons from "./ActionButtons";
// import Box from '@mui/material/Box';

export default function Datatable(params: any) {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();

  const refresh = () => {
    fetchData();
  };

  const showActionColumns = params.actionColumns !== false;

  const columns = [
    ...params.tableColumns,
    showActionColumns && {
      name: "Actions",
      width: "100px",
      cell: (row: { id: number }) => (
        <ActionButtons
          id={row.id}
          actions={params.actions}
          refreshHandle={refresh}
          onView={params.onView}
          onEdit={params.onEdit}
          onDelete={params.onDelete}
          state={state}
          module={params.module}
        />
      ),
    },
  ].filter(Boolean);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const parameters = {
      Id: params.paramId,
      page: page,
      limit: params.rowsPerPage ? params.rowsPerPage : rowsPerPage,
      keyword: params.searchQuery,
      filters: {
        ...params.filters,
      },
      ...params.dataFilter,
    };
    if (params.sortOrder) {
      parameters.sort_order = params.sortOrder;
    }

    await api
      .get(`${params.dataUrl}`, { params: parameters })
      .then((res: any) => {
        const modifiedData =
          res &&
          res.data.items.map((item: { id: any }, index: number) => {
            if (!item.id) {
              item.id = index + 1;
            }
            return item;
          });
        if (params.onDataAvailable) {
          params.onDataAvailable(
            res.data && res.data.items.length === 0 ? false : true
          );
        }

        setData(modifiedData);
        setPage(res.data.meta.currentPage);
        setTotalPages(res.data.meta.totalItems);
        setIsLoading(false);

        if (params.getResData) {
          params.getResData(res.data);
        }
        if (params.loading) {
          params.loading((prevState: any) => !prevState);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  }, [
    params.paramId,
    params.searchQuery,
    params.filters,
    params.dataUrl,
    page,
    rowsPerPage,
    params.dataFilter,
    params.sortOrder,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, params.refreshHandler]);

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1); // Reset the page number when rows per page changes
  };

  const showPagination = params.pagination !== false;

  return (
    <div>
      {state && (
        <Alert variant="outlined" severity={state.status}>
          {state.message}
        </Alert>
      )}

      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        progressPending={isLoading}
        pagination={showPagination}
        paginationServer={true}
        paginationTotalRows={totalPages}
        onChangePage={(page: SetStateAction<number>) => setPage(page)}
        onChangeRowsPerPage={handleRowsPerPageChange}
        progressComponent={<Loader />}
        customStyles={params.customStyles ? params.customStyles : {}}
      />
    </div>
  );
}

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 250,
      }}
    >
      <CircularProgress sx={{ color: "#d6c38f" }} />
    </div>
  );
};
