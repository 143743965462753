import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./forum.module.css";
import ActionButtons from "../../../components/dataTables/ActionButtons";
import { DateView } from "../../../components/DateView/DateView";
import {
  GetUserId,
  GetUserRole,
  HasPermission,
} from "../../../helper/helperFunction";
import SwitchCell from "./switch";
import DynamicTabs from "../../../components/tabs/Tabs";

export default function ViewaAchivedForum() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryText, setSearchQueryText] = useState("");

  const navigate = useNavigate();
  const userId = GetUserId();
  const tableColumns = [
    {
      cell: (row: {
        topic: string;
        description: string;
        slug: string;
        id: string;
      }) => (
        <div
          style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
          onClick={() => onView(row.slug, row.id)}
        >
          <Typography sx={{ fontWeight: "bold", lineHeight: "30px" }}>
            {row.topic}
          </Typography>
          <span>{row.description}</span>
        </div>
      ),
      name: "Topic",
      sortable: false,
      width: "700px",
    },
    {
      selector: (row: { forum_category?: string }) => row.forum_category,
      name: "Category",
      sortable: false,
    },
    {
      selector: (row: { totalThreads: string }) =>
        row.totalThreads ? row.totalThreads : "-",
      name: "Thread",
      sortable: false,
    },
    {
      name: "Last Thread",
      cell: (row: { last_thread: { creatorName: string; addedOn: string } }) =>
        row.last_thread &&
        row.last_thread.addedOn && (
          <div style={{ whiteSpace: "pre-wrap" }}>
            {/* <span>Lorem ipsum</span> */}

            <span>
              <DateView date={row.last_thread.addedOn} />
            </span>
            <Typography sx={{ fontWeight: "bold", lineHeight: "23px" }}>
              by {row.last_thread.creatorName}
            </Typography>
          </div>
        ),
      sortable: true,
      width: "150px",
    },
    {
      cell: (row: { id: any; archive: any }) => (
        <>
          <HasPermission permission="edit.manage-users">
            <SwitchCell id={row.id} is_active={row.archive} refresh={refresh} />
          </HasPermission>
        </>
      ),
      name: "Archive",
      sortable: false,
      width: "100px",
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row: { slug: string; id: string; creator: { id: number } }) =>
        row.creator.id === userId || GetUserRole() === "Super Admin" ? (
          <ActionButtons
            id={row.slug}
            actions={{
              view: false,
            }}
            onEdit={onEdit}
            onDelete={() => onDelete(row.id)}
            module={"manage-forums"}
          />
        ) : null,
    },
  ];

  const onEdit = async (id: string) => {
    navigate(`/admin/forum/edit/${id}`);
  };

  const onDelete = async (id: string) => {
    const res = await api.delete(resolveUrl(AdminApi.FORUM_DELETE, id));
    if (res.data.status) {
      refresh();
    }
    return res;
  };
  const onView = async (slug: string, id: string) => {
    navigate(`/admin/forum/${slug}`);
  };
  const [reloadData, setReloadData] = useState(false);

  const refresh = () => {
    setReloadData((prevReloadData) => !prevReloadData);
  };
  const tabs = [
    {
      label: "General Topics",
      content: (
        <>
          <h2 className="content-title">General Topics</h2>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.FORUM_ARCHIVE_VIEW_GENERAL}
            module="manage-forums"
            actionColumns={false}
            searchQuery={searchQuery}
            refreshHandler={reloadData}
          />
        </>
      ),
    },
    {
      label: "Discussion",
      content: (
        <>
          <h2 className="content-title">Discussion</h2>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.FORUM_ARCHIVE_VIEW_COURSE}
            searchQuery={searchQuery}
            module="manage-forums"
            actionColumns={false}
            refreshHandler={reloadData}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Achived Forums</h1>
      </div>
      <Box
        className="inner-container forums_search"
        sx={{ mb: 4, display: "flex", justifyContent: "space-between", p: "0" }}
      >
        <form onSubmit={(event) => event.preventDefault()}>
          <Box sx={{ display: "flex" }} className={styles.searchBy}>
            <InputLabel>Search By</InputLabel>
            <TextField
              id="coursetitle"
              variant="outlined"
              fullWidth
              onChange={(event) => setSearchQueryText(event.target.value)}
            />
            <Box className="btn-wrapper" marginLeft={1}>
              <Button
                className="btn"
                onClick={() => setSearchQuery(searchQueryText)}
              >
                Go
              </Button>
            </Box>
          </Box>
        </form>
      </Box>

      <DynamicTabs tabs={tabs} />
      {/* <div className="page-container Forums"> */}

      {/* <div className="General_Topics">
          <h2 className="content-title">General Topics</h2>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.FORUM_ARCHIVE_VIEW_GENERAL}
            module="manage-forums"
            actionColumns={false}
            searchQuery={searchQuery}
            refreshHandler={reloadData}
          />
        </div> */}
      {/* <h2 className="content-title">Discussion</h2>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.FORUM_ARCHIVE_VIEW_COURSE}
          searchQuery={searchQuery}
          module="manage-forums"
          actionColumns={false}
          refreshHandler={reloadData}
        /> */}
      {/* </div> */}
    </>
  );
}
