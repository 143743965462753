import React, { useEffect, useState } from "react";
import { getKidsData } from "../../../../../helper/helperFunction";
import { useAuth } from "../../../../../hooks/auth/auth";
import styles from "./Kids.module.scss";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { SERVER_KEY } from "../../../../../config/constants/EnviormentVariables";

interface Kisds {
  id: number;
  first_name: string;
  last_name: string;
  profile_picture?: string;
}

const KisdsList = ({ filterState, limit = 1000 }: any) => {
  const navigate = useNavigate();

  const [studentData, setStudentData] = useState<Kisds[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const Parent = useAuth();
  const id = Parent.state?.user.id;

  useEffect(() => {
    const fetchKidsData = async () => {
      try {
        setIsLoading(true);

        const res = await getKidsData(id ?? "", {
          ...filterState,
          limit: limit,
        });
        setStudentData(res);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchKidsData();

    return () => {};
  }, [filterState, limit, id]);

  const onView = async (id: number) => {
    navigate(`/admin/student/view/${id}`);
  };

  if (isLoading) {
    return (
      isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 350,
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      )
    );
  }
  const baseUrl = SERVER_KEY;

  return (
    <>
      <Box className={styles.studentProfileList}>
        {studentData &&
          studentData.map((student) => (
            <Card
              key={student.id}
              sx={{ maxWidth: 345, marginRight: 3, marginBottom: 3 }}
              className={styles.studentCardBx}
            >
              <CardContent className={styles.studentCardContent}>
                <Avatar
                  alt={
                    student?.first_name ? student.first_name.toUpperCase() : ""
                  }
                  src={`${baseUrl}${student?.profile_picture}`}
                  className={styles.stuProfile}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  className={styles.stuName}
                >
                  {student.first_name} {student.last_name}
                </Typography>
                {/* <Typography variant="body1" className={styles.stuLastActive}>
                  <b>Last Active</b> 1 hr ago
                </Typography> */}
              </CardContent>
              <div className={styles.stuViewDetailBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onView(student.id)}
                  className={styles.stuViewBtn}
                >
                  View details
                </Button>
              </div>
            </Card>
          ))}
      </Box>
    </>
  );
};

export default KisdsList;
