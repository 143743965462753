import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  topic: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),
  tags: Yup.array()
    .of(Yup.string().required("This field is required"))
    .min(1, "At least one tags is required"),
  category: Yup.array()
    .of(Yup.string().required("This field is required"))
    .min(1, "At least one category is required"),
  type_value: Yup.array().test({
    test: function (value) {
      return this.parent.type === "Course" ? !!value.length : true;
    },
    message: "Subject is required",
  }),
});

export default validationSchema;
