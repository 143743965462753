import { useState } from "react";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi } from "../../../config/apis";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { DateView } from "../../../components/DateView/DateView";

export default function ViewTeacherCourses() {
  const [filters, setFilters] = useState({});

  const navigate = useNavigate();

  const onView = async (slug: string) => {
    navigate(`/admin/teacher-courses/${slug}`);
  };

  const tableColumns = [
    {
      selector: (row: { name: string }) => row.name,
      name: "Subject Name",
      sortable: true,
    },
    {
      selector: (row: { created_at: string }) => (
        <DateView date={row.created_at} />
      ),
      name: "Created At",
      sortable: false,
    },
    {
      selector: (row: { section: any[] }) => {
        let countLessons = 0;
        row.section.forEach((section) => {
          countLessons += section.courseContent.length;
        });
        return countLessons;
      },
      name: "Lessons",
      sortable: false,
    },
    {
      selector: (row: { created_at: string }) => "150",
      name: "Subjects Completed",
      sortable: false,
    },
    // {
    //   selector: (row: { created_at: string }) => "150min",
    //   name: "Total Time",
    //   sortable: false,
    // },
    {
      selector: (row: { studentCourses: any[] }) => row.studentCourses.length,
      name: "Active Students",
      sortable: false,
    },
    {
      cell: (row: { slug: any }) => (
        <>
          <Button className="view" onClick={() => onView(row.slug)}>
            view
          </Button>
        </>
      ),
      name: "Action",
      sortable: false,
    },
  ];

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">My Subjects</h1>
      </div>

      <div className="page-container">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.TEACHER_COURSES_PAGINATE}
          filters={filters}
          onView={onView}
          actionColumns={false}
          actions={{
            edit: false,
            delete: false,
          }}
          module="manage-teacher-courses"
        />
      </div>
    </>
  );
}
