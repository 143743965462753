import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import styles from "./studentQuizes.module.scss";
import { getResultData } from "../../../../../helper/helperFunction";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormLoader from "../../../../../components/loader/FormLoader";

interface Result {
  correctAnswers: number;
  totalQuestions: number;
  obtainedMarks: number;
  totalMarks: number;
  completionTime: number;
  courseSlug?: string;
}

const StudentQuizesDetailResult = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [result, setResult] = useState<Result>();
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const getResult = async () => {
      try {
        setLoading(true);
        const res = await getResultData(params.id ?? "");
        setResult(res);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getResult();
  }, [params.id]);

  return (
    <>
      {loading ? (
        <Box
          className="loader"
          sx={{
            display: "flex",
            height: "50vh",
            position: "static",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          {result && (
            <Box className={styles.quizesDetailPanel}>
              <Box className={styles.quizesDetailPanelResult}>
                <Grid container className={styles.qDPRContainer}>
                  <Grid item xs={12} className={styles.qDPRTopTitle}>
                    <Typography
                      variant="body1"
                      className={styles.qDPRTitleText}
                    >
                      Test Completed
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12} className={styles.qDPRTime}>
                <Typography variant="h2" className={styles.qDPRTimeText}>
                  {result?.completionTime} : 00
                </Typography>
              </Grid> */}
                  <Grid item xs={12} className={styles.qDPRNote}>
                    <Box className={styles.qDPRNoteContainer}>
                      <Typography variant="h4" className={styles.qDPRNoteAns}>
                        You correctly answered {""}
                        {result && result?.correctAnswers}
                      </Typography>
                      <Typography variant="h4" className={styles.qDPRNoteTotal}>
                        questions out of {result && result?.totalQuestions}.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={styles.qDPRScore}>
                    <Typography variant="h4" className={styles.qDPRScoreTitle}>
                      You scored
                    </Typography>
                    <Typography variant="h4" className={styles.qDPRScoreText}>
                      {result && result?.obtainedMarks} /{" "}
                      {result && result?.totalMarks}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={styles.qDPRAction}>
                    <Grid container className={styles.qDPRActionContainer}>
                      {/* <Grid item xs={6} className={styles.qDPRActionAssign}>
                    <Button>Re Assign Quiz</Button>
                  </Grid> */}
                      <Grid item xs={12} className={styles.qDPRActionClose}>
                        <Button
                          onClick={() =>
                            navigate(
                              `/admin/student-quizes-answer/view/${params.id}`
                            )
                          }
                        >
                          Check your answers
                        </Button>
                        <Button
                          onClick={() =>
                            navigate(
                              `/admin/student-courses/view/${result?.courseSlug}`
                            )
                          }
                        >
                          Ok
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </div>
      )}
    </>
  );
};

export default StudentQuizesDetailResult;
