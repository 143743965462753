import { Link } from "react-router-dom";

const BucketFileViewer = ({ filePath, name }: any) => {
  const getFileUrl = (filePath: string) => {
    const { REACT_APP_SERVER_BASE } = process.env;

    // Check if REACT_APP_API_BASE is defined before constructing the URL
    if (!REACT_APP_SERVER_BASE) {
      return null; // or handle the undefined case as needed
    }

    const baseUrl = REACT_APP_SERVER_BASE;

    const fileUrl = filePath && `${filePath.replace("\\", "/")}`;
    return fileUrl;
  };

  const fileUrl = getFileUrl(filePath);

  // If fileUrl is undefined or null, don't render the link
  if (!fileUrl) {
    return null;
  }

  return (
    <Link className="view_link" to={fileUrl} target="_blank">
      {name}
    </Link>
  );
};

export default BucketFileViewer;
