import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Alert } from "@mui/material";
import mainLogo from "../../static/images/logo.png";
import { resolveUrl, api, AuthApi } from "../../config/apis";
import BootstrapInput from "../../layout/admin/partials/BootstrapInput";
import { useForm } from "react-hook-form";
import FormLoader from "../../components/loader/FormLoader";
import { toast } from "react-toastify";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

type FormData = {
  password: string;
  confirmPassword: string;
};

function ChangePassword() {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [token, setToken] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState([""]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const passwordInput = register("password", {
    required: "Password is required",
    pattern: {
      // value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*?])/,
      message:
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character",
    },
  });

  const confirmPasswordInput = register("confirmPassword", {
    required: "Confirm Password is required",
    validate: (value) =>
      value === watch("password") || "Passwords do not match",
  });
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    api
      .get(resolveUrl(AuthApi.FORGET_PASSWORD_TOKEN_VERIFY, params.token))
      .then((res) => {
        if (res.data && res.data.status) {
          if (params.token) setToken(params.token);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) navigate("/not-found");
      });
  }, []);

  const onSubmit = async (formData: FormData) => {
    setIsLoading(true);
    setIsError(false);

    const payload = {
      password: formData.password,
      confirm_password: formData.confirmPassword,
    };

    try {
      const res = await api.post(
        resolveUrl(AuthApi.CHANGE_PASSWORD, token),
        payload
      );

      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // Redirect the user to the login page after 5 seconds
      setTimeout(() => {
        navigate("/auth/login");
      }, 5000);

      setIsLoading(false);
      setIsError(false);
    } catch (error: any) {
      // Handle errors
      setIsLoading(false);
      setIsError(true);

      let errorMessage = "An error occurred.";

      if (error.response) {
        errorMessage = error.response.data.message;
      } else if (error.code === "auth/weak-password") {
        errorMessage =
          "The password is too weak. Please choose a stronger password.";
      }

      setErrorMsg([errorMessage]);
    }
  };

  <BootstrapInput />;

  return (
    <>
      {isLoading && <FormLoader />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "88vh",
          justifyContent: "center",
        }}
        className="parent-login-wrap"
      >
        <Box className="login-wrap create-password">
          <Box className="logo-wrap">
            <Box
              component="img"
              sx={{
                height: 40,
                width: 232,
                mb: 2,
              }}
              alt="Logo"
              src={mainLogo}
            />

            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
          </Box>
          <Box
            className="login-form"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            {isError && (
              <Alert severity="error">
                <ul style={{ padding: "0", margin: "0" }}>
                  {errorMsg.map((value: string, index: number) => {
                    return <li key={index}>{value}</li>;
                  })}
                </ul>
              </Alert>
            )}
            <InputLabel htmlFor="password">Password:</InputLabel>
            <FormControl
              variant="standard"
              sx={{ width: "100%", mb: 2, mt: 1 }}
            >
              <TextField
                {...passwordInput}
                fullWidth
                type={showPassword ? "text" : "password"}
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && (
                <Alert severity="error">{errors.password.message}</Alert>
              )}
            </FormControl>
            <InputLabel htmlFor="password">Confirm Password:</InputLabel>
            <FormControl
              variant="standard"
              sx={{ width: "100%", mb: 2, mt: 1 }}
            >
              <TextField
                {...confirmPasswordInput}
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.confirmPassword && (
                <Alert severity="error">{errors.confirmPassword.message}</Alert>
              )}
            </FormControl>

            <Button
              className="darkbeige"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change Password
            </Button>
            <Box className="forget-link">
              <Link to="/auth/login">Back to login</Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ChangePassword;
