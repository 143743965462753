import { Link, useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { api, resolveUrl, AdminApi } from "../../../config/apis";
import styles from "./manage-discount.module.scss";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DiscountFormModal from "./Modal";
import React from "react";
import { DateView } from "../../../components/DateView/DateView";

export default function ViewDiscount() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState<string>("");
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [formSuccess, setFormSuccess] = useState(false);

  const tableColumns = [
    {
      selector: (row: { code: string }) => (
        <Typography sx={{ textTransform: "capitalize" }}>{row.code}</Typography>
      ),
      name: "Code",
      sortable: true,
    },
    {
      selector: (row: { name: string }) => (
        <Typography sx={{ textTransform: "capitalize" }}>{row.name}</Typography>
      ),
      name: "Discount Name",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row: { type: string }) => (
        <Typography sx={{ textTransform: "capitalize" }}>{row.type}</Typography>
      ),
      name: "Type",
      sortable: false,
    },
    {
      selector: (row: { count: string }) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {row.count}
        </Typography>
      ),
      name: "Count",
      sortable: false,
    },
    {
      selector: (row: { used: string }) => (
        <Typography sx={{ textTransform: "capitalize" }}>{row.used}</Typography>
      ),
      name: "Used",
      sortable: false,
    },
    {
      selector: (row: { start_date: string }) => (
        <DateView date={row.start_date} />
      ),
      name: "Start Date",
      sortable: false,
    },
    {
      selector: (row: { end_date: string }) => <DateView date={row.end_date} />,
      name: "End Date",
      sortable: false,
    },
    {
      selector: (row: { discount_type: string }) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {row.discount_type}
        </Typography>
      ),
      name: "Offer",
      sortable: false,
    },
    {
      selector: (row: { updated_at: string }) => (
        <DateView date={row.updated_at} />
      ),
      name: "Updated On",
      sortable: false,
    },
  ];

  const onEdit = async (id: string) => {
    setViewId(id);
    if (id) {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
  }, [formSuccess]);

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.DISCOUNT_PERMOTION, id));
  };

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={AdminApi.DISCOUNT_PAGINATE}
        onEdit={onEdit}
        onDelete={onDelete}
        actions={{
          view: false,
        }}
        refreshHandler={formSuccess}
        module="manage-promotion"
      />
      <Box className={styles.course_content}>
        <Box className="btn-wrapper">
          <Link to={""} onClick={toggleModal}>
            Add new
          </Link>
        </Box>
      </Box>
      <DiscountFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
