import { Box, Typography } from "@mui/material";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Link } from "react-router-dom";
import { truncateTextWithEllipsis } from "../../../helper/helperFunction";
import EditFAQS from "./edit";
import { useEffect, useState } from "react";
import Datatable from "../../../components/dataTables/Datatable";
import FAQSCat from "../manage-faqs-catagory/index";
import Tooltip from "@mui/material/Tooltip";

export const FAQS = ({ heading }: any) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [editId, setEditId] = useState<string>();

  const tableColumns = [
    {
      cell: (row: { question: string }) => row.question ?? "N/A",
      name: "Question",
      sortable: false,
      width: "300px",
      wrap: true,
    },

    {
      cell: (row: { answer: any }) => (
        <>
          <Tooltip
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: row.answer && row.answer,
                }}
              ></div>
            }
            arrow
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  row.answer && truncateTextWithEllipsis(row.answer ?? "", 100),
              }}
            ></div>
          </Tooltip>
        </>
      ),
      name: "Answer",
      sortable: false,
      width: "500",
    },
    {
      cell: (row: { categoryTitle: string }) => row.categoryTitle,
      name: "Category",
      sortable: false,
      width: "150px",
    },
  ];

  const onEdit = async (id?: string) => {
    setEditId(id);
    setOpen(true);
  };

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.FAQS_DEL, id));
  };
  useEffect(() => {
    setReRender(formSuccess);
    setOpen(false);
  }, [formSuccess]);

  useEffect(() => {
    if (!open) {
      setEditId("");
    }
  }, [open]);

  return (
    <>
      <FAQSCat />
      <Box padding="20px 30px 20px 30px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: "40px",
              fontWeight: "700",
              paddingTop: "20px",
              paddingBottom: "10px",
              textAlign: "left",
            }}
          >
            {heading ?? ""}
          </Typography>
          {/* <Link className="btn" to="#" onClick={() => { setOpen(true) }}>Add Catagory</Link> */}
        </Box>
        <Box className="page-container">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.FAQS_PAGINATION}
            onEdit={onEdit}
            onDelete={onDelete}
            actions={{
              view: false,
            }}
            module="manage-CMS"
            // rowsPerPage={5}
            refreshHandler={reRender}
          />
        </Box>
        <Box sx={{ width: "150px", marginLeft: "auto" }}>
          <Link
            className="btn"
            to="#"
            onClick={() => {
              setOpen(true);
            }}
          >
            Add
          </Link>
        </Box>
      </Box>
      <EditFAQS
        handleClose={handleClose}
        open={open}
        setFormSuccess={setFormSuccess}
        width="800px"
        editId={editId}
      />
    </>
  );
};
