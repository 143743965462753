import Layout from "../layout/admin/Layout";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import EditRole from "../pages/admin/manage-roles/edit";
import ViewRoles from "../pages/admin/manage-roles/view";
import ViewCourses from "../pages/admin/manage-courses/view";
import EditCourses from "../pages/admin/manage-courses/editCourse/edit";
// import ParentProfile from "../pages/admin/parent/edit.tsx___";
import EditStudents from "../pages/admin/parent-dashbard/manage-kids/add-kids/edit";
import ViewQuiz from "../pages/admin/manage-quiz/view";
import EditQuiz from "../pages/admin/manage-quiz/edit";
import Setting from "../pages/admin/settings/setting";
import { ProtectRoutes } from "../hooks/protectedRoutes/protectedRoutes";
import { HasPermission } from "../hooks/protectedRoutes/HasPermission";
import ViewForum from "../pages/admin/manage-forums/view";
import AddForum from "../pages/admin/manage-forums/add";
import ViewCategory from "../pages/admin/manage-forums/categories/view";
import VeiwDetail from "../pages/admin/manage-courses/veiwdetail";
import ViewThreads from "../pages/admin/manage-forums/manage-threads/viewthreads";
import ThreadViewDetail from "../pages/admin/manage-forums/manage-threads/threadViewDetail";
import ViewCourseCatalog from "../pages/admin/course-catalog/view";
import CourseDetail from "../pages/admin/course-catalog/course-detail";
import CourseCart from "../pages/admin/manage-purchase-courses/CourseCart";
import CourseEnrolled from "../pages/admin/course-catalog/course-enrolled";
import StudentAssignments from "../pages/admin/student-dashboard/courses/student-assignments/Student-assignments";
import StudentQuizes from "../pages/admin/student-dashboard/courses/student-quizes/Studnet-quizes";
import StudentCourses from "../pages/admin/student-dashboard/courses/student-courses/studentCourses";
import ViewTransactions from "../pages/admin/transactions/view";
import DetailTransactions from "../pages/admin/transactions/details";
import ViewEnrolledStudents from "../pages/admin/enrolled-students/view";
import ViewAdminSupport from "../pages/admin/admin-support/view";
import DetailsAdminSupport from "../pages/admin/admin-support/details";
import AssignCourseDetail from "../pages/admin/student-dashboard/courses/student-coursedetail/course-detail";
import EditAdminSupport from "../pages/admin/admin-support/edit";
import Messaging from "../pages/admin/messaging/list";
import ViewQuestionBank from "../pages/admin/manage-question-bank/view";
import EditQuestionBank from "../pages/admin/manage-question-bank/edit";
import ViewRefundRequests from "../pages/admin/manage-refund-requests/view";
import ViewTeacherCourses from "../pages/admin/teacher-courses/view";
import ViewTeacherStudents from "../pages/admin/teacher-students/view";
import KidsListView from "../pages/admin/parent-dashbard/manage-kids/manage-kids-list/KidsListView";
import { KisdsDetails } from "../pages/admin/parent-dashbard/manage-kids/kids-details/view/view";
import StudentQuizesDetail from "../pages/admin/student-dashboard/courses/student-quizes-detail/student-quizes-detail";
import StudentQuizesDetailResult from "../pages/admin/student-dashboard/courses/student-quizes-detail/student-quizes-result";
import StudentQuizesDetailTable from "../pages/admin/student-dashboard/courses/student-quizes-detail/student-quizes-final-table";
import StudentAssignmentsDetail from "../pages/admin/student-dashboard/courses/student-assignments/student-assignment-detail/student-assignment-detail";
import TopStudentPanel from "../pages/admin/teacher-dashbaord/studnets/top-student/top-student";
import ViewStudentPanel from "../pages/admin/teacher-dashbaord/studnets/top-student/student-view";
import CoursePanel from "../pages/admin/teacher-dashbaord/studnets/courses/course-panel";
import CourseViewPanel from "../pages/admin/teacher-dashbaord/studnets/courses/course-view";
import QuizePanel from "../pages/admin/teacher-dashbaord/studnets/quize/quize-panel";
import QuizeTable from "../pages/admin/teacher-dashbaord/studnets/quize/quize-table";
import QuizeTableView from "../pages/admin/teacher-dashbaord/studnets/quize/quize-inner-table";
import SettingPanel from "../pages/admin/teacher-dashbaord/studnets/setting/setting-panel";
import ViewParentsUser from "../pages/admin/manage-users/parents/view";
import EditParents from "../pages/admin/manage-users/parents/edit";
import ViewParentUsers from "../pages/admin/manage-users/parents/view";
import EditParent from "../pages/admin/manage-users/parents/edit";
import ViewTeacherUsers from "../pages/admin/manage-users/teachers/view";
import EditTeacher from "../pages/admin/manage-users/teachers/edit";
import ViewStudentUsers from "../pages/admin/manage-users/students/view";
import EditStudent from "../pages/admin/manage-users/students/edit";
import DetailsUser from "../pages/admin/manage-users/students/details";
import DetailsParent from "../pages/admin/manage-users/parents/details";
import ViewComplaints from "../pages/admin/manage-complaints/view";
import DetailsComplaints from "../pages/admin/manage-complaints/details";
import EditComplaints from "../pages/admin/manage-complaints/edit";
import DetailsTeacher from "../pages/admin/manage-users/teachers/details";
import EditStudentProfile from "../pages/admin/manage-users-profile/students/edit";
import EditParentProfile from "../pages/admin/manage-users-profile/parents/edit";
import EditTeacherProfile from "../pages/admin/manage-users-profile/teachers/edit";
import { GetUserRole } from "../helper/helperFunction";
import TeacherAssignments from "../pages/admin/teacher-dashbaord/dashboard/assignments";
import StudentQuizesAnswer from "../pages/admin/student-dashboard/courses/student-quizes-detail/student-quiz-answers";
import AssignmentEditor from "../pages/admin/teacher-dashbaord/assignments/AssignmentEditor";
import HomePageCms from "../pages/admin/manage-pages/HomePage";
import EditHomeSchooling from "../pages/admin/manage-home-schooling/edit";
import EditOurPrograms from "../pages/admin/manage-our-programs/edit";
import EditWhyStudy from "../pages/admin/manage-why-study/edit";
import FooterPageCms from "../pages/admin/manage-pages/FrontendFooter";
import PopupsCms from "../pages/admin/manage-pages/Popups";
import TermsAndConditionCms from "../pages/admin/manage-pages/temsAndCondition";
import ViewNoticeBoard from "../pages/admin/manage-notice-board/view";
import DetailsNoticeBoard from "../pages/admin/manage-notice-board/details";
import EditNoticeBoard from "../pages/admin/manage-notice-board/edit";
import AboutPageCms from "../pages/admin/manage-pages/AboutPage";
import FaqsPageCms from "../pages/admin/manage-pages/FaqsPage";
import ViewPurchasedCouses from "../pages/admin/manage-purchased-course/view";
import TheSuperCurriculumCms from "../pages/admin/manage-pages/TheSuperCurriculumPage";
import ParentFormPopup from "../pages/admin/parent-form-popup";
import IGCSE from "../pages/admin/manage-pages/IGCSE";
import SecondaryKS3 from "../pages/admin/manage-pages/Secondary-KS3-page";
import ViewSubjectAcces from "../pages/admin/manage-assign-and-revoke/view";
import QuizesDetail from "../pages/admin/manage-courses/content/quizes-detail/quiz-answers";
import StudentAssignmentsPDFEdit from "../pages/admin/student-dashboard/courses/student-assignments/student-assignment-detail/student-assignment-pdf-edit";
import GlobalCMSPopup from "../pages/admin/parent-form-popup";
import AssignmentsDetail from "../pages/admin/manage-courses/Assignment/Assignment-view";
import ViewaAchivedForum from "../pages/admin/manage-forums/archivedforum";
import AllCoursesReports from "../pages/admin/manage-student-report-download/studentAllCoursesReport";
import ViewInstallment from "../pages/admin/manage-purchased-course/view-installment";
import EmailsCms from "../pages/admin/manage-pages/emails";
import ConatactPageCms from "../pages/admin/manage-pages/ContactPage";

const commonRoutes = [
  {
    path: "",
    element: <Dashboard />,
  },
  {
    path: "thank-you",
    element: <Dashboard />,
  },
  {
    path: "add-student",
    element: <EditStudents />,
  },
  {
    path: "course-catalog",
    element: <ViewCourseCatalog />,
  },
  {
    path: "course-cart",
    element: <CourseCart />,
  },
  {
    path: "course-cart/view/:id",
    element: <CourseCart />,
  },

  {
    path: "course-catalog/view/:id",
    element: <CourseDetail />,
  },
  {
    path: "course-enrolled",
    element: <CourseEnrolled />,
  },

  // cms
  {
    element: <HasPermission permission="view.manage-CMS" />,
    children: [
      {
        path: "cms/home",
        element: <HomePageCms />,
      },
      {
        path: "cms/about",
        element: <AboutPageCms />,
      },
      {
        path: "cms/faqs",
        element: <FaqsPageCms />,
      },
      {
        path: "cms/footer",
        element: <FooterPageCms />,
      },
      {
        path: "cms/the-super-curriculum",
        element: <TheSuperCurriculumCms />,
      },
      {
        path: "cms/contact-us",
        element: <ConatactPageCms />,
      },
      {
        path: "cms/privacy-policy",
        element: (
          <GlobalCMSPopup
            type="privacy-policy"
            heading="Privacy Policy"
            successPathUrl="/admin/cms/privacy-policy"
          />
        ),
      },
      // {
      //   path: "cms/terms-conditions",
      //   element: (
      //     <GlobalCMSPopup
      //       type="terms-conditions"
      //       heading="Terms & Conditions"
      //       successPathUrl="/admin/cms/terms-conditions"
      //     />
      //   ),
      // },
      {
        path: "cms/IGCSE",
        element: <IGCSE />,
      },
      {
        path: "cms/Secondary-KS3",
        element: <SecondaryKS3 />,
      },
      {
        path: "cms/terms-conditions",
        element: <PopupsCms />,
      },
      {
        path: "cms/emails",
        element: <EmailsCms />,
      },
      // {
      //   path: "cms/termsandconditions",
      //   element: <TermsAndConditionCms />,
      // },
    ],
  },

  {
    element: <HasPermission permission="view.manage-courses" />,
    children: [
      {
        path: "courses",
        element: <ViewCourses />,
      },
      {
        path: "courses/view/:id?",
        element: <VeiwDetail />,
      },
      {
        path: "courses/quiz/view/:id?",
        element: <QuizesDetail />,
      },
      {
        path: "courses/assignment/view/:id",
        element: <AssignmentsDetail />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-courses" />,
    children: [
      {
        path: "courses/edit/:id?",
        element: <EditCourses />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-roles" />,
    children: [
      {
        path: "roles",
        element: <ViewRoles />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-roles" />,
    children: [
      {
        path: "roles/edit/:id?",
        element: <EditRole />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-quizes" />,
    children: [
      {
        path: "quiz",
        element: <ViewQuiz />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-quizes" />,
    children: [
      {
        path: "quiz/edit/:id?",
        element: <EditQuiz />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-forums" />,
    children: [
      {
        path: "forum",
        element: <ViewForum />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-forums" />,
    children: [
      {
        path: "achived-forum",
        element: <ViewaAchivedForum />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-forums" />,
    children: [
      {
        path: "forum/edit/:id?",
        element: <AddForum />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-forums" />,
    children: [
      {
        path: "forum/:forumSlug",
        element: <ViewThreads />,
      },
      {
        path: "forum/:forumSlug/:slug",
        element: <ThreadViewDetail />,
      },
    ],
  },

  {
    element: <HasPermission permission="view.manage-forums" />,
    children: [
      {
        path: "forum/categories/view",
        element: <ViewCategory />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.add-student" />,
    children: [
      {
        path: "add-student",
        element: <EditStudents />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.add-student" />,
    children: [
      {
        path: "add-student/edit/:id?",
        element: <EditStudents />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-settings" />,
    children: [
      {
        path: "setting",
        element: <Setting />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-transactions" />,
    children: [
      {
        path: "transactions",
        element: <ViewTransactions />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-transactions" />,
    children: [
      {
        path: "upcoming-installments",
        element: <ViewInstallment />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-transactions" />,
    children: [
      {
        path: "transactions/:id",
        element: <DetailTransactions />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-my-students-subjects" />,
    children: [
      {
        path: "student/subjects",
        element: <ViewEnrolledStudents />,
      },
    ],
  },

  {
    element: <HasPermission permission="view.manage-admin-support" />,
    children: [
      {
        path: "support",
        element: <ViewAdminSupport />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-admin-support" />,
    children: [
      {
        path: "support/:id",
        element: <DetailsAdminSupport />,
      },
    ],
  },
  {
    element: <HasPermission permission="create.manage-admin-support" />,
    children: [
      {
        path: "support/edit/:id?",
        element: <EditAdminSupport />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-complaints" />,
    children: [
      {
        path: "complaints",
        element: <ViewComplaints />,
      },
      {
        path: "complaint/:id",
        element: <DetailsComplaints />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-complaints" />,
    children: [
      {
        path: "complaint/edit/:id?",
        element: <EditComplaints />,
      },
    ],
  },

  {
    element: <HasPermission permission="view.manage-messages" />,
    children: [
      {
        path: "messages",
        element: <Messaging />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-question-bank" />,
    children: [
      {
        path: "question-bank",
        element: <ViewQuestionBank />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-question-bank" />,
    children: [
      {
        path: "question-bank/edit/:id?",
        element: <EditQuestionBank />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-refund-request" />,
    children: [
      {
        path: "refund-request",
        element: <ViewRefundRequests />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-notice-board" />,
    children: [
      {
        path: "notice-board",
        element: <ViewNoticeBoard />,
      },
      {
        path: "notice-board/edit/:id?",
        element: <EditNoticeBoard />,
      },
    ],
  },
];

const parent = [
  {
    element: <HasPermission permission="view.manage-kids" />,
    children: [
      {
        path: "student",
        element: <KidsListView />,
      },
      {
        path: "student/view/:id",
        element: <KisdsDetails />,
      },
      {
        path: "student-reports-all-courses/:id",
        element: <AllCoursesReports />,
      },
      {
        path: "student/view/:courseSlug/:id",
        element: <KisdsDetails />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-admin-support" />,
    children: [
      {
        path: "student/edit/:id?",
        element: <EditStudents />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-users" />,
    children: [
      {
        path: "user/parents",
        element: <ViewParentUsers />,
      },
      {
        path: "user/parents/view/:id",
        element: <DetailsParent />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-users" />,
    children: [
      {
        path: "user/parents/edit/:id",
        element: <EditParentProfile />,
      },
      {
        path: "user/parents/edit",
        element: <EditParent />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-users" />,
    children: [
      {
        path: "user/teachers",
        element: <ViewTeacherUsers />,
      },
      {
        path: "user/teachers/view/:id",
        element: <DetailsTeacher />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-users" />,
    children: [
      {
        path: "user/teachers/edit/:id",
        element: <EditTeacherProfile />,
      },
      {
        path: "user/teachers/edit",
        element: <EditTeacher />,
      },
    ],
  },
  {
    element: (
      <HasPermission
        permission={["view.manage-users", "view.manage-assign-students"]}
      />
    ),
    children: [
      {
        path: "user/student",
        element: <ViewStudentUsers />,
      },
      {
        path: "user/student/view/:id",
        element: <KisdsDetails />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-users" />,
    children: [
      {
        path: "user/student/edit/:id",
        element: <EditStudentProfile />,
      },
      {
        path: "user/student/edit",
        element: <EditStudent />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-teacher-profile" />,
    children: [
      {
        path: "profile/teacher",
        element: <EditTeacherProfile />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-parent-profile" />,
    children: [
      {
        path: "profile/parent",
        element: <EditParentProfile />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-student-profile" />,
    children: [
      {
        path: "profile/student",
        element: <EditStudentProfile />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-student-profile" />,
    children: [
      {
        path: "student/progress-report/:id?",
        element: <KisdsDetails />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-parent-courses" />,
    children: [
      {
        path: "purchased-courses",
        element: <ViewPurchasedCouses />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-assign-and-revoke" />,
    children: [
      {
        path: "subject-access",
        element: <ViewSubjectAcces />,
      },
    ],
  },
];

const student = [
  {
    element: <HasPermission permission="view.manage-student-courses" />,
    children: [
      {
        path: "student-courses",
        element: <StudentCourses />,
      },
      {
        path: "student-courses/view/:slug?",
        element: <AssignCourseDetail />,
      },

      {
        path: "student-quizes",
        element: <StudentQuizes />,
      },
      {
        path: "student-quizes/view/:id",
        element: <StudentQuizesDetail />,
      },
      {
        path: "student-quizes-answer/view/:id",
        element: <StudentQuizesAnswer />,
      },
      {
        path: "student-quizes/quiz-result/:id",
        element: <StudentQuizesDetailResult />,
      },
      {
        path: "student-quizes/student-quizes-detail-table/:id?",
        element: <StudentQuizesDetailTable />,
      },

      {
        path: "student-quizes",
        element: <StudentQuizes />,
      },
      {
        path: "student-assignments",
        element: <StudentAssignments />,
      },
      {
        path: "student-assignments/view/:id",
        element: <StudentAssignmentsDetail />,
      },
    ],
  },
];
const teacher = [
  {
    element: <HasPermission permission="view.manage-teacher-courses" />,
    children: [
      {
        path: "teacher-courses",
        element: <ViewTeacherCourses />,
      },
      {
        path: "teacher-courses/:slug",
        element: <VeiwDetail />,
      },
    ],
  },
  // Teacher Dashboard
  {
    path: "student-assignments/edit-pdf/:id",
    element: <StudentAssignmentsPDFEdit />,
  },
  // Top Student
  {
    path: "top-student",
    element: <TopStudentPanel />,
  },
  {
    path: "assignments/view/:id",
    element: <StudentAssignmentsDetail />,
  },
  {
    path: "assignments/view/:id/editor",
    element: <AssignmentEditor />,
  },
  {
    path: "students/assignments",
    element: <TeacherAssignments />,
  },
  {
    path: "view-student",
    element: <ViewStudentPanel />,
  },

  // Courses panel
  {
    path: "courses-panel",
    element: <CoursePanel />,
  },
  {
    path: "courses-view",
    element: <CourseViewPanel />,
  },

  // Quiz panel
  {
    path: "quize-panel",
    element: (
      <QuizePanel
        onFiltersChange={function (filters: {
          teacherId: any;
          status: string | null;
          searchQuery: string | null;
        }): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
  },
  {
    path: "quize-table",
    element: <QuizeTable />,
  },
  {
    path: "quize-table-view",
    element: <QuizeTableView />,
  },
];

const allRoutes = [
  {
    path: "/admin",
    element: <Layout />,
    pathName: "Dashboard",
    children: [...commonRoutes, ...student, ...parent, ...teacher],
  },
];

export const AdminRoutes = {
  element: <ProtectRoutes />,
  children: allRoutes,
};
