import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import AboutBannerCms from "../manage-about-banner"
import AboutServices from "../manage-about-services"
import DynamicMeta from "../manage-meta-tag/dynamic-meta";

const AboutPageCms = (() => {
    const tabs = [
        {
            label: 'About Banner',
            content: <AboutBannerCms heading={"About Banner"}/>,
        },
        {
            label: 'Our Excellence',
            content: <AboutServices heading={"Our Excellence"}/>,
        },
        {
            label: "About Meta Tag",
            content: <DynamicMeta type="about-page" heding="About Meta"/>,
        },
    ];

    return (
        <>
            <h1>About</h1>
            <Box className="setting-tabs-container">
                <DynamicTabs tabs={tabs} />
            </Box>
        </>
    )
})
export default AboutPageCms