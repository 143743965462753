import { Link, useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { DateView } from "../../../components/DateView/DateView";
import { GetUserRole, HasPermission } from "../../../helper/helperFunction";
import { useAuth } from "../../../hooks/auth/auth";
import {
  Box,
  Button,
  CardMedia,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import playvideo from "../../../static/images/play-img.png";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import { useState } from "react";
import ActionButtons from "../../../components/dataTables/ActionButtons";
import CustomModal from "../../../components/popups/Modal";
import FilterBase from "./filter/filter-base";
import ProgressCourseCell from "../../../components/BucketProgressBarSubject";

type FilterState = {
  text: string | null;
};

export default function ViewCourses() {
  const navigate = useNavigate();

  const baseUrl = SERVER_KEY;

  const tableColumnsSuperAdmin = [
    {
      cell: (row: { name: string; featured_image?: string }) => (
        <>
          <ProgressCourseCell name={row.name} image={row.featured_image} />
        </>
      ),
      name: "Subject Name",
      sortable: true,
      minWidth: "250px",
    },

    {
      selector: (row: { created_at: string }) => (
        <DateView date={row.created_at} />
      ),
      name: "Created At",
      sortable: false,
    },
    {
      selector: (row: { assignedTeacher: string }) => row.assignedTeacher,
      name: "Assigned Teacher",
      sortable: false,
    },
    {
      selector: (row: { lessons: string }) => row.lessons,
      name: "Lesson",
      sortable: false,
    },
    {
      selector: (row: { completedCourses: number }) => row.completedCourses,
      name: "Subjects Completed",
      sortable: false,
    },
    // {
    //   selector: (row: { hours: number, minutes: number }) => ((row.hours * 60) + row.minutes) + " min",
    //   name: "Total Time",
    //   sortable: false,
    // },
    {
      selector: (row: { activeStudents: number }) => row.activeStudents,
      name: "Active Students",
      sortable: false,
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row: { id: number; delete: string }) => (
        <>
          {row.delete === "disable" ? (
            <Button className="delete" onClick={() => setShowModal(true)}>
              Delete
            </Button>
          ) : (
            ""
          )}
          <ActionButtons
            id={row.id}
            onView={onView}
            onEdit={onEdit}
            actions={{
              edit: true,
              delete: row.delete === "disable" ? false : true,
            }}
            onDelete={onDelete}
            module={"manage-courses"}
          />
        </>
      ),
    },
  ];

  const tableColumnsTeacher = [
    {
      cell: (row: { CourseName: string; featureImage?: string }) => (
        <>
          <ProgressCourseCell name={row.CourseName} image={row.featureImage} />
        </>
      ),
      name: "Subject Name",
      sortable: true,
      minWidth: "250px",
    },

    {
      selector: (row: { lectures: string }) => row.lectures,
      name: "Lesson",
      sortable: false,
    },
    {
      selector: (row: { courseCompleted: number }) => row.courseCompleted,
      name: "Subjects Completed",
      sortable: false,
    },
    // {
    //     selector: (row: { hours: number, minutes: number }) => ((row.hours * 60) + row.minutes) + " min",
    //     name: "Total Time",
    //     sortable: false,
    // },
    {
      selector: (row: { enrolledStudents: number }) => row.enrolledStudents,
      name: "Enrolled Students",
      sortable: false,
    },
    {
      selector: (row: { activeStudents: number }) => row.activeStudents,
      name: "Active Students",
      sortable: false,
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row: { id: number; delete: string }) => (
        <>
          {row.delete === "disable" ? (
            <Button className="delete" onClick={() => setShowModal(true)}>
              Delete
            </Button>
          ) : (
            ""
          )}
          <ActionButtons
            id={row.id}
            onView={onView}
            onEdit={onEdit}
            actions={{
              edit: true,
              delete: row.delete === "disable" ? false : true,
            }}
            onDelete={onDelete}
            module={"manage-courses"}
          />
        </>
      ),
    },
  ];

  const onEdit = async (id: string) => {
    navigate(`/admin/courses/edit/${id}`);
  };
  const onView = async (id: string) => {
    navigate(`/admin/courses/view/${id}`);
  };

  // const onDelete = async (id: string) => {
  //   return await api.delete(resolveUrl(AdminApi.COURSES_DELETE, id));
  // };
  const onDelete = async (
    id: string
  ): Promise<{ success: boolean; data?: any; error?: any }> => {
    try {
      const response = await api.delete(
        resolveUrl(AdminApi.COURSES_DELETE, id)
      );
      setReload((prevState: any) => !prevState);
      return { success: true, data: response.data };
    } catch (error: any) {
      console.error(error);
      return { success: false, error: error.message };
    }
  };

  const auth = useAuth();
  const userID = auth.state?.user.id;

  const [filters, setFilters] = useState<FilterState>();

  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);
  };

  const [reLoad, setReload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModule = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Subjects</h1>
        <HasPermission permission="create.manage-courses">
          <Box className="btn-wrapper">
            <Link to={"edit"}>Add a subject</Link>
          </Box>
        </HasPermission>
      </div>

      <Grid container spacing={2} className="yi_my_filter">
        <Grid item xs={9}>
          <FilterBase
            onFiltersChange={handleFiltersChange}
            placeholder="Subject Name"
          />
        </Grid>
      </Grid>

      <div className="page-container">
        <FormHelperText error>{}</FormHelperText>

        <Datatable
          tableColumns={
            GetUserRole() === "Super Admin"
              ? tableColumnsSuperAdmin
              : tableColumnsTeacher
          }
          dataUrl={
            GetUserRole() === "Super Admin"
              ? AdminApi.COURSES_PAGINATE
              : resolveUrl(AdminApi.USER_TEACHER_COURSES, `${userID}`)
          }
          onEdit={onEdit}
          onView={onView}
          onDelete={onDelete}
          module="manage-courses"
          dataFilter={filters}
          actionColumns={false}
          refreshHandler={reLoad}
        />
      </div>
      <CustomModal state={showModal} setState={setShowModal} width={800}>
        <button className="closeBtn" onClick={closeModule}>
          +
        </button>
        The deletion of the course is not possible as it has already been
        purchased by someone.
      </CustomModal>
    </>
  );
}
