import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[^\s]*$/, "Spaces are not allowed in the first name"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[^\s]*$/, "Spaces are not allowed in the last name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  subject_detail: Yup.string().required("subject detail is required"),
  gender: Yup.string().required("Gender is required"),
  date_of_birth: Yup.string().required("Date of Birth is required"),
  // identity_picture: Yup.string().required("Upload Passport is required"),
  year_group: Yup.string().required("Year Group is required"),
  // profile_picture: Yup.string().required("Profile Picture is required"),
});

export default validationSchema;
