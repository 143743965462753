import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import playvideo from "../../../../../static/images/play-img.png";
import styles from "./assin-cousre.module.scss";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { SERVER_KEY } from "../../../../../config/constants/EnviormentVariables";
import { truncateTextWithEllipsis } from "../../../../../helper/helperFunction";

interface SingleCourseCardProps {
  title: string;
  price: number;
  progress: any;
  handlePurchaseNow: () => void;
  futureImage?: string;
}

const SingleCourseCard: React.FC<SingleCourseCardProps> = ({
  title,
  price,
  handlePurchaseNow,
  progress,
  futureImage,
}) => {
  const baseUrl = SERVER_KEY;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#071836" : "#308fe8",
    },
  }));

  const [value, setValue] = React.useState<number | null>(2);

  return (
    <>
      <Card>
        <Box className={`${styles.subject_thumbnail} subject__image`}>
          <CardMedia
            component="img"
            image={`${futureImage ? futureImage : playvideo}`}
            alt="green iguana"
          />

          <Box className={styles.subject_intro}>
            <Grid item xs={6}>
              {/* <Typography variant="h5" component="h2">
                {title}
              </Typography> */}
            </Grid>
            {/* <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </Grid> */}
          </Box>
        </Box>
        <CardContent>
          <Grid className={styles.videoCourses} xs={12} container>
            <Tooltip title={title} arrow>
              <Typography
                variant="h5"
                component="h2"
                className={styles.subject_intro}
              >
                {title && truncateTextWithEllipsis(title ?? "-", 40)}
              </Typography>
            </Tooltip>
            <Grid item xs={7}>
              <Typography
                variant="h5"
                component="h2"
                display={"flex"}
                justifyContent={"space-between"}
              >
                <span>Progress</span>
                <span>{progress}%</span>
              </Typography>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Grid>
            <Grid item xs={5} sx={{ textAlign: "right" }}>
              <Button
                className="dark-btn"
                variant="contained"
                onClick={handlePurchaseNow}
              >
                View
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default SingleCourseCard;
