import {
  Avatar,
  Box,
  Fade,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  Button as Btn,
  Checkbox,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";
import {
  FetchPopupData,
  GetUserRole,
  isAdminUser,
  toTitleCase,
} from "../../../helper/helperFunction";
import { Button, Modal } from "react-bootstrap";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import styles from "./transations.module.scss";
import { DateView } from "../../../components/DateView/DateView";
import Form from "../../../components/forms/Form";
import { Textarea } from "@mui/joy";
import { CSVLink } from "react-csv";
import CustomModal from "../../../components/popups/Modal";

export default function ViewTransactions() {
  const navigate = useNavigate();

  type FiltersData = {
    username: string | null;
    email: string | null;
    start_date: dayjs.Dayjs | null;
    end_date: dayjs.Dayjs | null;
    type?: string | null;
  };

  const initialFilterData: FiltersData = {
    email: "",
    start_date: null,
    end_date: null,
    username: "",
    type: "",
  };
  const [filterData, setFilterData] = useState<FiltersData>(initialFilterData);

  const [filters, setFilters] = useState<FiltersData>(initialFilterData);
  const [showRefundMoal, setShowRefundMoal] = useState(false);
  const [refundID, setRefundID] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState(false);

  const [formData, setFormData] = useState<{ refundReason: string }>({
    refundReason: "",
  });
  const [reportData, setReportData] = useState<any[]>([]);

  const isAdmin = isAdminUser();

  const { REACT_APP_SERVER_BASE } = process.env;
  const [poupData, setPopupData] = useState<string>("");
  const type = "payment terms and conditions";
  useEffect(() => {
    const fetchData = async () => {
      await FetchPopupData(type, setPopupData);
    };
    fetchData();
  }, []);
  const tableColumns = [
    {
      cell: (row: { name: string; userProfile: string }) => (
        <>
          <Avatar
            sx={{ width: 40, height: 40, mr: 2 }}
            alt={row.name.toUpperCase()}
            src={`${REACT_APP_SERVER_BASE}${row.userProfile}`}
          />
          {toTitleCase(row.name)}
        </>
      ),
      name: "Name",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row: { email: string }) => row.email,
      name: "Email",
      sortable: false,
      minWidth: "300px",
    },
    {
      selector: (row: { amount: string }) => {
        return `${CURRENCY_SYMBOL}${parseFloat(row.amount).toFixed(2)}`;
      },
      name: "Amount",
      sortable: false,
      minWidth: "150px",
    },
    {
      selector: (row: { created_at: string }) => {
        return <DateView date={row.created_at} />;
      },
      name: "Date",
      sortable: true,
      minWidth: "150px",
    },
    {
      selector: (row: { status: string }) => {
        return `${toTitleCase(row.status)}`;
      },
      name: "Status",
      sortable: false,
      minWidth: "100px",
    },
    {
      selector: (row: { paymentType: string }) => {
        return `${toTitleCase(row.paymentType)}`;
      },
      name: "Payment Type",
      sortable: false,
      minWidth: "205px",
    },
    {
      selector: (row: { id: string; refundRequest: any; paymentType: any }) => {
        if (!isAdmin) {
          if (!row.refundRequest) {
            if (row.paymentType === "full payment") {
              return (
                <Button
                  style={{ padding: "9px 15px" }}
                  onClick={(e) => requestRefund(row.id)}
                >
                  Refund Request
                </Button>
              );
            } else {
              return "";
            }
          }
          return (
            <>
              {row.refundRequest.type !== "active" ? (
                <Tooltip title={row.refundRequest.refund_response}>
                  <span>{toTitleCase(row.refundRequest.type)}</span>
                </Tooltip>
              ) : (
                <>{toTitleCase(row.refundRequest.type)}</>
              )}
            </>
          );
        }
      },
      name: !isAdmin ? "Refund" : "",
      sortable: false,
      width: isAdmin ? "0" : "200px",
    },
  ];

  const requestRefund = (id: string) => {
    setRefundID(id);
    setShowRefundMoal(true);
  };

  const closeRefundMoal = () => {
    setRefundID("");
    setShowRefundMoal(false);
    setErrors({});
    setFormData({
      refundReason: "",
    });
    setAcceptTerms(false);
  };

  const onView = async (id: string) => {
    navigate(`/admin/transactions/${id}`);
  };

  const reportDataCallback = useCallback(async () => {
    const parameters = {
      filters: {
        ...filters,
      },
    };
    const res = await api.get(
      isAdmin
        ? AdminApi.TRANSACTION_REPORT
        : AdminApi.TRANSACTION_REPORT_PEARENT,
      { params: parameters }
    );
    setReportData(res.data);
  }, [filters]);

  useEffect(() => {
    reportDataCallback();
  }, [reportDataCallback]);

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };
  const handleFormSuccess = async (response: any) => {
    if (response) {
      setShowRefundMoal(false);
      setFormSuccess((prevState: any) => !prevState);
      setFormData({
        refundReason: "",
      });
    }
  };
  const [acceptTerms, setAcceptTerms] = useState(false); // State for the checkbox

  const [showModal, setShowModal] = useState(false);
  const closeModule = () => {
    setShowModal(false);
  };

  const handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;

    setAcceptTerms(isChecked);

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      acceptTerms: isChecked ? undefined : prevErrors.acceptTerms,
    }));
  };

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    const errors: any = {};

    // if (!acceptTerms) {
    //   errors.acceptTerms = "You must accept the terms and conditions.";
    // }

    if (!formData.refundReason) {
      errors.refundReason = "Refund reason is required.";
    }

    setErrors(errors);

    const hasErrors = Object.keys(errors).length > 0;

    return !hasErrors;
  };

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Transactions</h1>
        <Button className="btn">
          <CSVLink
            style={{
              display: "inline-block",
              width: "100%",
              height: "100%",
              fontSize: "14px",
              padding: "7px 5px",
              color: "#fff",
              textDecoration: "none",
            }}
            data={reportData}
            filename="Transactions"
          >
            Download Report
          </CSVLink>
        </Button>
      </div>

      <Box
        className={`${styles.innerContainer} ${
          isAdmin && styles.innerContainerAdmin
        }`}
      >
        <Typography variant="body1" className={styles.transationsBodyText}>
          Search By
        </Typography>
        <Box className={styles.transationsFieldContainer}>
          {isAdmin && (
            <TextField
              className={styles.transationsFieldEmail}
              id="email"
              variant="outlined"
              fullWidth
              placeholder="Email"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setFilters(filterData);
                }
              }}
              onChange={(event) =>
                setFilterData((prevState: any) => {
                  return {
                    ...prevState,
                    email: event.target.value,
                  };
                })
              }
            />
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date from"
              className={styles.transationsFieldDateTo}
              maxDate={dayjs()}
              format="YYYY-MM-DD"
              onChange={(value) =>
                setFilterData((prevState: any) => {
                  return {
                    ...prevState,
                    start_date: value,
                  };
                })
              }
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date to"
              className={styles.transationsFieldDateFrom}
              maxDate={dayjs()}
              format="YYYY-MM-DD"
              onChange={(value) =>
                setFilterData((prevState: any) => {
                  return {
                    ...prevState,
                    end_date: value,
                  };
                })
              }
            />
          </LocalizationProvider>
          <Select
            displayEmpty
            className={styles.transationsFieldtype}
            sx={{ backgroundColor: "#fff", marginLeft: "15px" }}
            value={filterData.type || ""}
            onChange={(event) =>
              setFilterData((prevState: any) => {
                return {
                  ...prevState,
                  type: event.target.value,
                };
              })
            }
          >
            <MenuItem value="">Select Type</MenuItem>
            <MenuItem value="payment on installment">Instalment</MenuItem>
            <MenuItem value="full payment">Fully Paid</MenuItem>
          </Select>
          {isAdmin && (
            <>
              <Typography
                variant="body1"
                className={styles.transationsBodyTextTwo}
              >
                Or
              </Typography>
              <TextField
                id="username"
                className={styles.transationsFieldName}
                variant="outlined"
                fullWidth
                placeholder="Name"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setFilters(filterData);
                  }
                }}
                onChange={(event) =>
                  setFilterData((prevState: any) => {
                    return {
                      ...prevState,
                      username: event.target.value,
                    };
                  })
                }
              />
            </>
          )}

          <Box className={styles.transationsBtn}>
            <Button className="btn" onClick={() => setFilters(filterData)}>
              Go
            </Button>
          </Box>
          {isAdmin ? (
            <>
              <Box className={`${styles.transationsBtn} ${styles.Refund_btn}`}>
                <Button
                  className="btn"
                  onClick={() => navigate(`/admin/refund-request`)}
                >
                  Refund Requests
                </Button>
              </Box>
            </>
          ) : (
            ""
          )}
        </Box>
      </Box>

      <div className="page-container table_refund_wapper">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={
            isAdmin
              ? AdminApi.TRANSACTION_PAGINATE
              : AdminApi.PARENT_TRANSACTIONS
          }
          onView={onView}
          filters={filters}
          refreshHandler={formSuccess}
          actions={{
            edit: false,
            delete: false,
          }}
          module="manage-transactions"
        />
      </div>

      <CustomModal state={showRefundMoal} setState={setShowRefundMoal}>
        <button className="closeBtn" onClick={closeRefundMoal}>
          +
        </button>

        <h1>Refund Request</h1>
        <Form
          submitUrl={resolveUrl(AdminApi.REFUND_REQUEST, refundID)}
          formData={formData}
          editId={refundID}
          successPath="/admin/transactions"
          onSuccess={handleFormSuccess}
          validate={customValidation}
        >
          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <InputLabel sx={{ textAlign: "left" }}>Reason</InputLabel>
            </Grid>
            <Grid item xs={12}>
              <Textarea
                value={formData.refundReason}
                name="refundReason"
                variant="outlined"
                minRows={4}
                maxRows={4}
                onChange={handleOnChange}
              />
              {errors.refundReason && (
                <FormHelperText error>{errors.refundReason}</FormHelperText>
              )}
            </Grid>
          </Grid>

          {/* <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item container alignItems={"center"}>
              <Checkbox
                checked={acceptTerms}
                name="acceptTerms"
                onChange={handleCheckboxChange}
              />
              <InputLabel
                sx={{
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#0F203F",
                  fontFamily: "Poppins",
                }}
              >
                I agree to the{" "}
                <span
                  onClick={() => setShowModal(true)}
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Terms and Conditions
                </span>
              </InputLabel>
            </Grid>
            {errors.acceptTerms && (
              <FormHelperText error>{errors.acceptTerms}</FormHelperText>
            )}
          </Grid> */}
        </Form>
      </CustomModal>

      <CustomModal state={showModal} setState={setShowModal} width={800}>
        <button className="closeBtn" onClick={closeModule}>
          +
        </button>
        <div dangerouslySetInnerHTML={{ __html: poupData }}></div>
      </CustomModal>
    </>
  );
}
