import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi } from "../../../config/apis";
import { DateView } from "../../../components/DateView/DateView";
import { Avatar, Box, CardMedia, Typography } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import playvideo from "../../../static/images/play-img.png";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";
import AvatarImage from "../../../components/Avatar";
import ProgressCourseCell from "../../../components/ProgressBarSubject";

export default function TopCourses() {
  const navigate = useNavigate();
  const baseUrl = SERVER_KEY;

  const tableColumns = [
    {
      cell: (row: { courseName: string; featureImage?: string }) => (
        <>
          <ProgressCourseCell name={row.courseName} image={row.featureImage} />
        </>
      ),
      name: "Subject Name",
      sortable: true,
      minWidth: "300px",
    },
    {
      cell: (row: { teacherProfile: any; assignedTo: string }) => (
        <>
          <AvatarImage imageName={row.teacherProfile} name={row.assignedTo} />
          {toTitleCase(`${row.assignedTo}`)}
        </>
      ),
      name: "Assigned To",
      sortable: true,
      minWidth: "150px",
    },
    {
      selector: (row: { enrolledStudents: string }) => row.enrolledStudents,
      name: "Students Enrolled",
      sortable: false,
      minWidth: "150px",
    },
    {
      selector: (row: { lessons: string }) => row.lessons,
      name: "Lessons",
      sortable: false,
      minWidth: "150px",
    },
    // {
    //   selector: (row: { duration: string }) => row.duration,
    //   name: "Duration",
    //   sortable: false,
    // },
    {
      selector: (row: { fees: string }) => `${CURRENCY_SYMBOL}${row.fees}`,
      name: "Fee",
      sortable: false,
      minWidth: "150px",
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/courses/view/${id}`);
  };

  return (
    <>
      <Box className="page-container" sx={{ paddingTop: "0" }} mb={0}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.TOP_COURSES}
          onView={onView}
          actions={{
            view: true,
            delete: false,
            edit: false,
          }}
          pagination={false}
          module="manage-courses"
        />
      </Box>
    </>
  );
}
