import { Grid, CardMedia } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import * as React from "react";
import Box from "@mui/material/Box";
import { HasPermission } from "../../../helper/helperFunction";
import GlobalSwitchCell from "../../../components/globalStatus";
import { AdminApi } from "../../../config/apis";
import AvatarImage from "../../../components/Avatar";

type CourseInfoProps = {
  video?: string;
  title?: string;
  content: string;
  instructor?: string;
  status?: string;
  profile_image?: string;
};

export default function CourseInfo({
  video,
  title,
  content,
  instructor,
  status,
  profile_image,
}: CourseInfoProps): JSX.Element {
  const [value, setValue] = React.useState<number | null>(2);

  const params = useParams<{ id: string }>();

  return (
    <Grid className="page-container course-info" container sx={{ mb: 5 }}>
      <Grid item xl={10} lg={12} container>
        <Grid item xl={1.5} lg={2} md={3} xs={3} sx={{ paddingRight: 2 }}>
          <CardMedia
            component="img"
            alt={"video"}
            height="112"
            width="156"
            image={video}
          />
        </Grid>
        <Grid item xl={10.5} lg={10} md={9} xs={9}>
          <Grid container alignItems={"center"}>
            <Grid item>
              <h2>{title}</h2>
            </Grid>
          </Grid>
          <Grid item py={2}>
            <div dangerouslySetInnerHTML={{ __html: content && content }}></div>
          </Grid>
          <Grid container alignItems={"center"}>
            <Grid item>
              <AvatarImage
                width={"40px"}
                height={"40px"}
                marginRight={"0"}
                imageName={profile_image ?? ""}
                name={instructor ?? ""}
              />
            </Grid>
            <Grid className="instructorName" item>
              <p>{instructor}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={2} lg={12} xs={12}>
        <HasPermission permission="edit.manage-courses">
          <Grid
            className="btn-wrapper"
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Grid item pr={2}>
              <GlobalSwitchCell
                EndPoint={AdminApi.SUBJECT_STATUS}
                id={params.id}
                method="GET"
                is_active={status === "active" ? true : false}
              />
            </Grid>
            <Grid item>
              <Link
                to={`/admin/courses/edit/${params.id}`}
                style={{ marginRight: "0" }}
              >
                Edit Subject
              </Link>
            </Grid>
          </Grid>
        </HasPermission>
      </Grid>
    </Grid>
  );
}
