import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  marks: Yup.number()
    .required("Marks is required")
    .positive("Marks must be a positive number")
    .integer("Marks must be an integer"),
});

export default validationSchema;
