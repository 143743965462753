import { useEffect, useState } from "react";
import { UserStudentDetails } from "../../../../helper/helperFunction";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import styles from "./manage-user-student.module.css";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";
import moment from "moment";

type User = {
  avgScore: number;
  city: string | null;
  country: string | null;
  email: string;
  enrolledCourses: number;
  gender: string | null;
  name: string;
  phoneNumber: string;
  profile: number;
  registrationDate: string;
  role: string;
  state: string | null;
  streetAddress: string;
  fatherName: string;
  dateOfBirth: any;
  idCard: any;
  subjectDetails: any;
  yearGroup: any;
  courseCompleted: any;
};

export default function StudentDetails({ paramId }: any) {
  const [studentInfo, setStudentInfo] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      const res = await UserStudentDetails(paramId ?? "");
      setStudentInfo(res);
      setIsLoading(true);
    };
    fetchStudentDetails();
  }, [paramId]);

  const dummyImage =
    "https://cdn.vectorstock.com/i/preview-1x/49/49/student-graduated-avatar-isolated-vector-11224949.webp";

  const headingstyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "16.8px",
    marginBottom: "10px",
  };
  const paraStyle = {
    color: "#071836",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16.8px",
    marginBottom: "20px",
  };

  return (
    <>
      {isLoading ? (
        studentInfo ? (
          <Box className={styles.studentProfileList} pb={2}>
            <Grid container item xs={12}>
              <Grid
                item
                xs={12}
                px={4}
                py={3}
                sx={{ backgroundColor: "#F3EACE" }}
              >
                <Typography
                  variant="h3"
                  sx={{ fontSize: "26px", fontWeight: 700 }}
                >
                  Personal Data
                </Typography>
              </Grid>

              <Grid item xs={2.5} pt={3}>
                <Card className={styles.studentCardBx} sx={{ width: "100%" }}>
                  <CardContent
                    className={styles.studentCardContent}
                    sx={{ width: "100%" }}
                  >
                    <Avatar
                      src={
                        studentInfo && studentInfo.profile
                          ? `${SERVER_KEY}${studentInfo.profile}`
                          : dummyImage
                      }
                      className={styles.stuProfile}
                      sx={{ width: "108px", height: "115px" }}
                    />
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={styles.stuName}
                    >
                      <span className="bold">
                        {studentInfo?.name ?? ""}
                        <span>
                          {studentInfo.gender === "Female" ? "d/o" : "s/o"}{" "}
                          {studentInfo?.fatherName ?? ""}
                        </span>
                      </span>
                    </Typography>
                    <Typography
                      variant="body1"
                      className={styles.stuLastActive}
                    >
                      Registration Date
                    </Typography>
                    <Typography
                      variant="body1"
                      className={styles.stuLastActive}
                    >
                      {/* <b>Progress</b> {studentData.progress}% */}
                      {moment(studentInfo.registrationDate ?? "").format(
                        "MM-DD-YYYY"
                      ) ?? "-"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={9.5} container pt={3} pl={4}>
                <Grid xs={4} sx={{ paddingRight: "20px" }}>
                  <Typography variant="h5" sx={headingstyle}>
                    Gender
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {studentInfo.gender ?? "-"}
                  </Typography>
                  <Typography variant="h5" sx={headingstyle}>
                    Phone Number
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {studentInfo.phoneNumber ?? "-"}
                  </Typography>
                  <Typography variant="h5" sx={headingstyle}>
                    Subject Details
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {(studentInfo.subjectDetails &&
                      studentInfo.subjectDetails) ??
                      "-"}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography variant="h5" sx={headingstyle}>
                    Address
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {studentInfo.streetAddress ?? "-"}
                  </Typography>
                  <Typography variant="h5" sx={headingstyle}>
                    Subjects Completed
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {studentInfo.courseCompleted === 0
                      ? "-"
                      : studentInfo.courseCompleted}
                  </Typography>
                  <Typography variant="h5" sx={headingstyle}>
                    Date of Birth
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {moment(studentInfo.dateOfBirth ?? "").format(
                      "MM-DD-YYYY"
                    ) ?? "-"}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography variant="h5" sx={headingstyle}>
                    Date of Birth
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {moment(studentInfo.dateOfBirth ?? "").format(
                      "MM-DD-YYYY"
                    ) ?? "-"}
                  </Typography>
                  <Typography variant="h5" sx={headingstyle}>
                    Year Group
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {studentInfo.yearGroup ?? "-"}
                  </Typography>
                  <Typography variant="h5" sx={headingstyle}>
                    City & State
                  </Typography>
                  <Typography variant="body2" sx={paraStyle}>
                    {studentInfo.city ?? "-"} {studentInfo.state ?? ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Typography sx={{ padding: "30px" }}>
            There are no records to display.
          </Typography>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      )}
    </>
  );
}
