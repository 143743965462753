import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../hooks/auth/auth";
import { HasPermission } from "../../helper/helperFunction";

export default function ActionButtons(params: any) {
  const [openConfirm, setOpenConfirm] = useState(false);

  const { state } = useAuth();

  const actions = {
    edit:
      params.actions && params.actions.hasOwnProperty("edit")
        ? params.actions.edit
        : true,
    view:
      params.actions && params.actions.hasOwnProperty("view")
        ? params.actions.view
        : true,
    delete:
      params.actions && params.actions.hasOwnProperty("delete")
        ? params.actions.delete
        : true,
  };

  const onEdit = () => {
    params.onEdit(params.id);
  };
  const onView = () => {
    params.onView(params.id);
  };

  const onDelete = () => {
    params
      .onDelete(params.id)
      .then((res: any) => {
        setOpenConfirm(false);
        params.refreshHandle();
        params.state.status = "success";
        params.state.message = res.data.message;
      })
      .catch((err: any) => {
        setOpenConfirm(false);
        params.refreshHandle();
        params.state.status = "error";
        params.state.message = err;
      });
  };

  return (
    <>
      {/* {state &&
        state.userRolePermissions.includes(`delete.${params.module}`) &&
        actions.delete && (
          <Button className="delete" onClick={() => setOpenConfirm(true)}>
            Delete
          </Button>
        )}
      {state &&
        state.userRolePermissions.includes(`view.${params.module}`) &&
        actions.view && (
          <Button className="view" onClick={() => onView()}>
            view
          </Button>
        )}
      {state &&
        state.userRolePermissions.includes(`edit.${params.module}`) &&
        actions.edit && (
          <Button className="edit" onClick={() => onEdit()}>
            Edit
          </Button>
        )} */}

      <HasPermission permission={`delete.${params.module}`}>
        {actions.delete && (
          <Button className="delete" onClick={() => setOpenConfirm(true)}>
            Delete
          </Button>
        )}
      </HasPermission>
      <HasPermission permission={`view.${params.module}`}>
        {actions.view && (
          <Button className="view" onClick={() => onView()}>
            view
          </Button>
        )}
      </HasPermission>
      <HasPermission permission={`edit.${params.module}`}>
        {actions.edit && (
          <Button className="edit" onClick={() => onEdit()}>
            Edit
          </Button>
        )}
      </HasPermission>

      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ textAlign: "center", padding: "20px" }}>
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete the record?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button className="btn" onClick={() => setOpenConfirm(false)}>
              Disagree
            </Button>
            <Button className="btn" onClick={onDelete} autoFocus>
              {" "}
              Agree{" "}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
