import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  GetAuthState,
  GetUserName,
  toTitleCase,
} from "../../../helper/helperFunction";
import Form from "../../../components/forms/Form";
import { Avatar, Box, Grid, InputLabel, Typography } from "@mui/material";
import { Textarea } from "@mui/joy";
import Moment from "react-moment";
import styles from "./adminSupport.module.scss";
export default function DetailsAdminSupport() {
  const params = useParams();

  const CURRENT_USER = GetAuthState("USER");

  const [data, setData] = useState<any>({});
  const [msgData, setMsgData] = useState<any>([]);
  const [formData, setFormData] = useState({
    message: "",
    user_id: CURRENT_USER.id,
  });

  const handleFormSuccess = (res: any) => {
    setFormData((prevState) => {
      return {
        message: "",
        user_id: CURRENT_USER.id,
      };
    });
    const tmpMessages = msgData;
    tmpMessages.push(res.adminSupportMessage);
    setMsgData(tmpMessages);
  };

  const viewData = useCallback(async () => {
    if (params.id) {
      const res = await api.get(resolveUrl(AdminApi.SUPPORT_VIEW, params.id));
      setData(res.data.adminSupport);
      setMsgData(res.data.adminSupportMessages);
    }
  }, [params.id]);

  useEffect(() => {
    viewData();
  }, [viewData]);

  return (
    <Box className={styles.aTDMain}>
      <Grid container className={styles.aTTDContainer}>
        <Grid item xs={10} className={styles.aTTDContentType}>
          <Grid container className={styles.aTTDContentTypeContainer}>
            <Grid item xs={12} className={styles.aTTDContentTypeTitle}>
              <Typography
                variant="h4"
                className={styles.aTTDContentTypeTitleContent}
              >
                {data.title}
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.aTTDContentTypeName}>
              {data.type && (
                <>
                  <Typography
                    variant="body1"
                    className={styles.aTTDContentTypeContent}
                  >
                    Type
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.aTTDContentTypeContentText}
                  >
                    {toTitleCase(data.type)}
                  </Typography>
                  <Avatar
                    sx={{ width: 27, height: 27, mr: 0 }}
                    className={styles.aTTDContentTypeContentAvator}
                    alt={GetUserName()}
                    src="/static/images/avatar/2.jpg"
                  />
                </>
              )}
              {data.user && (
                <Typography
                  variant="body1"
                  className={styles.aTTDContentTypeContentName}
                >
                  {toTitleCase(
                    `${data.user.first_name} ${data.user.last_name}`
                  )}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className={styles.aTTDContentStatus}>
          <Grid container className={styles.aTTDContentStatusContainer}>
            <Grid item xs={12} className={styles.aTTDContentStatusText}>
              {data.status && (
                <>
                  <Typography
                    variant="body1"
                    className={styles.aTTDContentStatusTitle}
                  >
                    Status
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.aTTDContentStatusContent}
                  >
                    {toTitleCase(data.status)}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={12} className={styles.aTTDContentPriorityText}>
              {data.priority && (
                <>
                  <Typography
                    variant="body1"
                    className={styles.aTTDContentPriorityTitle}
                  >
                    Priority
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.aTTDContentPriorityContent}
                  >
                    {toTitleCase(data.priority)}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={styles.aTTDDescription}>
        <Grid item xs={12} className={styles.aTTDDescriptionContent}>
          {data.description}
        </Grid>
      </Grid>
      <Grid container className={styles.aTTDMsgContainer}>
        <Typography variant="h2" className={styles.aTTDMsgTitle}>
          Messages
        </Typography>
      </Grid>
      {data && (
        <div>
          {msgData &&
            msgData.map((message: any, index: number) => {
              return (
                <Grid container key={index} className={styles.aTTDMsgBox}>
                  <Box className={styles.aTTDMsgImgContainer}>
                    <Avatar
                      sx={{ width: 87, height: 87, mr: 0 }}
                      alt={message.user.first_name.toUpperCase()}
                      src="/static/images/avatar/2.jpg"
                    />
                  </Box>
                  <Box className={styles.aTTDMsgTextContainer}>
                    <Typography
                      variant="h4"
                      className={styles.aTTDMsgTextTitle}
                    >
                      {toTitleCase(
                        `${message.user.first_name} ${message.user.last_name}`
                      )}
                    </Typography>
                    <Typography variant="body1" className={styles.aTTDMsgDate}>
                      <Moment format="MMM DD, YYYY">
                        {message.updated_at}
                      </Moment>
                    </Typography>
                    <Typography
                      variant="body1"
                      className={styles.aTTDMsgContent}
                    >
                      {message.message}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

          <Form
            submitUrl={AdminApi.SUPPORT_POST_MESSAGE}
            editId={params.id}
            formData={formData}
            successPath={`/admin/support/${params.id}`}
            saveText="Submit"
            onSuccess={handleFormSuccess}
          >
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3 }}
              className={styles.aTTDForm}
            >
              <Grid item xs={2} className={styles.aTTDFormTitle}>
                <InputLabel
                  sx={{
                    pt: 2,
                    fontWeight: "700",
                    fontSize: "21px",
                    color: "#071836",
                    marginBottom: "10px",
                  }}
                  className={styles.aTTDFormTitleContent}
                >
                  Post A Message
                </InputLabel>
              </Grid>
              <Grid item xs={12} className={styles.aTTDFormTextArea}>
                <Textarea
                  placeholder=""
                  name="message"
                  value={formData.message}
                  minRows={4}
                  maxRows={4}
                  onChange={(event) =>
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        message: event.target.value,
                      };
                    })
                  }
                />
              </Grid>
            </Grid>
          </Form>
        </div>
      )}
    </Box>
  );
}
