import ViewRoles from "../manage-roles/view";
import DynamicTabs from "../../../components/tabs/Tabs";
// import DiscountForm from "../manage-discount/edit";
import ViewDiscount from "../manage-discount/view";
import SettingGeneral from "../manage-general/view";
import SettingAdmins from "./setting-admins";
import SettingChangePassword from "./setting-change-password";
import ViewAdmin from "../manage-admins/view";
import GTMCode from "../manage-gtm/dynamic-gtm";
import {
  HasPermission,
  HasPermissionTab,
} from "../../../helper/helperFunction";

function Settings() {
  const tabs = [
    {
      label: "General",
      content: (
        <HasPermission permission="manage-general-setting.view">
          <SettingGeneral />
        </HasPermission>
      ),
    },
    {
      label: "Admins",
      content: (
        <HasPermission permission="manage-admins.view">
          <ViewAdmin />
        </HasPermission>
      ),
    },
    {
      label: "Change Password",
      content: <SettingChangePassword />,
    },
    {
      label: "Discount",
      content: (
        <HasPermission permission="manage-discount.view">
          <ViewDiscount />
        </HasPermission>
      ),
    },
    {
      label: "Role management",
      content: (
        <HasPermission permission="manage-role-permissions.view">
          <ViewRoles />
        </HasPermission>
      ),
    },

    {
      label: "GTM Code",
      content: (
        <HasPermission permission="manage-gtm-code.view">
          <GTMCode heding="Google Analytics Codes" type="gtm-code" />
        </HasPermission>
      ),
    },
  ];
  const allowedTabs = tabs.filter((tab) => {
    const hasPermission = HasPermissionTab(tab.content.props.permission);
    return (
      tab.content !== null &&
      (tab.content.props.permission ? hasPermission : true)
    );
  });
  return (
    <div className="setting-tabs-container">
      <DynamicTabs tabs={allowedTabs} />
    </div>
  );
}

export default Settings;
