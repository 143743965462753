import React from "react";
interface CourseDescriptionProps {
  description: string;
}

const CourseDescription: React.FC<CourseDescriptionProps> = ({
  description,
}) => {
  return (
    <div>
      <h1>Subject Description</h1>
      {/* <p>{description}</p> */}

      <div
        dangerouslySetInnerHTML={{ __html: description && description }}
      ></div>

      {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dicta eius enim inventoreus optio ratione veritatis. Beatae deserunt illum ipsam magniima mollitia officiis quia tempora!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dicta eius enim Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dicta eius enim inventoreus optio ratione veritatis. Beatae deserunt illum ipsam magniima mollitia officiis quia tempora!
            </p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dicta eius enim inventoreus optio ratione veritatis. Beatae deserunt illum ipsam magniima mollitia officiis quia tempora!
            </p>
            <h2>Features</h2>
            <ul>
                <li>consectetur adipisicing elit.  </li>
                <li>Cum dicta eius enim inventoreus optio ratione  </li>
                <li>    consectetur adipisicing elit.  </li>
                <li>veritatis. Beatae deserunt illum ipsam .  </li>
                <li>mollitia officiis quia tempora!Lorem ipsum  </li>
                <li>dolor sit amet, consectetur adipisicing elit.   </li>

            </ul>
            <h2>What you will learn</h2>
            <ul>
                <li><span>Week 1</span> consectetur adipisicing elit. </li>
                <li><span>Week 2</span> Cum dicta eius enim inventoreus optio ratione </li>
                <li><span>Week 3</span> veritatis. Beatae deserunt illum ipsam magniima.</li>
                <li><span>Week 4</span> mollitia officiis quia tempora!Lorem ipsum.</li>
                <li><span>Week 5</span> dolor sit amet, consectetur adipisicing elit.</li>

            </ul> */}
    </div>
  );
};

export default CourseDescription;
