import { FormEvent, useEffect, useState } from "react"
import { GetAuthState, toTitleCase } from "../../../helper/helperFunction"
import Form from "../../../components/forms/Form"
import { AdminApi, api, resolveUrl } from "../../../config/apis"
import { Box, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { Textarea } from "@mui/joy";
import { useParams } from "react-router-dom";
import styles from './style.module.scss'
import CKEditorComponent from "../../../components/CKEditorComponent";


export default function EditNoticeBoard() {

  const params = useParams();

  type FormData = {
    title: string,
    description: string,
    type: string,
    user_id: string,
    noticeFor: string,
  }

  const CURRENT_USER = GetAuthState('USER')

  const [formData, setFormData] = useState<FormData>({
    title: '',
    description: '',
    type: 'alert',
    user_id: CURRENT_USER.id,
    noticeFor: "general"
  });

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const selectedValue = event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: selectedValue,
    }));
  };

  
  useEffect(() => {

    const fechData = async () => {
      const res = await api.get(resolveUrl(AdminApi.NOTICE_BOARD_EDIT, params.id ?? ""));
      setFormData({
        ...formData,
        title: res.data.data.title,
        description: res.data.data.notice,
        noticeFor: res.data.data.noticeFor
      })
    }
    fechData()
  }, [params.id])

  const handleEditorChange = (event: any, editor: any) => {
    const editor_data = editor.getData();
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = editor_data;
    const links = tempDiv.querySelectorAll('a');
    links.forEach(link => {
        if (link.host !== window.location.host) {
            link.setAttribute('target', '_blank');
        }
    });
    
    const data = tempDiv.innerHTML;
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: data,
    }));
  };

  return (
    <Box className={styles.aSCreateTicketMain}>
      <Typography variant="h2" className={styles.aSCTTitle}>{params.id ? "Edit Notice" : "Create New Notice" }</Typography>
      <Box className={styles.aSCTContainer}>
        <Form
          submitUrl={AdminApi.NOTICEBOARD_SAVE}
          formData={formData}
          editId={params.id}
          successPath="/admin/notice-board"
        >
          <Box className={styles.aSCTContentTitle}>
            <InputLabel sx={{ textAlign: "left" }} className={styles.aSCTContentTitleLabel} >Title</InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Title"
              value={formData.title}
              name="title"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
            />
          </Box>
          <Box className={styles.aSCTContentDesc}>
            <InputLabel sx={{ textAlign: "left" }} className={styles.aSCTContentDescLabel}>Description</InputLabel>
            <CKEditorComponent
              initialValue={formData.description} 
              onChange={handleEditorChange}
            />
          </Box>
          <Box className={styles.aSCTContentType}>
            <InputLabel sx={{ textAlign: "left" }} className={styles.aSCTContentTypeLabel} >Notice For</InputLabel>
            <Select
              fullWidth
              name="noticeFor"
              sx={{ Width: "100%" }}
              onChange={handleSelectChange}
              defaultValue="slected"
              value={formData.noticeFor}
              className={styles.aSCTContentTypeInput}
            >
              {["general", "student", "parent", "teacher"].map((items: any, i) => {
                return (
                  <MenuItem key={i} value={items}>
                    {toTitleCase(items)}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}
