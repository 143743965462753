import { FC } from "react";
import { ContentTypeProp } from "./ContentProps";
import { Grid } from "@mui/material";
import { Button } from "react-bootstrap";
import { getObjectKeyValue } from "../../../../../helper/helperFunction";
import QuizIcon from "../../../../../static/images/quiz-icon.png";
import EditIcon from "../../../../../static/images/edit.jpg";
import DeleteIcon from "../../../../../static/images/trash.jpg";

const Quiz: FC<ContentTypeProp> = function ({
  contentIndex,
  content,
  sectionIndex,
  editContent,
  removeContent,
  quiz,
  editDisabled,
}) {
  return (
    <Grid
      className="single-lesson"
      container
      alignItems="center"
      justifyContent="space-between"
      key={contentIndex}
    >
      <Grid item>
        <h2>
          <span>Part {contentIndex + 1}: </span>
          <img src={QuizIcon} alt="icon" />
          {quiz && getObjectKeyValue(quiz.quiz, "name", "id", content.quiz)}
          {content.textData}
        </h2>
      </Grid>
      <Grid item>
        <Button
          className="course-btn"
          disabled={editDisabled}
          style={editDisabled ? { opacity: 0.5 } : {}}
          onClick={(e) =>
            editContent(
              e,
              sectionIndex,
              "quiz",
              contentIndex,
              true,
              "",
              "",
              content.id
            )
          }
        >
          <span className="tooltip">Edit Quiz</span>
          <img src={EditIcon} alt="icon" />
        </Button>
        <Button
          className="course-btn"
          variant="contained"
          disabled={editDisabled}
          style={editDisabled ? { opacity: 0.5 } : {}}
          onClick={(e) =>
            removeContent(e, "content", contentIndex, sectionIndex)
          }
        >
          <span className="tooltip">Remove Quiz</span>
          <img src={DeleteIcon} alt="icon" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Quiz;
