import styles from "../teacherDashboard.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import ScoreAvgComp from "./dashboard-components/Score-element";
import NoticeBoard from "../../manage-notice-board/noticeBoard";
import TopStudentTable from "../studnets/top-student/top-student-detail";
import { Link } from "react-router-dom";
import Enrolledstudents from "../../manage-courses/Enrolledstudents/Enrolledstudents";
import ViewAssignment from "../../manage-courses/Assignment/Assignment";
import React, { useCallback, useEffect, useState } from "react";
import ViewQuizes from "../../manage-courses/Quizes/Quizes";
import { CourseData } from "../../../../Type/courseTypes";
import DynamicTabs from "../../../../components/tabs/Tabs";
import { AdminApi, api } from "../../../../config/apis";
import Degrecap from "../../../../../src/static/images/degree-cap.png";
import { CURRENCY_SYMBOL } from "../../../../config/constants/constants";
import Card from "../../../../layout/admin/partials/Card";
import TeacherAssignments from "./assignments";
import TeacherQuizzes from "./quizz";
import TeacherCourses from "./Courses";

const DEFAULT_COURSE_VIEW = {
  name: "",
  have_live_quiz: false,
  price: "",
  year: "",
  description: "",
  sections: [],
  teacherId: "",
};

const TeacherDashboardContent = () => {
  React.useState<CourseData>(DEFAULT_COURSE_VIEW);

  const tabs = [
    {
      label: "Subjects",
      content: <TeacherCourses />,
    },
    {
      label: "Assigments",
      content: <TeacherAssignments />,
    },
    {
      label: "Quiz",
      content: <TeacherQuizzes />,
    },
  ];

  type CardType = { heading: any; paragraph: string; imageUrl: string };
  const [cardsData, setCardsData] = useState<null | CardType[]>([]);

  const editData = useCallback(async () => {
    const res = await api.get(AdminApi.DASHBOARD_STATS);

    if (res.data.status) {
      const cardData: CardType[] = [];
      if (res.data.data.courses) {
        cardData.push({
          heading: res.data.data.courses,
          paragraph: "Subjects",
          imageUrl: Degrecap,
        });
      }

      if (res.data.data.studentCourses) {
        cardData.push({
          heading: res.data.data.studentCourses,
          paragraph: "Subjects Enrolled",
          imageUrl: Degrecap,
        });
      }

      if (res.data.data.students) {
        cardData.push({
          heading: res.data.data.students,
          paragraph: "Students",
          imageUrl: Degrecap,
        });
      }

      setCardsData(cardData);
    }
  }, []);

  useEffect(() => {
    editData();
  }, [editData]);

  return (
    <Box className={styles.studentDashboardMain}>
      <Grid container spacing={"30px"} className={styles.studentDashboardGrid}>
        <Grid container paddingLeft={3.5}>
          <Grid container spacing={3}>
            <Card cardItem={cardsData} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          className={styles.studentDashboardCourse}
        >
          <Grid container className={styles.studentDashboardCourse}>
            {/* <Grid item xs={6}>
                        <Typography  variant='body1'>Top Students</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body1'><Link to={'top-student'}>See All</Link></Typography>
                    </Grid> */}
            <Grid item xs={12}>
              <TopStudentTable />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          className={styles.studentDashboardNoticeBoard}
        >
          <Box>
            <NoticeBoard />
          </Box>
        </Grid>
        <Grid item xs={12} className={styles.studentDashboardBottomGrid}>
          <DynamicTabs tabs={tabs} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeacherDashboardContent;
