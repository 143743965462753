import Form from "../../../components/forms/Form";
import styles from "./manage-quize.module.scss";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  InputLabel,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormHelperText,
  Alert,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Textarea from "@mui/joy/Textarea";
import { Add } from "@mui/icons-material";
import { getQuizesBank } from "../../../helper/helperFunction";
import * as Yup from "yup";

interface QuizData {
  name: any;
  description: string;
  tags: string[];
  totalMarks: number | null;
  duration: any;
  is_live: boolean;
  content: QuizContent[];
  courseId?: number;
}

interface QuizContent {
  question: string;
  answers: string[];
  answer_key: number[];
  type: string;
  id?: any;
}
interface QuizBank {
  question: string;
  answers: string[];
  answer_key: number[];
  type: string;
  id: any;
}

interface CourseData {
  SelectCourse: SelectCourse[];
}
interface SelectCourse {
  id: number;
  name: string;
}

export default function EditQuiz() {
  const [CoursesData, setCoursesData] = useState<CourseData>();
  const [quizBank, setQuizBank] = useState<QuizBank[]>([]);

  const defaultQuizContent: QuizContent = {
    question: "",
    answers: [""],
    answer_key: [],
    type: "multiple-choices",
    id: null,
  };
  const [fieldErrors, setFieldErrors] = useState<any>([]);

  const [quizContent, setQuizContent] = useState<QuizContent[]>([
    defaultQuizContent,
  ]);

  const [editedQuizIndex, setEditedQuizIndex] = useState<number | null>(null);

  const [formData, setFormData] = useState<QuizData>({
    name: "",
    description: "",
    tags: [],
    totalMarks: null,
    duration: "",
    is_live: false,
    content: [],
  });

  const [open, setOpen] = React.useState(false);

  const [quizBankOpen, setquizBankOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleQuizBankClose = () => {
    setquizBankOpen(false);
    setQuizContent([defaultQuizContent]);
  };

  const handleClose = () => {
    setOpen(false);
    setQuizContent([defaultQuizContent]);
  };

  const validateQuizContent = (content: any) => {
    const errors = [];

    // Iterate through each question in the quiz content
    for (let i = 0; i < content.length; i++) {
      const question = content[i];
      const fieldError: any = {};

      // Check if the question field is empty or undefined
      if (!question.question || question.question.trim() === "") {
        fieldError.question = "This field is required";
      }

      // Check if answers array is empty or undefined
      if (
        !question.answers || // Check if answers array is undefined or empty
        question.answers.some(
          (answer: string) => !answer || answer.trim() === ""
        ) // Check if any answer is empty or undefined
      ) {
        fieldError.answers = "Option field should not be empty";
      }

      // Check if answer_key array is empty or undefined
      if (!question.answer_key || question.answer_key.length === 0) {
        fieldError.answer_key = "Select at least one answer";
      }

      errors.push(fieldError);
    }
    setFieldErrors(errors);
    return errors.every((error) => Object.keys(error).length === 0);
  };

  const params = useParams();

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };
  const handleChangeTags = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tags = event.target.value.split(",");
    setFormData({ ...formData, tags });
  };

  const handleOnSelectChange = (
    event: SelectChangeEvent<string | boolean>,
    child: React.ReactNode
  ) => {
    const target = event.target.value as string | boolean;

    setFormData({
      ...formData,
      [event.target.name]: target,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hours = Number(event.target.value);
    const minutes = ((formData as QuizData).duration ?? 0) % 60;
    const duration = hours * 60 + minutes;

    setFormData((prevData) => ({
      ...prevData,
      duration: duration,
    }));
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hours = formData.duration ? Math.floor(formData.duration / 60) : 0;
    const minutes = Number(event.target.value);
    const duration = hours * 60 + minutes;

    setFormData((prevData) => ({
      ...prevData,
      duration: duration,
    }));
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 996,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const editData = useCallback(async () => {
    if (params.id) {
      const res = await api.get(resolveUrl(AdminApi.QUIZ_VIEW, params.id));

      setFormData({
        courseId: res.data.course.id,
        name: res.data.quiz.name,
        description: res.data.quiz.description,
        tags: res.data.quiz.tags !== "" ? JSON.parse(res.data.quiz.tags) : "",
        duration: res.data.quiz.duration,
        totalMarks: res.data.quiz.totalMarks,
        content: res.data.quiz.contents.map((contentItem: any) => ({
          question: contentItem.question,
          answers: JSON.parse(contentItem.answers),
          answer_key: JSON.parse(contentItem.answer_key),
          type: contentItem.type,
        })),

        is_live: false,
      });
    }
  }, [params.id]);

  const selectCourse = useCallback(async () => {
    const course = await api.get(resolveUrl(AdminApi.GET_COURSES));
    setCoursesData({
      SelectCourse: course.data.Courses,
    });
  }, []);

  useEffect(() => {
    editData();
    selectCourse();
  }, [editData, selectCourse]);

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedQuizContent = [...quizContent];
    updatedQuizContent[0].question = event.target.value;
    setQuizContent(updatedQuizContent);
  };

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedQuizContent = [...quizContent];
    updatedQuizContent[0].answers[index] = event.target.value;
    setQuizContent(updatedQuizContent);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionIndex: number,
    optionIndex: number
  ) => {
    const updatedQuizContent = [...quizContent];

    const checked = event.target.checked;

    if (checked) {
      if (!updatedQuizContent[questionIndex].answer_key.includes(optionIndex)) {
        updatedQuizContent[questionIndex].answer_key.push(optionIndex);
      }
    } else {
      updatedQuizContent[questionIndex].answer_key = updatedQuizContent[
        questionIndex
      ].answer_key.filter((idx) => idx !== optionIndex);
    }

    setQuizContent(updatedQuizContent);
  };

  const handleAddOption = () => {
    const updatedQuizContent = [...quizContent];

    updatedQuizContent[0].answers.push("");
    setQuizContent(updatedQuizContent);
  };

  const handleRemoveOption = (index: number) => {
    const updatedQuizContent = [...quizContent];
    updatedQuizContent[0].answers.splice(index, 1);
    setQuizContent(updatedQuizContent);
  };

  const handleQuizAdd = () => {
    // Perform validation here
    const isValid = validateQuizContent(quizContent);

    if (!isValid) {
      // Handle invalid data, show error message, etc.
      console.error(
        "Quiz content is invalid. Please make sure all fields are filled out."
      );
      return;
    }

    if (editedQuizIndex !== null) {
      // Handle edit case
    } else {
      const UpdatedQuizContent = [...quizContent];
      const UpdatedQuizContentWithoutId = UpdatedQuizContent.map(
        ({ id, ...rest }) => rest
      );

      setFormData((prevFormData: QuizData) => ({
        ...prevFormData,
        content: [...prevFormData.content, ...UpdatedQuizContentWithoutId],
      }));
    }

    setEditedQuizIndex(null);
    setOpen(false);
    setquizBankOpen(false);
    setQuizContent([defaultQuizContent]);
  };

  const handleRemoveQuiz = (index: number) => {
    const updatedFormData = { ...formData };
    updatedFormData.content.splice(index, 1);
    setFormData(updatedFormData);
  };

  const handleEditQuiz = (index: number) => {
    setEditedQuizIndex(index);
    setOpen(true);
    const updatedFormData = { ...formData };
    const selectedQuiz = updatedFormData.content[index];
    setQuizContent([selectedQuiz]);
  };

  const addFromQuizBank = () => {
    setquizBankOpen(true);
    setQuizContent([]);
  };

  useEffect(() => {
    const getQuizes = async () => {
      try {
        const res = await getQuizesBank();
        setQuizBank(
          res.items.map((contentItem: any) => ({
            question: contentItem.question,
            answers: JSON.parse(contentItem.answers),
            answer_key: JSON.parse(contentItem.answer_key),
            type: contentItem.type,
            id: contentItem.id,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    };

    getQuizes();
  }, []);

  const handleAddQuizBank = (id: number) => {
    const copiedData = { ...quizBank.find((quiz) => quiz.id === id) };
    const { question, answers, answer_key, type, id: quizId } = copiedData;

    const updatedQuizContent: QuizContent = {
      question: question ?? "",
      answers: answers ?? [],
      answer_key: answer_key ?? [],
      type: type ?? "",
      id: quizId ?? -1,
    };

    setQuizContent((prevContent) => [...prevContent, updatedQuizContent]);
  };

  const handleRemoveQuizBank = (id: number) => {
    setQuizContent((prevContent) =>
      prevContent.filter((quiz) => quiz.id !== id)
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Quiz title is required"),
    totalMarks: Yup.number()
      .min(0, "Total Marks must be positive number")
      .required("Total Marks is required"),
    // duration: Yup.string().required("Duration is required"),
    courseId: Yup.string().required("Select Course is required"),
  });

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="h1" className="main-heading">
            Edit Quiz
          </Typography>
        </Box>

        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container">
            <Form
              submitUrl={AdminApi.QUIZ_SAVE}
              formData={formData}
              editId={params.id}
              successPath="/admin/quiz"
              validate={customValidation}
            >
              <Grid
                className={styles.qEFFieldContainer}
                item
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item className={styles.qEFFieldContainerLabel}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Select Course
                  </InputLabel>
                </Grid>
                <Grid item className={styles.qEFFieldContainerInput}>
                  <Select
                    fullWidth
                    name="courseId"
                    sx={{ Width: "100%" }}
                    onChange={handleOnSelectChange}
                    value={
                      formData.courseId
                        ? formData.courseId.toString()
                        : "selected"
                    }
                    disabled={
                      !!params.id &&
                      CoursesData?.SelectCourse?.some(
                        (item) => item.id === formData.courseId
                      )
                    }
                  >
                    <MenuItem value="selected"> Select Any</MenuItem>
                    {CoursesData?.SelectCourse &&
                      CoursesData?.SelectCourse.map((item: any) => {
                        return (
                          <MenuItem value={item.id} key={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {errors.courseId && (
                    <FormHelperText error>{errors.courseId}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                className={styles.qEFFieldContainer}
                item
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid className={styles.qEFFieldContainerLabel} item>
                  <InputLabel sx={{ textAlign: "left" }}>Quiz Title</InputLabel>
                </Grid>
                <Grid item className={styles.qEFFieldContainerInput}>
                  <TextField
                    fullWidth
                    value={formData.name}
                    name="name"
                    variant="outlined"
                    onChange={handleOnChange}
                    error={errors.name !== undefined}
                    helperText={errors.name}
                  />
                </Grid>
              </Grid>

              <Grid
                className={styles.qEFFieldContainer}
                item
                xs={12}
                container
                sx={{ mb: 3 }}
              >
                <Grid item className={styles.qEFFieldContainerLabel}>
                  <InputLabel sx={{ pt: 2 }}>Description</InputLabel>
                </Grid>
                <Grid item className={styles.qEFFieldContainerTextArea}>
                  <Textarea
                    placeholder="Description"
                    name="description"
                    minRows={4}
                    maxRows={4}
                    defaultValue={formData.description}
                    onChange={handleOnChange}
                  />
                  {errors.description && (
                    <FormHelperText error>{errors.description}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                className={styles.qEFFieldContainer}
                item
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item className={styles.qEFFieldContainerLabel}>
                  <InputLabel sx={{ textAlign: "left" }}>Tags</InputLabel>
                </Grid>
                <Grid item className={styles.qEFFieldContainerInput2}>
                  <TextField
                    placeholder="Enter Tags"
                    variant="outlined"
                    value={formData.tags ? formData.tags.join(",") : []}
                    fullWidth
                    onChange={handleChangeTags}
                  />
                </Grid>
              </Grid>

              <Grid
                className={styles.qEFFieldContainer}
                item
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item className={styles.qEFFieldContainerLabel}>
                  <InputLabel>Duration</InputLabel>
                </Grid>
                <Grid container className={styles.qEFFieldContainerDateNTime}>
                  <Grid
                    item
                    className={styles.qEFFieldContainerDateNTimeContent}
                  >
                    <TextField
                      placeholder="0"
                      type="number"
                      onChange={handleHoursChange}
                      value={Math.floor(formData.duration / 60) || ""}
                      className={styles.qEFCDNTContentInput}
                    />
                    <Typography variant="body1">Hours</Typography>
                  </Grid>
                  <Grid
                    item
                    className={styles.qEFFieldContainerDateNTimeContent}
                  >
                    <TextField
                      placeholder="0"
                      type="number"
                      value={formData.duration % 60 || ""} // Display minutes
                      onChange={handleMinutesChange}
                      className={styles.qEFCDNTContentInput}
                    />
                    <Typography variant="body1">Mins</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {errors.duration && (
                      <FormHelperText error>{errors.duration}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={styles.qEFFieldContainer}
                item
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item className={styles.qEFFieldContainerLabel}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Total Marks
                  </InputLabel>
                </Grid>
                <Grid item className={styles.qEFFieldContainerLabelInput3}>
                  <TextField
                    variant="outlined"
                    type="number"
                    name="totalMarks"
                    value={formData.totalMarks}
                    fullWidth
                    onChange={handleOnChange}
                    error={errors.totalMarks !== undefined}
                    helperText={errors.totalMarks}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                sx={{ mb: 2 }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <h2>Quiz Content</h2>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ mr: 2 }}
                    className="light-btn"
                    variant="contained"
                    endIcon={<Add />}
                    onClick={addFromQuizBank}
                  >
                    Add From Quiz Bank
                  </Button>
                  <Button
                    className="light-btn"
                    variant="contained"
                    endIcon={<Add />}
                    onClick={handleOpen}
                  >
                    Add Content
                  </Button>
                </Grid>
              </Grid>

              <TableContainer
                component={Paper}
                className={styles.qETableContainer}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className={styles.qETableHead}>
                    <TableRow
                      // className="quiz-heading-row"
                      className={styles.qETableRow}
                    >
                      <TableCell align="left">Order #</TableCell>
                      <TableCell align="left">Question</TableCell>
                      {/* <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Marks</TableCell> */}
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.content.map((content, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{content.question}</TableCell>
                        {/* <TableCell align="right">{content.type}</TableCell> */}
                        {/* <TableCell align="right">10</TableCell> */}
                        <TableCell align="right">
                          {" "}
                          <Button
                            className={styles.qEFBtnRemove}
                            onClick={() => handleRemoveQuiz(index)}
                          ></Button>
                          <Button
                            className={styles.qEFBtnEdit}
                            onClick={() => handleEditQuiz(index)}
                          ></Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Modal
                className={styles.qEFAddContentModule}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={open}>
                  <Box sx={style} className={styles.qEFACMContainer}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        container
                        sx={{ mb: 3, alignItems: "center" }}
                      >
                        <Grid container className={styles.qEFACMFieldContainer}>
                          <Grid
                            item
                            xs={12}
                            className={styles.qEFACMFieldContent}
                          >
                            <Grid
                              item
                              xs={2}
                              className={styles.qEFACMFieldContentLabel}
                            >
                              <InputLabel sx={{ textAlign: "left" }}>
                                Question
                              </InputLabel>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              className={styles.qEFACMFieldContentInputQue}
                            >
                              <TextField
                                fullWidth
                                name="question"
                                variant="outlined"
                                placeholder="Enter Your Question"
                                value={quizContent[0]?.question}
                                onChange={handleQuestionChange}
                              />
                              {fieldErrors[0]?.question && (
                                <FormHelperText error>
                                  {fieldErrors[0]?.question}
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={styles.qEFACMFieldContent}
                          >
                            <Grid
                              item
                              xs={2}
                              className={styles.qEFACMFieldContentLabel}
                            >
                              <InputLabel sx={{ textAlign: "left" }}>
                                Answers
                              </InputLabel>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              className={styles.qEFACMFieldContentInputAns}
                            >
                              {fieldErrors[0]?.answers && (
                                <Alert
                                  severity="error"
                                  sx={{ width: "100%", padding: "0!important" }}
                                >
                                  {fieldErrors[0].answers}
                                </Alert>
                              )}
                              {fieldErrors[0]?.answer_key && (
                                <Alert
                                  severity="error"
                                  sx={{ width: "100%", padding: "0!important" }}
                                >
                                  {fieldErrors[0]?.answer_key}
                                </Alert>
                              )}

                              {quizContent.map((item, questionIndex) => (
                                <Grid container key={questionIndex}>
                                  {item.answers.map((option, optionIndex) => (
                                    <Grid
                                      container
                                      key={optionIndex}
                                      className={styles.qEFACMFCIAnsContent}
                                    >
                                      <Grid
                                        item
                                        className={styles.qEFACMFCIAnsInput}
                                      >
                                        <TextField
                                          fullWidth
                                          variant="outlined"
                                          value={option}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            handleOptionChange(
                                              event,
                                              optionIndex
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        className={styles.qEFACMFCIAnsCheckBox}
                                      >
                                        <Checkbox
                                          color="success"
                                          checked={item.answer_key.includes(
                                            optionIndex
                                          )}
                                          onChange={(event) =>
                                            handleCheckboxChange(
                                              event,
                                              questionIndex,
                                              optionIndex
                                            )
                                          }
                                        />
                                      </Grid>
                                      {optionIndex !== 0 && (
                                        <Grid
                                          item
                                          className={styles.qEFACMFCIAnsRemove}
                                        >
                                          <Button
                                            onClick={() =>
                                              handleRemoveOption(optionIndex)
                                            }
                                          ></Button>
                                        </Grid>
                                      )}
                                    </Grid>
                                  ))}
                                  <Grid item xs={4}>
                                    <Button
                                      onClick={handleAddOption}
                                      className={styles.qEFACMFCIAddBtn}
                                    >
                                      Add Option
                                    </Button>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container className={styles.qEFACMFCISaveBtn}>
                          <Grid item xs={12}>
                            <Button onClick={handleQuizAdd}>save</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </Modal>
              <Modal
                className={styles.qEFAddContentModule}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={quizBankOpen}
                onClose={handleQuizBankClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={quizBankOpen}>
                  <Box sx={style} className={styles.qEFACMContainer}>
                    <TableContainer
                      component={Paper}
                      className={styles.qETableContainer}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className={styles.qETableHead}>
                          <TableRow
                            // className="quiz-heading-row"
                            className={styles.qETableRow}
                          >
                            <TableCell align="left">Order #</TableCell>
                            <TableCell align="left">Question</TableCell>
                            <TableCell align="right">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quizBank.map((content, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">
                                {content.question}
                              </TableCell>
                              <TableCell align="right">
                                {quizContent.some(
                                  (quiz) => quiz.id === content.id
                                ) ? (
                                  <Button
                                    onClick={() =>
                                      handleRemoveQuizBank(content.id)
                                    }
                                    className={styles.addFromRemoveBtn}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      handleAddQuizBank(content.id)
                                    }
                                    className={styles.addFromAddBtn}
                                  >
                                    Add
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid
                      container
                      className={styles.qEFACMFCISaveBtn}
                      sx={{ mt: 2 }}
                    >
                      <Grid item xs={12}>
                        <Button onClick={handleQuizAdd}>save</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </Modal>
            </Form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
