import Card from "../../../layout/admin/partials/Card";
import Degrecap from "../../../../src/static/images/degree-cap.png";
import { useState, useEffect, useCallback } from "react";
import { AdminApi, api } from "../../../config/apis";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";

const AdminCardData = () => {
  type CardType = { heading: any; paragraph: string; imageUrl: string };

  const [cardsData, setCardsData] = useState<null | CardType[]>([]);

  const editData = useCallback(async () => {
    const res = await api.get(AdminApi.GERNEL_ENROLMENTS);

    if (res.status) {
      const cardData: CardType[] = [];
      cardData.push({
        heading: res.data.currentMonthRegistration,
        paragraph: "Registration This Month",
        imageUrl: Degrecap,
      });

      cardData.push({
        heading: `${CURRENCY_SYMBOL}${parseFloat(
          res.data.currentMonthRevenueAmount
        ).toLocaleString("en-US")}`,
        paragraph: "Revenue This Month",
        imageUrl: Degrecap,
      });

      cardData.push({
        heading: `${CURRENCY_SYMBOL}${parseFloat(
          res.data.currentMonthRefundAmount
        ).toLocaleString("en-US")}`,
        paragraph: "Refunds This Month",
        imageUrl: Degrecap,
      });

      cardData.push({
        heading: res.data.totalRegistration,
        paragraph: "Total Registrations",
        imageUrl: Degrecap,
      });

      cardData.push({
        heading: `${CURRENCY_SYMBOL}${parseFloat(
          res.data.totalRevenueAmount
        ).toLocaleString("en-US")}`,
        paragraph: "Total Revenue ",
        imageUrl: Degrecap,
      });

      cardData.push({
        heading: `${CURRENCY_SYMBOL}${parseFloat(
          res.data.totalRefundAmount
        ).toLocaleString("en-US")}`,
        paragraph: "Total Refunds ",
        imageUrl: Degrecap,
      });

      setCardsData(cardData);
    }
  }, []);

  useEffect(() => {
    editData();
  }, [editData]);

  return (
    <>
      <Card cardItem={cardsData} />
    </>
  );
};

export default AdminCardData;
