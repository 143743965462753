import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "../admin/partials/footer/Footer";
import "../../static/css/style.scss";
import { Outlet } from "react-router";

function Layout() {
  const theme = createTheme({
    typography: {
      fontFamily: "Poppins, sans-serif",
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth={false} className="login-form-bg">
          <CssBaseline />
          <Outlet />
          <Footer />
        </Container>
      </ThemeProvider>
    </>
  );
}

export default Layout;
