import { Box, Grid } from "@mui/material";
import styles from './manage-discount.module.scss'
import CourseListContent from "./CouseCartComponent/CourseList";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from "./Stripe/PaymentForm";
import CourseCartList from "../manage-purchased-course/CourseCartList"
import { SelectedDataProvider } from "../../../context/SelectedDataContext"
import { useParams } from "react-router-dom";




const CourseCart = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
    const params = useParams();

    return (
        <SelectedDataProvider>
            <Box sx={{ width: "100%" }}  className={`${styles.paymentMain} testnew`}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={7} xl={8} className={styles.purchaseListSb}>
                        {params.id ? (
                            <CourseCartList />
                            ) : (
                            <CourseListContent />
                        )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} xl={4} className={styles.paymentStripe} >
                        <Elements stripe={stripePromise}>
                            <PaymentForm />
                        </Elements>
                    </Grid>
                </Grid>
            </Box>
        </SelectedDataProvider>
    );
};

export default CourseCart;
