import { useState, useEffect } from "react";
import { api, AdminApi } from "../../../../../config/apis";
import style from "../../studentDashboard.module.scss";
import {Box, Card, CardContent, Grid, Paper, Typography } from "@mui/material";


const CourseAvgComp = () => {
  const [CourseAvgData, setCourseAvgData] = useState<any>();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(AdminApi.STUDENT_DASHBOARD_STATE);
        setCourseAvgData(res.data)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
      <Card className={style.courseMain}>
        <CardContent className={style.courseMainContent}>
          <Grid container spacing={"30px"} className={style.courseGrid}>
            <Grid item xs={12} sm={12} md={12} lg={4} className={style.courseCard}>
              <Paper className={style.courseCardContent}>
                <Typography variant="h6" className={style.courseTitle}>Subjects Completed</Typography>
                <Typography variant="body1" className={style.courseScore}>{CourseAvgData && CourseAvgData.courseCompleted}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} className={style.courseCard}>
              <Paper className={style.courseCardContent}>
                <Typography variant="h6" className={style.courseTitle}>Subjects Enrolled</Typography>
                <Typography variant="body1" className={style.courseScore}>{CourseAvgData && CourseAvgData.enrolledCourses}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} className={style.courseCard}>
              <Paper className={style.courseCardContent}>
                <Typography variant="h6" className={style.courseTitle}>Avg Score</Typography>
                <Typography variant="body1" className={style.courseScore}>{CourseAvgData && CourseAvgData.avgScore}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
};

export default CourseAvgComp;

