import { useEffect, useState } from "react";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import Datatable from "../../../components/dataTables/Datatable";
import { toTitleCase } from "../../../helper/helperFunction";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "react-bootstrap";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GlobalSwitchCell from "../../../components/globalStatus";
import AssignCourse from "./edit";
import Tooltip from "@mui/material/Tooltip";

export default function ViewSubjectAcces() {
  const [order, setOrder] = useState("DESC");
  const navigate = useNavigate();

  type FiltersData = {
    student_name: string | null;
    course_name: string | null;
    parent_name: string | null;
  };

  const [filters, setFilters] = useState<{} | FiltersData>({});
  const [filterData, setFilterData] = useState<{} | FiltersData>({});
  const [reRender, setReRender] = useState(false);
  const [revokeParam, setRevokeParam] = useState<any>({
    studentId: null,
    courseId: null,
  });
  const { REACT_APP_SERVER_BASE } = process.env;
  const tableColumns = [
    {
      cell: (row: { student: any; studentProfile: string }) => (
        <>
          <Avatar
            sx={{ width: 40, height: 40, mr: 2 }}
            alt={row.student.toUpperCase()}
            src={`${REACT_APP_SERVER_BASE}${row.studentProfile}`}
          />
          {toTitleCase(`${row.student}`)}
        </>
      ),
      name: "Student",
      sortable: false,
    },
    {
      selector: (row: { course: string }) => row.course,
      name: "Course",
      sortable: false,
    },
    {
      cell: (row: { parent: string; parentProfile: string }) => (
        <>
          <Avatar
            sx={{ width: 40, height: 40, mr: 2 }}
            alt={row.parent.toUpperCase()}
            src={`${REACT_APP_SERVER_BASE}${row.parentProfile}`}
          />
          {toTitleCase(`${row.parent}`)}
        </>
      ),
      name: "Parent",
      sortable: false,
    },
    {
      cell: (row: {
        status: string;
        courseId: number;
        studentId: number;
        revokable: boolean;
      }) => (
        <>
          <Tooltip
            title={
              row.revokable
                ? ""
                : "It is not permissible to revoke because the student has completed 50% of this course."
            }
            arrow
          >
            <Button
              disabled={!row.revokable}
              style={row.revokable ? {} : { opacity: "0.5" }}
              className={styles.revoke_btn}
              onClick={() => onConfirm(row.studentId, row.courseId)}
            >
              Revoke
            </Button>
          </Tooltip>
        </>
      ),
      name: "",
      sortable: false,
      width: "150px",
    },
    {
      cell: (row: { status: string; courseId: number; studentId: number }) => (
        <>
          <GlobalSwitchCell
            EndPoint={AdminApi.REVOKW_GRANT_SUBJECT}
            is_active={row.status === "hold" ? false : true}
            requestBody={{
              courseId: row.courseId,
              studentId: row.studentId,
              status: row.status === "hold" ? "pending" : "hold",
            }}
            succesMsg={
              row.status === "hold"
                ? "course active successfully!"
                : "course inactive successfully!"
            }
            setReloadData={setReRender}
          />
        </>
      ),
      name: "Status",
      sortable: false,
      width: "150px",
    },
    {
      cell: (row: { studentId: any }) => (
        <>
          <button
            style={{ border: 0, cursor: "pointer" }}
            className="view"
            onClick={() => onView(row.studentId)}
          >
            view
          </button>
        </>
      ),
      name: "Action",
      sortable: false,
      width: "80px",
    },
  ];

  const onConfirm = async (studentId: number, courseId: number) => {
    setRevokeParam({
      studentId: studentId,
      courseId: courseId,
    });
    setConfirmOpen(true);
  };

  const handleRevoke = async () => {
    const { studentId, courseId } = revokeParam;
    try {
      const res = await api.post(resolveUrl(AdminApi.REVOKW_GRANT_SUBJECT), {
        courseId: courseId,
        studentId: studentId,
        status: "cancelled",
      });
      toast.success(res.data.message || "Operation successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setConfirmOpen(false);
      setReRender((prevState: any) => !prevState);
      setRevokeParam({
        studentId: null,
        courseId: null,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onView = async (id: string) => {
    navigate(`/admin/student/view/${id}`);
  };
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setOpen(false);
  }, [reRender]);

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Grant and Retract Subject</h1>
        <Button onClick={() => setOpen(true)}>Assign Course</Button>
      </div>
      <Grid container className={styles.enrolledStudnetFilter}>
        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          className={styles.enrolledStudnetFilterContainer}
        >
          <Typography
            variant="body1"
            className={styles.enrolledStudnetFilterTitle}
          >
            Search By
          </Typography>
          <TextField
            id="student"
            variant="outlined"
            fullWidth
            placeholder="Student Name"
            className={styles.enrolledStudnetFilterStudentName}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  student_name: event.target.value,
                };
              })
            }
          />
          <TextField
            id="course"
            variant="outlined"
            fullWidth
            placeholder="Subject Name"
            className={styles.enrolledStudnetFilterCourseName}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  course_name: event.target.value,
                };
              })
            }
          />
          <TextField
            id="teacher"
            variant="outlined"
            fullWidth
            placeholder="Parent Name"
            className={styles.enrolledStudnetFilterTeacherName}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  parent_name: event.target.value,
                };
              })
            }
          />
          <Button
            className={styles.enrolledStudnetFilterBtn}
            onClick={() => setFilters(filterData)}
          >
            Go
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={2}
          className={styles.enrolledStudnetFilterOrderContainer}
        >
          <Select
            value={order}
            onChange={(event) => setOrder(event.target.value)}
            className={styles.enrolledStudnetFilterOrderContent}
          >
            <MenuItem value="ASC" selected>
              Ascending
            </MenuItem>
            <MenuItem value="DESC">Descending</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <div className="page-container Grant_Retract_Subject">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.GRANT_AND_RETRACT_SUBJECT_ACCESS}
          dataFilter={filters}
          actionColumns={false}
          module="view.manage-assign-and-revoke"
          refreshHandler={reRender}
          sortOrder={order}
        />
      </div>
      <Dialog open={ConfirmOpen} onClose={handleConfirmClose}>
        <Box padding={3}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Revoke this Subject?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmClose}>Cancel</Button>
            <Button onClick={() => handleRevoke()} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <AssignCourse
        handleClose={handleClose}
        open={open}
        width="800px"
        setReRender={setReRender}
      />
    </>
  );
}
