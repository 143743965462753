import { useCallback, useEffect, useState } from "react";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";
import { toTitleCase } from "../../../helper/helperFunction";
import { CURRENCY_SYMBOL } from "../../../config/constants/constants";
import { disconnect } from "process";
import Moment from "react-moment";
import styles from "./transations.module.scss";
import { Box, Grid, Typography } from "@mui/material";

export default function DetailTransactions() {
  const params = useParams();

  type UserData = {
    id: number;
    name: string;
    email: string;
  };

  type DiscountType = {
    id: number;
    discount: string;
    name: string;
    type: string;
  };

  type OrderItems = {
    id: number;
    student: string;
    price: string;
    course: string;
  };

  type TransactionData = {
    remainingInstallments: number;
    remainingAmount: number;
    id: number;
    date: string;
    totalAmount: string;
    user: UserData;
    discount: DiscountType | null;
    orderItems: OrderItems[];
    type?: string;
    Subtotal?: string;
    discountAmount?: string;
  };

  const [transaction, setTransaction] = useState<TransactionData | null>();

  const calculateSubTotal = () => {
    let price = 0;

    if (transaction) {
      transaction.orderItems.forEach((item) => {
        price = price + parseFloat(item.price);
      });
    }

    return price;
  };

  const calculateDiscount = () => {
    let disconnect = 0;

    if (transaction && transaction.discount) {
      if (transaction.discount.type === "percentage") {
        disconnect =
          calculateSubTotal() *
          (parseFloat(transaction.discount.discount) / 100);
      } else {
        disconnect = parseFloat(transaction.discount.discount);
      }
    }

    return disconnect;
  };

  const calculateTotal = () => {
    let totalAmount = 0;

    if (transaction) {
      totalAmount = calculateSubTotal();

      if (transaction.discount) {
        totalAmount = totalAmount - calculateDiscount();
      }
    }

    return totalAmount;
  };

  const viewData = useCallback(async () => {
    if (params.id) {
      const res = await api.get(
        resolveUrl(AdminApi.TRANSACTION_VIEW, params.id)
      );
      setTransaction(res.data.transaction);
    }
  }, [params.id]);

  useEffect(() => {
    viewData();
  }, [viewData]);

  return (
    <>
      <h1>Detail Transactions</h1>
      <Box className={styles.detailTransationsContainer}>
        {transaction && (
          <Grid container className={styles.detailTransationsPaymentInfo}>
            <Grid
              item
              xs={12}
              className={styles.detailTransationsPaymentInfoTitle}
            >
              <Typography
                variant="h4"
                className={styles.detailTransationsPaymentInfoTitleText}
              >
                Transaction ID
              </Typography>
              <Typography
                variant="h2"
                className={styles.detailTransationsPaymentInfoId}
              >
                #{transaction.id}
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              lg={3}
              className={styles.detailTransationsPaymentInfoSub}
            >
              <Typography
                variant="h4"
                className={styles.detailTransationsPaymentInfoSubTitle}
              >
                User Name
              </Typography>
              <Typography
                variant="body1"
                className={styles.detailTransationsPaymentInfoSubContent}
              >
                {toTitleCase(transaction.user.name)}
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              lg={3}
              className={styles.detailTransationsPaymentInfoSub}
            >
              <Typography
                variant="h4"
                className={styles.detailTransationsPaymentInfoSubTitle}
              >
                Email
              </Typography>
              <Typography
                variant="body1"
                className={styles.detailTransationsPaymentInfoSubContent}
              >
                {transaction.user.email}
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              lg={3}
              className={styles.detailTransationsPaymentInfoSub}
            >
              <Typography
                variant="h4"
                className={styles.detailTransationsPaymentInfoSubTitle}
              >
                Date / Time
              </Typography>
              <Typography
                variant="body1"
                className={styles.detailTransationsPaymentInfoSubContent}
              >
                <Moment format="MMM DD, YYYY">{transaction.date}</Moment>
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              lg={3}
              className={styles.detailTransationsPaymentInfoSub}
            >
              <Typography
                variant="h4"
                className={styles.detailTransationsPaymentInfoSubTitle}
              >
                Payment Type
              </Typography>
              <Typography
                variant="body1"
                className={styles.detailTransationsPaymentInfoSubContent}
              >
                {transaction.type}
              </Typography>
            </Grid>
          </Grid>
        )}

        {transaction && (
          <Grid
            container
            spacing={"24px"}
            className={styles.detailTransationsPaymentContent}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8}
              className={styles.detailTransationsPaymentContentTable}
            >
              <Grid
                container
                className={styles.detailTransationsPaymentContentTableHead}
              >
                <Grid
                  item
                  xs={1}
                  className={
                    styles.detailTransationsPaymentContentTableHeadContent
                  }
                >
                  <Typography
                    variant="h4"
                    className={
                      styles.detailTransationsPaymentContentTableHeadText
                    }
                  >
                    S No.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={
                    styles.detailTransationsPaymentContentTableHeadContent
                  }
                >
                  <Typography
                    variant="h4"
                    className={
                      styles.detailTransationsPaymentContentTableHeadText
                    }
                  >
                    Student Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={
                    styles.detailTransationsPaymentContentTableHeadContent
                  }
                >
                  <Typography
                    variant="h4"
                    className={
                      styles.detailTransationsPaymentContentTableHeadText
                    }
                  >
                    Subject Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={
                    styles.detailTransationsPaymentContentTableHeadContent
                  }
                >
                  <Typography
                    variant="h4"
                    className={
                      styles.detailTransationsPaymentContentTableHeadText
                    }
                  >
                    Price
                  </Typography>
                </Grid>
              </Grid>
              {transaction.orderItems.map((item, index) => {
                return (
                  <Grid
                    container
                    key={index}
                    className={styles.detailTransationsPaymentContentTableBody}
                  >
                    <Grid
                      item
                      xs={1}
                      className={
                        styles.detailTransationsPaymentContentTableBodyContent
                      }
                    >
                      <Typography
                        variant="h4"
                        className={
                          styles.detailTransationsPaymentContentTableBodyText
                        }
                      >
                        {index + 1}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={
                        styles.detailTransationsPaymentContentTableBodyContent
                      }
                    >
                      <Typography
                        variant="h4"
                        className={
                          styles.detailTransationsPaymentContentTableBodyText
                        }
                      >
                        {item.student === "NULL"
                          ? `Student ${index}`
                          : item.student}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={
                        styles.detailTransationsPaymentContentTableBodyContent
                      }
                    >
                      <Typography
                        variant="h4"
                        className={
                          styles.detailTransationsPaymentContentTableBodyText
                        }
                      >
                        {item.course}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={
                        styles.detailTransationsPaymentContentTableBodyContent
                      }
                    >
                      <Typography
                        variant="h4"
                        className={
                          styles.detailTransationsPaymentContentTableBodyText
                        }
                      >{`${CURRENCY_SYMBOL}${parseFloat(item.price).toFixed(
                        2
                      )}`}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {transaction.type === "Payment on Installment" ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                className={styles.detailTransationsPaymentTotal}
              >
                <Box className={styles.detailTransationsPaymentTotalContainer}>
                  <Grid
                    container
                    className={styles.detailTransationsPaymentTotalContain}
                  >
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitle}
                    >
                      Subtotal
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitlePrice}
                    >{`${CURRENCY_SYMBOL}${transaction.Subtotal}`}</Grid>
                  </Grid>
                  <Grid
                    container
                    className={styles.detailTransationsPaymentTotalContain}
                  >
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitle}
                    >
                      Discount
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitlePrice}
                    >{`- ${CURRENCY_SYMBOL}${transaction.discountAmount}`}</Grid>
                  </Grid>
                  <Grid
                    container
                    className={`${styles.detailTransationsPaymentTotalContain} ${styles.total}`}
                  >
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitle}
                    >
                      Total
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitlePrice}
                    >{`${CURRENCY_SYMBOL}${transaction.totalAmount}`}</Grid>
                  </Grid>
                  <Grid
                    container
                    className={styles.detailTransationsPaymentTotalContain}
                  >
                    {transaction.remainingInstallments ? (
                      <>
                        <Grid
                          item
                          xs={6}
                          className={styles.detailTransationsPaymentTotalTitle}
                        >
                          Remaining
                          <span className={styles.installments_remain}>
                            ({transaction.remainingInstallments} installments)
                          </span>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          className={
                            styles.detailTransationsPaymentTotalTitlePrice
                          }
                        >{`${CURRENCY_SYMBOL}${transaction.remainingAmount}`}</Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                className={styles.detailTransationsPaymentTotal}
              >
                <Box className={styles.detailTransationsPaymentTotalContainer}>
                  <Grid
                    container
                    className={styles.detailTransationsPaymentTotalContain}
                  >
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitle}
                    >
                      Subtotal
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitlePrice}
                    >{`${CURRENCY_SYMBOL}${calculateSubTotal().toFixed(
                      2
                    )}`}</Grid>
                  </Grid>
                  <Grid
                    container
                    className={styles.detailTransationsPaymentTotalContain}
                  >
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitle}
                    >
                      Discount
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitlePrice}
                    >{`- ${CURRENCY_SYMBOL}${calculateDiscount().toFixed(
                      2
                    )}`}</Grid>
                  </Grid>
                  <Grid
                    container
                    className={styles.detailTransationsPaymentTotalContain}
                  >
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitle}
                    >
                      Total
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={styles.detailTransationsPaymentTotalTitlePrice}
                    >{`${CURRENCY_SYMBOL}${calculateTotal().toFixed(2)}`}</Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </>
  );
}
