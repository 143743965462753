import { Box, Modal } from "@mui/material";
import AddCategoriesForm from "./edit";
import styles from "./manage-catagories.module.css";

type CatagoryFormModalProps = {
  open: boolean;
  id: string;
  closeModal: () => void;
  setFormSuccess: (value: boolean) => void;
};

const PopupForm: React.FC<CatagoryFormModalProps> = ({
  open,
  id,
  closeModal,
  setFormSuccess,
}) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={{ width: "669px" }} className={styles.popup}>
        <AddCategoriesForm id={id} setFormSuccess={setFormSuccess} />
      </Box>
    </Modal>
  );
};

export default PopupForm;
