import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import styles from "./style.module.scss";
import ImageUpload from "../../../components/UploadImage";
import * as Yup from "yup";

export default function FooterFrontend() {
  type FormData = {
    title?: string;
    description?: string;
    phone?: string;
    email?: string;
    address?: string;
    copyRightText?: string;
    facebookLink?: string;
    twitterLink?: string;
    youtubeLink?: string;
    linkedinLink?: string;
    addressURL?: string;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
    phone: Yup.string().required("This field is required"),
    email: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
    copyRightText: Yup.string().required("This field is required"),
    facebookLink: Yup.string().required("This field is required"),
    twitterLink: Yup.string().required("This field is required"),
    youtubeLink: Yup.string().required("This field is required"),
    linkedinLink: Yup.string().required("This field is required"),
    addressURL: Yup.string().required("This field is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    phone: "",
    email: "",
    address: "",
    copyRightText: "",
    facebookLink: "",
    twitterLink: "",
    youtubeLink: "",
    linkedinLink: "",
    addressURL: "",
  });

  const [onSuccess, setOnSuccess] = useState<any>();

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.FRONTEND_FOOTER_VIEW));
        setFormData(res.data.viewHomePageFooter);
        setFormData({
          title: res.data.viewHomePageFooter.title,
          description: res.data.viewHomePageFooter.description,
          phone: res.data.viewHomePageFooter.phone,
          email: res.data.viewHomePageFooter.email,
          address: res.data.viewHomePageFooter.address,
          copyRightText: res.data.viewHomePageFooter.copyRightText,
          facebookLink: res.data.viewHomePageFooter.facebookLink,
          twitterLink: res.data.viewHomePageFooter.twitterLink,
          youtubeLink: res.data.viewHomePageFooter.youtubeLink,
          linkedinLink: res.data.viewHomePageFooter.linkedinLink,
          addressURL: res.data.viewHomePageFooter.addressUrl,
        });
        setOnSuccess("");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [onSuccess]);

  return (
    <Box className={styles.aSCreateTicketMain}>
      <Box className={styles.aSCTContainer}>
        <Form
          submitUrl={AdminApi.FRONTEND_FOOTER_SAVE}
          formData={formData}
          successPath="/admin/cms/footer"
          validate={customValidation}
          onSuccess={setOnSuccess}
        >
          <Typography
            variant="h5"
            sx={{
              padding: "20px 0",
              marginBottom: "0!important",
              fontWeight: "700",
            }}
          >
            Description
          </Typography>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Title
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Title"
              value={formData.title}
              name="title"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.title !== undefined}
              helperText={errors.title}
            />
          </Box>
          <Box className={styles.aSCTContentDesc}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentDescLabel}
            >
              Description
            </InputLabel>
            <Textarea
              placeholder="Description"
              name="description"
              value={formData.description}
              minRows={4}
              maxRows={4}
              onChange={handleOnChange}
              className={styles.aSCTContentDescInput}
            />
            {errors.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Box>
          <Typography
            variant="h5"
            sx={{
              padding: "20px 0",
              marginBottom: "0!important",
              fontWeight: "700",
            }}
          >
            Contact Us
          </Typography>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Address
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Address Text"
              value={formData.address}
              name="address"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.address !== undefined}
              helperText={errors.address}
            />
          </Box>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Address Url
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Address Url"
              value={formData.addressURL}
              name="addressURL"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.addressURL !== undefined}
              helperText={errors.addressURL}
            />
          </Box>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Email
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Email"
              value={formData.email}
              name="email"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.email !== undefined}
              helperText={errors.email}
            />
          </Box>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Phone
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Phone"
              value={formData.phone}
              name="phone"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.phone !== undefined}
              helperText={errors.phone}
            />
          </Box>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Facebook
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Url"
              value={formData.facebookLink}
              name="facebookLink"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.facebookLink !== undefined}
              helperText={errors.facebookLink}
            />
          </Box>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Twitter
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Url"
              value={formData.twitterLink}
              name="twitterLink"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.twitterLink !== undefined}
              helperText={errors.twitterLink}
            />
          </Box>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Youtube
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Url"
              value={formData.youtubeLink}
              name="youtubeLink"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.youtubeLink !== undefined}
              helperText={errors.youtubeLink}
            />
          </Box>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Linkedin
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Url"
              value={formData.linkedinLink}
              name="linkedinLink"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.linkedinLink !== undefined}
              helperText={errors.linkedinLink}
            />
          </Box>
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Copyright
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Url"
              value={formData.copyRightText}
              name="copyRightText"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.copyRightText !== undefined}
              helperText={errors.copyRightText}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}
