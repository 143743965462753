import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import CustomModal from "../popups/Modal";
import { useAuth } from "../../hooks/auth/auth";
import { FetchPopupData, GetUserRole } from "../../helper/helperFunction";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AdminApi, api } from "../../config/apis";

const GlobalPopupManager: React.FC = () => {
  const [loginCount, setLoginCount] = useState<any>({
    enrolledKids: null,
    kids: null,
    loginCount: null,
  });

  const Parent = useAuth();
  const isLoggedIn = Parent?.state?.status;

  const isParent = GetUserRole() === "Parent";

  useEffect(() => {
    const getLoginCount = async () => {
      try {
        const res = await api.get(AdminApi.PARENT_LOGIN_COUNT);
        const { enrolledKids, kids, loginCount } = res.data;
        setLoginCount({
          enrolledKids: enrolledKids,
          kids: kids,
          loginCount: loginCount,
        });
      } catch (error) {
        console.error(error);
      }
    };

    getLoginCount();
  }, []);

  useEffect(() => {
    const haspopupSecondShown = Cookies.get("popupSecondShown");

    if (loginCount && loginCount.kids > 0 && loginCount.enrolledKids == 0) {
      if (!haspopupSecondShown && isLoggedIn && isParent) {
        setShowSecondPopup(true);
        Cookies.set("popupSecondShown", "true", { expires: 1 });
      }
    }
  }, [loginCount, isLoggedIn, isParent]);

  const [showSecondPopup, setShowSecondPopup] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    const hasPopupBeenShown = Cookies.get("addStudentPopup");

    if (!hasPopupBeenShown && isLoggedIn && isParent) {
      if (loginCount.kids == 0 && loginCount.enrolledKids == 0) {
        setShowPopup(true);
        Cookies.set("addStudentPopup", "true", { expires: 365 });
      }
    }
  }, [isLoggedIn, isParent, loginCount]);

  const closePopup = () => {
    setShowPopup(false);
    setShowSecondPopup(false);
  };
  const navigate = useNavigate();

  const [poupData, setPopupData] = useState<string>("");
  const [poupData2nd, set2ndPopupData] = useState<string>("");
  const type = "parent-first-login";
  const type2 = "parent-2nd-login";
  useEffect(() => {
    const fetchData = async () => {
      await FetchPopupData(type, setPopupData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await FetchPopupData(type2, set2ndPopupData);
    };
    fetchData();
  }, []);

  return (
    <div>
      {showPopup && (
        <CustomModal state={showPopup} setState={""} width={800}>
          <button className="closeBtn" onClick={closePopup}>
            +
          </button>
          <div dangerouslySetInnerHTML={{ __html: poupData }}></div>
          <Button
            className="btn"
            onClick={() => navigate(`/admin/student/edit`)}
          >
            Add Student
          </Button>
        </CustomModal>
      )}

      <CustomModal state={showSecondPopup} setState={""} width={800}>
        <button className="closeBtn" onClick={closePopup}>
          +
        </button>
        <div dangerouslySetInnerHTML={{ __html: poupData2nd }}></div>
        <Button
          className="btn"
          onClick={() => navigate(`/admin/course-catalog`)}
        >
          Get a Subject
        </Button>
      </CustomModal>
    </div>
  );
};

export default GlobalPopupManager;
