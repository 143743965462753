import { Link } from "react-router-dom";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import AdminFormModal from "./modal";
import React from "react";
import { DateView } from "../../../components/DateView/DateView";
import Datatable from "../../../components/dataTables/Datatable";
import ActionButtons from "../../../components/dataTables/ActionButtons";
import { Row } from "react-bootstrap";

export default function ViewAdmin() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState("");
  const [reRender, setReRender] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const tableColumns = [
    {
      selector: (row: any) => row.name,
      name: "Name",
      sortable: false,
    },
    {
      selector: (row: any) => row.email,
      name: "Email",
      sortable: false,
      minWidth: "320px",
    },
    {
      selector: (row: any) => row.role,
      name: "Role",
      sortable: false,
    },
    {
      selector: (row: any) => row.location,
      name: "Location",
      sortable: false,
    },
    {
      selector: (row: any) => <DateView date={row.addedOn} />,
      name: "Added on",
      sortable: false,
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row: { id: number; role: string }) => (
        <span>
          {row.role && row.role.toLowerCase() !== "super admin" && (
            <ActionButtons
              id={row.id}
              actions={{
                view: false,
              }}
              onEdit={onEdit}
              onDelete={onDelete}
              module="manage-admins"
            />
          )}
        </span>
      ),
    },
  ];

  const onEdit = async (id: any) => {
    setViewId(id);
    if (id) {
      setIsModalOpen(true);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const onDelete = async (id: any) => {
    return await api.delete(resolveUrl(AdminApi.ADMIN_DELETE, id));
  };

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
    setReRender(formSuccess);
  }, [formSuccess]);

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={AdminApi.ADMIN_USER_PAGINATION}
        onEdit={onEdit}
        onDelete={onDelete}
        refreshHandler={reRender}
        module="manage-admins"
        actionColumns={false}
      />
      <div className="course_content">
        <Box className="btn-wrapper">
          <Link to={""} onClick={toggleModal}>
            Add Admin
          </Link>
        </Box>
      </div>
      <AdminFormModal
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
