import Datatable from "../../../../../../../components/dataTables/Datatable";
import { AdminApi } from "../../../../../../../config/apis/admin";
import { resolveUrl } from "../../../../../../../config/apis";
import CircularProgressWithLabel from "../../../../../../../components/ProgressCircle";
import moment from "moment";
import { limitText } from "../../../../../../../helper/helperFunction";
import { Tooltip } from "@mui/material";
import { useStudentId } from "../../../../../../../context/getStudentId";

const TeacherAssignmentFeedback = (props: any) => {
  const { studentId } = useStudentId();

  const tableColumns = [
    {
      selector: (row: { feedback?: string }) => (
        <Tooltip title={row.feedback} arrow>
          <p>{row.feedback ? limitText(row.feedback, 40) : "-"}</p>
        </Tooltip>
      ),
      name: "Feedback",
      minWidth: "360px",
      wrap: "yes",
    },
    {
      selector: (row: any) => (
        <Tooltip title={moment(row.date).format("MMMM DD, YYYY")} arrow>
          <span>{moment(row.date).format("MMMM DD, YYYY")}</span>
        </Tooltip>
      ),
      name: "Added On",
      minWidth: "150px",
    },
    {
      selector: (row: any) => (
        <CircularProgressWithLabel value={row.progress} />
      ),
      name: "Progress",
      minWidth: "150px",
      right: "true",
    },
  ];
  const courseId = {
    courseId: props?.courseId,
  };
  return (
    <>
      <div className="courseDataTable">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(
            AdminApi.TEACHERS_ASSIGNMENT_FEEDBACK,
            studentId ?? ""
          )}
          actions={{
            view: true,
            edit: false,
            delete: false,
          }}
          actionColumns={false}
          rowsPerPage={5}
          module="manage-kids"
          dataFilter={courseId}
        />
      </div>
    </>
  );
};

export default TeacherAssignmentFeedback;
