import { useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Box, Typography, TextField } from "@mui/material";
import CKEditorComponent from "../../../components/CKEditorComponent";

type IGlobalCMSPopup = {
  type?: string;
  heading?: string;
  successPathUrl?: string;
};

const EditRegistrationEmail = ({
  type,
  heading,
  successPathUrl,
}: IGlobalCMSPopup) => {
  // Define the initial form data
  const initialFormData = {
    type: type,
    subject: "",
    content: "",
  };

  // States
  const [onSuccess, setOnSuccess] = useState<any>();
  const [formData, setFormData] = useState(initialFormData);

  // Fetch view data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.REGISTRATION_EMAIL_VIEW, formData.type)
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          subject: res.data.subject ?? "",
          content: res.data.content ?? "",
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [onSuccess]);

  // Handle editor change
  const handleEditorChange = (event: any, editor: any) => {
    const editor_data = editor.getData();
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editor_data;
    const links = tempDiv.querySelectorAll("a");
    links.forEach((link) => {
      if (link.host !== window.location.host) {
        link.setAttribute("target", "_blank");
      }
    });

    const data = tempDiv.innerHTML;
    setFormData((prevFormData) => ({
      ...prevFormData,
      content: data,
    }));
  };

  // Handle subject change
  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      subject: value,
    }));
  };

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{ fontSize: "40px", fontWeight: "700", padding: "20px 0" }}
      >
        {heading}
      </Typography>
      <Box>
        <Form
          submitUrl={AdminApi.REGISTRATION_EMAIL_SAVE}
          formData={formData}
          successPath={"/admin/cms/emails"}
          onSuccess={setOnSuccess}
        >
          <Box sx={{ padding: "0" }}>
            <TextField
              placeholder="Subject"
              variant="outlined"
              fullWidth
              value={formData.subject}
              onChange={handleSubjectChange}
              sx={{ marginBottom: "20px" }}
            />
          </Box>
          <Box sx={{ padding: "0" }}>
            <CKEditorComponent
              initialValue={formData.content} // Pass the content as the editorData prop
              onChange={handleEditorChange}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default EditRegistrationEmail;
