import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  answerKey: Yup.array()
    .of(
      Yup.number().required(
        "A checkbox must have at least one answer key selected"
      )
    )
    .min(1, "A checkbox must have at least one answer key selected."),
  answer: Yup.array()
    .of(Yup.string())
    .min(2, "All quizzes must have at least two non-empty options."),
  question: Yup.string().required("Question is required"),
});

export default validationSchema;
