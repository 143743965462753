import { Typography, Link } from "@mui/material";
import BannerImg from "./asset/img/bannerImg.jpg";
import "./style/style.css";
import { FrontendApi } from "../../../config/apis/frontend";
import { useEffect, useState } from "react";
import { api } from "../../../config/apis";
import ImageViewer from "../../../components/imageViewer";

type TBannerData = {
  heading?: string;
  description?: string;
  image?: string;
};

const InnerBannerFaq = function () {
  const [banner, setBanner] = useState<TBannerData>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(FrontendApi.FAQ_PAGE_CMS_BANNER);
        const { Banner } = res.data.FAQPageInfo;
        setBanner(Banner);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  function getSecondToLastWord(inputString: string): string {
    const words = inputString.trim().split(" ");
    return words.length >= 3 ? words[words.length - 1] : "";
  }

  const heading = banner?.heading || "";
  const secondToLastWord = getSecondToLastWord(heading);

  const styledHeading = heading.replace(
    secondToLastWord,
    `<span class='textGradian'> ${secondToLastWord} </span>`
  );

  return (
    <div className="innerBannerFaq">
      <div className="container">
        <div className="contain">
          <div className="textContain">
            <div className="bradcrumbs">
              <Link href="/home">Home</Link> \{" "}
              <Link href="/curriculum" className="active">
                FAQs
              </Link>
            </div>
            <div className="pageTitle">
              <Typography
                variant="h1"
                dangerouslySetInnerHTML={{ __html: styledHeading }}
              />
            </div>
            <div className="pageDesc">
              <Typography variant="body1">
                {banner && banner.description}
              </Typography>
            </div>
          </div>
          <div className="bannerImg">
            {banner && banner.image ? (
              <ImageViewer
                imagePath={banner?.image ?? ""}
                altText={banner?.heading}
              />
            ) : (
              <img src={BannerImg} alt="Banner Img" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerBannerFaq;
