import React, { useEffect, useState } from "react";
import Datatable from "../../../../components/dataTables/Datatable";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import Switch from "@mui/joy/Switch";
import PopupForm from "./Modal";
import { Button, InputLabel, TextField } from "@mui/material";
import styles from "../forum.module.css";
import Moment from "react-moment";

function SwitchCell() {
  const [checked, setChecked] = React.useState<boolean>(false);

  return (
    <>
      <Switch
        checked={checked}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setChecked(event.target.checked)
        }
        sx={{
          "--Switch-trackWidth": "33.49px",
          "--Switch-trackHeight": "18.41px",
          "--Switch-thumbSize": "12px",
        }}
      />
    </>
  );
}
export default function ViewCategories() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewId, setViewId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryText, setSearchQueryText] = useState("");
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setViewId("");
    }
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(false);
  }, [formSuccess]);

  const tableColumns = [
    {
      selector: (row: { name: string }) => row.name,
      name: "Title",
      sortable: true,
    },
    {
      selector: (row: { name: string }) => "Admin",
      name: "Added By",
      sortable: false,
    },
    {
      selector: (row: { created_at: string }) => (
        <Moment format="MMM DD, YYYY">{row.created_at ?? ""}</Moment>
      ),
      name: "Added On",
      sortable: true,
      width: "150px",
    },
    {
      selector: (row: { type: string }) => "10",
      name: "Forums",
      sortable: false,
      width: "150px",
    },

    {
      cell: SwitchCell,
      sortable: false,
      width: "60px",
    },
  ];

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.CATEGORY_DELETE, id));
  };

  const onEdit = async (id: string) => {
    if (id) {
      setIsModalOpen(true);
      setViewId(id);
    }
  };

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Category</h1>
      </div>

      <Box
        className="inner-container"
        sx={{ mb: 4, display: "flex", justifyContent: "space-between", p: "0" }}
      >
        <form>
          <Box sx={{ display: "flex" }} className={styles.searchBy}>
            <InputLabel>Search By</InputLabel>
            <TextField
              id="coursetitle"
              variant="outlined"
              required
              fullWidth
              name="name"
              onChange={(event) => setSearchQueryText(event.target.value)}
            />
            <Box className="btn-wrapper">
              <Button
                className="btn"
                onClick={() => setSearchQuery(searchQueryText)}
              >
                Go
              </Button>
            </Box>
          </Box>
        </form>

        <Box className="btn-wrapper">
          <Link to={""} onClick={toggleModal}>
            Add Category
          </Link>
        </Box>
      </Box>

      <div className="page-container Forums">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.CATEGORY_PAGINATE}
          searchQuery={searchQuery}
          onEdit={onEdit}
          onDelete={onDelete}
          refreshHandler={formSuccess}
          actions={{
            view: false,
          }}
          module="manage-forums"
        />
      </div>
      <PopupForm
        id={viewId}
        open={isModalOpen}
        closeModal={toggleModal}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
}
