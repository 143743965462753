import StudentCourseList from './StudentCourseList';
import styles from './studnetCourses.module.scss'
import { Box, Typography } from "@mui/material";
import Filter from '../filters/filters';
import { useState } from 'react';


type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};


const StudentCourses = () => {


  const [filters, setFilters] = useState<FilterState>();
  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);
  };


  return (
    <Box className={styles.coursePagePanel}>
      <Typography variant="h2" className={styles.coursePanelTitle}>My Subject</Typography>
      <Filter onFiltersChange={handleFiltersChange} />
      <StudentCourseList filterState={filters} />
    </Box>
  );
};

export default StudentCourses;
