import { Textarea } from "@mui/joy";
import {
  Box,
  Select,
  MenuItem,
  Button,
  CardMedia,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import UploadIcon from "../../../../static/images/upload.svg";

import * as React from "react";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";
import { CourseData, UploadPreview } from "../../../../Type/courseTypes";
import CustomModal from "../../../../components/popups/Modal";
import { useNavigate } from "react-router-dom";
import CKEditorComponent from "../../../../components/CKEditorComponent";

interface EditCourseInfoProps {
  formData: CourseData;
  setFormData: React.Dispatch<React.SetStateAction<CourseData>>;
  errors: CourseData;
  setErrors: React.Dispatch<React.SetStateAction<CourseData>>;
}

export default function EditCourseInfo({
  formData,
  setFormData,
  setErrors,
  errors,
}: EditCourseInfoProps) {
  const params = useParams();

  const [uploadPreview, setUploadPreview] = useState<UploadPreview>();
  const [version, setVersion] = useState();
  const [getVersion, setGetVersion] = useState<any>();
  const [copyVersion, setCopyVersion] = useState<any>();
  const [demoVideo, setDemoVideo] = useState<string | null>(null);

  // METHODS
  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;

    setFormData({
      ...formData,
      [event.target.name]: selectedFiles?.[0],
    });

    setUploadPreview({
      featured_image: {
        blob: true,
        src: URL.createObjectURL(selectedFiles?.[0]),
      },
    });
  };
  const uploadDemoVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    setFormData({
      ...formData,
      [event.target.name]: selectedFile,
    });
    if (selectedFile) {
      setDemoVideo(URL.createObjectURL(selectedFile));
    } else {
      setDemoVideo(null);
    }
  };

  const editData = useCallback(async () => {
    if (params.id) {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.COURSES_VIEW, params.id),
          {
            params: {
              version: copyVersion || formData.version,
            },
          }
        );

        if (copyVersion) {
          setFormData({
            ...formData,
            name: res.data.course.name,
            description: res.data.course.description,
            featured_image: res.data.course.featured_image,
            have_live_quiz: res.data.course.have_live_quiz,
            price: res.data.course.price,
            teacherId: res.data.course.teacher.id,
            year: res.data.course.year,
            demo: res.data.course.demo,
            secID: [],
            contentID: [],
            sections: res.data.course.section.map((section: any) => {
              const { id: id, ...sectionWithoutId } = section;
              return {
                ...sectionWithoutId,
                courseContent: section.courseContent.map((contentItem: any) => {
                  const { id, ...contentWithoutId } = contentItem;
                  return contentWithoutId;
                }),
              };
            }),
            addable: "allowed",
          });
        } else {
          setFormData({
            ...formData,
            name: res.data.course.name,
            description: res.data.course.description ?? "",
            featured_image: res.data.course.featured_image,
            have_live_quiz: res.data.course.have_live_quiz,
            price: res.data.course.price,
            teacherId: res.data.course.teacher.id,
            year: res.data.course.year,
            sections: res.data.course.section,
            demo: res.data.course.demo,
            secID: [],
            contentID: [],
            version: formData.version || version,
            addable: res.data.course.addable,
          });
          // setOriginalFormData({ ...formData });
        }

        setVersion(res.data.course.version ?? null);

        setUploadPreview({
          featured_image: {
            blob: false,
            src: res.data.course.featured_image,
          },
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [params.id, formData.version, copyVersion, version]);

  // function isDeepEqual(obj1: any, obj2: any) {
  //   return JSON.stringify(obj1) === JSON.stringify(obj2);
  // }

  // function checkForChanges() {
  //   if (!isDeepEqual(originalFormData, formData)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // const isUpdated = checkForChanges();

  // Use Effect Hook
  useEffect(() => {
    editData();
  }, [editData]);

  // function confirmUnload(e: any) {
  //   if (isUpdated) {
  //     const confirmationMessage =
  //       "You have unsaved changes. Are you sure you want to leave?";
  //     e.returnValue = confirmationMessage;
  //     return confirmationMessage;
  //   }
  // }

  // Attach event listeners using addEventListener
  // window.addEventListener("beforeunload", confirmUnload);

  // useEffect(() => {
  //   const handleBeforeUnload = (event: { returnValue: string }) => {
  //     if (isUpdated) {
  //       const message =
  //         "You have unsaved changes. Are you sure you want to leave?";
  //       event.returnValue = message;
  //       return message;
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [isUpdated]);

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showVersionPopup, setShowVersionPopup] = useState<boolean>(false);
  const closePopup = () => {
    setShowPopup(false);
    setShowVersionPopup(false);
  };

  const { REACT_APP_SERVER_BASE } = process.env;
  const baseUrl = REACT_APP_SERVER_BASE;
  // const demo_video_url = `${baseUrl}${formData.demo}`;
  const demo_video_url = formData.demo;

  const handleOnSelectChange = (event: SelectChangeEvent<number | string>) => {
    const target = event.target.value as string | boolean;
    setFormData({
      ...formData,
      [event.target.name]: target,
    });

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };

  useEffect(() => {
    if (formData.version && version && formData.version > version) {
      setShowVersionPopup(true);
    } else {
      setCopyVersion(null);
    }
  }, [formData.version]);

  const handleOnNewSelectChange = (
    event: SelectChangeEvent<number | string>
  ) => {
    const target = event.target.value as string | boolean;
    setGetVersion(target);
  };

  const handVersionCopy = () => {
    setCopyVersion(getVersion);

    setFormData({
      ...formData,
      copySection: true,
    });
    closePopup();
  };
  const handleEditorChange = (event: any, editor: any) => {
    const editor_data = editor.getData();
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editor_data;
    const links = tempDiv.querySelectorAll("a");
    links.forEach((link) => {
      if (link.host !== window.location.host) {
        link.setAttribute("target", "_blank");
      }
    });

    const data = tempDiv.innerHTML;
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: data,
    }));
  };
  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xs={2}>
              <InputLabel>Subject Title*</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="outlined"
                id="coursetitle"
                required
                fullWidth
                name="name"
                value={formData.name}
                placeholder="Subject title"
                onChange={handleOnChange}
                error={errors.name !== undefined}
                helperText={errors.name}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container sx={{ mb: 3 }}>
            <Grid item xs={2}>
              <InputLabel sx={{ pt: 2 }}>Description*</InputLabel>
            </Grid>
            <Grid item xs={9} className="subject_edit_content">
              <CKEditorComponent
                initialValue={formData.description ?? ""}
                onChange={handleEditorChange}
              />
              {/* <Textarea
                placeholder="Description"
                name="description"
                minRows={4}
                maxRows={4}
                defaultValue={formData.description}
                onChange={handleOnChange}
                error={errors.description !== undefined}
              /> */}

              {errors.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            {/* <Grid item xs={2}>
              <InputLabel>Duration</InputLabel>
            </Grid>

            <Grid item xs={1}>
              <TextField
                placeholder="0"
                id="hours"
                type="number"
                name="hours"
                value={formData.hours ?? ""}
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={1} sx={{ ml: 3, fontSize: 14 }}>
              <InputLabel>Hours</InputLabel>
            </Grid>

            <Grid item xs={1}>
              <TextField
                placeholder="0"
                type="number"
                id="minutes"
                value={formData.minutes ?? ""}
                name="minutes"
                onChange={handleOnChange}
              />
            </Grid>
            <Grid item xs={1} sx={{ ml: 3, fontSize: 14 }}>
              <InputLabel>Mins</InputLabel>
            </Grid> */}

            <Grid item xs={2}>
              <InputLabel>Fees*</InputLabel>
            </Grid>
            <Grid item xs={1}>
              <TextField
                id="price"
                name="price"
                value={formData.price}
                onChange={handleOnChange}
                error={errors.price !== undefined}
                helperText={errors.price}
                type="number"
              />
            </Grid>

            <Grid item xs={1} sx={{ ml: 3 }}>
              <InputLabel>Year*</InputLabel>
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="year"
                type="text"
                name="year"
                onChange={handleOnChange}
                value={formData.year}
                error={errors.year !== undefined}
                helperText={errors.year}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{ mb: 3, alignItems: "center" }}
            className="browse"
          >
            <Grid item xs={2}>
              <InputLabel sx={{ pt: 2 }}>Demo Video</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <input
                style={{ padding: "11px 47px 11px 10px", cursor: "pointer" }}
                name="demo"
                accept="video/mp4"
                type="file"
                onChange={uploadDemoVideo}
              />
              {errors.demo && (
                <FormHelperText error>{errors.demo}</FormHelperText>
              )}
            </Grid>
          </Grid>
          {formData.demo ? (
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center" }}
              className="browse"
            >
              <Grid item xs={2}></Grid>
              <Grid item xs={2.5}>
                <Button
                  onClick={() => {
                    setShowPopup(true);
                  }}
                  className="btn"
                >
                  Play Demo
                </Button>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {version && (
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel>Subject Version</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e: SelectChangeEvent<number | string>) =>
                    handleOnSelectChange(e)
                  }
                  name="version"
                  value={formData.version ?? version}
                >
                  {version &&
                    Array.from({ length: version }, (_, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  <MenuItem value={version + 1}>Create New</MenuItem>
                </Select>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={2} className="upload-image circle profile" container>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              name="featured_image"
              accept="image/*"
              type="file"
              onChange={uploadImage}
            />
            {uploadPreview && uploadPreview.featured_image && (
              <Box
                component="img"
                alt="Featured Image"
                sx={{
                  width: 400,
                  height: 250,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                src={
                  uploadPreview.featured_image.blob
                    ? uploadPreview.featured_image.src
                    : `${uploadPreview.featured_image.src}`
                  // : `${SERVER_KEY}${uploadPreview.featured_image.src}`
                }
              />
            )}

            {(!uploadPreview || !uploadPreview.featured_image) && (
              <Box component="img" alt="Upload Icon" src={UploadIcon} />
            )}
          </IconButton>
          <InputLabel>Upload Thumbnail</InputLabel>
        </Grid>
      </Grid>
      <CustomModal state={showPopup} setState={""} width={800}>
        <button className="closeBtn" onClick={closePopup}>
          +
        </button>
        <Grid item xs={12}>
          <CardMedia
            component="video"
            image={demoVideo ? demoVideo : demo_video_url}
            controls
            controlsList="nodownload"
          />
        </Grid>
      </CustomModal>
      {/* <CustomModal state={showVersionPopup} setState={""} width={350}>
        <button className="closeBtn" onClick={closePopup}>
          +
        </button>
        <Grid item xs={12}>
          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xs={12} mb={1}>
              <InputLabel>Subject Version:</InputLabel>
            </Grid>
            <Grid item xs={12}>
              <Select
                style={{ maxWidth: "100px" }}
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e: SelectChangeEvent<number | string>) =>
                  handleOnSelectChange(e)
                }
                name="version"
                value={formData.version ?? version}
              >
                {version &&
                  Array.from({ length: version }, (_, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal> */}
      <CustomModal state={showVersionPopup} setState={""} width={400}>
        <button className="closeBtn" onClick={closePopup}>
          +
        </button>
        <Grid item xs={12}>
          <Grid item xs={12} container sx={{ alignItems: "center" }}>
            <Grid item xs={12} mb={1}>
              <InputLabel>Choose a version to duplicate:</InputLabel>
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="version"
                onChange={(e: SelectChangeEvent<number | string>) =>
                  handleOnNewSelectChange(e)
                }
              >
                {version &&
                  Array.from({ length: version }, (_, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} container justifyContent={"space-between"}>
              <Button className="btn" onClick={handVersionCopy}>
                Copy Version
              </Button>
              <Button className="btn" onClick={closePopup}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
}
