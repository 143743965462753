import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import BootstrapInput from "../../../layout/admin/partials/BootstrapInput";

export default function EditRole() {
  type RoleData = {
    name?: string;
    permissions?: RolePermissionData;
    role_id?: string;
  };

  type RolePermissionData = {
    [key: string]: boolean;
  };

  const [formData, setFormData] = useState<RoleData>({
    name: "",
    permissions: {},
  });

  const [permissions, setPermissions] = useState<any>([]);
  const [modules, setModules] = useState<any>([]);

  const [rolePermissions, setRolePermission] = useState<RolePermissionData>({});

  const params = useParams();

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handlePermissionChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      permissions: {
        ...formData.permissions,
        [`${target.dataset.permission}`]: target.checked,
      },
    });

    setRolePermission({
      ...rolePermissions,
      [`${target.dataset.permission}`]: target.checked,
    });
  };

  const editData = useCallback(async () => {
    if (params.id) {
      const res = await api.get(resolveUrl(AdminApi.ROLES_VIEW, params.id));

      const rolePermission = await api.get(
        resolveUrl(AdminApi.ROLE_PERMISSION, params.id)
      );
      setRolePermission(rolePermission.data.rolePermissions);

      setFormData({
        name: res.data.role.name,
        role_id: params.id,
        permissions: rolePermission.data.rolePermissions,
      });
    }

    const permissionRes = await api.get(AdminApi.PERMISSIONS_LIST);
    setPermissions(permissionRes.data);

    const modulesRes = await api.get(AdminApi.MODULES_LIST);
    setModules(modulesRes.data);
  }, [params.id]);

  useEffect(() => {
    editData();
  }, [editData]);

  return (
    <>
      <h1 className="main-heading">Edit Role</h1>
      <Box className="page-container">
        <Form
          submitUrl={AdminApi.ROLES_SAVE}
          formData={formData}
          editId={params.id}
          successPath="/admin/setting"
        >
          <Box className="field">
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Role Name
              </InputLabel>
              <BootstrapInput
                required
                id="outlined-required"
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleOnChange}
                size="small"
              />
            </FormControl>
          </Box>

          <Table>
            <TableHead>
              <TableRow className="table-head">
                <TableCell className="table-heading">Name</TableCell>
                {permissions.map((permission: any) => {
                  return (
                    <TableCell className="table-heading" key={permission.id}>
                      {permission.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {modules.map((module: any) => {
                return (
                  <TableRow key={module.id}>
                    <TableCell className="table-cell">{module.name}</TableCell>
                    {permissions.map((permission: any) => {
                      return (
                        <TableCell key={`${permission.name}.${module.name}`}>
                          <Checkbox
                            name="rolePermission"
                            value={true}
                            inputProps={
                              {
                                "data-permission": `${permission.name}.${module.name}`,
                              } as any
                            }
                            color="success"
                            checked={
                              rolePermissions &&
                              rolePermissions[
                                `${permission.name}.${module.name}`
                              ]
                                ? true
                                : false
                            }
                            onChange={handlePermissionChange}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Form>
      </Box>
    </>
  );
}
