import { Avatar, Box, Dialog, IconButton } from "@mui/material";
import { toTitleCase } from "../../../../helper/helperFunction";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DateView } from "../../../../components/DateView/DateView";
import FilterBase from "../filter/filter-base";
import SwitchCell from "../../../../components/switch";
import AvatarImage from "../../../../components/Avatar";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ViewTeacherUsers() {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const navigate = useNavigate();

  const [filterData, setFilterData] = useState<null | {}>({});
  const [filters, setFilters] = useState<null | {}>({});

  const handleFiltersChange = (filterState: any) => {
    setFilters(filterState);
  };

  const tableColumns = [
    {
      selector: (row: { id: number }) => `#${row.id}`,
      name: "ID",
      sortable: false,
      width: "100px",
    },

    {
      cell: (row: { Name: string; profile_picture: string }) => (
        <>
          <AvatarImage imageName={row.profile_picture} name={row.Name} />
          {toTitleCase(`${row.Name}`)}
        </>
      ),
      selector: "Name",
      name: "Name",
      sortable: true,
      width: "200px",
    },
    {
      selector: (row: { Email: string }) => row.Email,
      name: "Email",
      sortable: false,
      width: "300px",
    },
    {
      selector: (row: { Place: string }) => row.Place,
      name: "City",
      sortable: false,
    },

    {
      selector: (row: { AddedOn: string }) => <DateView date={row.AddedOn} />,
      name: "Added on",
      sortable: false,
    },
    {
      selector: (row: { students: string }) => row.students,
      name: "Students",
      sortable: false,
    },
    {
      cell: (row: { id: any; is_active: any }) => {
        return <SwitchCell id={row.id} is_active={row.is_active} />;
      },
      sortable: false,
      width: "60px",
    },
  ];
  const onView = async (id: string) => {
    navigate(`/admin/user/teachers/view/${id}`);
  };
  const onEdit = async (id: string) => {
    navigate(`/admin/user/teachers/edit/${id}`);
  };

  const onDelete = async (id: string) => {
    try {
      const res = await api.delete(resolveUrl(AdminApi.USER_DEL_BY_ADMIN, id));
      return res;
    } catch (error: any) {
      setErrorMsg(error.response.data.message);
      setOpenConfirm(true);
      throw error;
    }
  };

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Teachers</h1>
        <Box className="btn-wrapper">
          <Link to={"edit"}>Add Teacher</Link>
        </Box>
      </div>
      <FilterBase onFiltersChange={handleFiltersChange} placeholder="Name" />

      <div className="page-container teachers_add_yi">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.USER_TEACHER_PAGINATE}
          filters={filters}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
          actions={{
            view: true,
            edit: true,
            delete: true,
          }}
          module="manage-users"
        />
        <Dialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <DialogTitle id="alert-dialog-title" style={{ padding: 0 }}>
              Alert!
              <IconButton
                aria-label="close"
                onClick={() => setOpenConfirm(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errorMsg}
              </DialogContentText>
            </DialogContent>
          </Box>
        </Dialog>
      </div>
    </>
  );
}
