import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import styles from "./style.module.scss";
import * as Yup from "yup";

import CKEditorComponent from "../../../components/CKEditorComponent";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function EditFAQS({
  open,
  handleClose,
  width,
  setFormSuccess,
  editId,
}: any) {
  type FormData = {
    question?: string;
    answer: any;
    id?: number | null;
    categoryId?: number | null;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Question is required"),
    answer: Yup.string().required("Answer is required"),
    categoryId: Yup.string().required("Category is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [formData, setFormData] = useState<FormData>({
    question: "",
    answer: "",
    categoryId: null,
  });

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  useEffect(() => {
    if (editId) {
      const fetchData = async () => {
        try {
          const res = await api.get(resolveUrl(AdminApi.FAQS_VIEW, editId));
          const { question, answer, id, categoryId } = res.data.data;

          setFormData({
            question: question ?? "",
            answer: answer ?? "",
            id: id,
            categoryId: categoryId ?? "",
          });
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, [editId]);

  const [catList, setCatList] = useState<any>([]);

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const res = await api.get(AdminApi.FAQS_CAT_LIST);
        setCatList(res.data.categories);
      } catch (error) {
        console.error(error);
      }
    };
    fetchListData();
  }, [open]);

  useEffect(() => {
    if (!open) {
      setFormData({
        question: "",
        answer: "",
        categoryId: null,
      });
    }
  }, [open]);

  const handleSelectCategory = (
    event: any
  ) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      categoryId: value as unknown as number,
    });
  };

  const updateUpdated = {
    data: [formData],
  };
  // Handle editor change
  const handleEditorChange = (event: any, editor: any) => {
    const editor_data = editor.getData();
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = editor_data;
    const links = tempDiv.querySelectorAll("a");
    links.forEach((link) => {
      if (link.host !== window.location.host) {
        link.setAttribute("target", "_blank");
      }
    });

    const data = tempDiv.innerHTML;
    setFormData((prevFormData) => ({
      ...prevFormData,
      answer: data,
    }));
  };
  const showHideClassName = open
    ? "faq-popup display-block"
    : "faq-popup display-none";
    
  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  }));
  return (
    <>
      <div className={`${showHideClassName} faqs_form`}>
        <section className="faq-popup-main">
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <Box className={styles.aSCreateTicketMain}>
            <Typography
              variant="h2"
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                paddingTop: "20px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              Add FAQ'S
            </Typography>
            <Box className={styles.aSCTContainer}>
              <Form
                submitUrl={AdminApi.FAQS_SAVE}
                formData={updateUpdated}
                successPath="/admin/cms/faqs"
                validate={customValidation}
                setFormSuccess={setFormSuccess}
              >
                <Box className={styles.aSCTContentDesc}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentDescLabel}
                  >
                    Category
                  </InputLabel>
                  <select
                    className="faq-select"
                    name="categoryId"
                    value={formData.categoryId ?? ''}
                    onChange={(e) => handleSelectCategory(e)}
                  >
                    {catList &&
                      catList.map((category: any) => (
                        <option key={category.id} value={category.id}>
                          {category.title}
                        </option>
                      ))}
                  </select>
                  {errors.categoryId && (
                    <FormHelperText error>{errors.categoryId}</FormHelperText>
                  )}
                </Box>
                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Question
                  </InputLabel>

                  <TextField
                    fullWidth
                    placeholder="Enter Question"
                    value={formData.question}
                    name="question"
                    variant="outlined"
                    onChange={handleOnChange}
                    className={styles.aSCTContentTitleInput}
                    error={errors.question !== undefined}
                    helperText={errors.question}
                  />
                </Box>
                <Box className={styles.aSCTContentDesc}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentDescLabel}
                  >
                    Answer
                  </InputLabel>
                  {/* <Textarea
                    placeholder="Answer"
                    name="answer"
                    value={formData.answer}
                    minRows={4}
                    maxRows={4}
                    onChange={handleOnChange}
                    className={styles.aSCTContentDescInput}
                  /> */}
                  <div>
                    <CKEditorComponent
                      initialValue={formData.answer}
                      onChange={handleEditorChange}
                    />
                    {errors.answer && (
                      <FormHelperText error>{errors.answer}</FormHelperText>
                    )}
                  </div>
                </Box>
              </Form>
            </Box>
          </Box>
        </section>
      </div>
    </>
  );
}
