import { FormHelperText, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Form from "./forms/Form";
import { AdminApi } from "../../../../config/apis";
import AssinTeacher from "./assinTeacher";
import CourseContent from "./CourseContent";
import EditCourseInfo from "./EditCourseInfo";
import { useCourseFormData } from "../coureseState";
import { useState } from "react";
import * as Yup from "yup";

export default function EditCourses() {
  const params = useParams();
  const { formData, setFormData } = useCourseFormData();

  const validationSchema = Yup.object().shape({
    teacherId: Yup.string().required("Teacher is required"),
    year: Yup.string()
      .matches(
        /^\d{4}-\d{4}$/,
        "Invalid year format. Please use the format 'XXXX-XXXX'"
      )
      .required("Year is required"),
    price: Yup.string().required("Price is required"),
    description: Yup.string().required("Description is required"),
    name: Yup.string().required("Subject Title is required"),
    demo: Yup.mixed()
      .nullable()
      .test("is-video", "File must be a video", function (value) {
        if (!value) return true;
        if (!(value instanceof File)) return true;
        const allowedVideoTypes = [
          "video/mp4",
          "video/mpeg",
          "video/quicktime",
        ];
        return allowedVideoTypes.includes(value.type);
      }),

    // sections: Yup.array()
    //   .min(1, "At least one sections is required")
    //   .of(
    //     Yup.object().test(
    //       "has-dynamic-array",
    //       "At least one Content is required",
    //       function (obj) {
    //         return Object.values(obj).some(
    //           (value) => Array.isArray(value) && value.length >= 1
    //         );
    //       }
    //     )
    //   )
    //   .required("Sections is required"),
  });

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  return (
    <>
      <Typography variant="h1" className="main-heading">
        {params.id ? "Edit Subject" : "Add Subject"}
      </Typography>
      <Grid container className="page-container edit_subject">
        <Grid item xs={12} className="inner-container">
          <Form
            submitUrl={AdminApi.COURSES_SAVE}
            formData={formData}
            editId={params.id}
            successPath="/admin/courses"
            multipart={true}
            validate={customValidation}
            showProgress={true}
          >
            <EditCourseInfo
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
            />

            <CourseContent
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
            />
            <AssinTeacher
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
            />
          </Form>
        </Grid>
      </Grid>
    </>
  );
}
