import { useEffect, useState } from "react";
import styles from "./studnetCourses.module.scss";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  TextField,
  Typography,
  CardContent,
  CardMedia,
  Card,
  Avatar,
} from "@mui/material";
import Datatable from "../../../../../../../components/dataTables/Datatable";
import { useAuth } from "../../../../../../../hooks/auth/auth";
import { AdminApi } from "../../../../../../../config/apis/admin";
import { resolveUrl } from "../../../../../../../config/apis";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import playvideo from "../../../../../../../static/images/play-img.png";
import {
  GetUserName,
  GetUserRole,
  getAssinTeachers,
} from "../../../../../../../helper/helperFunction";
import { useParams } from "react-router-dom";
import { SERVER_KEY } from "../../../../../../../config/constants/EnviormentVariables";
import ProgressCourseCell from "../../../../../../../components/BucketProgressBarSubject";
import { useStudentId } from "../../../../../../../context/getStudentId";

interface Teacher {
  id: string;
  name: string;
}

interface TeacherName {
  id: number;
  first_name: string;
  last_name: string;
}

interface Course {
  id: number;
  name: string;
  teacher: TeacherName;
  totalLessons: any;
  progress: any;
  featured_image?: string;
  teacherProfile?: string;
}

const StudentCourseList = ({ filterState }: any) => {
  const auth = useAuth();

  const { REACT_APP_SERVER_BASE } = process.env;

  const { studentId } = useStudentId();

  // Status
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#071836" : "#308fe8",
    },
  }));

  const navigate = useNavigate();

  const handleClick = (id: string) => {
    navigate(`/admin/student-courses/view/${id}`);
  };

  const baseUrl = SERVER_KEY;

  const tableColumns = [
    {
      cell: (row: any) => (
        <>
          <ProgressCourseCell
            name={row.name}
            progress={row.progress != null ? row.progress : 0}
            image={row.featured_image}
          />
        </>
      ),
      name: "Subject Name",
      sortable: false,
      width: "350px",
    },
    // {
    //   cell: (row: Course) => (
    //     <Grid container className={styles.courseTableColCourseNameContainer}>
    //       <Grid item className={styles.courseTableColCourseNameImgGrid}>
    //         <CardMedia
    //           component="img"
    //           className={styles.courseTableColCourseNameImg}
    //           image={`${
    //             row.featured_image ? baseUrl + row.featured_image : playvideo
    //           }`}
    //           alt="green iguana"
    //         />
    //       </Grid>
    //       <Grid item className={styles.courseTableColCourseNameTextGrid}>
    //         <Grid
    //           container
    //           className={styles.courseTableColCourseNameTextContent}
    //         >
    //           <Grid
    //             item
    //             xs={12}
    //             className={styles.courseTableColCourseNameTextTitle}
    //           >
    //             <Typography
    //               variant="h5"
    //               component="h2"
    //               className={styles.courseTableColCourseNameTitle}
    //             >
    //               {row.name}
    //             </Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={6}
    //             className={styles.courseTableColCourseNameTextContentProgress}
    //           >
    //             <Typography
    //               variant="body1"
    //               className={styles.courseTableColCourseNameProgress}
    //             >
    //               Progress
    //             </Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={6}
    //             className={styles.courseTableColCourseNameTextContentPercent}
    //           >
    //             <Typography
    //               variant="body1"
    //               className={styles.courseTableColCourseNamePercent}
    //             >
    //               {row.progress}%
    //             </Typography>
    //           </Grid>
    //           <Grid
    //             item
    //             xs={12}
    //             className={styles.courseTableColCourseNameTextContentBar}
    //           >
    //             <BorderLinearProgress
    //               variant="determinate"
    //               value={row.progress}
    //               className={styles.courseTableColCourseNameBar}
    //             />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   ),
    //   name: "Subject Name",
    //   width: "430px",
    //   sortable: true,
    // },
    {
      cell: (row: Course) => (
        <Grid container className={styles.courseTableColAssignTeacher}>
          <Grid
            item
            className={styles.courseTableColAssignTeacherContentAvatarContent}
          >
            <Avatar
              className={styles.courseTableColAssignTeacherContentAvatar}
              sx={{ width: 34, height: 34, mr: 0 }}
              alt={GetUserName()}
              src={`${REACT_APP_SERVER_BASE}${row.teacherProfile}`}
            />
          </Grid>
          <Grid
            item
            className={styles.courseTableColAssignTeacherContentNameContent}
          >
            <Typography
              variant="h4"
              className={styles.courseTableColAssignTeacherContentName}
            >
              {row.teacher.first_name} {row.teacher.last_name}
            </Typography>
          </Grid>
        </Grid>
      ),

      name: "Assigned Teacher",
      width: "250px",
      sortable: false,
    },
    {
      cell: (row: Course) => (
        <Grid container className={styles.courseTableColTotalLessons}>
          <Grid item className={styles.courseTableColTotalLessonsContent}>
            <Typography
              variant="h4"
              className={styles.courseTableColTotalLessonsContentText}
            >
              {row.totalLessons}
            </Typography>
          </Grid>
        </Grid>
      ),

      name: "Total Lessons",
      width: "150px",
      sortable: false,
    },
    // {
    //   cell: (row: { hours: number, minutes: number }) => (
    //     <Grid container className={styles.courseTableColTotalTime}>
    //       <Grid item className={styles.courseTableColTotalTimeContent}>
    //       <Typography variant="h4" className={styles.courseTableColTotalTimeContentText}>{((row.hours * 60) + row.minutes) + " min"}</Typography>
    //       </Grid>
    //     </Grid>
    //   ),
    //   name: "Total Time",
    //   width: "150px",
    //   sortable: false,
    // },
    // {
    //   cell: (row: Course) => (
    //     <Grid container className={styles.courseTableColMark}>
    //       <Grid item className={styles.courseTableColMarkContent}>
    //       <Typography variant="h4" className={styles.courseTableColMarkContentText}>Not Market</Typography>
    //       </Grid>
    //     </Grid>
    //   ),
    //   name: "Markes",
    //   width: "230px",
    //   sortable: false,
    // },
    // {
    //   selector: () => "80",
    //   name: "Markes",
    //   width: "10%",
    // },
    // {
    //   cell: (row: { id: any }) => (
    //     <>
    //       <Button
    //         className="dark-btn"
    //         variant="contained"
    //         onClick={() => handleClick(row.id)}
    //       >
    //         Resume
    //       </Button>
    //     </>
    //   ),
    //   name: "",
    //   sortable: false,
    //   width: "155px",
    // },
  ];

  return (
    <>
      <div className="page-container courseDataTable">
        <Datatable
          className={styles.courseDataTable}
          tableColumns={tableColumns}
          dataUrl={resolveUrl(AdminApi.STUDENT_ASSIGN_COURSES, `${studentId}`)}
          actions={{
            view: true,
            edit: false,
            delete: false,
          }}
          filters={filterState}
          actionColumns={false}
          module="manage-kids"
        />
      </div>
    </>
  );
};

export default StudentCourseList;
