import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Autocomplete, Box, InputLabel, TextField } from "@mui/material";
import styles from "./style.module.scss";
import * as Yup from "yup";
import Modal from "@mui/material/Modal";
import { PopupStyle } from "../../../helper/helperFunction";
import SelectOptionsApi from "../../../components/SelectApi";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function AssignCourse({
  open,
  handleClose,
  width,
  setReRender,
}: any) {
  type FormData = {
    courseId?: number | null;
    studentId?: number | null;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    studentId: Yup.string().required("Student is required"),
    courseId: Yup.string().required("Student is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState<any>(null);

  const [formData, setFormData] = useState<FormData>({
    courseId: null,
    studentId: null,
  });

  const handleUpdate = (name: any, value: any) => {
    setFormData((prevFormData: any) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const [selectedStudentQ, setSelectedStudentQ] = useState("a");

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.STUDENT_LIST), {
          params: {
            text: selectedStudentQ,
          },
        });
        setStudents(res.data);
      } catch (error) {
        console.error("Error fetching students", error);
      }
    };

    fetchStudents();
  }, [selectedStudentQ]);

  const handleSelectStudent = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setSelectedStudentQ(target.value);
  };
  const handleAutocompleteChange = (newValue: any) => {
    const { id } = newValue;
    setSelectedStudent(newValue);
    setFormData({
      ...formData,
      studentId: id,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      studentId: undefined,
    }));
  };

  useEffect(() => {
    if (!open) {
      setFormData({
        courseId: null,
        studentId: null,
      });
      setSelectedStudent(null);
    }
  }, [open]);
  const renderClearButton = () => {
    if (!selectedStudent) {
      return null;
    }

    return (
      <IconButton
        aria-label="clear selection"
        onClick={() => handleSelectStudentRemove()}
      >
        <ClearIcon />
      </IconButton>
    );
  };

  const handleSelectStudentRemove = () => {
    setFormData({
      ...formData,
      studentId: null,
    });
    setSelectedStudent(null);
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={PopupStyle} maxWidth={width}>
          <Box className={styles.aSCreateTicketMain}>
            <Box className={styles.aSCTContainer}>
              <Form
                submitUrl={AdminApi.GRANT_SUBJECT}
                formData={formData}
                successPath="/admin/subject-access"
                validate={customValidation}
                setFormSuccess={setReRender}
              >
                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Student Name
                  </InputLabel>
                  <Autocomplete
                    options={students}
                    getOptionLabel={(option: any) => option.name}
                    value={selectedStudent}
                    onChange={(event, newValue: any) =>
                      handleAutocompleteChange(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Enter name"
                        variant="outlined"
                        onChange={handleSelectStudent}
                        className={styles.aSCTContentTitleInput}
                        error={errors.studentId !== undefined}
                        helperText={errors.studentId}
                        InputProps={{
                          ...params.InputProps,
                          style: { fontFamily: "Poppins" },

                          endAdornment: (
                            <>
                              {selectedStudent && (
                                <IconButton
                                  className={styles.clear_btn}
                                  aria-label="clear"
                                  onClick={() => {
                                    handleSelectStudentRemove();
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    ListboxProps={{
                      style: { fontFamily: "Poppins" },
                    }}
                  />
                </Box>
                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Student ID
                  </InputLabel>
                  <Autocomplete
                    options={students}
                    getOptionLabel={(option: any) => option.id}
                    value={selectedStudent}
                    onChange={(event, newValue: any) =>
                      handleAutocompleteChange(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Enter ID"
                        variant="outlined"
                        onChange={handleSelectStudent}
                        className={styles.aSCTContentTitleInput}
                        error={errors.studentId !== undefined}
                        helperText={errors.studentId}
                        InputProps={{
                          ...params.InputProps,
                          style: { fontFamily: "Poppins" },
                          endAdornment: (
                            <>
                              {selectedStudent && (
                                <IconButton
                                  className={styles.clear_btn}
                                  aria-label="clear"
                                  onClick={() => {
                                    handleSelectStudentRemove();
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    ListboxProps={{
                      style: { fontFamily: "Poppins" },
                    }}
                  />
                </Box>

                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Parent Name
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={selectedStudent ? selectedStudent.parent : ""}
                    variant="outlined"
                    className={styles.aSCTContentTitleInput}
                    disabled
                  />
                </Box>

                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Subjects
                  </InputLabel>
                  <SelectOptionsApi
                    apiEndpoint={AdminApi.SUBJECT_LIST}
                    formData={formData}
                    onUpdate={handleUpdate}
                    labelKey="name"
                    name="courseId"
                    valueKey="id"
                    defaultText="Select Option"
                    errorText={errors.courseId}
                  />
                </Box>
              </Form>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
