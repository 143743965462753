import { useState } from "react";
import { CourseData } from "../../../Type/courseTypes";

export const useCourseFormData = () => {
  const [formData, setFormData] = useState<CourseData>({
    name: "",
    have_live_quiz: false,
    price: "",
    year: "",
    description: "",
    sections: [],
    teacherId: "",
    secID: [],
    contentID: [],
    version: undefined,
  });

  return { formData, setFormData };
};
