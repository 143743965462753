import { useEffect, useState } from "react";
// import HomeSchooling from "../home-schooling";
// // import HomeBanner from "../homeBanner";
// import OurPrograms from "../our-programs";
// import SampleLessons from "../sample-lessons";
// import StudyEdexOnline from "../study-edexOnline";
import { FrontendApi, api, resolveUrl } from "../../../config/apis";
import { SampleCourse, Slide, HomePageContentInfo } from "../type/type";
import AOS from "aos";
import "aos/dist/aos.css";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../static/css/style.scss";
// import Layout from "../../../layout/frontend/Layout";
import { Helmet, HelmetProvider } from "react-helmet-async";
import track from "react-tracking";
import React from "react";

const Layout = React.lazy(() => import("../../../layout/frontend/Layout"));
const HomeBanner = React.lazy(() => import("../homeBanner"));
const HomeSchooling = React.lazy(() => import("../home-schooling"));
const OurPrograms = React.lazy(() => import("../our-programs"));
const SampleLessons = React.lazy(() => import("../sample-lessons"));
const StudyEdexOnline = React.lazy(() => import("../study-edexOnline"));

const HomePage = function () {
  // Initialize AOS
  AOS.init();

  const [sampleCourse, setSampleCourse] = useState<SampleCourse[]>([]);
  const [studyOnline, setStudyOnline] = useState<Slide[]>([]);
  const [ourPrograms, setOurPrograms] = useState<Slide[]>([]);
  const [schooling, setSchooling] = useState<Slide[]>();

  const [homeBanner, setBanner] = useState<HomePageContentInfo>();
  const [enrollNowInfo, setEnrollNowInfo] = useState<HomePageContentInfo>();
  const [ourProgramsInfo, setOurProgramsInfo] = useState<HomePageContentInfo>();
  const [schoolingInfo, setSchoolingInfo] = useState<HomePageContentInfo>();
  const [studyOnlineInfo, setStudyOnlineInfo] = useState<HomePageContentInfo>();
  const [sampleCourseInfo, setSampleCourseInfo] =
    useState<HomePageContentInfo>();

  type TmetaData = {
    title: string;
    description: string;
    keywords: string;
    image: string;
  };

  const [loading, setLoading] = useState(true);
  const [metaData, setmetaData] = useState<TmetaData>();

  const { REACT_APP_SERVER_BASE } = process.env;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(FrontendApi.HOME_PAGE_CMS);
        setSampleCourse(res.data.HomePageContent.SampleCourse);
        setStudyOnline(res.data.HomePageContent.StudyOnline);
        setOurPrograms(res.data.HomePageContent.OurPrograms);
        setSchooling(res.data.HomePageContent.Schooling);
        setBanner(res.data.HomePageInfo.Banner);
        setStudyOnlineInfo(res.data.HomePageInfo.StudyOnline);
        setSchoolingInfo(res.data.HomePageInfo.Schooling);
        setOurProgramsInfo(res.data.HomePageInfo.OurPorgrams);
        setEnrollNowInfo(res.data.HomePageInfo.EnrollNow);
        setSampleCourseInfo(res.data.HomePageInfo.SampleCourse);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const res = await api.get(
          resolveUrl(FrontendApi.META_TAGS_VIEW, "home-page")
        );
        setmetaData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMetaData();
  }, []);

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      </>
    );
  }
  const imageUrl = `${REACT_APP_SERVER_BASE}${metaData?.image}`;

  return (
    <>
      <Layout enrollNowInfo={enrollNowInfo}>
        <HelmetProvider>
          <Helmet>
            <title>{metaData && metaData.title}</title>
            <meta
              name="description"
              content={metaData && metaData.description}
            />
            <meta name="keywords" content={metaData && metaData.keywords} />
            <meta property="og:image" content={metaData && imageUrl} />
            <meta property="og:image:width" content="2500" />
            <meta property="og:image:height" content="933" />
            <meta property="og:image:type" content="image/jpeg" />

            <meta
              property="og:image:alt"
              content={metaData && metaData.title}
            />
            <meta
              property="og:description"
              content={metaData && metaData.description}
            />
            <meta property="og:title" content={metaData && metaData.title} />

            {/* <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:site" content="@exampletwitter" />
                        <meta name="twitter:title" content="Mountain Sunset" />
                        <meta name="twitter:description" content="A beautiful sunset over the mountains" />
                        <meta name="twitter:image" content="https://example.com/path/to/image.jpg" /> */}
          </Helmet>
        </HelmetProvider>
        <HomeBanner banner={homeBanner} />
        <HomeSchooling schooling={schooling} schoolingInfo={schoolingInfo} />
        <OurPrograms
          ourPrograms={ourPrograms}
          ourProgramsInfo={ourProgramsInfo}
        />
        <StudyEdexOnline
          studyOnlineInfo={studyOnlineInfo}
          studyOnline={studyOnline}
        />
        <SampleLessons
          sampleCourse={sampleCourse}
          sampleCourseInfo={sampleCourseInfo}
        />
      </Layout>
    </>
  );
};
export const TrackedHome = track(
  { page: "Home" },
  { dispatchOnMount: (contextData: any) => ({ event: "pageLoaded" }) }
)(HomePage);

export default HomePage;
