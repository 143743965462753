import Card from "../../../layout/admin/partials/Card";
import Degrecap from "../../../../src/static/images/degree-cap.png";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { getCourseView } from "../../../helper/helperFunction";
import { useCallback, useEffect, useState } from "react";

export default function CourseCard<PROPS>({ ...props }: PROPS): JSX.Element {
  const params = useParams<{ id: string }>();
  const [CardData, setCardData] = useState<any>();

  const fetchCourseViewData = useCallback(async () => {
    try {
      const res = await getCourseView(params.id ?? "");

      setCardData(res.course);
    } catch (error) {
      // Handle error here
    }
  }, [params.id]);

  useEffect(() => {
    fetchCourseViewData();
  }, [fetchCourseViewData]);

  const cards_Data = [
    {
      heading: CardData?.kidsEnrolled,
      paragraph: "Enrolled Student",
      imageUrl: Degrecap,
    },
    {
      heading: CardData?.activeLearner,
      paragraph: "Active Students",
      imageUrl: Degrecap,
    },
    {
      heading: CardData?.completed,
      paragraph: "Completed",
      imageUrl: Degrecap,
    },
  ];

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Card cardItem={cards_Data} />
      </Grid>
    </>
  );
}
