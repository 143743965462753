import styles from "../parentDashboard.module.scss";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import CourseAvgComp from "./parent-dashboard-components/Course-score";
import NoticeBoard from "../../manage-notice-board/noticeBoard";
import { Link } from "react-router-dom";
import ViewCourseCatalog from "../../course-catalog/view";
import StudentCard from "../manage-kids/manage-all-kids/view";
import GlobalPopupManager from "../../../../components/parentModal";
import { useState, useEffect } from "react";

const ParentDashboard = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
            width: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#001c28" }} />
        </div>
      )}
      <div style={loading ? { display: "none" } : {}}>
        <Box className={styles.studentDashboardMain}>
          <Grid
            container
            // spacing={"30px"}
            className={styles.studentDashboardGrid}
          >
            <Grid item xs={12} className={styles.studentDashboardScore}>
              <CourseAvgComp />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              // lg={8.18}
              lg={12}
              className={styles.studentDashboardCourse}
            >
              <Grid container className={styles.studentDashboardCourseGrid}>
                <Grid
                  item
                  xs={12}
                  className={styles.studentDashboardCourseTitle}
                >
                  <Typography
                    variant="h2"
                    className={styles.studentDashboardCourseTitleContent}
                  >
                    Students Enrolled
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={styles.studentDashboardCardWrap}>
                <Grid
                  xs={12}
                  md={12}
                  lg={8.18}
                  className={styles.studentDashboardCourseLink}
                >
                  <StudentCard />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={3.82}
                  className={styles.studentDashboardNoticeBoard}
                >
                  <Box>
                    <NoticeBoard />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <GlobalPopupManager />
        </Box>
      </div>
    </>
  );
};

export default ParentDashboard;
