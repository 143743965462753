import { Typography, Box } from "@mui/material";
import ImgHead1 from "./asset/img/lessons-1.jpg";
import ImgHead2 from "./asset/img/lessons-2.jpg";
import ImgHead3 from "./asset/img/lessons-3.jpg";
import "./style/style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HomePageContentInfo, SampleCourse } from "../type/type";
import { Link } from "react-router-dom";
import ImageViewer from "../../../components/imageViewer";
import Modal from "@mui/material/Modal";
import { useState } from "react";

interface SampleLessonsProps {
  sampleCourse: SampleCourse[];
  sampleCourseInfo?: HomePageContentInfo;
}

const SampleLessons = function ({
  sampleCourse,
  sampleCourseInfo,
}: SampleLessonsProps) {
  const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1167,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 981,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 681,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState<string | null>("");

  const handleClose = () => setOpen(false);

  const showVideo = (url: string | null) => {
    if (!url) {
      // Handle null or undefined input
      return;
    }

    const videoUrl = new URL(url);
    const pathname = videoUrl.pathname;
    const searchParams = new URLSearchParams(videoUrl.search);

    let videoID = "";

    if (pathname === "/watch") {
      videoID = searchParams.get("v") || "";
    } else {
      const parts = pathname.split("/");
      videoID = parts[parts.length - 1];
    }

    // Check if a valid videoID was found
    if (videoID) {
      setVideo(videoID);
      setOpen(true);
    } else {
      // Handle the case where the videoID couldn't be extracted
      console.error("Invalid YouTube URL");
    }
  };

  const PopupStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    width: "100%",
    lineHeight: "0",
    maxHeight: "90vh",
    overflowY: "auto",
  };

  return (
    <>
      <div className="sampleLessonsMain">
        <div className="container">
          <Typography
            variant="h2"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            {sampleCourseInfo && sampleCourseInfo.title}
          </Typography>
          <div className="lessonCardContain">
            <Slider {...sliderSettings}>
              {sampleCourse &&
                sampleCourse.map((item) => {
                  return (
                    // <div className="lCard" data-aos="flip-left" data-aos-duration="1500">
                    <div className="lCard">
                      <ImageViewer
                        imagePath={item.image ?? ""}
                        altText={item.title}
                      />
                      <div className="textContain">
                        <Typography variant="body1">{item.title}</Typography>
                        <Link
                          to="#"
                          className="loginBtn"
                          onClick={() => {
                            showVideo(item.buttonURL ?? "");
                          }}
                        >
                          {item.buttonText ?? "Start Now"}
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={PopupStyle} maxWidth={700}>
          <iframe
            style={{ border: "0" }}
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/${video ?? ""}`}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Box>
      </Modal>
    </>
  );
};

export default SampleLessons;
