import { Box } from "@mui/material";
import DynamicTabs from "../../../components/tabs/Tabs";
import GlobalCMSPopup from "../parent-form-popup";
import EditRegistrationEmail from "../manage-email-registration/edit";

const EmailsCms = () => {
  const tabs = [
    {
      label: "Parent Registration",
      content: (
        <EditRegistrationEmail
          type="parent-signup"
          heading="Parent Registration"
        />
      ),
    },
    {
      label: "Student Registration",
      content: (
        <EditRegistrationEmail
          type="student-signup"
          heading="Student Registration"
        />
      ),
    },
    {
      label: "Teacher Registration",
      content: (
        <EditRegistrationEmail
          type="teacher-signup"
          heading="Teacher Registration"
        />
      ),
    },
    {
      label: "Subject Completion",
      content: (
        <EditRegistrationEmail
          type="subject-completion"
          heading="Subject Completion"
        />
      ),
    },
  ];

  return (
    <>
      <h1>Emails</h1>
      <Box className="setting-tabs-container">
        <DynamicTabs tabs={tabs} />
      </Box>
    </>
  );
};
export default EmailsCms;
