import React, { useState, useCallback, useEffect } from "react";
import Form from "../../../../components/forms/Form";
import { FormEvent } from "react";
import { Box, InputLabel, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Textarea from "@mui/joy/Textarea";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { useAuth } from "../../../../hooks/auth/auth";
import { useLocation, useParams } from "react-router-dom";
import { getThreads } from "../../../../helper/helperFunction";

interface Comment {
  user_id: string;
  thread?: string;
  comment: string;
  comment_parent?: number | null;
}
interface Props {
  thread?: string;
  comment_parent?: number | null;
  setFormSuccess: any;
  formSuccess?: any;
  setCommentFormHide?: any;
}

const AddComment: React.FC<Props> = ({
  thread,
  comment_parent,
  setFormSuccess,
  setCommentFormHide,
  formSuccess,
}) => {
  const params = useParams();

  const user = useAuth();

  const defaultFormData: Comment = {
    user_id: user?.state?.user?.id,
    thread: thread,
    comment: "",
    comment_parent: comment_parent,
  };

  const [formData, setFormData] = useState<Comment>(defaultFormData);
  const location = useLocation();

  useEffect(() => {
    setFormData({
      ...formData,
      comment_parent: comment_parent,
    });
  }, [comment_parent, formData]);

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    setFormData(defaultFormData);
    setCommentFormHide(true);
  }, [formSuccess]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container className="page-container" sx={{ p: 0 }}>
        <Grid item xs={12} className="inner-container" marginTop={2}>
          <Form
            submitUrl={AdminApi.COMMENT_SAVE}
            formData={formData}
            successPath={location}
            setFormSuccess={setFormSuccess}
            saveText="Send"
          >
            <Grid item xs={12} container sx={{ mb: 3 }}>
              <Grid item xs={2}>
                <InputLabel
                  sx={{
                    pt: 2,
                    fontWeight: "700",
                    fontSize: "21px",
                    color: "#071836",
                    marginBottom: "10px",
                  }}
                >
                  Quick Reply
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Textarea
                  placeholder="Add a comment"
                  name="comment"
                  minRows={4}
                  maxRows={4}
                  // defaultValue={payload.comment}
                  value={formData.comment}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddComment;
