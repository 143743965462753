import { Box, Modal, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from "./manage-discount.module.scss";
import DiscountForm from "./edit";

type DiscountFormModalProps = {
  open: boolean;
  id: string;
  closeModal: () => void;
  setFormSuccess: (value: boolean) => void;
};

const DiscountFormModal: React.FC<DiscountFormModalProps> = ({
  open,
  id,
  closeModal,
  setFormSuccess,
}) => {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box className={styles.popup}>
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={closeModal}
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <Close />
        </IconButton>
        <DiscountForm id={id} setFormSuccess={setFormSuccess} />
      </Box>
    </Modal>
  );
};

export default DiscountFormModal;
