import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api } from "../../../config/apis";
import {
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import { useParams } from "react-router-dom";
import styles from "./style.module.scss";
import * as Yup from "yup";
import ImageUpload from "../../../components/UploadImage";

export default function EnrollNowHeading() {
  const params = useParams();

  type FormData = {
    title: string;
    description: string;
    image?: File | null;
  };

  const [errors, setErrors] = useState<any>({});

  const MAX_TITLE_LENGTH = 50;
  const MAX_DESCRIPTION_LENGTH = 200;

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .max(
        MAX_TITLE_LENGTH,
        `Title must be at most ${MAX_TITLE_LENGTH} characters`
      ),
    description: Yup.string()
      .required("Description is required")
      .max(
        MAX_DESCRIPTION_LENGTH,
        `Description must be at most ${MAX_DESCRIPTION_LENGTH} characters`
      ),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    image: null,
    
  });



  
  const [onSucces, setOnSusses] = useState<any>();

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  useEffect(() => {
    const fetchDta = async () => {
      try {
        const res = await api.get(AdminApi.ENROLL_NOW_INFO_VIEW);
        setFormData({
          title: res.data.viewHomeEnroll.title ?? "",
          description: res.data.viewHomeEnroll.description ?? "",
        });
        setUploadPreviewProp(res.data.viewHomeEnroll.image);

      } catch (error) {
        console.error(error);
      }
    };
    fetchDta();
  }, [onSucces]);
  const [uploadPreviewProp, setUploadPreviewProp] = useState(null);

  const handleIDImageChange = (name: any, selectedFile: any) => {
    setFormData({
      ...formData,
      image: selectedFile,
    });
  };
  return (
    <Box className={styles.aSCreateTicketMain}>
      <Box className={styles.aSCTContainer}>
        <Form
          submitUrl={AdminApi.ENROLL_NOW_INFO_SAVE}
          formData={formData}
          editId={params.id}
          successPath="/admin/cms/contact-us"
          validate={customValidation}
          onSucces={setOnSusses}
          multipart={true}

        >
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Title
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Title"
              value={formData.title}
              name="title"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.title !== undefined}
              helperText={errors.title}
            />
          </Box>
          <Box className={styles.aSCTContentDesc}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentDescLabel}
            >
              Description
            </InputLabel>
            <Textarea
              placeholder="Description"
              name="description"
              value={formData.description}
              minRows={4}
              maxRows={4}
              onChange={handleOnChange}
              className={styles.aSCTContentDescInput}
            />
            {errors.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Box>
          <Box
            className={`${styles.aSCTContentType} identity_picture home_admin`}
          >
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTypeLabel}
            >
              Image
            </InputLabel>
            <ImageUpload
              name="image"
              label="Upload Image"
              previewWidth={200}
              maxFileSize={5242880}
              onChange={handleIDImageChange}
              uploadPreviewProp={uploadPreviewProp}
              isRequired={true}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}
