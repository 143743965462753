import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { api, AdminApi, resolveUrl } from "../../../../../../../config/apis";
// import { LineChart } from "@mui/x-charts";
import style from "../kisds.module.css";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { useStudentId } from "../../../../../../../context/getStudentId";

export const StudentLoginTimeChart = () => {
  const { studentId } = useStudentId();
  const getCurrentMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
    return currentMonth;
  };
  const currentMonth = getCurrentMonth();

  const [months, setMonths] = useState<number[]>([]);
  const [activeTime, setActiveTime] = useState<number[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<any>(currentMonth);
  const [selectedOption, setSelectedOption] = useState<string>("monthly");
  const currentYear = new Date().getFullYear();

  const handleMonthChange = (event: SelectChangeEvent<number | string>) => {
    setSelectedMonth(event.target.value);
  };

  const handleOptionChange = (event: SelectChangeEvent<number | string>) => {
    const selectedValue = event.target.value as string;
    setSelectedOption(selectedValue);
  };

  function timeStringToSeconds(timeString: any) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    // Calculate the total seconds
    return hours * 3600 + minutes * 60 + seconds;
  }

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        let paramsApi: any = {
          id: studentId,
          year: currentYear,
        };

        if (selectedOption === "monthly") {
          paramsApi.month = selectedMonth;
        }

        const StudentTracking = await api.get(
          resolveUrl(AdminApi.TIME_TRACKING_STUDENT_REPORT),
          {
            params: paramsApi,
          }
        );
        const months = Object.keys(StudentTracking.data.activeTime).map(
          (month) => parseInt(month) + 1
        );
        setMonths(months);

        const active_Time = Object.keys(StudentTracking.data.activeTime).map(
          (key) => {
            const timeString = StudentTracking.data.activeTime[key];
            const totalSeconds = timeStringToSeconds(timeString);
            return totalSeconds;
          }
        );

        setActiveTime(active_Time ?? []);

        setIsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchGraphData();
  }, [currentYear, studentId, selectedMonth, selectedOption]);

  const year_count = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data: any = {
    labels: selectedOption === "yearly" ? year_count : months,
    datasets: [
      {
        label: "Active Time",
        data: activeTime ?? [],
        borderColor: "#a3915e",
        backgroundColor: "#a3915e",
        borderWidth: 2,
        pointRadius: 0,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        borderJoinStyle: "round",
        tension: 0.5,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 31,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          callback: function (value: any) {
            if (value >= 3600) {
              const hours = Math.floor(value / 3600);
              return `${hours}h`;
            } else if (value >= 60) {
              const minutes = Math.floor(value / 60);
              return `${minutes}m`;
            } else {
              return `${value}s`;
            }
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const value = context.parsed.y;
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            const seconds = value % 60;
            return `${hours ? hours + "h" : ""} ${
              minutes ? minutes + "m" : ""
            } ${seconds ? seconds + "s" : ""}`;
          },
          title: function (context: any) {
            if (selectedOption === "monthly") {
              return `Date: ${context[0].label} ${
                year_count[selectedMonth - 1]
              } `; // Show date in monthly view
            } else {
              return `Month: ${year_count[context[0].dataIndex]}`; // Show month in yearly view
            }
          },
        },
      },
    },
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <Box
            className={style.chart_wrap}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                className={style.chart_title}
                variant="h5"
                gutterBottom
              >
                Time Spent
              </Typography>
            </Box>
            <Box className={style.chart_select_wrap}>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                sx={{
                  "& .MuiSelect-select": {
                    padding: "10.5px 14px",
                  },
                }}
              >
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
              {selectedOption === "yearly" ? (
                <Select
                  value={currentYear}
                  disabled
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10.5px 14px",
                    },
                  }}
                >
                  <MenuItem value={currentYear}>{currentYear}</MenuItem>
                </Select>
              ) : (
                <Select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10.5px 14px",
                    },
                  }}
                >
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              )}
            </Box>
          </Box>

          {isDataLoaded ? (
            activeTime.length > 0 ? (
              <Chart type="bar" data={data} options={options} />
            ) : (
              <Typography>There are no records to display</Typography>
            )
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 350,
              }}
            >
              <CircularProgress sx={{ color: "#d6c38f" }} />
            </div>
          )}
        </Box>
      </Grid>
    </>
  );
};
