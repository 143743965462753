import { Box, Typography } from "@mui/material";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Link, useNavigate } from "react-router-dom";
import OurProgramsHeading from "./AddSectionTitle";
import ImageView from "../../../components/imageShow";
import { truncateTextWithEllipsis } from "../../../helper/helperFunction";
import EditOurPrograms from "./edit";
import { useEffect, useState } from "react";

const OurPrograms = () => {
  const navigate = useNavigate();

  const tableColumns = [
    {
      cell: (row: { title: string }) => row.title ?? "N/A",
      name: "Title",
      sortable: false,
    },

    {
      cell: (row: { description: string }) =>
        row.description && truncateTextWithEllipsis(row.description ?? "", 20),
      name: "Description",
      sortable: false,
      width: "500px",
    },

    {
      cell: (row: { image: string }) => (
        <>
          <ImageView imageName={row.image} />
        </>
      ),
      name: "Image",
      sortable: false,
    },
  ];

  const onEdit = async (id?: string) => {
    setEditId(id);
    setOpen(true);
  };

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.OUR_PROGRAMS_TABLE_DEL, id));
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [editId, setEditId] = useState<string>();

  useEffect(() => {
    setReRender(formSuccess);
    setOpen(false);
  }, [formSuccess]);

  useEffect(() => {
    if (!open) {
      setEditId("");
    }
  }, [open]);

  return (
    <>
      <Box padding="20px 30px 20px 30px">
        <Typography
          variant="h2"
          sx={{
            fontSize: "40px",
            fontWeight: "700",
            paddingTop: "20px",
            paddingBottom: "10px",
            textAlign: "center",
          }}
        >
          Our Programs
        </Typography>
        <OurProgramsHeading />
        <Box className="page-container">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.OUR_PROGRAMS_TABLE_PAGINATION}
            onEdit={onEdit}
            onDelete={onDelete}
            actions={{
              view: false,
            }}
            module="manage-CMS"
            // rowsPerPage={5}
            refreshHandler={reRender}
          />
        </Box>
        <Box sx={{ width: "150px", marginLeft: "auto" }}>
          <Link
            className="btn"
            to="#"
            onClick={() => {
              setOpen(true);
            }}
          >
            Add
          </Link>
        </Box>
      </Box>
      <EditOurPrograms
        handleClose={handleClose}
        open={open}
        setFormSuccess={setFormSuccess}
        width="800px"
        editId={editId}
      />
    </>
  );
};
export default OurPrograms;
