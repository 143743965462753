import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Box, InputLabel, TextField, Typography } from "@mui/material";
import styles from "./style.module.scss";
import * as Yup from "yup";
import Modal from "@mui/material/Modal";
import { PopupStyle } from "../../../helper/helperFunction";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function EditFAQSCat({
  open,
  handleClose,
  width,
  setFormSuccess,
  editId,
}: any) {
  type FormData = {
    title?: string;
    id?: number | null;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [formData, setFormData] = useState<FormData>({
    title: "",
  });

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  useEffect(() => {
    if (editId) {
      const fetchData = async () => {
        try {
          const res = await api.get(resolveUrl(AdminApi.FAQS_CAT_VIEW, editId));
          const { title, id } = res.data.data;
          setFormData({
            title: title ?? "",
            id: id,
          });
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
  }, [editId]);

  useEffect(() => {
    if (!open) {
      setFormData({
        title: "",
      });
    }
  }, [open]);
  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  }));
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={PopupStyle} maxWidth={width}>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <Box className={styles.aSCreateTicketMain}>
            <Typography
              variant="h2"
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                paddingTop: "20px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              Add Category
            </Typography>

            <Box className={styles.aSCTContainer}>
              <Form
                submitUrl={AdminApi.FAQS_CAT_SAVE}
                formData={formData}
                successPath="/admin/cms/faqs"
                validate={customValidation}
                setFormSuccess={setFormSuccess}
              >
                <Box className={styles.aSCTContentTitle}>
                  <InputLabel
                    sx={{ textAlign: "left" }}
                    className={styles.aSCTContentTitleLabel}
                  >
                    Title
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter Title"
                    value={formData.title}
                    name="title"
                    variant="outlined"
                    onChange={handleOnChange}
                    className={styles.aSCTContentTitleInput}
                    error={errors.title !== undefined}
                    helperText={errors.title}
                  />
                </Box>
              </Form>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
