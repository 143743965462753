import React, { useState, useCallback, useEffect } from "react";
import Form from "../../../components/forms/Form";
import { FormEvent } from "react";
import { Box, FormHelperText, InputLabel, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Textarea from "@mui/joy/Textarea";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../../../hooks/auth/auth";
import { useParams } from "react-router-dom";
import validationSchema from "./validation";

type CourseData = {
  SelectCourse?: [];
};

type Category = {
  id: string;
  name: string;
};
type TopicData = {
  type?: string;
  type_value?: number[];
  topic?: string | undefined;
  description?: string;
  tags?: string[];
  creatorId?: number;
  moderate?: true;
  category: string[];
};

export default function AddForum() {
  const params = useParams();

  const user = useAuth();
  const [formData, setFormData] = useState<TopicData>({
    type: "",
    type_value: [],
    topic: undefined,
    description: "",
    tags: [],
    creatorId: user?.state?.user?.id,
    moderate: true,
    category: [],
  });

  const [forumId, setForumId] = useState(null);

  const [CoursesData, setCoursesData] = useState<CourseData>({});

  const [categoryData, setCategoryData] = useState<Category[]>([]);

  const selectCourse = useCallback(async () => {
    const course = await api.get(AdminApi.GET_COURSES);

    setCoursesData({
      SelectCourse: course.data.Courses,
    });
  }, []);

  useEffect(() => {
    selectCourse();
  }, [selectCourse]);

  const fetchCatagories = useCallback(async () => {
    const res = await api.get(resolveUrl(AdminApi.CATEGORY_LIST));

    setCategoryData(res.data);
  }, []);

  const fetchData = useCallback(async () => {
    const res = await api.get(resolveUrl(AdminApi.FORUM_VIEW, params.id));
    setFormData({
      type: res.data.forum.type,
      type_value: [],
      topic: res.data.forum.topic,
      description: res.data.forum.description,
      tags: res.data.forum.tags ?? [],
      creatorId: user?.state?.user?.id,
      moderate: true,
      category: res.data.forum.categoriesIds ?? [],
    });
    setForumId(res.data.forum.id);
  }, [params.id]);

  useEffect(() => {
    fetchCatagories();
  }, [fetchCatagories]);

  useEffect(() => {
    if (params.id) {
      fetchData();
    }
  }, [params.id]);

  const [showCourses, setshowCourses] = useState(false);

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };
  const handleChangeTags = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tags = event.target.value.split(",");

    setFormData({ ...formData, tags });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      tags: undefined,
    }));
  };
  const handleOnTypeChange = (
    event: SelectChangeEvent<string | boolean>,
    child: React.ReactNode
  ) => {
    const target = event.target.value as string | boolean;
    if (target === "Course") {
      setshowCourses(true);
    } else {
      setshowCourses(false);
    }
    setFormData({
      ...formData,
      [event.target.name]: target,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };
  const handleOnCourseChange = (
    event: SelectChangeEvent<string | boolean>,
    child: React.ReactNode
  ) => {
    const target: any = event.target.value;
    setFormData({
      ...formData,
      type_value: [target],
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      type_value: undefined,
    }));
  };
  const handleSelectCategory = (
    event: SelectChangeEvent<typeof formData.category>
  ) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      category: value as string[],
    });

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      category: undefined,
    }));
  };

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error: any) {
      const newErrors: any = {};
      if (error.inner) {
        error.inner.forEach((validationError: any) => {
          newErrors[validationError.path] = validationError.message;
        });
      } else {
        console.error(error);
      }

      setErrors(newErrors);
      return false;
    }
  };

  // ------------
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <h1 className="main-heading">
          {params.id ? "Edit Forum" : "Add Forum"}
        </h1>
      </Box>

      <Grid container className="page-container add_forum_yi">
        <Grid item xs={12} className="inner-container">
          <Form
            submitUrl={AdminApi.FORUM_SAVE}
            formData={formData}
            editId={forumId ?? ""}
            successPath="/admin/forum"
            validate={customValidation}
          >
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Type</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  name="type"
                  value={formData.type}
                  sx={{ Width: "100%" }}
                  onChange={handleOnTypeChange}
                  defaultValue="slected"
                >
                  <MenuItem value="slected">Select Any</MenuItem>
                  {["General", "Course"].map((items: any, i) => {
                    return <MenuItem value={items}>{items}</MenuItem>;
                  })}
                </Select>
                {errors.type && (
                  <FormHelperText error>{errors.type}</FormHelperText>
                )}
              </Grid>
            </Grid>
            {showCourses && (
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Select Subject
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    fullWidth
                    name="type_value"
                    sx={{ Width: "100%" }}
                    onChange={handleOnCourseChange}
                    defaultValue="slected"
                  >
                    <MenuItem value="slected"> Select Any</MenuItem>
                    {CoursesData.SelectCourse &&
                      CoursesData.SelectCourse.map((items: any) => {
                        return (
                          <MenuItem value={items.id}>{items.name}</MenuItem>
                        );
                      })}
                  </Select>
                  {errors.type_value && (
                    <FormHelperText error>{errors.type_value}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>
                  Select Category
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  name="categories"
                  multiple
                  value={formData.category}
                  onChange={handleSelectCategory}
                  defaultValue={[]}
                >
                  {categoryData.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Topic</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="Enter Topic"
                  value={formData.topic}
                  name="topic"
                  variant="outlined"
                  onChange={handleOnChange}
                  error={errors.topic !== undefined}
                  helperText={errors.topic}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container sx={{ mb: 3 }}>
              <Grid item xs={2}>
                <InputLabel sx={{ pt: 2 }}>Description</InputLabel>
              </Grid>
              <Grid item xs={8}>
                <Textarea
                  placeholder="Description"
                  name="description"
                  minRows={4}
                  maxRows={4}
                  defaultValue={formData.description}
                  onChange={handleOnChange}
                />
                {errors.description && (
                  <FormHelperText error>{errors.description}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Tag</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder="Enter Tags"
                  value={formData.tags}
                  name="tags"
                  variant="outlined"
                  onChange={handleChangeTags}
                  error={errors.tags !== undefined}
                  helperText={errors.tags}
                />
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Box>
  );
}
