import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { firebaseAuth, firebaseDB } from "../../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { GetAuthState, getTeachers } from "../../../helper/helperFunction";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
// import { Teachers } from "../../../Type/courseTypes";
import styles from "./msg.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import SendImgs from "../../../static/images/paper-plane.svg";
import AttechImgs from "../../../static/images/paperclip.svg";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import AvatarImage from "../../../components/Avatar";

export default function Messaging() {
  type MessageType = {
    createdAt: { seconds: number; nanoseconds: number };
    name: string;
    text: string;
    uid: string;
    date: any;
  };

  type ChatUsers = {
    chatUsers?: any;
  };

  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const [chatUsers, setChatUsers] = useState<ChatUsers>({
    chatUsers: [
      {
        name: "",
        first_name: "",
        profile_picture: "",
      },
    ],
  });

  const [chatUserUID, setChatUserUID] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const [chatUser, setChatUser] = useState<{
    firebase_uid: string;
    first_name: string;
    last_name: string;
    userMeta: {
      profile_picture: string;
    };
  } | null>(null);

  const [user] = useAuthState(firebaseAuth);

  const CURRENT_USER = GetAuthState("USER");

  const FIREBASE_USER = firebaseAuth.currentUser;

  const sendMessage = async (event: any) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    if (FIREBASE_USER && chatUserUID) {
      await addDoc(
        collection(
          firebaseDB,
          "users",
          FIREBASE_USER.uid,
          "chatUsers",
          chatUserUID,
          "messages"
        ),
        {
          text: message,
          name: `${CURRENT_USER.first_name} ${CURRENT_USER.last_name}`,
          createdAt: serverTimestamp(),
          uid: FIREBASE_USER.uid,
        }
      );
      await addDoc(
        collection(
          firebaseDB,
          "users",
          chatUserUID,
          "chatUsers",
          FIREBASE_USER.uid,
          "messages"
        ),
        {
          text: message,
          name: `${CURRENT_USER.first_name} ${CURRENT_USER.last_name}`,
          createdAt: serverTimestamp(),
          uid: FIREBASE_USER.uid,
        }
      );
      setMessage("");
    } else {
      return;
    }
  };

  // Callback Hook
  const getChatUser = async () => {
    setLoading(true);
    try {
      const users = await api.get(
        resolveUrl(AdminApi.CHAT_USERS, CURRENT_USER.id),
        {
          params: {
            text: search,
          },
        }
      );

      setChatUsers({
        chatUsers: users.data,
      });
    } catch (error) {
      console.error("Error fetching chat users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatUser();
  }, [CURRENT_USER, search]);

  // useEffect(() => {
  //   const getMessages = () => {
  //     if (FIREBASE_USER && chatUserUID) {
  //       // console.log(FIREBASE_USER.uid);
  //       // console.log(chatUserUID)
  //       const q = query(
  //         collection(
  //           firebaseDB,
  //           "users",
  //           FIREBASE_USER.uid,
  //           "chatUsers",
  //           chatUserUID,
  //           "messages"
  //         ),
  //         orderBy("createdAt"),
  //         limit(50)
  //       );
  //       const unsubscribe = onSnapshot(q, (QuerySnapshot) => {

  //         let messages: any = [];
  //         QuerySnapshot.forEach((doc) => {
  //           messages.push({ ...doc.data(), id: doc.id });
  //         });
  //         setMessages(messages);
  //       });
  //       return () => unsubscribe;
  //     }
  //   };
  //   getChatUser();
  //   getMessages();
  // }, [chatUserUID, FIREBASE_USER, getChatUser]);

  useEffect(() => {
    const getMessages = () => {
      if (FIREBASE_USER && chatUserUID) {
        const q = query(
          collection(
            firebaseDB,
            "users",
            FIREBASE_USER.uid,
            "chatUsers",
            chatUserUID,
            "messages"
          ),
          orderBy("createdAt"),
          limit(50)
        );
        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
          let messages: any = {};
          QuerySnapshot.forEach((doc) => {
            const data = doc.data();
            const messageDate = new Date(
              data.createdAt.seconds * 1000
            ).toDateString();

            if (!messages[messageDate]) {
              messages[messageDate] = [];
            }
            messages[messageDate].push({
              ...data,
              id: doc.id,
              date: messageDate,
            });
          });
          setMessages(messages);
        });
        return () => unsubscribe;
      }
    };
    getMessages();
  }, [chatUserUID, FIREBASE_USER]);

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
  };

  // Function to format timestamp to human-readable format
  const formatTimestamp = (seconds: any, nanoseconds: any) => {
    if (
      seconds === null ||
      nanoseconds === null ||
      seconds === undefined ||
      nanoseconds === undefined
    ) {
      return "Invalid timestamp"; // Return a message indicating invalid timestamp
    }

    const date = new Date(seconds * 1000 + nanoseconds / 1000000);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <Box className={styles.msgBox}>
      <Grid container className={styles.msgBoxContainer}>
        <Grid item xs={4} className={styles.msgBoxLeftGrid}>
          <Box className={styles.msgBoxUpperSearch}>
            {/* <Typography variant="body1">Search</Typography> */}
            <Box
              className={styles.msgBoxUpperSearchBox}
              sx={{ mr: 2, display: "flex", flexDirection: "row-reverse" }}
            >
              <TextField
                placeholder="Search"
                size="small"
                className={styles.msgBoxUpperSearchBoxInput}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
                onChange={(e) => handleSearch(e)}
              />
            </Box>
          </Box>
          <Box className={styles.msgBoxLeftTeacherList}>
            {loading ? (
              <Box className={styles.CircularProgress}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {chatUsers.chatUsers && chatUsers.chatUsers.length > 0 ? (
                  <ul className={styles.msgBoxLeftTeacherListContent}>
                    {chatUsers.chatUsers.map(
                      (chatUser: {
                        firebase_uid: string;
                        first_name: string;
                        last_name: string;
                        userMeta: {
                          profile_picture: string;
                        };
                      }) => (
                        <li
                          key={chatUser.firebase_uid}
                          className={styles.msgBoxLeftTeacherListTeacher}
                          onClick={() => {
                            setChatUserUID(chatUser.firebase_uid);
                            setChatUser(chatUser);
                          }}
                        >
                          <Box className={styles.msgBoxLeftTeacherListAvator}>
                            <AvatarImage
                              imageName={chatUser.userMeta.profile_picture}
                              name={chatUser.first_name}
                            />
                          </Box>
                          <Box className={styles.msgBoxLeftTeacherListTitle}>
                            <Typography
                              variant="h4"
                              className={styles.msgBoxLeftTeacherListName}
                            >{`${chatUser.first_name} ${chatUser.last_name}`}</Typography>
                            {/* <Typography variant="body1" className={styles.msgBoxLeftTeacherListSub}>Teacher</Typography> */}
                          </Box>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <ul className={styles.msgBoxLeftTeacherListContent}>
                    <li className={styles.msgBoxLeftTeacherListTeacher}>
                      No chat users available
                    </li>
                  </ul>
                )}
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={8} className={styles.msgBoxRightGrid}>
          {chatUserUID && (
            <Grid container className={styles.msgBoxRightGridMegerName}>
              <Grid
                item
                xs={6}
                sm={6}
                md={7}
                lg={8}
                className={styles.msgBoxRightGridMegerContent}
              >
                <Box className={styles.msgBoxRightGridMegerAvator}>
                  {chatUser && (
                    <AvatarImage
                      imageName={chatUser.userMeta.profile_picture}
                      name={chatUser.first_name}
                    />
                  )}
                </Box>
                <Box className={styles.msgBoxRightGridMegerTitle}>
                  {chatUser && (
                    <Typography
                      variant="h2"
                      className={styles.msgBoxRightGridMegerTitleName}
                    >{`${chatUser.first_name} ${chatUser.last_name}`}</Typography>
                  )}
                  {/* <Typography variant="body1" className={styles.msgBoxRightGridMegerTitleSub}>Teacher</Typography> */}
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={5}
                lg={4}
                className={styles.msgBoxRightGridMegerSearchBox}
              >
                {/* <TextField
                  placeholder="Search"
                  size="small"
                  name="search"
                  className={styles.msgBoxRightGridMegerSearchBoxInput}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  onChange={(e) => handleSearch(e)}
                /> */}
              </Grid>
            </Grid>
          )}
          {/* <Box className={styles.msgBoxRightGridMegerBox}>
            {chatUserUID &&
              messages?.map((message: MessageType) => (
                <Grid className={styles.msgBoxRightGridMegerChat}>
                  <div
                    className={`chat-bubble ${
                      user && message.uid === user.uid ? "right" : "left"
                    }`}
                  > */}
          {/* <Avatar sx={{ width: 40, height: 40 ,mr:2 }} alt={message.name.toUpperCase()} src="/static/images/avatar/2.jpg" /> */}
          {/* <div className={styles.msgBoxRightGridMegerChatRight}> */}
          {/* <p className="user-name">{message.name}</p> */}
          {/* <p className={styles.msgBoxRightGridMegerUserMsg}>
                        {message.text}
                      </p>
                      <p className={styles.msgBoxRightGridMegerUserTime}>
                        {message.createdAt &&
                          formatTimestamp(
                            message.createdAt.seconds,
                            message.createdAt.nanoseconds
                          )}
                      </p>
                    </div>
                  </div>
                </Grid>
              ))}
          </Box> */}

          <Box className={styles.msgBoxRightGridMegerBox}>
            {chatUserUID &&
              Object.keys(messages).map((date) => (
                <>
                  <Box className={styles.msgBoxDate}>
                    <Typography variant="subtitle1">{date}</Typography>
                  </Box>
                  {messages[date].map((message: MessageType) => (
                    <Grid className={styles.msgBoxRightGridMegerChat}>
                      {user && message.uid === user.uid ? (
                        <>
                          <div
                            className={`chat-bubble ${styles.msgBox_wrap_right}`}
                          >
                            <div
                              className={styles.msgBoxRightGridMegerChatRight}
                            >
                              <p className={styles.msgBoxRightGridMegerUserMsg}>
                                {message.text}
                              </p>
                              <p
                                className={styles.msgBoxRightGridMegerUserTime}
                              >
                                {formatTimestamp(
                                  message.createdAt.seconds,
                                  message.createdAt.nanoseconds
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`chat-bubble ${styles.msgBox_wrap_left}`}
                          >
                            <div
                              className={styles.msgBoxRightGridMegerChatRight}
                            >
                              <p
                                className={styles.msgBoxRightGridMegerUserTime}
                              >
                                {formatTimestamp(
                                  message.createdAt.seconds,
                                  message.createdAt.nanoseconds
                                )}
                              </p>
                              <p className={styles.msgBoxRightGridMegerUserMsg}>
                                {message.text}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </Grid>
                  ))}
                </>
              ))}
          </Box>

          <Box className={styles.msgBoxRightGridMsgSender}>
            {chatUserUID && (
              <form className="send-message" onSubmit={sendMessage}>
                <input
                  className={styles.msgBoxRightGridMsgSenderBox}
                  value={message}
                  placeholder="Enter Your Message Here"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button
                  type="submit"
                  className={styles.msgBoxRightGridMsgSenderBtnAttech}
                >
                  <img src={AttechImgs} alt={AttechImgs} />
                </button>
                <button
                  type="submit"
                  className={styles.msgBoxRightGridMsgSenderBtnSend}
                >
                  <img src={SendImgs} alt={SendImgs} />
                </button>
              </form>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
