import { Link, useNavigate, useParams } from "react-router-dom";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { Box } from "@mui/material";
import { DateView } from "../../../../components/DateView/DateView";
import { useAuth } from "../../../../hooks/auth/auth";

export default function TeacherQuizzes() {
  const navigate = useNavigate();

  const auth = useAuth();
  const userID = auth.state?.user.id;

  const tableColumns = [
    {
      selector: (row: { quizTitle: string }) => row.quizTitle,
      name: "Quiz Title",
      sortable: true,
    },
    {
      selector: (row: { courseTitle: string }) => row.courseTitle,
      name: "Subject Name",
      sortable: false,
    },
    {
      selector: (row: { courseSection: string }) => row.courseSection,
      name: "Subjects section",
      sortable: false,
    },
    {
      selector: (row: { assignedStudents: string }) => row.assignedStudents,
      name: "Assigned Student",
      sortable: false,
    },
    {
      selector: (row: { completed: string }) => row.completed,
      name: "Completed",
      sortable: false,
    },
    {
      selector: (row: { time: string }) => row.time,
      name: "Time",
      sortable: false,
    },
    {
      selector: (row: { avgMarks: string }) => row.avgMarks,
      name: "Avg Marks",
      sortable: false,
    },
    {
      selector: (row: { totalMarks: string }) => row.totalMarks,
      name: "Total Score",
      sortable: false,
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/courses/view/${id}`);
  };

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(AdminApi.USER_TEACHER_QUIZZES, `${userID}`)}
        onView={onView}
        actions={{
          edit: false,
          delete: false,
          view: false,
        }}
        module="manage-users"
        actionColumns={false}
      />
    </>
  );
}
