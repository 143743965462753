import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import Degrecap from "../../../../static/images/degree-cap.png";

interface cardData {
  heading: string;
  paragraph: string;
  imageUrl: string;
  sxWidth: number;
  index?: number;
}

type CardType = { heading: any; paragraph: string; imageUrl: string };

type props = {
  courseId?: number | null;
  paramId?: number | null;
};

export const SingleCourseProgress = ({ courseId, paramId }: props) => {
  const [cardsData, setCardsData] = useState<null | CardType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const completedCoursesRes = await api.get(
          AdminApi.STUDENT_COURSES_COMPLETED_SINGLE,
          {
            params: {
              studentId: paramId,
              courseId: courseId,
            },
          }
        );

        if (completedCoursesRes.status) {
          const cardData: CardType[] = [];

          cardData.push({
            heading: completedCoursesRes.data.status,
            paragraph: "Subject Status",
            imageUrl: Degrecap,
          });

          cardData.push({
            heading: completedCoursesRes.data.percentage
              ? completedCoursesRes.data.percentage + "%"
              : "-",
            paragraph: "Percentage",
            imageUrl: Degrecap,
          });

          setCardsData(cardData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [paramId, courseId]);

  return (
    <Grid container>
      {cardsData &&
        cardsData.map((card, index) => (
          <CardItem
            key={index}
            heading={card.heading}
            paragraph={card.paragraph}
            imageUrl={card.imageUrl}
            sxWidth={5.88}
            index={index}
          />
        ))}
    </Grid>
  );
};

const CardItem: React.FC<cardData> = ({
  heading,
  paragraph,
  imageUrl,
  sxWidth,
  index,
}) => {
  return (
    <>
      <Grid
        item
        xs={sxWidth}
        container
        sx={{ backgroundColor: "white" }}
        className="dashbaord-card"
        style={{ borderRadius: "5px" }}
        marginRight={index === 0 ? { marginRight: "1.9%" } : ""}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="body1">{paragraph}</Typography>
          <Typography
            variant="h3"
            className={heading && heading.replace(/\s+/g, "")}
          >
            {heading}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ justifyContent: "end", display: "flex" }}
        >
          <img
            src={imageUrl}
            alt={heading}
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};
