import { useState } from "react";
import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import styles from "./enrolled.module.css";
import PurchasedCousesGrid from "./subjectGrid";
import DynamicTabs from "../../../components/tabs/Tabs";
import CousesGridIntallment from "./subjectGridInstallment";
import AdminAssignSubjectGrid from "./adminAssignSubjectGrid";

export default function ViewPurchasedCouses() {
  const [order, setOrder] = useState("descending");

  type FiltersData = {
    student_name: string | null;
    course_name: string | null;
    teacher_name: string | null;
  };

  const [filters, setFilters] = useState<{} | FiltersData>({});
  const [filterData, setFilterData] = useState<{} | FiltersData>({});

  const tabs = [
    {
      label: "Fully Paid",
      content: <PurchasedCousesGrid filters={filters} />,
    },
    // {
    //   label: "Installment",
    //   content: <CousesGridIntallment filters={filters} />,
    // },
    {
      label: "Admin Assigned Subjects",
      content: <AdminAssignSubjectGrid filters={filters} />,
    },
  ];

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Subject Enrolled</h1>
      </div>
      <Grid container className={styles.enrolledStudnetFilter}>
        <Grid
          item
          xs={12}
          md={12}
          lg={10}
          className={styles.enrolledStudnetFilterContainer}
        >
          <Typography
            variant="body1"
            className={styles.enrolledStudnetFilterTitle}
          >
            Search By
          </Typography>
          <TextField
            id="student"
            variant="outlined"
            fullWidth
            placeholder="Student Name"
            className={styles.enrolledStudnetFilterStudentName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setFilters(filterData);
              }
            }}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  student_name: event.target.value,
                };
              })
            }
          />
          <TextField
            id="course"
            variant="outlined"
            fullWidth
            placeholder="Subject Name"
            className={styles.enrolledStudnetFilterCourseName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setFilters(filterData);
              }
            }}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  course_name: event.target.value,
                };
              })
            }
          />
          <TextField
            id="teacher"
            variant="outlined"
            fullWidth
            placeholder="Teacher Name"
            className={styles.enrolledStudnetFilterTeacherName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setFilters(filterData);
              }
            }}
            onChange={(event) =>
              setFilterData((prevState: FiltersData) => {
                return {
                  ...prevState,
                  teacher_name: event.target.value,
                };
              })
            }
          />
          <Button
            className={styles.enrolledStudnetFilterBtn}
            onClick={() => setFilters(filterData)}
          >
            Go
          </Button>
        </Grid>
      </Grid>
      <DynamicTabs tabs={tabs} />
    </>
  );
}
