import { useEffect, useState } from "react";
import style from "./style.module.scss";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { GetAuthState, GetUserRole } from "../../../helper/helperFunction";
import CustomModal from "../../../components/popups/Modal";

interface CardData {
  title: string;
  notice: string;
  updated_at: string;
  id: string;
  is_viewed: boolean;
}

const NoticeBoard = () => {
  const currentUser = GetUserRole();
  const user = GetAuthState("USER");
  const currentUserId = user.id ?? "";
  const [cardsData, setCardData] = useState<CardData[]>();
  const [viewNotice, setViewNotice] = useState<string>("");
  const [updateData, setUpdateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);

  useEffect(() => {
    const fechData = async () => {
      setIsLoadingList(true);

      const res = await api.get(resolveUrl(AdminApi.NOTICE_BOARD_VIEW), {
        params: {
          role: currentUser,
          limit: -1,
          id: currentUserId,
        },
      });
      setCardData(res.data.items);
      setIsLoadingList(false);
    };
    fechData();
  }, [currentUser, currentUserId, updateData]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    setViewNotice("");
  };

  const ViewData = async (id: any) => {
    try {
      if (id) {
        setIsLoading(true);

        const res = await api.get(resolveUrl(AdminApi.NOTICE_BOARD_EDIT, id));
        setViewNotice(res.data.data.notice);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onView = async (id: string, is_viewed: boolean) => {
    try {
      ViewData(id);
      setModalOpen(true);

      if (!is_viewed) {
        const response = await api.post(
          resolveUrl(AdminApi.NOTICE_BOARD_IS_READED),
          {
            noticeId: id,
            userId: currentUserId,
          }
        );
        setUpdateData((prevState: any) => !prevState);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function getTimeDifference(updated_at: any) {
    const currentDate = new Date();
    const noticeDate = new Date(updated_at);
    const timeDifference = currentDate.getTime() - noticeDate.getTime(); // Convert to numbers

    // Calculate minutes, hours, or display the date based on the condition
    if (timeDifference < 60000) {
      // Less than 1 minute
      return `${Math.floor(timeDifference / 1000)} seconds ago`;
    } else if (timeDifference < 3600000) {
      // Less than 1 hour
      return `${Math.floor(timeDifference / 60000)} minutes ago`;
    } else if (timeDifference < 86400000) {
      // Less than 24 hours
      return `${Math.floor(timeDifference / 3600000)} hours ago`;
    } else {
      // More than 24 hours, display the date
      return noticeDate.toDateString();
    }
  }

  return (
    <Box className={style.noticeBoardMain}>
      <Typography variant="h3" className={style.noticeBoardTitle}>
        Notice Board
      </Typography>
      <Box className={style.noticeWrap}>
        {isLoadingList ? (
          <Loader />
        ) : (
          cardsData &&
          cardsData.map((item) => (
            <Card key={item.id} className={style.noticeBoardListContent}>
              <CardContent className={style.noticeTextContent}>
                <Typography
                  variant="h5"
                  className={style.noticeTitle}
                  onClick={() => onView(item.id, item.is_viewed)}
                  style={item.is_viewed ? { opacity: "0.5" } : {}}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {item.title}
                </Typography>

                {/* <div dangerouslySetInnerHTML={{ __html: item.notice }}></div> */}
              </CardContent>
              <CardContent className={style.noticeTimeContent}>
                <Typography
                  variant="body1"
                  color="primary"
                  className={style.noticeTime}
                  style={item.is_viewed ? { opacity: "0.5" } : {}}
                >
                  {getTimeDifference(item.updated_at)}
                </Typography>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
      <CustomModal state={modalOpen} setState={setModalOpen}>
        <button className="closeBtn" onClick={handleCloseModal}>
          +
        </button>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: viewNotice && viewNotice }}
          ></div>
        )}
      </CustomModal>
    </Box>
  );
};

export default NoticeBoard;

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 250,
      }}
    >
      <CircularProgress sx={{ color: "#d6c38f" }} />
    </div>
  );
};
