import { GetUserRole, isAdminUser } from "../../../helper/helperFunction";
import ViewComplaintsAdmin from "./ComplaintsAdminView";
import ViewComplaintsUsers from "./ComplaintsOtherUsers";

export default function ViewComplaints() {
  const isAdmin = isAdminUser();

  if (isAdmin) {
    return <ViewComplaintsAdmin />;
  } else {
    return <ViewComplaintsUsers />;
  }
}
