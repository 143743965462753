import { Alert, Box, Button, LinearProgress } from "@mui/material";
import { FormEvent, useState } from "react";
import { api, resolveUrl } from "../../../../../config/apis";
import FormLoader from "../../../../../components/loader/FormLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

export default function Form(params: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const navigate = useNavigate();

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setIsError(false);

    function validateContent(params: any) {
      const formData = params.formData;
      let errorMessages = [];

      // Check if formData.sections is defined and not empty
      if (!formData.sections || formData.sections.length === 0) {
        errorMessages.push("Validation Error: No sections exist.");
      }

      formData.sections.forEach(
        (section: { courseContent: any[]; title: any }) => {
          // Check if courseContent is defined and not empty
          if (!section.courseContent || section.courseContent.length === 0) {
            errorMessages.push(
              `Validation Error: Section "${section.title}" must have at least one content item.`
            );
          } else {
            // Check if all course contents are marked for deletion
            const allDeleted = section.courseContent.every(
              (content) => content.isDelete === true
            );

            if (allDeleted) {
              errorMessages.push(
                `Validation Error: Section "${section.title}" must have at least one content item.`
              );
            }
          }
        }
      );

      // Show alert if there are error messages
      if (errorMessages.length > 0) {
        setIsError(true);
        setIsLoading(true);
        setErrorMsg(errorMessages);
        return false;
      }

      return true; // Validation passes
    }

    // Call the function and log the result
    if (!validateContent(params)) {
      setIsLoading(false);
      return false;
    }

    const isValid = params && params.validate ? await params.validate() : true;

    if (isValid) {
      await api
        .post(resolveUrl(params.submitUrl, params.editId), params.formData, {
          headers: {
            "Content-Type": params.multipart
              ? "multipart/form-data"
              : "application/json",
          },
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
            setState({ ...state, open: true });
          },
        })
        .then((res: any) => {
          setIsLoading(false);
          handleClose();
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          if (params.onSuccess) {
            params.onSuccess(res.data);
          }
          if (params.setFormSuccess) {
            params.setFormSuccess((prevState: any) => !prevState);
          }
          navigate(params.successPath ? params.successPath : "/admin");
        })
        .catch((error: any) => {
          setIsLoading(false);
          setIsError(true);

          if (error.response) {
            if (error.response.status === 400) {
              const errorMessage = error.response.data.message;
              setErrorMsg(errorMessage);
            } else if (error.response.status === 404) {
              const errorMessage = error.response.data.message;
              setErrorMsg([errorMessage]);
            } else if (error.response.status === 401) {
              const errorMessage = error.response.data.message;
              setErrorMsg([errorMessage]);
            } else if (error.response.status === 500) {
              const errorMessage = error.response.statusText;
              setErrorMsg([errorMessage]);
            }
          } else {
            setErrorMsg(["Something went wrong please try again."]);
          }
        });
    } else {
      setIsLoading(false);
      setErrorMsg(["Something went wrong please try again."]);
    }
  };
  const [state, setState] = useState<any>({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <>
      {isLoading && <FormLoader />}
      <Box
        component="form"
        className={params.classname}
        onSubmit={handleFormSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        {isError && (
          <Alert severity="error" sx={{ width: "100%" }}>
            <ul style={{ padding: "0", margin: "0" }}>
              {Array.isArray(errorMsg) ? (
                errorMsg &&
                errorMsg.map((value, index) => <li key={index}>{value}</li>)
              ) : (
                <li>{errorMsg}</li>
              )}
            </ul>
          </Alert>
        )}
        {params.children}

        <Button
          className={`${params.btnClass ? params.btnClass : ""} btn`}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={params.disable}
        >
          {params.saveText ? params.saveText : "Save"}
        </Button>
      </Box>

      {params.showProgress && open && (
        <Box className="upload_progress">
          <Alert variant="filled" severity="info">
            {`Upload progress: ${uploadProgress}%`}
          </Alert>
        </Box>
      )}
    </>
  );
}
