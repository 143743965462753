import styles from './studentQuizes.module.scss'
import { Box, Typography } from "@mui/material";
import StudentQuizesTable from "./Student-quizes-table";
import Filter from '../filters/filters';
import { useState } from 'react';



type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

const StudentQuizes = () => {
  
  const [filters, setFilters] = useState<FilterState>();
  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);    
  };

  return (
    <Box className={styles.quizesPagePanel}>
    <Typography variant="h2" className={styles.quizesPanelTitle}>My Quizes</Typography>
    <Filter  onFiltersChange={handleFiltersChange} />
    <StudentQuizesTable filterState={filters}/>
    </Box>
  );
};

export default StudentQuizes;
