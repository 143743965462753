import { Box, Modal } from "@mui/material";
import styles from "./manage-discount.module.scss";
import PurchaseCourseForm from "./edit";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

type DiscountFormModalProps = {
  open: boolean;
  id: string;
  closeModal: () => void;
};

const PurchaseCourseFormModal: React.FC<DiscountFormModalProps> = ({
  open,
  id,
  closeModal,
}) => {
  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  }));
  return (
    <Modal open={open} onClose={closeModal}>
      <Box className={styles.popup}>
        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
        <PurchaseCourseForm id={id} closeModal={closeModal} />
      </Box>
    </Modal>
  );
};

export default PurchaseCourseFormModal;
