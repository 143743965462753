import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { Box, Button, Tooltip } from "@mui/material";
import { DateView } from "../../../../components/DateView/DateView";
import { useAuth } from "../../../../hooks/auth/auth";
import { useEffect, useState, FormEvent } from "react";
import { GetUserRole, toTitleCase } from "../../../../helper/helperFunction";
import { toast } from "react-toastify";

export default function TeacherAssignments() {
  const navigate = useNavigate();

  const auth = useAuth();
  const userID = auth.state?.user.id;
  const onView = async (studentAssignmentId: string) => {
    navigate(`/admin/assignments/view/${studentAssignmentId}`);
  };
  const CURRENT_ROLE = GetUserRole();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const rows = document.querySelectorAll(".rdt_TableRow");
    rows.forEach((row) => {
      const childSpan = row.querySelector("span.not_marked_yet");
      if (childSpan) {
        row.classList.add("parent_not_marked");
      }
    });
  }, [isLoading]);

  const tableColumns = [
    {
      selector: (row: { courseName: string; is_marked: any }) => (
        <>
          <Tooltip title={row.courseName ? row.courseName : ""} arrow>
            <span>{row.courseName}</span>
          </Tooltip>
          {row.is_marked ? "" : <span className="not_marked_yet"></span>}
        </>
      ),
      name: "Subject Name",
      width: "150px",
      sortable: false,
    },
    {
      selector: (row: { assignmentTitle: string }) => row.assignmentTitle,
      name: "Title",
      width: "150px",
      sortable: false,
    },
    {
      selector: (row: { studentAssignmentMarkingStatus: string }) =>
        row.studentAssignmentMarkingStatus,
      name: "Marked",
      sortable: false,
      minWidth: "160px",

      cell: (row: { studentAssignmentMarkingStatus: string }) => (
        <div
          style={{
            color:
              row.studentAssignmentMarkingStatus === "marked" ? "green" : "red",
          }}
        >
          {row.studentAssignmentMarkingStatus
            ? toTitleCase(row.studentAssignmentMarkingStatus)
            : ""}
        </div>
      ),
    },

    {
      selector: (row: { submittedOn: string }) => (
        <DateView date={row.submittedOn} />
      ),

      name: "Submitted on",
      width: "150px",
      sortable: false,
    },
    {
      selector: (row: { submittedBy: string }) => row.submittedBy,
      name: "Submitted By",
      minWidth: "250px",
      sortable: false,
    },
    {
      selector: (row: { totalMarks: string }) => row.totalMarks,
      name: "Total Marks",
      minWidth: "150px",
      sortable: false,
    },
    {
      selector: (row: { obtainedMarks: string }) => row.obtainedMarks,
      name: "Marks Obtained",
      minWidth: "150px",
      sortable: false,
    },
    CURRENT_ROLE === "Teacher" && {
      cell: (row: {
        isSubmitted: boolean;
        file_path: string | null;
        studentAssignmentStatus: string;
        studentAssignmentId: string;
      }) => (
        <>
          {row.studentAssignmentStatus === "pending" && (
            <Button
              style={{ padding: "8px 10px", marginLeft: "0" }}
              className="btn"
              onClick={(e) => reassignRequest(e, row.studentAssignmentId)}
            >
              Re-Assign
            </Button>
          )}
          {row.studentAssignmentStatus === "closed" && "Not Requested"}
          {row.studentAssignmentStatus === "approved" && "Approved"}
          {row.studentAssignmentStatus === "rejected" && "Rejected"}
        </>
      ),
      name: "Reassign Request",
      width: "170px",
    },
    {
      cell: (row: { studentAssignmentId: any }) => (
        <>
          <Button
            className="view"
            onClick={() => onView(row.studentAssignmentId)}
          >
            view
          </Button>
        </>
      ),
      name: "Action",
      sortable: false,
    },
  ];

  const reassignRequest = async (
    event: FormEvent,
    assignmentId: number | string
  ) => {
    try {
      const res = await api.post(
        resolveUrl(AdminApi.REASSIGN_ASSIGNMENT_REQUEST),
        {
          id: assignmentId,
          status: "approved",
        }
      );

      if (res.data.status === true) {
        setFormSuccess((prevState: any) => !prevState);
        toast.success("Assignment reassignment successful!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [formSuccess, setFormSuccess] = useState(false);

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const Url_current = currentPath === "/admin/students/assignments";
  return (
    <>
      {Url_current ? (
        <>
          <div className="course-content">
            <h1 className="main-heading">Assignments</h1>
          </div>
        </>
      ) : (
        ""
      )}
      <div className={Url_current ? "page-container" : ""}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(AdminApi.USER_TEACHER_ASSIGNMENT, `${userID}`)}
          onView={onView}
          actions={{
            edit: false,
            delete: false,
            view: false,
          }}
          refreshHandler={formSuccess}
          module="manage-courses"
          actionColumns={false}
          loading={setIsLoading}
        />
      </div>
    </>
  );
}
