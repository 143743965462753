import { Typography } from "@mui/material";
import ImgBanner1 from "./asset/img/Knowledge-Rich-Curriculum.jpg";
import ImgBanner2 from "./asset/img/Non-Academic-Enrichment.jpg";
import ImgBanner3 from "./asset/img/Broad-and-Balanced-Knowledge.jpg";
import ImgBanner4 from "./asset/img/Character-and-Moral-Education-(Akhlaq).jpg";
import ImgBanner5 from "./asset/img/Extra-Curricular-Activities.jpg";
import "./style/style.css";
import { HomePageContentInfo, Slide } from "../type/type";
import ImageViewer from "../../../components/imageViewer";
import PlaceHolderImage from "./asset/img/placeholder.webp";
import { useReadMorePost } from "../../../helper/helperFunction";
interface KnowledgeSkillsProps {
  knowledgeSkillsInfo?: HomePageContentInfo;
  knowledgeSkills?: Slide[];
}

const KnowledgeSkills = function ({
  knowledgeSkillsInfo,
  knowledgeSkills,
}: KnowledgeSkillsProps) {
  const { isExpanded, toggleReadMore, truncateText } = useReadMorePost(600);

  return (
    <div className="knowledgeSkillsMain">
      <div className="container">
        <div
          className="titleContent"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Typography variant="h2">
            {knowledgeSkillsInfo?.title ?? ""}
          </Typography>
          <Typography variant="body1">
            {knowledgeSkillsInfo?.description ?? ""}
          </Typography>
        </div>
        <div className="cardContainer">
          {knowledgeSkills &&
            knowledgeSkills.map((item, index) => {
              return (
                <div
                  key={index}
                  className="cardContain"
                  data-aos={`fade-${index % 2 === 0 ? "right" : "left"}`}
                  data-aos-duration="2500"
                >
                  <div className="imgContainer">
                    {item.image ? (
                      <ImageViewer
                        imagePath={item.image ?? ""}
                        altText={item.title}
                      />
                    ) : (
                      <img src={PlaceHolderImage} alt="imageNo1" />
                    )}
                  </div>
                  <div className="textContent">
                    <Typography variant="h4">{item.title}</Typography>
                    <Typography variant="body1">
                      {item.description &&
                        (isExpanded(index)
                          ? item.description
                          : truncateText(item.description))}
                      {item.description && item.description.length > 600 && (
                        <button
                          className="read_more_btn"
                          onClick={() => toggleReadMore(index)}
                        >
                          {isExpanded(index) ? "Read Less" : "Read More"}
                        </button>
                      )}
                    </Typography>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeSkills;
