import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageViewer = ({ imagePath, altText }: any) => {
  const getImageUrl = (imagePath: string) => {
    const { REACT_APP_SERVER_BASE } = process.env;

    if (!REACT_APP_SERVER_BASE) {
      return null;
    }

    const baseUrl = REACT_APP_SERVER_BASE;

    const imageUrl = imagePath && `${baseUrl}${imagePath.replace("\\", "/")}`;
    return imageUrl;
  };

  const imageUrl = getImageUrl(imagePath);

  if (!imageUrl) {
    return null;
  }

  return (
    <LazyLoadImage src={imageUrl} alt={altText} className="image-viewer" />
  );
};

export default ImageViewer;
