import { Box, Typography } from "@mui/material";
import EnrollNowHeading from "./AddSectionTitle";


const EnrollNow = (() => {



    return (
        <>
            <Box padding="20px 30px 20px 30px">
                <Typography variant="h2" sx={{ fontSize: "40px", fontWeight: "700", paddingTop: "20px", paddingBottom: "10px", textAlign: "center" }}>
                    Enroll Now
                </Typography>
                <EnrollNowHeading />

            </Box>
        </>
    )
})
export default EnrollNow




