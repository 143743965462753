import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetUserRole,
  HasPermission,
  UserStudentDetails,
  truncateTextWithEllipsis,
} from "../../../../helper/helperFunction";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import styles from "./manage-user-student.module.css";
import { SERVER_KEY } from "../../../../config/constants/EnviormentVariables";
import moment from "moment";
import { saveAs } from "file-saver";
import Tooltip from "@mui/material/Tooltip";
import { useStudentId } from "../../../../context/getStudentId";

type User = {
  avgScore: number;
  city: string | null;
  country: string | null;
  email: string;
  enrolledCourses: number;
  gender: string | null;
  name: string;
  phoneNumber: string;
  profile: number;
  registrationDate: string;
  role: string;
  state: string | null;
  streetAddress: string;
  fatherName: string;
  dateOfBirth: any;
  idCard: any;
  subjectDetails: any;
  yearGroup: any;
  courseCompleted: any;
};

export default function StudentDetails(props: any) {
  const [studentInfo, setStudentInfo] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const { studentId } = useStudentId();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentDetails = async () => {
      const res = await UserStudentDetails(studentId ?? "");
      setStudentInfo(res);
      setIsLoading(true);
    };
    fetchStudentDetails();
  }, [studentId]);

  const dummyImage =
    "https://cdn.vectorstock.com/i/preview-1x/49/49/student-graduated-avatar-isolated-vector-11224949.webp";

  const onStudentCardClick = () => {
    navigate(`/admin/user/student/edit/${studentId}`);
  };
  const onViewId = (url: string) => {
    window.open(`${SERVER_KEY}${url}`, "_blank");
  };

  const headingstyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "16.8px",
    marginBottom: "10px",
  };
  const paraStyle = {
    color: "#071836",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16.8px",
    marginBottom: "20px",
  };

  const isTeacher = GetUserRole() === "Teacher";

  return (
    <>
      {isLoading ? (
        studentInfo ? (
          <Box className={styles.studentProfileList} padding={"20px 0"}>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <Card className={styles.studentCardBx} sx={{ width: "100%" }}>
                  <CardContent
                    className={styles.studentCardContent}
                    sx={{ width: "100%" }}
                  >
                    <Avatar
                      src={
                        studentInfo && studentInfo.profile
                          ? `${SERVER_KEY}${studentInfo.profile}`
                          : dummyImage
                      }
                      className={styles.stuProfile}
                      sx={{ width: "108px", height: "115px" }}
                    />
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={styles.stuName}
                    >
                      <span className="bold">
                        {studentInfo?.name ?? ""}
                        {/* <span>
                          {studentInfo.gender === "Female" ? "d/o" : "s/o"}{" "}
                          {studentInfo?.fatherName ?? ""}
                        </span> */}
                      </span>
                    </Typography>
                    {!isTeacher && (
                      <>
                        <Typography
                          variant="body1"
                          className={styles.stuLastActive}
                        >
                          Registration Date
                        </Typography>
                        <Typography
                          variant="body1"
                          className={styles.stuLastActive}
                        >
                          {/* <b>Progress</b> {studentData.progress}% */}
                          {moment(studentInfo.registrationDate ?? "").format(
                            "MM-DD-YYYY"
                          ) ?? "-"}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                  {!isTeacher && (
                    <>
                      <div className={styles.stuViewDetailBtn}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onViewId(studentInfo.idCard ?? "")}
                          className={styles.stuViewBtn}
                        >
                          View ID
                        </Button>
                      </div>

                      <div className={styles.stuViewDetailBtn}>
                        <FileDownloadLink fileUrl={studentInfo.idCard ?? ""} />
                        <HasPermission permission="edit.manage-users">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={onStudentCardClick}
                            className={`${styles.stuDownloadBtn} ${styles.stuEditBtn}`}
                          >
                            Edit
                          </Button>
                        </HasPermission>
                      </div>
                    </>
                  )}
                </Card>
              </Grid>
              <Grid item xs={8} container sx={{ paddingLeft: "40px" }}>
                {!isTeacher && (
                  <>
                    <Grid xs={6} sx={{ paddingRight: "20px" }}>
                      <Typography variant="h5" sx={headingstyle}>
                        Gender
                      </Typography>
                      <Typography variant="body2" sx={paraStyle}>
                        {studentInfo.gender ?? "-"}
                      </Typography>
                      <Typography variant="h5" sx={headingstyle}>
                        Phone Number
                      </Typography>
                      <Typography variant="body2" sx={paraStyle}>
                        {studentInfo.phoneNumber ?? "-"}
                      </Typography>
                      <Typography variant="h5" sx={headingstyle}>
                        Subject Details
                      </Typography>
                      <Tooltip title={studentInfo.subjectDetails} arrow>
                        <Typography variant="body2" sx={paraStyle}>
                          {studentInfo.subjectDetails &&
                            truncateTextWithEllipsis(
                              studentInfo.subjectDetails ?? "-",
                              40
                            )}
                        </Typography>
                      </Tooltip>
                      <Typography variant="h5" sx={headingstyle}>
                        Address
                      </Typography>
                      <Typography variant="body2" sx={paraStyle}>
                        {studentInfo.streetAddress ?? "-"}
                      </Typography>
                      <Typography variant="h5" sx={headingstyle}>
                        Subjects Completed
                      </Typography>
                      <Typography variant="body2" sx={paraStyle}>
                        {studentInfo.courseCompleted === 0
                          ? "-"
                          : studentInfo.courseCompleted}
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography variant="h5" sx={headingstyle}>
                        Date of Birth
                      </Typography>
                      <Typography variant="body2" sx={paraStyle}>
                        {moment(studentInfo.dateOfBirth ?? "").format(
                          "MM-DD-YYYY"
                        ) ?? "-"}
                      </Typography>
                      <Typography variant="h5" sx={headingstyle}>
                        Email
                      </Typography>
                      <Typography
                        variant="body2"
                        className="student_detail_email"
                        sx={paraStyle}
                      >
                        {studentInfo.email ?? "-"}
                      </Typography>
                      <Typography variant="h5" sx={headingstyle}>
                        Year Group
                      </Typography>
                      <Typography variant="body2" sx={paraStyle}>
                        {studentInfo.yearGroup ?? "-"}
                      </Typography>
                      <Typography variant="h5" sx={headingstyle}>
                        City & State
                      </Typography>
                      <Typography variant="body2" sx={paraStyle}>
                        {studentInfo.city ?? "-"} {studentInfo.state ?? ""}
                      </Typography>
                    </Grid>
                  </>
                )}
                {isTeacher && (
                  <>
                    <Grid xs={6} sx={{ paddingRight: "20px" }}>
                      <Typography variant="h5" sx={headingstyle}>
                        Gender
                      </Typography>
                      <Typography variant="body2" sx={paraStyle}>
                        {studentInfo.gender ?? "-"}
                      </Typography>
                      <Typography variant="h5" sx={headingstyle}>
                        Email
                      </Typography>
                      <Typography
                        variant="body2"
                        className="student_detail_email"
                        sx={paraStyle}
                      >
                        {studentInfo.email ?? "-"}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Typography sx={{ padding: "30px" }}>
            There are no records to display.
          </Typography>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      )}
    </>
  );
}

const FileDownloadLink = ({ fileUrl }: any) => {
  const filePath = `${SERVER_KEY}${fileUrl}`;

  const handleDownload = async () => {
    try {
      // Fetch the file as a blob from the provided URL

      const response = await fetch(filePath);
      const blob = await response.blob();

      // Trigger the "Save As" dialog
      saveAs(blob, "1695814480952.jpg");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      className={styles.stuDownloadBtn}
    >
      Download File
    </Button>
  );
};
