import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SingleCourseCard from "./single-course-card";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { getAllCoursesOutlet } from "../../../helper/helperFunction";
import styles from "./cousre-catalog.module.scss";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, Typography } from "@mui/material";

type Courses = {
  courses: any[];
};
interface ViewCourseCatalogProps {
  limit?: number;
  loadMoreVisible?: boolean;
}
export default function ViewCourseCatalog({
  limit: propLimit = 10,
  loadMoreVisible = true,
}: ViewCourseCatalogProps) {
  const navigate = useNavigate();

  const [allCourse, setAllCourse] = useState<Courses>({ courses: [] });
  const [searchText, setSearchText] = useState<string>("");
  const [limit, setLimit] = useState<number>(propLimit);
  const [meta, setMeta] = useState<any>();
  const [loading, setLoading] = useState(true);

  // const [page, setpage] = useState<number>();

  const handlePurchaseNow = (id: string) => {
    navigate(`/admin/course-catalog/view/${id}`);
  };

  const fetchAllCourses = React.useCallback(
    async (searchText: string, limit: number) => {
      try {
        const res = await getAllCoursesOutlet(searchText, limit);
        setAllCourse({
          courses: res.items,
        });
        setMeta(res.meta);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  React.useEffect(() => {
    fetchAllCourses(searchText, limit);
  }, [fetchAllCourses, searchText, limit]);

  const handleLoadMore = () => {
    setLoading(true);
    setLimit((limit) => limit + 4);
  };

  return (
    <>
      <div className="hide_search_dashboard">
        <Box sx={{ textAlign: "center" }}>
          <h1 className="main-heading">
            Subject Catalog
          </h1>
        </Box>
        <Box sx={{ mb: "50px", display: "flex", justifyContent: "center" }}>
          <Paper
            component="form"
            sx={{
              p: "4px 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 600,
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Subjects by Name"
              inputProps={{ "aria-label": "Search Subjects by keywords" }}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                setLoading(true);
              }}
            />
          </Paper>
        </Box>
      </div>

      <Grid container spacing={3} className="yi_four_boxes">
        {loading ? (
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <CircularProgress sx={{ color: "#d6c38f" }} />
            </div>
          </Grid>
        ) : allCourse && allCourse.courses.length > 0 ? (
          allCourse.courses.map((items: any) => {
            return (
              <>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={items.id}>
                  <SingleCourseCard
                    title={items.name}
                    price={items.price}
                    image={items.featured_image}
                    handlePurchaseNow={() => handlePurchaseNow(items.id)}
                  />
                </Grid>
              </>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center" }} variant="body1">
              No Subject found!
            </Typography>
          </Grid>
        )}

        <Grid
          container
          sx={{ mt: "5", justifyContent: "center", alignItems: "center" }}
        >
          {loadMoreVisible && meta && meta.itemCount !== meta.totalItems && (
            <Button
              variant="outlined"
              onClick={handleLoadMore}
              className="btn"
              sx={{ marginTop: "20px", border: "0" }}
            >
              Load more
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}
