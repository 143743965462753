import { Textarea } from "@mui/joy";
import {
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TextField,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CustomModal from "../../../../components/popups/Modal";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import {
  getObjectKeyValue,
  truncateTextWithEllipsis,
} from "../../../../helper/helperFunction";
import Videoicon from "../../../../static/images/video-icon.png";
import QuizIcon from "../../../../static/images/quiz-icon.png";
import AssignIcon from "../../../../static/images/assign-icon.png";
import Summary from "../../../../static/images/summary.png";
import EditIcon from "../../../../static/images/edit.jpg";
import DeleteIcon from "../../../../static/images/trash.jpg";
import Popover from "@mui/material/Popover";
import * as React from "react";
import {
  CourseData,
  CourseContentData,
  Quiz,
  CourseSectionData,
} from "../../../../Type/courseTypes";
import { useParams } from "react-router-dom";
import CourseSectionItem from "./CourseSection";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EditQuizInSubject } from "../../manage-quiz-in-subject/edit";
import * as Yup from "yup";
import BucketFileViewer from "../../../../components/buketFileViewer";

interface CourseContentProps {
  formData: CourseData;
  setFormData: React.Dispatch<React.SetStateAction<CourseData>>;
  errors?: any;
  setErrors: React.Dispatch<React.SetStateAction<CourseData>>;
}

interface QuizData {
  name: any;
  description: string;
  tags: string[];
  totalMarks: number | null;
  duration: any;
  is_live: boolean;
  content: QuizContent[];
}

interface QuizContent {
  question: string;
  answers: string[];
  answer_key: number[];
  type: string;
  id: any;
}

export default function CourseContent({
  formData,
  setFormData,
  errors,
}: CourseContentProps) {
  // STATES

  const [quiz, setQuiz] = useState<Quiz>({
    quiz: [],
  });

  // const quizzes:string[] = [];

  // useEffect(()=>{
  //   if (formData.sections) {
  //       formData.sections.forEach((sections:any) => {
  //         sections.courseContent.forEach((content: { type: string; quiz: string; }) => {
  //             if (content.type === "quiz") {
  //                 quizzes.push(content.quiz);
  //             }
  //         });
  //     });
  //   }

  // },[formData])

  const params = useParams();

  const [modals, setModals] = useState({
    section: false,
    content: false,
    quiz: false,
    assignment: false,
    video: false,
    summary: false,
    addFile: false,
  });

  useEffect(() => {
    setError({});
  }, [modals]);

  useEffect(() => {
    if (!modals.assignment) {
      setAssignmentFile(false);
      setUploadFile("");
      setUploadFileTitle("");
      setAssignmentPoints(null);
    }
  }, [modals.assignment]);
  useEffect(() => {
    if (!modals.addFile) {
      setUploadFile("");
      setUploadFileTitle("");
      setAssignmentDetails("");
      setAssignmentFile(false);
      setAssignmentName("");
    }
  }, [modals.addFile]);
  useEffect(() => {
    if (!modals.video) {
      setUploadFile("");
      setUploadFileTitle("");
      setVideoText("");
      setVideoFile(false);
    }
  }, [modals.video]);

  const [sectionName, setSectionName] = useState("");
  const [sectionIndex, setSectionIndex] = useState<number>(0);

  const [assignmentDetails, setAssignmentDetails] = useState("");
  const [assignmentName, setAssignmentName] = useState("");
  const [assignmentPoints, setAssignmentPoints] = useState<any>(null);
  const [quizId, setQuizId] = useState(-1);
  const [summaryText, setSummaryText] = useState("");
  const [videoFile, setVideoFile] = useState<File | boolean>(false);
  const [assignmentFile, setAssignmentFile] = useState<File | boolean>(false);
  const [uploadFile, setUploadFile] = useState<any>("");
  const [uploadFileTitle, setUploadFileTitle] = useState("");
  const [videoText, setVideoText] = useState("");
  const [contentIndex, setContentIndex] = useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);
  const [secIndex, setSecIndex] = useState<any>();
  const [quizDto, setQuizDto] = useState<QuizData>();

  // useEffect(() => {
  //   if (modals.addFile === false) {
  //     setUploadFile("");
  //     setAssignmentName("");
  //     setAssignmentDetails("");
  //   }
  // }, [modals.addFile]);

  const clearSectionIndex = () => {
    setSectionIndex(-1);
    setSectionName("");
    setIsEdit(false);
  };

  const [quizOpen, setQuizopen] = useState(false);

  const handleQuizClose = () => {
    setQuizopen(false);
    setContentIndex(0);
    setIsEdit(false);
  };

  // const handleIsDemo = (event: React.ChangeEvent<HTMLInputElement>, contentIndex: number, sectionIndex: number) => {
  //   let sections = formData.sections;

  //   if (sections) {
  //     const tmpSection = sections.map((section, secIndex) => {
  //       const contents = section.courseContent;

  //       const tmpContent = contents?.map((content, conIndex) => {
  //         if (conIndex === contentIndex && secIndex === sectionIndex) content.is_demo = true;
  //         else content.is_demo = false;

  //         return content;
  //       });

  //       section.courseContent = tmpContent;

  //       return section;
  //     });

  //     sections = tmpSection;

  //     setFormData({
  //       ...formData, sections
  //     });
  //   }

  // };

  // METHODS

  const openModal = (event: FormEvent, modal: string) => {
    const tmp_modals = modals;

    if (modal === "section") setModals({ ...tmp_modals, section: true });
    if (modal === "content") setModals({ ...tmp_modals, content: true });
    if (modal === "assignment") setModals({ ...tmp_modals, assignment: true });
    if (modal === "quiz") setModals({ ...tmp_modals, quiz: true });
    if (modal === "summary") setModals({ ...tmp_modals, summary: true });
    if (modal === "video") setModals({ ...tmp_modals, video: true });
    if (modal === "addFile") setModals({ ...tmp_modals, addFile: true });
  };

  const closeModals = () => {
    setModals((prevState: any) => {
      return Object.keys(prevState).reduce(function (state: any, key: string) {
        state[key] = false;
        return state;
      }, {});
    });

    setSectionIndex(-1);
  };

  const editSectionCallback = async (event: FormEvent, index: number) => {
    if (!sectionName) {
      setError({
        ...error,
        sectionName: sectionName ? null : "This field is required",
      });
      return false;
    }

    let sections = formData.sections;

    if (!sections) sections = [];

    if (!sectionName) {
      setError(true);
      return false;
    }

    const section = {
      title: sectionName,
    };

    if (isEdit) sections[index] = { ...sections[index], ...section };
    else sections.push(section);

    setFormData({ ...formData, sections });
    setSectionName("");
    setSectionIndex(0);
    closeModals();
    setIsEdit(false);
    setError(false);
  };

  const editSection = (
    event: FormEvent,
    sectionTitle: string,
    index: number
  ) => {
    setSectionIndex(index);
    setSectionName(sectionTitle);
    openModal(event, "section");
    setIsEdit(true);
  };

  const editContent = (
    event: FormEvent,
    sectionIndex: number,
    contentType: string = "",
    contentIndex: number = 0,
    edit = false,
    contentTitle: string = "",
    textDataContent: string = "",
    points?: any | null,
    quizDto?: QuizData,
    content?: any
  ) => {
    setSectionIndex(sectionIndex);
    if (contentType === "assignment") {
      openModal(event, "assignment");
      setAssignmentDetails(textDataContent);
      setAssignmentName(contentTitle);
      setUploadFile(content.upload_file);
      setUploadFileTitle(content.upload_title);
      setAssignmentPoints(points);
      setAssignmentFile(content.upload_file);
    }

    if (contentType === "quiz") {
      openModal(event, "quiz");
      setQuizId(points);
    }

    if (contentType === "summary") {
      openModal(event, "summary");
      setSummaryText(contentTitle);
    }

    if (contentType === "video") {
      openModal(event, "video");
      setVideoFile(content.upload_file);
      setVideoText(contentTitle);
      setUploadFile(content.upload_file);
      setUploadFileTitle(content.upload_title);
    }

    if (contentType === "addQuiz") {
      setQuizopen(true);
      setQuizDto(quizDto);
    }

    if (contentType === "addFile") {
      openModal(event, "addFile");
      setAssignmentDetails(content.textDataContent);
      setAssignmentName(content.textData);
      setUploadFile(content.upload_file);
      setUploadFileTitle(content.upload_title);
      setAssignmentFile(true);
    }

    if (edit) {
      setContentIndex(contentIndex);
      setIsEdit(true);
    }
  };
  const [error, setError] = useState<any>({});

  const editContentCallback = (
    event: FormEvent,
    contentType: string,
    index: number
  ) => {
    const sections = formData.sections;

    let content: CourseContentData = {};
    if (sections) {
      let contents: CourseContentData[] | undefined = [];

      if (sections?.[sectionIndex]) {
        contents = sections?.[sectionIndex].courseContent;
        if (isEdit && contents) content = contents[index];
      }
      if (!contents) contents = [];

      content.type = contentType;

      if (contentType === "assignment") {
        if (
          assignmentFile &&
          assignmentName &&
          assignmentDetails &&
          assignmentPoints &&
          assignmentPoints > 0
        ) {
          content.textData = assignmentName;
          content.textDataContent = assignmentDetails;
          content.upload_file = assignmentFile;
          content.points = assignmentPoints;
          setError({});
        } else {
          let errorMessage = null;
          if (!assignmentPoints) {
            errorMessage = "This field is required";
          } else if (assignmentPoints < 0) {
            errorMessage = "Points must be a positive number";
          }
          setError({
            ...error,
            assignmentName: assignmentName ? null : "This field is required",
            assignmentDetails: assignmentDetails
              ? null
              : "This field is required",
            assignmentFile: assignmentFile ? null : "This field is required",
            assignmentPoints: errorMessage,
          });
          return false;
        }
      }

      if (contentType === "addFile") {
        if (assignmentName && assignmentDetails && assignmentFile) {
          content.textData = assignmentName;
          content.textDataContent = assignmentDetails;

          if (
            typeof assignmentFile === "object" &&
            assignmentFile instanceof File
          ) {
            content.upload_file = assignmentFile;
          } else {
            content.upload_file = uploadFile;
          }
          setError({});
        } else {
          setError({
            ...error,
            assignmentName: assignmentName ? null : "This field is required",
            assignmentDetails: assignmentDetails
              ? null
              : "This field is required",
            assignmentFile:
              assignmentFile || uploadFile ? null : "This field is required",
          });
          return false;
        }
      }

      if (contentType === "quiz") {
        if (quizId) {
          content.quiz = quizId;
        } else {
          setError({
            ...error,
            quizId: "This field is required",
          });
          return false;
        }
      }

      if (contentType === "summary") {
        if (summaryText) {
          content.textDataContent = summaryText;
          setError({});
        } else {
          setError({
            ...error,
            summaryText: "This field is required",
          });
          return false;
        }
      }

      if (contentType === "video") {
        if (videoFile && videoText) {
          content.upload_file = videoFile;
          content.upload_title = videoText;
          setError({});
        } else {
          setError({
            ...error,
            videoFile: videoFile ? null : "This field is required",
            videoText: videoText ? null : "This field is required",
          });
          return false;
        }
      }
      if (contentType === "addQuiz") {
        if (quizDto) {
          const isValid =
            Array.isArray(quizDto.content) && quizDto.content.length > 0;
          if (isValid) {
            content.quizDto = quizDto;
            content.textData = quizDto?.name;
            setError({});
            setQuizopen(false);
          } else {
            setError({
              ...error,
              quizDto: videoFile ? null : "Add at least one Question",
            });
            return false;
          }
        }
      }

      if (isEdit) {
        content.isEdit = true;
        contents[index] = content;
      } else contents.push(content);

      sections[sectionIndex].courseContent = contents;

      formData.isEdit = true;

      setFormData({ ...formData, sections });
    }

    setSectionIndex(0);
    setContentIndex(0);
    setAssignmentName("");
    setAssignmentPoints(null);
    setQuizId(-1);
    setAssignmentDetails("");
    setVideoFile(false);
    setVideoText("");
    setSummaryText("");
    setUploadFile("");
    setAssignmentFile(false);
    closeModals();
    setIsEdit(false);
    setUploadFile("");
    setUploadFileTitle("");
    handleClose();
  };

  const handleQuizChange = (event: SelectChangeEvent<number>) => {
    const target = event.target.value as number;
    setError(false);
    setQuizId(target);
  };

  const uploadContentVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;

    setVideoFile(selectedFiles[0]);
  };
  const uploadContentDocument = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event.target.files as FileList;

    setAssignmentFile(selectedFiles[0]);
  };

  // Callback Hook

  const getQuizData = useCallback(async () => {
    const quiz = await api.get(resolveUrl(AdminApi.QUIZ, params.id));

    setQuiz({
      quiz: quiz.data.quiz,
    });
  }, [params.id]);

  // Use Effect Hook
  useEffect(() => {
    getQuizData();
  }, [getQuizData]);

  // ======== Popover =========

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    sectionIndexElem: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSectionIndex(sectionIndexElem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // a little function to help us with reordering the result
  const reOrderItem = (
    list: CourseSectionData[] | undefined,
    startIndex: number,
    endIndex: number
  ) => {
    if (list) {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    }
  };

  const onDragEnd = (result: any, sectionIndex: number) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (formData && formData.sections) {
      const items = reOrderItem(
        formData.sections[sectionIndex].courseContent,
        result.source.index,
        result.destination.index
      );

      if (items) {
        const itemsWithIndex = items.map((item, index) => ({
          ...item,
          sort: index,
        }));
        setFormData({
          ...formData,
          sections: [
            ...formData.sections.slice(0, sectionIndex),
            {
              ...formData.sections[sectionIndex],
              courseContent: itemsWithIndex,
            },
            ...formData.sections.slice(sectionIndex + 1),
          ],
        });
      }
    }
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "rgb(39 58 92 / 5%)" : "none",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [deleteParams, setDeleteParams] = useState({
    type: "",
    index: 0,
    courseSectionIndex: 0,
  });

  const handleClickOpen = (
    type: string,
    index: number,
    courseSectionIndex: number
  ) => {
    setDeleteParams({ type, index, courseSectionIndex });
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleDeleteConfirmed = () => {
    const { type, index, courseSectionIndex } = deleteParams;
    let tmp_data: any = formData;

    if (type === "section") {
      const delId = tmp_data["sections"][index];
      if (delId.id) {
        formData.secID.push(delId.id);
      }
      setFormData({
        ...formData,
        secID: formData.secID,
      });

      tmp_data["sections"].splice(index, 1);
      setConfirmOpen(false);
    }

    if (type === "content")
      tmp_data["sections"][courseSectionIndex]["courseContent"][
        index
      ].isDelete = true;
    const delContent =
      tmp_data["sections"][courseSectionIndex]["courseContent"][index];
    if (delContent.id) {
      formData.contentID.push(delContent.id);
    }

    setFormData({
      ...formData,
      contentID: formData.contentID,
      sections: tmp_data["sections"],
    });

    setConfirmOpen(false);
  };

  const removeContent = (
    event: FormEvent,
    type: string,
    index: number,
    courseSectionIndex: number = 0
  ) => {
    handleClickOpen(type, index, courseSectionIndex);
  };

  // const removeContent = (
  //   event: FormEvent,
  //   type: string,
  //   index: number,
  //   courseSectionIndex: number = 0
  // ) => {

  //   let tmp_data: any = formData;

  //   if (type === "section") {

  //     const delId = tmp_data["sections"][index];
  //     if (delId.id) {
  //       formData.secID.push(delId.id);
  //     }
  //     setFormData({
  //       ...formData,
  //       secID:  formData.secID,
  //     });

  //     tmp_data["sections"].splice(index, 1);

  //   }
  //   if (type === "content")
  //     tmp_data["sections"][courseSectionIndex]["courseContent"][index].isDelete = true;
  //     const delContent = tmp_data["sections"][courseSectionIndex]["courseContent"][index];
  //   if (delContent.id) {
  //     formData.contentID.push(delContent.id);
  //   }
  //     setFormData({
  //     ...formData,
  //     contentID:  formData.contentID,
  //     sections: tmp_data["sections"],
  //   });

  // };

  const editDisabled = formData.addable === "not_allowed";

  return (
    <>
      <Grid
        item
        xs={12}
        container
        sx={{ mb: 3, mt: 5 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <h2>Subject Content</h2>
        </Grid>
        <Grid item>
          <Button
            className="light-btn"
            variant="contained"
            onClick={(e) => openModal(e, "section")}
            disabled={editDisabled}
            style={editDisabled ? { opacity: 0.5 } : {}}
          >
            Add Section
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        sx={{ mb: 2 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container className="course-content">
          {/* <DragDropContext onDragEnd={onDragEnd} style={{ "width": "100%" }}>
            <Droppable droppableId="section-dropable" style={{ "width": "100%" }}>
              {(provided: any) => {
                return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                > */}
          {/* {errors.sections && (
            <FormHelperText error>{errors.sections}</FormHelperText>
          )} */}
          {formData.sections &&
            formData.sections.map((section: any, sectionIndex: number) => {
              return (
                <>
                  <CourseSectionItem
                    key={sectionIndex}
                    setSecIndex={setSecIndex}
                    secIndex={secIndex}
                    sectionIndex={sectionIndex}
                    section={section}
                    elementID={id}
                    open={open}
                    quiz={quiz}
                    anchorEl={anchorEl}
                    handleClick={handleClick}
                    editSection={editSection}
                    removeContent={removeContent}
                    handleClose={handleClose}
                    editContent={editContent}
                    onDragEnd={onDragEnd}
                    paramsId={params.id ?? ""}
                    editDisabled={editDisabled}
                  />
                  {/* {errors.sections && (
                    <FormHelperText error>{errors.sections}</FormHelperText>
                  )} */}
                </>
                // <Draggable key={sectionIndex} draggableId={`section-${sectionIndex}`} index={sectionIndex}>
                //   {
                //     (provided: any, snapshot: any) => {
                //       return (
                //         <div
                //           ref={provided.innerRef}

                //           {...provided.draggableProps}
                //           {...provided.dragHandleProps}
                //           style={getItemStyle(
                //             snapshot.isDragging,
                //             provided.draggableProps.style
                //           )}
                //         >
                //           <CourseSectionItem
                //             sectionIndex={sectionIndex}
                //             section={section}
                //             elementID={id}
                //             open={open}
                //             quiz={quiz}
                //             anchorEl={anchorEl}
                //             handleClick={handleClick}
                //             editSection={editSection}
                //             removeContent={removeContent}
                //             handleClose={handleClose}
                //             editContent={editContent}
                //             onDragEnd={onDragEnd}
                //           />
                //         </div>
                //       );
                //     }
                //   }
                // </Draggable>
              );
            })}
          {/* {provided.placeholder}
                </div>)
              }} 
            </Droppable>
          </DragDropContext> */}
        </Grid>
      </Grid>
      <CustomModal
        state={modals.section}
        setState={setModals}
        clearSectionIndex={clearSectionIndex}
      >
        <Grid
          className="content-single-popup"
          container
          item
          xs={12}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={4}>
            <InputLabel> Section Name*</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <TextField
              placeholder="Section Name"
              fullWidth
              name="section_name"
              value={sectionName}
              variant="outlined"
              onChange={(e) => setSectionName(e.target.value)}
            />

            {error.sectionName && (
              <FormHelperText error>{error.sectionName}</FormHelperText>
            )}
          </Grid>
          <Button
            className="btn"
            variant="contained"
            onClick={(e) => editSectionCallback(e, sectionIndex)}
          >
            {"Save"}
          </Button>
        </Grid>
      </CustomModal>

      <CustomModal
        state={modals.assignment}
        setState={setModals}
        clearSectionIndex={clearSectionIndex}
      >
        <Grid
          className="content-single-popup "
          container
          item
          xs={12}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={4}>
            <InputLabel> Assignment Title*</InputLabel>
          </Grid>
          <Grid item xs={8} marginBottom={2}>
            <TextField
              placeholder="Assignment Name"
              fullWidth
              name="assignment_name"
              value={assignmentName}
              variant="outlined"
              onChange={(e) => setAssignmentName(e.target.value)}
            />
            {error.assignmentName && (
              <FormHelperText error>{error.assignmentName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Upload File*</InputLabel>
          </Grid>
          <Grid item xs={8} className="browse" marginBottom={2}>
            <input
              name="content_document"
              accept=".doc, .docx, .pdf, .txt, .ppt, .pptx"
              type="file"
              onChange={uploadContentDocument}
            />
            {error.assignmentFile && (
              <FormHelperText error>{error.assignmentFile}</FormHelperText>
            )}
            {(typeof uploadFile === "object" && uploadFile instanceof File) ||
            uploadFile === "" ? (
              ""
            ) : (
              <BucketFileViewer
                name={uploadFileTitle ?? ""}
                filePath={uploadFile}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <InputLabel> Assignment Details*</InputLabel>
          </Grid>
          <Grid item xs={8} marginBottom={2}>
            <Textarea
              placeholder="Assignment Discription"
              name="assignment_discription"
              value={assignmentDetails}
              minRows={4}
              maxRows={4}
              onChange={(e) => setAssignmentDetails(e.target.value)}
            />
            {error.assignmentDetails && (
              <FormHelperText error>{error.assignmentDetails}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <InputLabel> Total Marks*</InputLabel>
          </Grid>
          <Grid item xs={8} marginBottom={2}>
            <TextField
              type="number"
              placeholder="Add marks"
              sx={{ width: "100px" }}
              name="points"
              value={assignmentPoints}
              variant="outlined"
              onChange={(e: any) => setAssignmentPoints(e.target.value)}
            />
            {error.assignmentPoints && (
              <FormHelperText error>{error.assignmentPoints}</FormHelperText>
            )}
          </Grid>
          <Button
            className="btn"
            variant="contained"
            onClick={(e) => editContentCallback(e, "assignment", contentIndex)}
          >
            {"Save"}
          </Button>
        </Grid>
      </CustomModal>

      <CustomModal
        state={modals.quiz}
        setState={setModals}
        clearSectionIndex={clearSectionIndex}
      >
        <Grid
          className="content-single-popup"
          container
          item
          xs={12}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={4}>
            <InputLabel>Quiz*</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Select
              name="quiz"
              fullWidth
              value={quizId || ""}
              sx={{ minWidth: 170 }}
              onChange={handleQuizChange}
              disabled={!quiz?.quiz || quiz?.quiz.length === 0}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Select Option</em>;
                }
                const selectedOption = quiz?.quiz?.find(
                  (option: any) => option?.id === selected
                );
                return selectedOption ? selectedOption.name : "";
              }}
            >
              <MenuItem key="" value="">
                <em>Select Option</em>
              </MenuItem>
              {quiz?.quiz?.length > 0 &&
                quiz.quiz.map((item: any) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>

            {error.quizId && (
              <FormHelperText error>{error.quizId}</FormHelperText>
            )}
          </Grid>
          <Button
            className="btn"
            variant="contained"
            onClick={(e) => editContentCallback(e, "quiz", contentIndex)}
          >
            {"Save"}
          </Button>
        </Grid>
      </CustomModal>

      <CustomModal
        state={modals.summary}
        setState={setModals}
        clearSectionIndex={clearSectionIndex}
      >
        <Grid
          className="content-single-popup"
          container
          item
          xs={12}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={4}>
            <InputLabel>Summary*</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Textarea
              placeholder="Summary"
              name="summary"
              value={summaryText}
              minRows={4}
              maxRows={4}
              onChange={(e) => setSummaryText(e.target.value)}
            />
            {error.summaryText && (
              <FormHelperText error>{error.summaryText}</FormHelperText>
            )}
          </Grid>
          <Button
            className="btn"
            variant="contained"
            onClick={(e) => editContentCallback(e, "summary", contentIndex)}
          >
            {"Save"}
          </Button>
        </Grid>
      </CustomModal>

      <CustomModal
        state={modals.video}
        setState={setModals}
        clearSectionIndex={clearSectionIndex}
      >
        <Grid className="content-single-popup" container xs={12} item>
          <Grid
            container
            item
            xs={12}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Grid item xs={4}>
              <InputLabel>Video Title*</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                placeholder="Video Title"
                fullWidth
                name="video_title"
                value={videoText}
                variant="outlined"
                onChange={(e) => setVideoText(e.target.value)}
              />
              {error.videoText && (
                <FormHelperText error>{error.videoText}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid
            className="browse"
            container
            item
            xs={12}
            sx={{ mt: 3, alignItems: "center", justifyContent: "center" }}
          >
            <Grid item xs={4}>
              <InputLabel>Upload File*</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <input
                name="content_video"
                accept="video/mp4"
                type="file"
                onChange={uploadContentVideo}
              />
              {error.videoFile && (
                <FormHelperText error>{error.videoFile}</FormHelperText>
              )}
              {(typeof uploadFile === "object" && uploadFile instanceof File) ||
              uploadFile === "" ? (
                ""
              ) : (
                <BucketFileViewer
                  name={uploadFileTitle ?? ""}
                  filePath={uploadFile}
                />
              )}
            </Grid>
            <Button
              className="btn"
              variant="contained"
              onClick={(e) => editContentCallback(e, "video", contentIndex)}
            >
              {"Save"}
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
      <CustomModal
        state={modals.addFile}
        setState={setModals}
        clearSectionIndex={clearSectionIndex}
      >
        <Grid
          className="content-single-popup "
          container
          item
          xs={12}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Grid item xs={4}>
            <InputLabel> Title*</InputLabel>
          </Grid>
          <Grid item xs={8} marginBottom={2}>
            <TextField
              fullWidth
              name="assignment_name"
              value={assignmentName}
              variant="outlined"
              onChange={(e) => setAssignmentName(e.target.value)}
            />
            {error.assignmentName && (
              <FormHelperText error>{error.assignmentName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Upload File*</InputLabel>
          </Grid>
          <Grid item xs={8} className="browse" marginBottom={2}>
            <input
              name="content_document"
              accept=".doc, .docx, .pdf, .txt, .ppt, .pptx"
              type="file"
              onChange={uploadContentDocument}
            />

            {error.assignmentFile && (
              <FormHelperText error>{error.assignmentFile}</FormHelperText>
            )}

            {(typeof uploadFile === "object" && uploadFile instanceof File) ||
            uploadFile === "" ? (
              ""
            ) : (
              <BucketFileViewer
                name={uploadFileTitle ?? ""}
                filePath={uploadFile}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <InputLabel sx={{ whiteSpace: "normal" }}>Description*</InputLabel>
          </Grid>
          <Grid item xs={8} marginBottom={2}>
            <Textarea
              name="assignment_discription"
              value={assignmentDetails}
              minRows={4}
              maxRows={4}
              onChange={(e) => setAssignmentDetails(e.target.value)}
            />
            {error.assignmentDetails && (
              <FormHelperText error>{error.assignmentDetails}</FormHelperText>
            )}
          </Grid>

          <Button
            className="btn"
            variant="contained"
            onClick={(e) => editContentCallback(e, "addFile", contentIndex)}
          >
            {"Save"}
          </Button>
        </Grid>
      </CustomModal>
      <div className="inner-container">
        <EditQuizInSubject
          open={quizOpen}
          handleClose={handleQuizClose}
          setQuizDto={setQuizDto}
          quizDto={quizDto}
          editContentCallback={editContentCallback}
          contentIndex={contentIndex}
          isEdit={isEdit}
          errorMsg={error.quizDto}
        />
      </div>
      <Dialog open={ConfirmOpen} onClose={handleConfirmClose}>
        <Box padding={3}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmClose}>Cancel</Button>
            <Button onClick={handleDeleteConfirmed} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
