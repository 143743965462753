import { Avatar, Box, Button, Typography } from "@mui/material";
import styles from "./quize.module.scss";
import { useState } from "react";
import FilterBase from "../filter/filter-base";
import Datatable from "../../../../../components/dataTables/Datatable";
import { useNavigate } from "react-router-dom";
import React from "react";
import { AdminApi } from "../../../../../config/apis";

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

const QuizeTable = () => {
  const [filters, setFilters] = useState<FilterState>();

  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);
  };

  const [value, setValue] = React.useState<number | null>(2);
  const navigate = useNavigate();

  const tableColumns = [
    {
      name: "Quiz Title",
      width: "156px",
      cell: () => (
        <>
          <Typography
            variant="h4"
            className={styles.quizeTableInnerContentBold}
          >
            Quiz Title Name
          </Typography>
        </>
      ),
    },
    {
      name: "Subject Name",
      width: "130px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizeTableInnerContent}>
            Subject title
          </Typography>
        </>
      ),
    },
    {
      name: "Assigned Teacher",
      width: "200px",
      cell: () => (
        <div className={styles.quizeTableInnerTeacher}>
          <Avatar className={styles.quizeTableInnerTeacherAvator}></Avatar>
          <Typography
            variant="h4"
            className={styles.quizeTableInnerTeacherName}
          >
            Adam Smith
          </Typography>
        </div>
      ),
    },
    {
      name: "Status",
      width: "100px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizeTableInnerStatus}>
            Live
          </Typography>
        </>
      ),
    },
    {
      name: "Assigned Student",
      width: "160px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizeTableInnerContent}>
            10
          </Typography>
        </>
      ),
    },
    {
      name: "Completed",
      width: "120px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizeTableInnerContent}>
            150
          </Typography>
        </>
      ),
    },
    {
      name: "Time",
      width: "120px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizeTableInnerContent}>
            15min
          </Typography>
        </>
      ),
    },
    {
      name: "Avg Marks",
      width: "120px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizeTableInnerContent}>
            75
          </Typography>
        </>
      ),
    },
    {
      name: "Total Score",
      width: "140px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.quizeTableInnerContent}>
            100
          </Typography>
        </>
      ),
    },
  ];
  const onViewStudent = async (id: string) => {
    navigate(`/admin/courses-panel/${id}`);
  };

  return (
    <Box className={styles.quizeTableMain}>
      <Typography variant="h2" className={styles.quizeTableTitle}>
        Quizes
      </Typography>
      <FilterBase onFiltersChange={handleFiltersChange} />
      <Box className={styles.quizeTableContainer}>
        <Button className={styles.quizeTableAddBtn}>Add a forum</Button>
        <Box className={styles.quizeTableContent}>
          <div className="page-container quizeTableMainInner">
            <Datatable
              tableColumns={tableColumns}
              dataUrl={AdminApi.COURSES_PAGINATE}
              onView={onViewStudent}
              actions={{
                view: true,
              }}
              module="student-view"
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default QuizeTable;
