import { FormEvent, useEffect, useState } from "react";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Box, InputLabel, TextField, Typography } from "@mui/material";
import styles from "./style.module.scss";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

type props = {
  type?: string;
  heding?: string;
};

export default function GTMCode({ type, heding }: props) {
  const location = useLocation();

  type FormData = {
    keywords?: string;
    type?: string;
  };

  const [errors, setErrors] = useState<any>({});

  const validationSchema = Yup.object().shape({
    keywords: Yup.string().required("This field is required"),
  });

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const [formData, setFormData] = useState<FormData>({
    keywords: "",
    type: type,
  });
  const [onSuccess, setOnSuccess] = useState<any>();

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(resolveUrl(AdminApi.META_TAGS, type));
        setFormData({
          keywords: res.data.keywords ?? "",
          type: type,
        });
        setOnSuccess("");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [onSuccess, type]);

  return (
    <Box className={styles.aSCreateTicketMain}>
      <Typography
        variant="h2"
        className={styles.aSCTTitle}
        sx={{
          padding: "20px 30px",
          textAlign: "center",
          marginBottom: "0!important",
        }}
      >
        {heding ?? ""}
      </Typography>
      <Box className={styles.aSCTContainer}>
        <Form
          submitUrl={AdminApi.META_TAGS_SAVE}
          formData={formData}
          successPath={location}
          validate={customValidation}
          multipart={true}
          onSuccess={setOnSuccess}
        >
          <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              GTM Code
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter GTM Code"
              value={formData.keywords}
              name="keywords"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
              error={errors.keywords !== undefined}
              helperText={errors.keywords}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}
