// import React, { useState } from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../hooks/auth/auth";
import styles from "./studentQuizes.module.scss";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getStudentQuizes,
  truncateTextWithEllipsis,
} from "../../../../../helper/helperFunction";
import { useNavigate } from "react-router-dom";
import { AdminApi, api, resolveUrl } from "../../../../../config/apis";
import { useQuizResContext } from "../../../../../context/QuizResponseContext";
import { toast } from "react-toastify";
import CustomModal from "../../../../../components/popups/Modal";
import FormLoader from "../../../../../components/loader/FormLoader";

interface QuizesData {
  quiz_id: number;
  content_id: number;
  studentQuizId: number;
  textData: string;
  subjectTitle: string;
  timeContent: string;
  quizesStatus: string;
  quizesScore: string;
  quizesScoreStatus: string;
  studentQuizStatus: string;
  quizesLiveStatus: string;
  duration: number;
  course_name: string;
  quiz_name: string;
  obtainedMarks: number | null;
  total_marks: number | null;
}

const StudentQuizesTable = ({ filterState, limit = 1000 }: any) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const userId = auth.state?.user.id;
  const [quizes, setQuizes] = useState<QuizesData[]>([]);
  const [isLoading, setIsLoding] = useState(true);

  useEffect(() => {
    const getQuizes = async () => {
      try {
        const res = await getStudentQuizes(userId ?? "", {
          ...filterState,
          limit: limit,
        });
        setQuizes(res.items);
        setIsLoding(false);
      } catch (error) {
        console.error(error);
        setIsLoding(false);
      }
    };

    getQuizes();

    return () => {};
  }, [filterState, limit, userId]);

  const [isDisabled, setIsDisabled] = useState(false);

  const handleStartNow = async (id: number, duration: number) => {
    try {
      setIsDisabled(true);
      const currentDate = new Date();
      const startingTime = currentDate.toISOString();

      const response = await api.post(resolveUrl(AdminApi.STUDENT_START_QUIZ), {
        quizId: id,
        startingTime: startingTime,
        studentId: userId,
      });

      if (response.data.status) {
        toast.success("Quiz started successfully");
        navigate(`/admin/student-quizes/view/${id}`);
        // setIsDisabled(false);
      } else {
        const { message } = response.data;
      }
    } catch (error) {
      console.error("An error occurred");
    }
  };

  const handleCheckResult = (id: number) => {
    navigate(`/admin/student-quizes/quiz-result/${id}`);
  };
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {!isLoading ? (
        quizes && quizes.length > 0 ? (
          <Box className={styles.quizesTableMain}>
            {quizes.map((item, index: number) => (
              <Card key={index} className={styles.quizesTableRow}>
                <CardContent className={styles.quizesTableTextContent}>
                  <Typography variant="h6" className={styles.titleContent}>
                    <Tooltip title={<span>{item && item.textData}</span>}>
                      <span>
                        {item &&
                          item.quiz_name &&
                          truncateTextWithEllipsis(item.quiz_name ?? "", 20)}
                      </span>
                    </Tooltip>
                  </Typography>

                  <Typography variant="body1" className={styles.subjectContent}>
                    {item.course_name}
                  </Typography>
                  {/* <Typography variant="body1" className={styles.timeContent}>
                    {item.quiz_name}
                  </Typography> */}
                </CardContent>
                <CardContent className={styles.quizesTableStatus}>
                  <Badge color="primary" className={styles.statusIconNew}>
                    {item.studentQuizStatus === "complete"
                      ? "Completed"
                      : item.studentQuizStatus === "resume"
                      ? "Resume"
                      : "Not Scheduled"}
                  </Badge>
                </CardContent>
                <CardContent className={styles.quizesTableScore}>
                  <Typography
                    variant="body1"
                    className={styles.quizesScoreContent}
                  >
                    {/* {item.quizesScore} */}
                    {item.obtainedMarks != null && item.obtainedMarks >= 0
                      ? item.obtainedMarks
                      : "-"}
                    /{item.total_marks}
                  </Typography>
                  {/* <Typography
                    variant="body1"
                    className={styles.quizesScoreStatus}
                  >
                    Qualified
                  </Typography> */}
                </CardContent>

                <CardContent className={styles.quizesTableAction}>
                  {item.studentQuizStatus === "complete" ? (
                    <Button
                      className={styles.quizesBtnCheckResult}
                      onClick={() => handleCheckResult(item.studentQuizId)}
                    >
                      Check Result
                    </Button>
                  ) : (
                    <div>
                      {item.studentQuizStatus === "resume" ? (
                        <Button
                          className={styles.quizesBtnCheckResult}
                          onClick={() =>
                            handleStartNow(item.quiz_id, item.duration)
                          }
                          disabled={isDisabled}
                        >
                          Resume
                        </Button>
                      ) : (
                        // <Button
                        //   className={styles.quizesBtnCheckResult}
                        //   onClick={() => handleStartNow(item.quiz_id,item.duration)}
                        // >
                        //   Start Now
                        // </Button>
                        <Button
                          className={styles.quizesBtnCheckResult}
                          onClick={() => handleOpenModal()}
                        >
                          Start Now
                        </Button>
                      )}
                    </div>
                  )}
                </CardContent>

                <CardContent className={styles.quizeLiveStatus}>
                  {/* <Badge color="primary" className={styles.quizeLiveStatusContent}> */}
                  {/* {item.quizesLiveStatus} */}
                  {/* Live Quiz */}
                  {/* </Badge> */}
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : (
          <Typography sx={{ padding: "30px" }}>
            There are no records to display
          </Typography>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      )}

      <CustomModal state={modalOpen} setState={setModalOpen}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          This Quiz is not schedule.
        </Typography>

        <button className="closeBtn" onClick={handleCloseModal}>
          +
        </button>
      </CustomModal>
    </>
  );
};

export default StudentQuizesTable;
