import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SingleCourseCard from "./single-course-card";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import styles from "./cousre-catalog.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, Typography } from "@mui/material";
import { getAllStuCoursesOutlet } from "../../../../../helper/helperFunction";
import { useAuth } from "../../../../../hooks/auth/auth";
import { AdminApi, api, resolveUrl } from "../../../../../config/apis";

type Courses = {
  courses: any[];
};

export default function ViewCourse() {
  const navigate = useNavigate();
  const { state } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const params = state?.user;

  const [allCourse, setAllCourse] = useState<Courses>({ courses: [] });

  const handlePurchaseNow = (id: string) => {
    navigate(`/admin/student-courses/view/${id}`);
  };

  const fetchAllCourses = React.useCallback(async () => {
    try {
      const course = await api.get(
        resolveUrl(AdminApi.STUDENT_ASSIGN_COURSES, `${params.id}`)
      );
      setAllCourse({
        courses: course.data.items,
      });
      setIsLoading(true);
    } catch (error) {
      console.error(error);
    }
  }, [params.id]);

  React.useEffect(() => {
    fetchAllCourses();
  }, [fetchAllCourses, params.id]);

  return (
    <>
      {isLoading ? (
        allCourse.courses.length !== 0 ? (
          <Grid container spacing={3} sx={{ ml: "0" }}>
            {allCourse.courses.slice(0, 3).map((items: any) => {
              return (
                <Grid item xs={4} key={items.id}>
                  <SingleCourseCard
                    progress={items.progress}
                    title={items.name}
                    price={items.price}
                    handlePurchaseNow={() => handlePurchaseNow(items.slug)}
                    futureImage={items.featured_image}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography sx={{ padding: "30px" }}>
            <p>No courses available.</p>
          </Typography>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      )}
    </>
  );
}
