import { Typography } from "@mui/material";
import ShadeImg from "./asset/img/sectionShade.png";
// import Img1 from './asset/img/img1.jpg';
// import Img2 from './asset/img/img2.jpg';
// import Img3 from './asset/img/img3.jpg';
import PlaceHolderImage from "./asset/img/placeholder.webp";
import "./style/style.css";
import { IBestLearningExp } from "../type/type";
import ImageViewer from "../../../components/imageViewer";
import { useReadMorePost } from "../../../helper/helperFunction";
import Slider from "react-slick";

interface IData {
  Data: IBestLearningExp;
}

const BestLearningExp = function ({ Data }: IData) {
  const { isExpanded, toggleReadMore, truncateText } = useReadMorePost(250);
  const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1167,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div
      className={`bestLearningMain ${
        Data && Data.image ? "" : "bestLearningMainbefore"
      }`}
    >
      <div className="container">
        <div className="textContainer">
          <div
            className="sectionBanner"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            {Data && Data.image ? (
              <ImageViewer
                imagePath={Data?.image ?? ""}
                altText={Data?.title}
              />
            ) : (
              <img src={ShadeImg} alt="sectionShade" />
            )}
          </div>
          <div
            className="textContain"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <Typography variant="h2">{Data.title ?? ""}</Typography>
            <Typography variant="body1">{Data.description ?? ""}</Typography>
          </div>
        </div>
        <div className="cardContainer">
          <div
            className="cardContain"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <Slider {...sliderSettings} className="customSlider">
              {Data &&
                Data.cardData.map((item, index) => (
                  <div className="card" key={index}>
                    {item.image ? (
                      <ImageViewer
                        imagePath={item.image ?? ""}
                        altText={item.title}
                      />
                    ) : (
                      <img src={PlaceHolderImage} alt="imageNo1" />
                    )}
                    <Typography variant="h4">{item.title}</Typography>
                    <Typography variant="body1">
                      {item.description &&
                        (isExpanded(index)
                          ? item.description
                          : truncateText(item.description))}
                      {item.description && item.description.length > 250 && (
                        <button
                          className="read_more_btn"
                          onClick={() => toggleReadMore(index)}
                        >
                          {isExpanded(index) ? "Read Less" : "Read More"}
                        </button>
                      )}
                    </Typography>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestLearningExp;
