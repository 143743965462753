import Datatable from "../../../../../components/dataTables/Datatable";
import { Box, Rating, Typography } from "@mui/material";
import { AdminApi } from "../../../../../config/apis";
import { useNavigate } from "react-router-dom";
import styles from "./course.module.scss";
import React from "react";
import ImgBase from "../../assets/play-img.png";

export default function CourseDetailTable() {
  const [value, setValue] = React.useState<number | null>(2);
  const navigate = useNavigate();

  const tableColumns = [
    {
      name: "Subject Name",
      minWidth: "440px",
      maxWidth: "calc(100% - 120px - 180px - 120px - 150px - 110px)",
      cell: () => (
        <>
          <Box className={styles.courseTextNameContent}>
            <Box className="togglebutton" component="img" src={ImgBase} />
            <Typography variant="h4">Subject title</Typography>
            <Rating
              name="simple-controlled"
              className={styles.courseRating}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
          </Box>
        </>
      ),
    },
    {
      name: "Lesson",
      width: "120px",
      cell: () => (
        <div className={styles.courseTableContent}>
          <Typography variant="body1" className={styles.courseTextContent}>
            10
          </Typography>
        </div>
      ),
    },
    {
      name: "Courses Compeleted",
      width: "180px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.courseTextContent}>
            150
          </Typography>
        </>
      ),
    },
    {
      name: "Total Time",
      width: "120px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.courseTextContent}>
            150min
          </Typography>
        </>
      ),
    },
    {
      name: "Active Students",
      width: "150px",
      cell: () => (
        <>
          <Typography variant="body1" className={styles.courseTextBoldContent}>
            80
          </Typography>
        </>
      ),
    },
  ];
  const onViewStudent = async (id: string) => {
    navigate(`/admin/courses-panel/${id}`);
  };

  return (
    <>
      <Box className="page-container" style={{ padding: 0 }}>
        <Box className={styles.coursePanelDetailContainer}>
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.COURSES_PAGINATE}
            onView={onViewStudent}
            actions={{
              view: true,
            }}
            module="student-view"
          />
        </Box>
      </Box>
    </>
  );
}
