import { FormEvent, useEffect, useState } from "react"
import Form from "../../../components/forms/Form"
import { AdminApi, api } from "../../../config/apis"
import { Box, InputLabel, TextField } from "@mui/material";
import styles from './style.module.scss'
import * as Yup from "yup";



export default function WhyStudyHeading() {


    type FormData = {
        title: string,
    }

    const [errors, setErrors] = useState<any>({});

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("This field is required"),
    });

    const customValidation = async () => {
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});

            const newErrors = {};

            setErrors((prevErrors: any) => ({
                ...prevErrors,
                ...newErrors,
            }));

            return Object.keys(newErrors).length === 0;
        } catch (error: any) {
            const newErrors: any = {};

            error.inner.forEach((validationError: any) => {
                newErrors[validationError.path] = validationError.message;
            });

            setErrors(newErrors);
            return false;
        }
    };


    const [formData, setFormData] = useState<FormData>({
        title: '',
    });

    const [onSucces, setOnSucces] = useState<any>();

    const handleOnChange = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        setFormData({
            ...formData,
            [target.name]: target.value,
        });
        setErrors((prevErrors: any) => ({
            ...prevErrors,
            [target.name]: undefined,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await api.get(AdminApi.WHY_STUDY_ONLINE_INFO_VIEW);
            setFormData({
                title: res.data.viewStudyOnline.title ?? ""
            })            
        }
        fetchData();
    }, [onSucces])



    return (
        <Box className={styles.aSCreateTicketMain}>
            <Box className={styles.aSCTContainer}>
                <Form
                    submitUrl={AdminApi.WHY_STUDY_ONLINE_INFO_SAVE}
                    formData={formData}
                    successPath="/admin/cms/home"
                    validate={customValidation}
                    onSucces={setOnSucces}
                >
                    <Box className={styles.aSCTContentTitle}>
                        <InputLabel sx={{ textAlign: "left" }} className={styles.aSCTContentTitleLabel} >Title</InputLabel>
                        <TextField
                            fullWidth
                            placeholder="Enter Title"
                            value={formData.title}
                            name="title"
                            variant="outlined"
                            onChange={handleOnChange}
                            className={styles.aSCTContentTitleInput}
                            error={errors.title !== undefined}
                            helperText={errors.title}
                        />
                    </Box>

                </Form>
            </Box>
        </Box>
    )
}
