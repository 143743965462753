import React, { useState } from 'react';
import Switch from "@mui/joy/Switch";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AdminApi, api, resolveUrl } from '../../config/apis';
import { toast } from 'react-toastify';

interface SwitchCellProps {
    id: string;
    is_active: boolean;
}

const SwitchCell: React.FC<SwitchCellProps> = ({ id, is_active }) => {
    const [checked, setChecked] = useState<boolean>(is_active);
    const [openConfirm, setOpenConfirm] = useState(false);

    const toggleConfirmDialog = () => {
        setOpenConfirm(!openConfirm);
    };

    const onActive = async () => {
        try {
            const response = await api.get(resolveUrl(AdminApi.USER_ACTIVE_ADMIN, id));
            setChecked(!checked); // Toggle the switch
            setOpenConfirm(false);
            toast.success('User activated/deactivated successfully');
        } catch (error) {
            setOpenConfirm(false);
            toast.error('Error activating/deactivating user');
        }
    };

    return (
        <>
            <Switch
                checked={checked}
                onChange={toggleConfirmDialog} // Open confirmation dialog on switch change
                sx={{
                    "--Switch-trackWidth": "33.49px",
                    "--Switch-trackHeight": "18.41px",
                    "--Switch-thumbSize": "12px",
                }}
            />

            <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box sx={{ textAlign: "center", padding: "20px" }}>
                    <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to activate/deactivate this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button className="btn" onClick={toggleConfirmDialog}>Disagree</Button>
                        <Button className="btn" onClick={onActive} autoFocus>Agree</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export default SwitchCell;
