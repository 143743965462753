import BestLearningExp from "../best-learning-exp";
import TourFromMain from "../guided-tour-form";
import HomeSchoolingInner from "../home-schooling-2";
import InnerBanner from "../inner-banner";
import StudyEdexOnlineInner from "../study-edexOnline-2";
import Layout from "../../../layout/frontend/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  IAboutBanner,
  IBestLearningExp,
  HomePageContentInfo,
  Slide,
} from "../type/type";
import { useEffect, useState } from "react";
import { FrontendApi, api, resolveUrl } from "../../../config/apis";
import { CircularProgress } from "@mui/joy";
import EnrollNow from "../enroll-from";
import HomeSchooling from "../home-schooling";
import { Helmet, HelmetProvider } from "react-helmet-async";

type TmetaData = {
  title: string;
  description: string;
  keywords: string;
  image: string;
};

const AboutPage = function () {
  const { REACT_APP_SERVER_BASE } = process.env;

  const [aboutBanner, setBanner] = useState<IAboutBanner>();
  const [schoolingInfo, setSchoolingInfo] = useState<HomePageContentInfo>();
  const [bestLearningExp, setBestLearningExp] = useState<IBestLearningExp>({
    title: "",
    description: "",
    image: "",
    cardData: [],
  });

  const [schooling, setSchooling] = useState<Slide[]>();
  const [studyOnlineInfo, setStudyOnlineInfo] = useState<HomePageContentInfo>();
  const [studyOnline, setStudyOnline] = useState<Slide[]>([]);
  const [enrollNowInfo, setEnrollNowInfo] = useState<HomePageContentInfo>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(FrontendApi.ABOUT_PAGE_CMS);
        const { description, title, image } =
          res.data.AboutPageInfo.OrganizationalExcellence;
        const { OrganizationalExcellence } = res.data.AboutPageContent;
        const { Banner } = res.data.AboutPageInfo;
        setBanner(Banner);
        setBestLearningExp({
          title: title ?? "",
          image: image ?? "",
          description: description ?? "",
          cardData: OrganizationalExcellence ?? [],
        });

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(FrontendApi.HOME_PAGE_CMS);
        setSchoolingInfo(res.data.HomePageInfo.Schooling);
        setSchooling(res.data.HomePageContent.Schooling);
        setStudyOnlineInfo(res.data.HomePageInfo.StudyOnline);
        setStudyOnline(res.data.HomePageContent.StudyOnline);
        setEnrollNowInfo(res.data.HomePageInfo.EnrollNow);

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [metaData, setmetaData] = useState<TmetaData>();

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const res = await api.get(
          resolveUrl(FrontendApi.META_TAGS_VIEW, "about-page")
        );
        setmetaData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMetaData();
  }, []);

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      </>
    );
  }

  // Initialize AOS
  AOS.init();
  const imageUrl = `${REACT_APP_SERVER_BASE}${metaData?.image}`;

  return (
    <>
      <Layout enrollNowInfo={enrollNowInfo}>
        <HelmetProvider>
          <Helmet>
            <title>{metaData && metaData.title}</title>
            <meta
              name="description"
              content={metaData && metaData.description}
            />
            <meta name="keywords" content={metaData && metaData.keywords} />
            <meta property="og:image" content={metaData && imageUrl} />
            <meta property="og:image:width" content="2500" />
            <meta property="og:image:height" content="933" />
            <meta property="og:image:type" content="image/jpeg" />

            <meta
              property="og:image:alt"
              content={metaData && metaData.title}
            />
            <meta
              property="og:description"
              content={metaData && metaData.description}
            />
            <meta property="og:title" content={metaData && metaData.title} />

            {/* <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:site" content="@exampletwitter" />
                        <meta name="twitter:title" content="Mountain Sunset" />
                        <meta name="twitter:description" content="A beautiful sunset over the mountains" />
                        <meta name="twitter:image" content="https://example.com/path/to/image.jpg" /> */}
          </Helmet>
        </HelmetProvider>

        <InnerBanner bannerData={aboutBanner} />
        <BestLearningExp Data={bestLearningExp} />
        {/* <HomeSchoolingInner schooling={schooling} schoolingInfo={schoolingInfo} /> */}
        <HomeSchooling
          schooling={schooling}
          schoolingInfo={schoolingInfo}
          bgColor="#FAF6EC"
        />
        <StudyEdexOnlineInner
          studyOnlineInfo={studyOnlineInfo}
          studyOnline={studyOnline}
        />
        {/* <TourFromMain /> */}
      </Layout>
    </>
  );
};

export default AboutPage;
