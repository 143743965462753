import React, { useState } from "react";
import styles from "../manage-discount.module.scss";
import { Button, TextField, Grid, Alert } from "@mui/material";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { useCartContext } from "../../../../context/CartContext";
import { toast } from "react-toastify";

const CourseDiscount = () => {
  const [discountCode, setDiscountCode] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { courseId, cartId, updateCartData } = useCartContext();

  const handleSubmit = async () => {
    if (discountCode === "") {
      setErrors(["Please enter a discount code"]);
      return;
    }

    try {
      const response = await api.post(resolveUrl(AdminApi.DISCOUNT_CODE), {
        discountCode: discountCode,
        courseId: courseId,
        cartId: cartId,
      });
      setErrors([]);
      toast.success("Discount code applied successfully");
      updateCartData();
      setIsSubmitted(true);
    } catch (error: any) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrors([error.response.data.message]);
      } else {
        setErrors(["An error occurred. Please try again."]);
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={styles.courseDiscountForm}>
          <TextField
            value={discountCode}
            onChange={(event) => setDiscountCode(event.target.value)}
            className={styles.courseDiscountInput}
            // error={errors.length > 0}
            // helperText={errors.length > 0 ? errors[0] : ""}
            disabled={isSubmitted}
          />
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            className={styles.courseDiscountBtn}
            disabled={isSubmitted}
          >
            Discount Code
          </Button>
          {errors.length > 0 && (
            <Alert severity="error" className={styles.courseDiscountError}>
              <ul style={{ padding: "0", margin: "0" }}>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </Alert>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default CourseDiscount;
