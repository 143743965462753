import Layout from "../../../layout/frontend/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { FrontendApi, api, resolveUrl } from "../../../config/apis";
import { CircularProgress } from "@mui/joy";
import { HomePageContentInfo } from "../type/type";
import { FetchPopupData } from "../../../helper/helperFunction";
import { Container, Grid } from "@mui/material";

type TmetaData = {
  title: string;
  description: string;
  keywords: string;
  image: string;
};

const TermsConditions = function () {
  const [enrollNowInfo, setEnrollNowInfo] = useState<HomePageContentInfo>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(FrontendApi.HOME_PAGE_CMS);
        setEnrollNowInfo(res.data.HomePageInfo.EnrollNow);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [poupData, setPopupData] = useState<string>("");
  const type = "terms-conditions";
  useEffect(() => {
    const fetchData = async () => {
      await FetchPopupData(type, setPopupData);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      </>
    );
  }

  AOS.init();
  return (
    <>
      <Layout enrollNowInfo={enrollNowInfo}>
        <Grid
          container
          spacing={4}
          sx={{ backgroundColor: "#fff" }}
          marginTop={2}
        >
          <Grid item xs={12} padding={5}>
            <div dangerouslySetInnerHTML={{ __html: poupData }}></div>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default TermsConditions;
