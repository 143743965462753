import React, { useEffect, useState } from "react";
import styles from "../manage-discount.module.scss";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { getCartData } from "../../../../helper/helperFunction";
import { useAuth } from "../../../../hooks/auth/auth";
import { api, resolveUrl, AdminApi } from "../../../../config/apis";
import { useCartContext } from "../../../../context/CartContext";
import { CURRENCY_SYMBOL } from "../../../../config/constants/constants";
import playvideo from "../../../../static/images/play-img.png";
import { useNavigate } from "react-router-dom";

interface CartItem {
  id: number;
}

interface CartDataType {
  cartItem: CartItem;
  id: number;
  individualPrice: string;
  courseName: string;
  studentName: string;
  courseFeatureImage?: string;
}

const CourseListContent = () => {
  const { REACT_APP_SERVER_BASE } = process.env;
  // get parent id from useAuth
  const Parent = useAuth();
  const cardId = Parent.state?.user.id;

  const [cardData, setCardData] = useState<CartDataType[] | null>(null);

  const { subTotalPrice, updateCartData, totalItemCount } = useCartContext();

  useEffect(() => {
    async function fetchData() {
      const data = await getCartData(cardId);
      setCardData(data.cartItems);
    }

    fetchData();
  }, [cardId, subTotalPrice]);
  const nevigate = useNavigate();

  useEffect(() => {
    if (cardData && cardData.length === 0) {
      nevigate("/admin/course-catalog");
    }
  }, [cardData]);

  const handleDelete = async (id: number) => {
    try {
      await api.delete(resolveUrl(AdminApi.CART_ITEM_REMOVE, id.toString()));
      updateCartData();
    } catch (error) {
      console.error(error);
    }
  };

  if (!cardData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress sx={{ color: "#d6c38f" }} />
      </div>
    );
  }

  return (
    <>
      <Box sx={{ width: "100%" }} className={styles.couserList}>
        {cardData.map((items, index) => (
          <Grid
            key={index}
            container
            spacing={2}
            className={styles.couserListContent}
          >
            <Box className={styles.couserThumbnailContent}>
              <img
                src={
                  `${items.courseFeatureImage}` ??
                  playvideo
                }
                alt={items.courseName}
              />
            </Box>
            <Box className={styles.couserTextContent}>
              <Typography
                variant="h3"
                sx={{ mt: 2 }}
                className={styles.couserTitle}
              >
                {items.courseName}
              </Typography>
              <Typography
                variant="h3"
                sx={{ mt: 2 }}
                className={styles.couserTitle}
              >
                {items.studentName}
              </Typography>
              <Typography sx={{ mt: 2 }} className={styles.couserPrice}>
                {items.individualPrice}
              </Typography>
            </Box>
            <Box className={styles.couserContentAction}>
              <Button
                variant="contained"
                color="primary"
                className={styles.couserActionBtn}
                onClick={() => handleDelete(items.cartItem.id)}
              >
                D
              </Button>
            </Box>
          </Grid>
        ))}
      </Box>
      <Grid className={styles.purchaseListBottom}>
        {/* <Button variant="contained" className={styles.purchaseUpdateBtn}>
          Update Cart
        </Button> */}
        <Box className={styles.purchaseListDetail}>
          <Grid className={styles.purchaseQty}>
            <Typography
              variant="body1"
              className={styles.purchaseQtyTextStatic}
            >
              Qty
            </Typography>
            <Typography
              variant="body1"
              className={styles.purchaseQtyTextDynamic}
            >
              {totalItemCount}
            </Typography>
          </Grid>
          <Grid className={styles.purchaseTotal}>
            <Typography variant="body1" className={styles.purchaseTotalStatic}>
              Total
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={styles.purchaseTotalDynamic}
            >
              {CURRENCY_SYMBOL}
              {subTotalPrice}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default CourseListContent;
