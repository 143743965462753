import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi } from "../../../config/apis";
import { DateView } from "../../../components/DateView/DateView";
import { Avatar, Box } from "@mui/material";
import { toTitleCase } from "../../../helper/helperFunction";
import AvatarImage from "../../../components/Avatar";

export default function TopTeachers() {
  const navigate = useNavigate();

  const tableColumns = [
    {
      cell: (row: { profile: any; name: string }) => (
        <>
          <AvatarImage imageName={row.profile} name={row.name} />
          {toTitleCase(`${row.name}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      minWidth: "200px",
    },
    {
      selector: (row: { courses: string }) => row.courses,
      name: "Subjects",
      sortable: false,
      minWidth: "100px",
    },
    {
      selector: (row: { students: string }) => row.students,
      name: "Students",
      sortable: false,
      minWidth: "100px",
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/user/teachers/view/${id}`);
  };

  return (
    <>
      <Box className="page-container" pt={0} mb={0}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.TOP_TEACHERS}
          onView={onView}
          actions={{
            view: true,
            delete: false,
            edit: false,
          }}
          pagination={false}
          module="manage-users"
        />
      </Box>
    </>
  );
}
