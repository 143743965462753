import { useEffect, useState } from "react";
import styles from "./studnetCourses.module.scss";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  TextField,
  Typography,
  CardContent,
  CardMedia,
  Card,
  Avatar,
} from "@mui/material";
import { useStudentCourseData } from "../fetch-data/useCourseData";
import Datatable from "../../../../../components/dataTables/Datatable";
import { useAuth } from "../../../../../hooks/auth/auth";
import { AdminApi } from "../../../../../config/apis/admin";
import { resolveUrl } from "../../../../../config/apis";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import playvideo from "../../../../../static/images/play-img.png";
import {
  GetUserName,
  getAssinTeachers,
} from "../../../../../helper/helperFunction";
import { SERVER_KEY } from "../../../../../config/constants/EnviormentVariables";
import ProgressCourseCell from "../../../../../components/BucketProgressBarSubject";
import { toTitleCase } from "../../../../../helper/helperFunction";
import AvatarImage from "../../../../../components/Avatar";

interface Teacher {
  id: string;
  name: string;
}

interface TeacherName {
  id: number;
  first_name: string;
  last_name: string;
}

interface Course {
  id: number;
  name: string;
  teacher: TeacherName;
  progress: string;
  hours: number;
  minutes: number;
  featured_image?: string;
}

const StudentCourseList = ({ filterState }: any) => {
  const auth = useAuth();
  const userId = auth.state?.user.id;

  // Status
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#071836" : "#308fe8",
    },
  }));

  const navigate = useNavigate();

  const handleClick = (slug: string) => {
    navigate(`/admin/student-courses/view/${slug}`);
  };

  const baseUrl = SERVER_KEY;

  const tableColumns = [
    {
      cell: (row: Course) => (
        <>
          <ProgressCourseCell
            name={row.name}
            progress={row.progress ? parseInt(row.progress) : 0}
            image={row.featured_image}
          />
        </>
      ),
      name: "Subject Name",
      width: "300px",
      sortable: true,
    },
    {
      cell: (row: {
        teacherProfile: string;
        teacher: { first_name: string; last_name: string };
      }) => (
        <>
          <AvatarImage
            imageName={row.teacherProfile}
            name={row.teacher.first_name}
          />
          {toTitleCase(`${row.teacher.first_name} ${row.teacher.last_name}`)}
        </>
      ),
      name: "Assigned Teacher",
      width: "250px",
      sortable: false,
    },
    {
      cell: (row: { totalLessons: string }) => (
        <Grid container className={styles.courseTableColTotalLessons}>
          <Grid item className={styles.courseTableColTotalLessonsContent}>
            <Typography
              variant="h4"
              className={styles.courseTableColTotalLessonsContentText}
            >
              {row.totalLessons}
            </Typography>
          </Grid>
        </Grid>
      ),

      name: "Total Lessons",
      // width: "150px",
      sortable: false,
    },
    // {
    //   cell: (row: Course) => (
    //     <Grid container className={styles.courseTableColTotalTime}>
    //       <Grid item className={styles.courseTableColTotalTimeContent}>
    //       <Typography variant="h4" className={styles.courseTableColTotalTimeContentText}>{((row.hours * 60) + row.minutes)} min</Typography>
    //       </Grid>
    //     </Grid>
    //   ),
    //   name: "Total Time",
    //   width: "150px",
    //   sortable: false,
    // },
    {
      cell: (row: { quizScore: string }) => (
        <Grid container className={styles.courseTableColMark}>
          <Grid item className={styles.courseTableColMarkContent}>
            <Typography
              variant="h4"
              className={styles.courseTableColMarkContentText}
            >
              {row.quizScore === "NaN" ? "N/A" : `${row.quizScore}%`}
            </Typography>
          </Grid>
        </Grid>
      ),
      name: "Quiz Score",
      // width: "150px",
      sortable: false,
    },
    {
      cell: (row: { assignmentScore: string }) => (
        <Grid container className={styles.courseTableColMark}>
          <Grid item className={styles.courseTableColMarkContent}>
            <Typography
              variant="h4"
              className={styles.courseTableColMarkContentText}
            >
              {row?.assignmentScore}%
            </Typography>
          </Grid>
        </Grid>
      ),
      name: "Assignment Score",
      width: "200px",
      sortable: false,
    },
    {
      cell: (row: { slug: string }) => (
        <>
          <Button
            className="dark-btn"
            variant="contained"
            onClick={() => handleClick(row.slug)}
          >
            View
          </Button>
        </>
      ),
      name: "",
      sortable: false,
      width: "155px",
    },
  ];

  return (
    <>
      <div className="page-container courseDataTable">
        <Datatable
          className={styles.courseDataTable}
          tableColumns={tableColumns}
          dataUrl={resolveUrl(AdminApi.STUDENT_ASSIGN_COURSES, `${userId}`)}
          actions={{
            view: false,
            edit: false,
            delete: false,
          }}
          filters={filterState}
          actionColumns={false}
          module="manage-student-courses"
        />
      </div>
    </>
  );
};

export default StudentCourseList;
