import styles from "./manage-quize.module.scss";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { useParams } from "react-router-dom";
import {
  InputLabel,
  TextField,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Textarea from "@mui/joy/Textarea";
import * as Yup from "yup";

interface QuizData {
  name: any;
  description: string;
  tags: string[];
  totalMarks: number | null;
  duration: any;
  is_live: boolean;
  content: QuizContent[];
  courseId?: number;
}

interface QuizContent {
  question: string;
  answers: string[];
  answer_key: number[];
  type: string;
  id: any;
}

export const QuizDescription = ({
  setFormData,
  formData,
  setCustomValue,
}: any) => {
  const params = useParams();

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      [target.name]: target.value,
    });

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };
  const handleChangeTags = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tags = event.target.value.split(",");
    setFormData({ ...formData, tags });
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hours = Number(event.target.value);
    const minutes = ((formData as QuizData).duration ?? 0) % 60;
    const duration = hours * 60 + minutes;

    setFormData((prevData: any) => ({
      ...prevData,
      duration: duration === 0 ? "" : duration,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      duration: undefined,
    }));
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hours = formData.duration ? Math.floor(formData.duration / 60) : 0;
    const minutes = Number(event.target.value);
    const duration = hours * 60 + minutes;

    setFormData((prevData: any) => ({
      ...prevData,
      duration: duration === 0 ? "" : duration,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      duration: undefined,
    }));
  };

  // const editData = useCallback(async () => {
  //   if (params.id) {
  //     const res = await api.get(resolveUrl(AdminApi.QUIZ_VIEW, params.id));

  //     setFormData({
  //       courseId: res.data.course.id,
  //       name: res.data.quiz.name,
  //       description: res.data.quiz.description,
  //       tags: res.data.quiz.tags !== "" ? JSON.parse(res.data.quiz.tags) : "",
  //       duration: res.data.quiz.duration,
  //       totalMarks: res.data.quiz.totalMarks,
  //       content: res.data.quiz.contents.map((contentItem: any) => ({
  //         question: contentItem.question,
  //         answers: JSON.parse(contentItem.answers),
  //         answer_key: JSON.parse(contentItem.answer_key),
  //         type: contentItem.type,
  //       })),

  //       is_live: false,
  //     });
  //   }
  // }, [params.id]);

  // useEffect(() => {
  //   editData();
  // }, [editData]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Quiz title is required"),
    // tags: Yup.string().required("Tags title is required"),
    duration: Yup.string().required("Duration title is required"),
    totalMarks: Yup.number()
      .min(0, "Total Marks must be positive number")
      .required("Total Marks is required"),
  });

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleNext = async () => {
    const isValid = await customValidation();
    if (isValid) {
      setCustomValue(1);
    }
  };
  return (
    <>
      <div className="inner-container">
        <form>
          <Grid
            className={styles.qEFFieldContainer}
            item
            xs={12}
            container
            sx={{ mb: 3, alignItems: "center" }}
          >
            <Grid className={styles.qEFFieldContainerLabel} item>
              <InputLabel sx={{ textAlign: "left" }}>Quiz Title*</InputLabel>
            </Grid>
            <Grid item className={styles.qEFFieldContainerInput}>
              <TextField
                fullWidth
                value={formData.name}
                name="name"
                variant="outlined"
                onChange={handleOnChange}
                error={errors.name !== undefined}
                helperText={errors.name}
              />
            </Grid>
          </Grid>

          <Grid
            className={styles.qEFFieldContainer}
            item
            xs={12}
            container
            sx={{ mb: 3 }}
          >
            <Grid item className={styles.qEFFieldContainerLabel}>
              <InputLabel sx={{ pt: 2 }}>Description</InputLabel>
            </Grid>
            <Grid item className={styles.qEFFieldContainerTextArea}>
              <Textarea
                placeholder="Description"
                name="description"
                minRows={4}
                maxRows={4}
                defaultValue={formData.description}
                onChange={handleOnChange}
              />
              {errors.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            className={styles.qEFFieldContainer}
            item
            xs={12}
            container
            sx={{ mb: 3, alignItems: "center" }}
          >
            <Grid item className={styles.qEFFieldContainerLabel}>
              <InputLabel sx={{ textAlign: "left" }}>Tags</InputLabel>
            </Grid>
            <Grid item className={styles.qEFFieldContainerInput2}>
              <TextField
                placeholder="Enter Tags"
                variant="outlined"
                value={formData.tags && formData.tags.join(",")}
                fullWidth
                onChange={handleChangeTags}
              />
              {errors.tags && (
                <FormHelperText error>{errors.tags}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            className={styles.qEFFieldContainer}
            item
            xs={12}
            container
            sx={{ mb: 3, alignItems: "center" }}
          >
            <Grid item className={styles.qEFFieldContainerLabel}>
              <InputLabel>Duration*</InputLabel>
            </Grid>
            <Grid container className={styles.qEFFieldContainerDateNTime}>
              <Grid item className={styles.qEFFieldContainerDateNTimeContent}>
                <TextField
                  placeholder="0"
                  type="number"
                  onChange={handleHoursChange}
                  value={Math.floor(formData.duration / 60) || ""}
                  className={styles.qEFCDNTContentInput}
                />
                <Typography variant="body1">Hours</Typography>
              </Grid>
              <Grid item className={styles.qEFFieldContainerDateNTimeContent}>
                <TextField
                  placeholder="0"
                  type="number"
                  value={formData.duration % 60 || ""} // Display minutes
                  onChange={handleMinutesChange}
                  className={styles.qEFCDNTContentInput}
                />
                <Typography variant="body1">Mins</Typography>
              </Grid>
              <Grid item xs={12}>
                {errors.duration && (
                  <FormHelperText error>{errors.duration}</FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={styles.qEFFieldContainer}
            item
            xs={12}
            container
            sx={{ mb: 3, alignItems: "center" }}
          >
            <Grid item className={styles.qEFFieldContainerLabel}>
              <InputLabel sx={{ textAlign: "left" }}>Total Marks*</InputLabel>
            </Grid>
            <Grid item className={styles.qEFFieldContainerLabelInput3}>
              <TextField
                variant="outlined"
                type="number"
                name="totalMarks"
                value={formData.totalMarks}
                fullWidth
                onChange={handleOnChange}
                error={errors.totalMarks !== undefined}
                helperText={errors.totalMarks}
              />
            </Grid>
          </Grid>
          <Button className="btn" onClick={() => handleNext()}>
            next
          </Button>
        </form>
      </div>
    </>
  );
};
