import { FormEvent, useCallback, useEffect, useState } from "react";
import { GetAuthState, toTitleCase } from "../../../helper/helperFunction";
import Form from "../../../components/forms/Form";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import { useParams } from "react-router-dom";
import styles from "./manage-complaints.module.scss";
import { GetUserRole } from "../../../helper/helperFunction";

export default function EditComplaints() {
  const params = useParams();

  type FormData = {
    // title: string;
    description: string;
    defendantId: string;
    complainantId: string;
  };

  const CURRENT_USER = GetAuthState("USER");
  const userRole = GetUserRole();

  const [formData, setFormData] = useState<FormData>({
    // title: "",
    description: "",
    defendantId: "",
    complainantId: CURRENT_USER.id,
  });
  

  const [userList, setUserList] = useState<any>([]);
  

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const selectedValue = event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: selectedValue,
    }));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (userRole === "Parent") {
          const res = await api.get(resolveUrl(AdminApi.TEACHERS));

          setUserList(res.data.items);
        } else if (userRole === "Teacher") {
          const res = await api.get(
            resolveUrl(AdminApi.CHILD_LIST, `${CURRENT_USER.id}`)
          );
          setUserList(res.data);
        }
      } catch (error) {
        // Handle any errors that occur during API calls or data retrieval
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, [CURRENT_USER.id, userRole]);

  return (
    <Box className={styles.aSCreateTicketMain}>
      <Typography variant="h2" className={styles.aSCTTitle}>
        Create New Complaint
      </Typography>
      <Box className={styles.aSCTContainer}>
        <Form
          submitUrl={AdminApi.COMPLAINTS_EDIT}
          formData={formData}
          editId={params.id}
          successPath="/admin/complaints"
        >
          {/* <Box className={styles.aSCTContentTitle}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTitleLabel}
            >
              Title
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Title"
              value={formData.title}
              name="title"
              variant="outlined"
              onChange={handleOnChange}
              className={styles.aSCTContentTitleInput}
            />
          </Box> */}
          <Box className={styles.aSCTContentDesc}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentDescLabel}
            >
              Description
            </InputLabel>
            <Textarea
              placeholder="Description"
              name="description"
              value={formData.description}
              minRows={4}
              maxRows={4}
              onChange={handleOnChange}
              className={styles.aSCTContentDescInput}
            />
          </Box>
          <Box className={styles.aSCTContentType}>
            <InputLabel
              sx={{ textAlign: "left" }}
              className={styles.aSCTContentTypeLabel}
            >
              Against
            </InputLabel>

            {userRole === "Teacher" && (
              <Select
                fullWidth
                name="defendantId"
                sx={{ Width: "100%" }}
                onChange={handleSelectChange}
                value={formData.defendantId}
                className={styles.aSCTContentTypeInput}
              >
                <MenuItem value="selected">Select Name</MenuItem>
                {userList &&
                  userList.map((item: any,index:any) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {`${toTitleCase(item.first_name)} ${toTitleCase(item.last_name)}`}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            {userRole === "Parent" && (
              <Select
                fullWidth
                name="defendantId"
                sx={{ Width: "100%" }}
                onChange={handleSelectChange}
                value={formData.defendantId}
                className={styles.aSCTContentTypeInput}
              >
                <MenuItem value="selected">Select Defendant</MenuItem>
                {userList &&
                  userList.map((item: any,index:any) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.Name} {toTitleCase(item.Name)}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
          </Box>
        </Form>
      </Box>
    </Box>
  );
}
