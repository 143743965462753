import { useState } from "react";
import { AdminApi } from "../../../../config/apis";
import {
  InputLabel,
  TextField,
  Typography,
  Grid,
  FormHelperText,
} from "@mui/material";
import ImageUpload from "../../../../components/UploadImage";
import SelectOptionsApi from "../../../../components/SelectApi";
import validationSchema from "./validation";
import Form from "../../../../components/forms/Form";
import SelectOptions from "../../../../components/Select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function EditParent() {
  const [formData, setFormData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "+44",
    gender: "",
    streetAddress: "",
    country_id: "",
    state: "",
    city: "",
    zipCode: "",
    profile_picture: "",
  });

  const [uploadPreviewProp, setUploadPreviewProp] = useState(null);

  const genderOptions = [
    { id: "Male", options: "Male" },
    { id: "Female", options: "Female" },
    { id: "Other", options: "Other" },
  ];

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleImageChange = (name: any, selectedFile: any) => {
    setFormData({
      ...formData,
      profile_picture: selectedFile,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      phone: `+${value}`,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      phone: undefined,
    }));
  };

  const handleUpdate = (name: any, value: any) => {
    setFormData((prevFormData: any) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  return (
    <>
      <div className="container global-user">
        <Typography variant="h1" className="main-heading">
          Add Parent
        </Typography>
        <Grid container className="page-container">
          <Grid item xs={12} className="inner-container" padding={0}>
            <Form
              successPath="/admin/user/parents"
              submitUrl={AdminApi.USER_MODULE_PARENT_SAVE}
              formData={formData}
              validate={customValidation}
              multipart={true}
            >
              <h2 className="content-title">Personal Info</h2>
              <Grid container justifyContent={"space-between"}>
                <Grid
                  item
                  xl={9}
                  lg={9}
                  xs={12}
                  sx={{ order: { lg: "inherit", md: 2 } }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} lg={3} xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        First Name <span className="required">*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6.5} lg={9} xs={12}>
                      <TextField
                        fullWidth
                        name="first_name"
                        variant="outlined"
                        placeholder="Enter First Name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        error={errors.first_name !== undefined}
                        helperText={errors.first_name}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} lg={3} xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Last Name <span className="required">*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6.5} lg={9} xs={12}>
                      <TextField
                        fullWidth
                        name="last_name"
                        variant="outlined"
                        placeholder="Enter Last Name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        error={errors.last_name !== undefined}
                        helperText={errors.last_name}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    sx={{ mb: 3, alignItems: "center" }}
                  >
                    <Grid item xl={2} lg={3} xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Email <span className="required">*</span>
                      </InputLabel>
                    </Grid>
                    <Grid item xl={6.5} lg={9} xs={12}>
                      <TextField
                        fullWidth
                        name="email"
                        variant="outlined"
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={errors.email !== undefined}
                        helperText={errors.email}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xl={2}
                  lg={2}
                  xs={12}
                  className="circle upload-image profile"
                  container
                  style={{ marginRight: "0" }}
                  sx={{ order: { lg: "inherit", md: 1 } }}
                >
                  <ImageUpload
                    name="profile_picture"
                    label="Upload Photo"
                    previewWidth={200}
                    maxFileSize={5242880}
                    onChange={handleImageChange}
                    uploadPreviewProp={uploadPreviewProp}
                    isRequired={true}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Phone <span className="required">*</span>
                  </InputLabel>
                </Grid>
                <Grid item xl={6.5} lg={9} xs={12}>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                  />
                  {errors.phone && (
                    <FormHelperText error>{errors.phone}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Gender <span className="required">*</span>
                  </InputLabel>
                </Grid>
                <Grid item xl={6.5} lg={9} xs={12}>
                  <SelectOptions
                    options={genderOptions}
                    formData={formData}
                    onUpdate={handleUpdate}
                    name="gender"
                    labelKey="options"
                    valueKey="id"
                    errorText={errors.gender}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} xs={12}>
                  <InputLabel sx={{ textAlign: "left" }}>
                    Street Address <span className="required">*</span>
                  </InputLabel>
                </Grid>
                <Grid item xl={6.5} lg={9} xs={12}>
                  <TextField
                    fullWidth
                    name="streetAddress"
                    variant="outlined"
                    placeholder="Street Address"
                    value={formData.streetAddress}
                    onChange={handleInputChange}
                    error={errors.streetAddress !== undefined}
                    helperText={errors.streetAddress}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ mb: 3, alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} md={12}>
                  <InputLabel>
                    Country <span className="required">*</span>
                  </InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <SelectOptionsApi
                    apiEndpoint={AdminApi.COUNTRIES}
                    formData={formData}
                    onUpdate={handleUpdate}
                    labelKey="name"
                    name="country_id"
                    valueKey="id"
                    defaultText="Select Option"
                    errorText={errors.country_id}
                  />
                </Grid>
                <Grid
                  item
                  xl={2.5}
                  lg={3}
                  md={12}
                  sx={{
                    textAlign: { lg: "center" },
                    marginTop: { lg: 0, xs: 3 },
                  }}
                >
                  <InputLabel sx={{ paddingLeft: { lg: 2 } }}>
                    State/County <span className="required">*</span>
                  </InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <TextField
                    fullWidth
                    name="state"
                    variant="outlined"
                    value={formData.state}
                    onChange={handleInputChange}
                    error={errors.state !== undefined}
                    helperText={errors.state}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                xs={12}
                container
                sx={{ alignItems: "center" }}
              >
                <Grid item xl={2} lg={3} md={12}>
                  <InputLabel>
                    City <span className="required">*</span>
                  </InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <TextField
                    fullWidth
                    name="city"
                    variant="outlined"
                    value={formData.city}
                    onChange={handleInputChange}
                    error={errors.city !== undefined}
                    helperText={errors.city}
                  />
                </Grid>
                <Grid
                  item
                  xl={2.5}
                  lg={3}
                  md={12}
                  sx={{
                    textAlign: { lg: "center" },
                    marginTop: { lg: 0, xs: 3 },
                  }}
                >
                  <InputLabel sx={{ paddingLeft: { lg: 2 } }}>
                    Zip/Postal Code <span className="required">*</span>
                  </InputLabel>
                </Grid>
                <Grid item xl={2} lg={3} xs={12}>
                  <TextField
                    fullWidth
                    name="zipCode"
                    variant="outlined"
                    placeholder="Zip/Postal Code "
                    value={formData.zipCode}
                    onChange={handleInputChange}
                    error={errors.zipCode !== undefined}
                    helperText={errors.zipCode}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
