import InnerBanner from "../inner-banner";
import Layout from "../../../layout/frontend/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  IAboutBanner,
  HomePageContentInfo,
} from "../type/type";
import { useEffect, useState } from "react";
import { FrontendApi, api, resolveUrl } from "../../../config/apis";
import { CircularProgress } from "@mui/joy";
import EnrollNow from "../enroll-from";
import { Helmet, HelmetProvider } from "react-helmet-async";

type TmetaData = {
  title: string;
  description: string;
  keywords: string;
  image: string;
};

const ContactPage = function () {
  const { REACT_APP_SERVER_BASE } = process.env;

  const [aboutBanner, setBanner] = useState<IAboutBanner>();


  const [enrollNowInfo, setEnrollNowInfo] = useState<HomePageContentInfo>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(FrontendApi.CONTACT_PAGE_CMS);
        
        const { Banner, EnrollNow } = res.data.ContactPageInfo;
        setBanner(Banner);
        setEnrollNowInfo(EnrollNow);


        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);



  const [metaData, setmetaData] = useState<TmetaData>();

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const res = await api.get(
          resolveUrl(FrontendApi.META_TAGS_VIEW, "contac-page")
        );
        setmetaData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMetaData();
  }, []);

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      </>
    );
  }

  // Initialize AOS
  AOS.init();
  const imageUrl = `${REACT_APP_SERVER_BASE}${metaData?.image}`;

  return (
    <>
      <Layout enrollNowInfo={enrollNowInfo}>
        <HelmetProvider>
          <Helmet>
            <title>{metaData && metaData.title}</title>
            <meta
              name="description"
              content={metaData && metaData.description}
            />
            <meta name="keywords" content={metaData && metaData.keywords} />
            <meta property="og:image" content={metaData && imageUrl} />
            <meta property="og:image:width" content="2500" />
            <meta property="og:image:height" content="933" />
            <meta property="og:image:type" content="image/jpeg" />

            <meta
              property="og:image:alt"
              content={metaData && metaData.title}
            />
            <meta
              property="og:description"
              content={metaData && metaData.description}
            />
            <meta property="og:title" content={metaData && metaData.title} />

          </Helmet>
        </HelmetProvider>

        <InnerBanner bannerData={aboutBanner} />
        <EnrollNow EnrollNowInfo={enrollNowInfo ?? ""} />

      </Layout>
    </>
  );
};

export default ContactPage;
