import { Box } from "@mui/material";
import FooterFrontend from "../manage-footer";

const FooterPageCms = (() => {


    return (
        <>
            <h1>Footer</h1>
            <Box className="setting-tabs-container">
            <FooterFrontend/>
            </Box>
        </>
    )
})
export default FooterPageCms