import DynamicTabs from "../../../../../../components/tabs/Tabs";
import { LecturesChart } from "../view/lecture-chart";
import { StudentActivityChart } from "../view/student-activity-log";
import { StudentLoginTimeChart } from "../view/student-login-time";

export const TrackingTab = (props: any) => {
  const tabColum = {
    label: "Time Spent",
    content: <StudentLoginTimeChart />,
  };
  const tabs = [
    {
      label: "Lectures",
      content: <LecturesChart courseId={props?.courseId} />,
    },
    {
      label: "Subject Activity",
      content: <StudentActivityChart courseId={props?.courseId} />,
    },
  ];
  if (!props?.courseId) {
    tabs && tabs.push(tabColum);
  }

  return (
    <>
      <DynamicTabs tabs={tabs} />
    </>
  );
};
