import {
  Box,
  CircularProgress,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import StudentAssignments from "../courses/student-assignments/Student-assignments";
import StudentQuizesTable from "../courses/student-quizes/Student-quizes-table";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import StudentCourseList from "../courses/student-courses/StudentCourseList";
import style from "./kisds.module.css";
import { Button } from "react-bootstrap";
import { StudentProgress } from "./Student-progress";
import { ActivityTab } from "../Activities-tab";
import { TrackingTab } from "../Tracking-tab";
import CourseSearch from "./CourseSearch";
import { SingleCourseProgress } from "./Student-progress/single-subjet-progress";
import { useStudentId } from "../../../../../../context/getStudentId";
import { GetUserRole } from "../../../../../../helper/helperFunction";

export const KisdsDetails = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 601,
        md: 961,
        lg: 1281,
        xl: 1921,
      },
    },
  });
  const nevigate = useNavigate();

  const [courseId, setCourseId] = useState<number | null>(null);
  const [courseName, setCourseName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  const { studentId, setNewId } = useStudentId();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setNewId(id);
    }
  }, []);

  const handleDownload = () => {
    const baseUrl = `/admin/student-reports-all-courses/${studentId}`;
    const queryParams = new URLSearchParams();
    const someParam: any = courseId;
    if (someParam) {
      queryParams.append("courseId", someParam);
      queryParams.append("courseName", courseName);
    }
    const finalUrl = queryParams.toString()
      ? `${baseUrl}?${queryParams.toString()}`
      : baseUrl;
    nevigate(finalUrl);
  };

  const isStudent = GetUserRole() === "Student";

  return (
    <>
      {studentId && (
        <ThemeProvider theme={theme}>
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 250,
                width: "100%",
              }}
            >
              <CircularProgress sx={{ color: "#001c28" }} />
            </div>
          )}
          <div style={loading ? { display: "none" } : {}}>
            <Box className="student-detail-view">
              <Box
                className="course-content"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: 3,
                }}
              >
                <h1 style={{ margin: "0 auto 0 0" }} className="main-heading">
                  {courseName && courseName}
                </h1>
                <CourseSearch
                  setCourseId={setCourseId}
                  setCourseName={setCourseName}
                />
                {!isStudent && (
                  <Button
                    onClick={handleDownload}
                    className="btn"
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    Download Report
                  </Button>
                )}
              </Box>

              <Grid container>
                {courseId ? (
                  <SingleCourseProgress courseId={courseId} />
                ) : (
                  <StudentProgress />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                container
                sx={{ marginTop: "30px" }}
                spacing={2}
              >
                <Grid xl={6} lg={6} md={12} sm={12} item>
                  <ActivityTab courseId={courseId} />
                </Grid>
                <Grid xl={6} lg={6} md={12} sm={12} item>
                  <TrackingTab courseId={courseId} />
                </Grid>
                {!courseId && (
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    style={{ paddingLeft: 0 }}
                  >
                    <Typography
                      className={style.title_courses}
                      variant="h5"
                      gutterBottom
                    >
                      Subjects
                    </Typography>
                    <StudentCourseList />
                  </Grid>
                )}

                <Grid
                  item
                  xl={courseId ? 12 : 6}
                  lg={courseId ? 12 : 6}
                  md={12}
                  sm={12}
                  container
                  sx={{ justifyContent: courseId ? "space-between" : "" }}
                >
                  <Grid
                    item
                    xl={courseId ? 5.93 : 12}
                    lg={courseId ? 5.93 : 12}
                    md={12}
                    sm={12}
                    className="page-container"
                    style={{ marginBottom: "15px" }}
                  >
                    <Typography
                      className={style.title}
                      variant="h5"
                      gutterBottom
                    >
                      Assignments
                    </Typography>
                    <StudentAssignments courseId={courseId} />
                  </Grid>
                  <Grid
                    item
                    xl={courseId ? 5.93 : 12}
                    lg={courseId ? 5.93 : 12}
                    md={12}
                    sm={12}
                    className=" page-container"
                  >
                    <Typography
                      className={style.title}
                      variant="h5"
                      gutterBottom
                    >
                      Quizzes
                    </Typography>
                    <StudentQuizesTable
                      course={""}
                      student={studentId ?? ""}
                      courseId={courseId}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </ThemeProvider>
      )}
    </>
  );
};
