import { useState, useEffect } from "react";
import mainLogo from "../../../static/images/logo.png";
import Checkbox from "@mui/material/Checkbox";
import CustomModal from "../../../components/popups/Modal";

import { AdminApi, AuthApi, api, resolveUrl } from "../../../config/apis";
import {
  Box,
  Alert,
  InputLabel,
  TextField,
  Typography,
  Grid,
  FormHelperText,
} from "@mui/material";
import SelectOptionsApi from "../../../components/SelectApi";
import { Link } from "react-router-dom";
import validationSchema from "./validation";
import Form from "../../../components/forms/Form";
import SelectOptions from "../../../components/Select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FetchPopupData } from "../../../helper/helperFunction";

function Register() {
  const genderOptions = [
    { id: "Male", options: "Male" },
    { id: "Female", options: "Female" },
    { id: "Other", options: "Other" },
  ];

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const closeModule = () => {
    setShowModal(false);
  };

  const handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;

    setAcceptTerms(isChecked);

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      acceptTerms: isChecked ? undefined : prevErrors.acceptTerms,
    }));
  };

  const [formData, setFormData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "+44",
    gender: "",
    streetAddress: "",
    country_id: "",
    state: "",
    city: "",
    zipCode: "",
  });
  const [poupData, setPopupData] = useState<string>("");
  const [succes, setSucces] = useState();

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      phone: `+${value}`,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      phone: undefined,
    }));
  };

  const handleUpdate = (name: any, value: any) => {
    setFormData((prevFormData: any) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const newErrors: any = {};

      if (!acceptTerms) {
        newErrors["acceptTerms"] = "You must accept the terms and conditions.";
      }

      setErrors({ ...newErrors });

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  const handleFormSuccess = async (response: any) => {
    if (response) {
      setSucces(response);
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        phone: "+44",
        gender: "",
        streetAddress: "",
        country_id: "",
        state: "",
        city: "",
        zipCode: "",
      });
      setAcceptTerms(false);
    }
  };

  const type = "parent-signup-popup";
  useEffect(() => {
    const fetchData = async () => {
      await FetchPopupData(type, setPopupData);
    };
    fetchData();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!succes ? (
          <Box
            className="login-wrap register-wrap"
            sx={{ maxWidth: "1000px", pt: 9 }}
          >
            <Box className="logo-wrap">
              <Box
                component="img"
                sx={{
                  height: 40,
                  width: 232,
                  mb: 2,
                }}
                alt="Logo"
                src={mainLogo}
              />
              <Typography component="h1" variant="h5">
                Welcome to Parent Portal Registration
              </Typography>
              <Typography component="p" pb={2}>
                Please enter your parent portal information below.
              </Typography>
            </Box>

            <Form
              successPath="/parents/auth/signup"
              submitUrl={AuthApi.PARENTS_SIGNUP}
              formData={formData}
              validate={customValidation}
              multipart={true}
              classname="login-form parent_regiter"
              saveText="Proceed Now"
              onSuccess={handleFormSuccess}
            >
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={12} container spacing={2}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    container
                    sx={{ alignItems: "center" }}
                  >
                    <Grid item xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        First Name*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="first_name"
                        variant="outlined"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        error={errors.first_name !== undefined}
                        helperText={errors.first_name}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    container
                    sx={{ alignItems: "center" }}
                  >
                    <Grid item xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Last Name*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="last_name"
                        variant="outlined"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        error={errors.last_name !== undefined}
                        helperText={errors.last_name}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    container
                    sx={{ alignItems: "center" }}
                  >
                    <Grid item xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>Email*</InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="email"
                        variant="outlined"
                        value={formData.email}
                        onChange={handleInputChange}
                        error={errors.email !== undefined}
                        helperText={errors.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    container
                    sx={{ alignItems: "center" }}
                  >
                    <Grid item xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>Phone*</InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                      />
                      {errors.phone && (
                        <FormHelperText error>{errors.phone}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={12} container>
                    <Grid item xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Gender*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <SelectOptions
                        options={genderOptions}
                        formData={formData}
                        onUpdate={handleUpdate}
                        name="gender"
                        labelKey="options"
                        valueKey="id"
                        errorText={errors.gender}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={12} container>
                    <Grid item xs={12}>
                      <InputLabel sx={{ textAlign: "left" }}>
                        Street Address*
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="streetAddress"
                        variant="outlined"
                        value={formData.streetAddress}
                        onChange={handleInputChange}
                        error={errors.streetAddress !== undefined}
                        helperText={errors.streetAddress}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12} container>
                    <Grid item xs={12}>
                      <InputLabel> Country* </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <SelectOptionsApi
                        apiEndpoint={AdminApi.COUNTRIES}
                        formData={formData}
                        onUpdate={handleUpdate}
                        labelKey="name"
                        name="country_id"
                        valueKey="id"
                        defaultText="Select Option"
                        errorText={errors.country_id}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12} container>
                    <Grid item xs={12}>
                      <InputLabel> State/County* </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="state"
                        variant="outlined"
                        value={formData.state}
                        onChange={handleInputChange}
                        error={errors.state !== undefined}
                        helperText={errors.state}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12} container>
                    <Grid item xs={12}>
                      <InputLabel> City* </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="city"
                        variant="outlined"
                        value={formData.city}
                        onChange={handleInputChange}
                        error={errors.city !== undefined}
                        helperText={errors.city}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12} container>
                    <Grid item xs={12}>
                      <InputLabel> Zip/Postal Code* </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="zipCode"
                        variant="outlined"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                        error={errors.zipCode !== undefined}
                        helperText={errors.zipCode}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <Box className="forget-link terms-condition">
                        <Checkbox
                          checked={acceptTerms}
                          name="acceptTerms"
                          onChange={handleCheckboxChange}
                        />
                        <Link to="#">
                          I Accept{" "}
                          <span onClick={() => setShowModal(true)}>
                            {" "}
                            Terms & Conditions{" "}
                          </span>
                        </Link>
                        <Box className="forget-link" minWidth={"220px"}>
                          <Link to="/parents/auth/login">
                            Already have an account ?
                          </Link>
                        </Box>
                      </Box>
                      {errors.acceptTerms && (
                        <FormHelperText error>
                          {errors.acceptTerms}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Box>
        ) : (
          <Box className="message_registering_wrap">
            <div className="message_registering">
              <h1>Thank You for Registering!</h1>
              <p>Please check your email for further instructions.</p>
            </div>
          </Box>
        )}
      </Box>
      <CustomModal state={showModal} setState={setShowModal} width={800}>
        <button className="closeBtn" onClick={closeModule}>
          +
        </button>

        <div dangerouslySetInnerHTML={{ __html: poupData }}></div>
      </CustomModal>
    </>
  );
}

export default Register;
