import { useNavigate, useParams } from "react-router-dom";
import Datatable from "../../../../components/dataTables/Datatable";
import { api, resolveUrl, AdminApi } from "../../../../config/apis";
import { useState } from "react";
import ActionButtons from "../../../../components/dataTables/ActionButtons";

export default function ViewQuizes() {
  const navigate = useNavigate();
  const params = useParams();
  const [filters, setFilters] = useState({});
  const tableColumns = [
    {
      selector: (row: { quizTitle: string }) => row.quizTitle,
      name: "Quiz Title",
      sortable: true,
    },
    {
      selector: (row: { courseTitle: string }) => row.courseTitle,
      name: "Subject Name",
      sortable: false,
    },
    {
      selector: (row: { courseSection: string }) => row.courseSection,
      name: "Subject section",
      sortable: false,
    },
    {
      selector: (row: { assignedStudents: string }) => row.assignedStudents,
      name: "Assigned Student",
      sortable: false,
    },
    {
      selector: (row: { completed: string }) => row.completed,
      name: "Completed",
      sortable: false,
    },
    {
      selector: (row: { time: string }) => (row.time ? row.time + " Min" : "-"),
      name: "Time",
      sortable: false,
    },
    {
      selector: (row: { avgMarks: string }) => row.avgMarks,
      name: "Obtained Marks",
      sortable: false,
    },
    {
      selector: (row: { totalMarks: string }) => row.totalMarks,
      name: "Total Marks",
      sortable: false,
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row: { quiz: number }) => (
        <ActionButtons
          id={row.quiz}
          onView={onView}
          actions={{
            edit: false,
            delete: false,
          }}
          module="manage-courses"
        />
      ),
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/courses/quiz/view/${id}`);
  };

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={resolveUrl(AdminApi.FETCH_QUIZZES_COURSES, `${params.id}`)}
        filters={filters}
        actionColumns={false}
        module="manage-courses"
      />
    </>
  );
}
