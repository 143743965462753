import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi } from "../../../config/apis";
import { DateView } from "../../../components/DateView/DateView";
import { Avatar, Box } from "@mui/material";
import {
  convertMinutesToTime,
  toTitleCase,
} from "../../../helper/helperFunction";
import AvatarImage from "../../../components/Avatar";

export default function TopStudents() {
  const navigate = useNavigate();

  const tableColumns = [
    {
      cell: (row: { profile: any; name: string }) => (
        <>
          <AvatarImage imageName={row.profile} name={row.name} />
          {toTitleCase(`${row.name}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      minWidth: "180px",
    },

    {
      selector: (row: { courses: number }) => row.courses,
      name: "Subjects",
      sortable: false,
      minWidth: "80px",
    },

    {
      selector: (row: { avgTime: number }) =>
        row.avgTime ? convertMinutesToTime(row.avgTime) : "-",
      name: "Avg Time",
      sortable: false,
      minWidth: "150px",
    },
    {
      selector: (row: { avgScore: string }) => row.avgScore,
      name: "Avg Score",
      sortable: false,
      minWidth: "110px",
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/student/view/${id}`);
  };

  return (
    <>
      <Box className="page-container" pt={0} mb={0}>
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.TOP_STUDENTS_LIST}
          onView={onView}
          actions={{
            view: true,
            delete: false,
            edit: false,
          }}
          pagination={false}
          module="manage-students"
        />
      </Box>
    </>
  );
}
