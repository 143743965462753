import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getTeachers } from "../../../../helper/helperFunction";
import { CourseData, Teachers } from "../../../../Type/courseTypes";
import { useParams } from "react-router-dom";

interface AssinTeacherProps {
  formData: CourseData;
  setFormData: React.Dispatch<React.SetStateAction<CourseData>>;
  errors: CourseData;
  setErrors: React.Dispatch<React.SetStateAction<CourseData>>;
}

export default function AssinTeacher({
  formData,
  setFormData,
  setErrors,
  errors,
}: AssinTeacherProps) {
  const param = useParams();

  const [teachers, setTeachers] = useState<Teachers>({
    teachers: [],
  });

  const handleOnSelectChange = (event: SelectChangeEvent<string | boolean>) => {
    const target = event.target.value as string | boolean;

    setFormData({
      ...formData,
      [event.target.name]: target,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };

  // Callback Hook
  const getTeacherData = useCallback(async () => {
    const teachers = await getTeachers();
    setTeachers({
      teachers: teachers,
    });
  }, []);

  // Use Effect Hook
  useEffect(() => {
    getTeacherData();
  }, []);

  return (
    <>
      <Box sx={{ mt: 6 }}>
        <h2>Assign Teacher </h2>
        <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
          <Grid item xs={2}>
            <InputLabel> Select Teacher*</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <Select
              name="teacherId"
              value={formData.teacherId}
              sx={{ minWidth: 170 }}
              onChange={handleOnSelectChange}
              // disabled={param.id ? true : false}
              error={errors.teacherId !== undefined}
            >
              {teachers &&
                teachers.teachers.map((teacher: any) => {
                  return (
                    <MenuItem value={teacher.id} key={teacher.id}>
                      {teacher.first_name} {teacher.last_name}
                    </MenuItem>
                  );
                })}
            </Select>
            {errors.teacherId && (
              <FormHelperText error>{errors.teacherId}</FormHelperText>
            )}
          </Grid>
        </Grid>
        {/* <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
          <Grid item xs={2}>
            <InputLabel>Allow Live Quiz</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <Select
              name="have_live_quiz"
              sx={{ minWidth: 170 }}
              value={formData.have_live_quiz === true ? 1 : 0}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </Grid>
        </Grid> */}
      </Box>
    </>
  );
}
