import { Box, Typography } from "@mui/material";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Link } from "react-router-dom";
import EditFAQSCat from "./edit";
import { useEffect, useState } from "react";
import Datatable from "../../../components/dataTables/Datatable";
import Moment from "react-moment";

const FAQSCat = ({ heading }: any) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [reRender, setReRender] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [editId, setEditId] = useState<string>();

  const tableColumns = [
    {
      cell: (row: any, index: number) => index + 1,
      name: "No.",
      sortable: false,
    },
    {
      cell: (row: { title: string }) => row.title ?? "N/A",
      name: "Category",
      sortable: false,
    },
    {
      cell: (row: { created_at: string }) =>
        <Moment format="MMM DD, YYYY">{row.created_at}</Moment> ?? "N/A",
      name: "Added On",
      sortable: false,
    },
  ];

  const onEdit = async (id?: string) => {
    setEditId(id);
    setOpen(true);
  };

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.FAQS_CAT_DEL, id));
  };

  useEffect(() => {
    setReRender(formSuccess);
    setOpen(false);
  }, [formSuccess]);

  useEffect(() => {
    if (!open) {
      setEditId("");
    }
  }, [open]);

  return (
    <>
      <Box padding="20px 30px 20px 30px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: "40px",
              fontWeight: "700",
              paddingTop: "20px",
              paddingBottom: "10px",
              textAlign: "left",
            }}
          >
            {heading ?? ""}
          </Typography>
          <Link
            className="btn"
            to="#"
            onClick={() => {
              setOpen(true);
            }}
          >
            Add Category
          </Link>
        </Box>
        <Box className="page-container seprater_table">
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.FAQS_CAT_PAGINATION}
            onEdit={onEdit}
            onDelete={onDelete}
            actions={{
              view: false,
            }}
            module="manage-CMS"
            // rowsPerPage={5}
            refreshHandler={reRender}
          />
        </Box>
      </Box>
      <EditFAQSCat
        handleClose={handleClose}
        open={open}
        setFormSuccess={setFormSuccess}
        width="800px"
        editId={editId}
      />
    </>
  );
};
export default FAQSCat;
