import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { Box } from "@mui/material";
import { Button } from "react-bootstrap";
import { DateView } from "../../../components/DateView/DateView";
import ActionButtons from "../../../components/dataTables/ActionButtons";

export default function ViewRoles() {
  const navigate = useNavigate();

  const tableColumns = [
    {
      selector: (row: { name: string }) => row.name,
      name: "Name",
      width: "900px",
      sortable: true,
    },
    {
      selector: (row: { created_at: string }) => (
        <DateView date={row.created_at} />
      ),
      name: "Created At",
      sortable: false,
    },
    {
      name: "Actions",
      width: "100px",
      cell: (row: { id: number; name: string }) => (
        <span>
          {row.name && row.name.toLowerCase() !== "super admin" && (
            <ActionButtons
              id={row.id}
              actions={{
                view: false,
              }}
              onEdit={onEdit}
              onDelete={onDelete}
              module="manage-roles"
            />
          )}
        </span>
      ),
    },
  ];

  const onEdit = async (id?: string) => {
    navigate(`/admin/roles/edit/${id}`);
  };

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.ROLES_DELETE, id));
  };

  return (
    <div>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={AdminApi.ROLES_PAGINATE}
        onEdit={onEdit}
        onDelete={onDelete}
        actionColumns={false}
        module="manage-roles"
      />
      <Box className="btn-wrapper">
        <Button onClick={() => navigate(`/admin/roles/edit`)}>Add New</Button>
      </Box>
    </div>
  );
}
