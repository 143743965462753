import {
  Box,
  FormControl,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { AdminApi, api, resolveUrl } from "../../../../../config/apis";
import { useParams } from "react-router-dom";

const QuizesDetail = () => {
  const { id } = useParams();
  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(
          resolveUrl(AdminApi.SUBJECT_QUIZ_DETAIL_VIEW, `${id}`)
        );

        setQuizData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      }
    };

    getData();
  }, [id]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress /> {/* Render CircularProgress while loading */}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Box className="quiz_result_detail">
        <Grid item container xs={12}>
          {quizData.map((questionData: any, index: any) => (
            <Question key={index} questionData={questionData} count={index} />
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default QuizesDetail;

const Question = ({ questionData, count }: any) => {
  const { question, answers, answer_key } = questionData;

  const answerOptions = answers.replace(/^\[|\]$/g, "").split(",");
  const answerkey = answer_key
    .replace(/^\[|\]$/g, "")
    .split(",")
    .map((str: string) => parseInt(str.replace(/"/g, ""), 10));

  return (
    <>
      <Grid item xs={12} className="question_wrap">
        <Typography variant="h2">
          #{count + 1} {question}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ padding: "20px 0" }}>
        {answerOptions.map((option: string, index: number) => (
          <Box key={index}>
            <FormControl component="fieldset">
              <FormControlLabel
                // className={quiz_index.includes(index) ? "corect" : "incorrect"}
                key={index}
                label={option}
                control={<Checkbox checked={answerkey.includes(index)} />}
              />
            </FormControl>
          </Box>
        ))}
      </Grid>
    </>
  );
};
