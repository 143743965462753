import { Typography } from "@mui/material";
// import EnrollIcon from './asset/img/EnrollIcon.svg';
// import BeginLearningIcon from './asset/img/Begin Learning.svg';
// import SupportIcon from './asset/img/Meeting-with-Learning-Support-Manager.svg';
// import LearningJourneyIcon from './asset/img/Learning-Journey.svg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/style.css";
import { HomePageContentInfo, Slide } from "../type/type";
import ImageViewer from "../../../components/imageViewer";
import { useReadMorePost } from "../../../helper/helperFunction";
import { useEffect } from "react";

interface homeSchoolingProps {
  schooling?: Slide[];
  schoolingInfo?: HomePageContentInfo;
  bgColor?: string;
}

const HomeSchooling = function ({
  schooling,
  schoolingInfo,
  bgColor,
}: homeSchoolingProps) {
  const { isExpanded, toggleReadMore, truncateText } = useReadMorePost(160);

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000, // Set autoplay speed to 3 second
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1167,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 981,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 681,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const isDesktop = window.innerWidth > 980;

  return (
    <div
      className={`homeSchoolingMain ${bgColor ? "homeSchoolingMainInner" : ""}`}
    >
      <div className="container">
        <Typography
          variant="h2"
          className="title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {schoolingInfo && schoolingInfo.title}
        </Typography>
        <Typography
          variant="body1"
          className="descText"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {schoolingInfo && schoolingInfo.description}
        </Typography>
        <div className="contain">
          <div
            className="sliderMian"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            {isDesktop ? (
              <Slider {...sliderSettings}>
                {schooling &&
                  schooling.map((item, index) => {
                    return (
                      <div className="card" key={item.id}>
                        <div className="iconContain">
                          <ImageViewer
                            imagePath={item.image ?? ""}
                            altText={item.title}
                          />
                        </div>
                        <div className="textContain">
                          <Typography variant="h4">{item.title}</Typography>
                          {
                            <Typography variant="body1">
                              {/* {item.description &&
                                (isExpanded(index)
                                  ? item.description
                                  : truncateText(item.description))}
                              {item.description &&
                                item.description.length > 100 && (
                                  <button
                                    className="read_more_btn"
                                    onClick={() => toggleReadMore(index)}
                                  >
                                    {isExpanded(index)
                                      ? "Read Less"
                                      : "Read More"}
                                  </button>
                                )} */}
                              {item.description && item.description}
                            </Typography>
                          }
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            ) : (
              <>
                <div className="slick-slider-responsive">
                  {schooling &&
                    schooling.map((item, index) => {
                      return (
                        <div className="card" key={item.id}>
                          <div className="iconContain">
                            <ImageViewer
                              imagePath={item.image ?? ""}
                              altText={item.title}
                            />
                          </div>
                          <div className="textContain">
                            <Typography variant="h4">{item.title}</Typography>
                            {
                              <Typography variant="body1">
                                {item.description &&
                                  (isExpanded(index)
                                    ? item.description
                                    : truncateText(item.description))}
                                {item.description &&
                                  item.description.length > 160 && (
                                    <button
                                      className="read_more_btn"
                                      onClick={() => toggleReadMore(index)}
                                    >
                                      {isExpanded(index)
                                        ? "Read Less"
                                        : "Read More"}
                                    </button>
                                  )}
                              </Typography>
                            }
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSchooling;
