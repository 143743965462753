import {
  Box,
  Chip,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import styles from "./manage-discount.module.scss";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { useState, useCallback, useEffect, FormEvent } from "react";
import Form from "../../../components/forms/Form";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import validationSchema from "./validation";
import { toTitleCase } from "../../../helper/helperFunction";

enum UseType {
  SINGLE = "one",
  MULTIPLE = "multiple",
  UNLIMITED = "unlimited",
}

enum DiscountFor {
  ALL = "all",
  ALL_YEAR = "all_year",
  SELECTED_COURSE = "selected_course",
}

enum DiscountType {
  FLAT = "flat",
  PERCENTAGE = "percent",
}

interface Course {
  id: number;
  name: string;
}

interface DiscountFormData {
  code: string;
  name: string;
  description: string;
  start_date?: any;
  end_date?: any | null;
  type: string;
  use_type: UseType;
  discount_for: DiscountFor;
  discount_for_value?: any;
  status: boolean;
  discount_type: DiscountType;
  discount: any;
  count: any;
}
interface DiscountFormProps {
  id: string;
  setFormSuccess: any;
}
const DiscountForm: React.FC<DiscountFormProps> = ({ id, setFormSuccess }) => {
  const ukLocale = "en-GB"; // UK locale

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [showCourses, setshowCourses] = useState(false);

  const defaultFormData: DiscountFormData = {
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    type: "discount",
    use_type: UseType.SINGLE,
    discount_for: DiscountFor.ALL,
    discount_for_value: [],
    status: false,
    discount_type: DiscountType.PERCENTAGE,
    discount: null,
    code: "",
    count: "",
  };

  const [formData, setFormData] = useState<DiscountFormData>(defaultFormData);

  const handleOnChangeNumber = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    const value = target.value !== "" ? Number(target.value) : null;

    setFormData({
      ...formData,
      [target.name]: value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const handleOnChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [target.name]: undefined,
    }));
  };

  const handleOnSlectedCourse = (
    event: SelectChangeEvent<string | boolean>,
    child: React.ReactNode
  ) => {
    const target = event.target.value as string | boolean;
    if (target === "selected_course") {
      setshowCourses(true);
    } else {
      setshowCourses(false);
    }
    setFormData({
      ...formData,
      [event.target.name]: target,
    });
  };
  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const selectedValue = event.target.value;

    setSelectedValues((prevSelectedValues) => [
      ...prevSelectedValues,
      selectedValue,
    ]);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: selectedValue,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };

  const discountForOptions = [
    { value: "one", label: "One time" },
    { value: "multiple", label: "Multiple times" },
    { value: "unlimited", label: "Unlimited" },
  ];

  const AppliedToOptions = [
    { value: "all", label: "All" },
    { value: "all_year", label: "All Year" },
    { value: "selected_course", label: "Selected Course" },
  ];

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const [multiSelectedValues, setMultiSelectedValues] = useState<string[]>([]);

  // Fetch courses data

  const [courses, setCourses] = useState<Course[]>([]);
  const fetchCourses = useCallback(async () => {
    const response = await api.get(
      resolveUrl(AdminApi.DISCOUNT_PERMOTION_COURSES, id)
    );
    setCourses(response.data ?? []);
  }, [id]);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  // Fetch Form data

  const editData = useCallback(async () => {
    if (id) {
      const res = await api.get(resolveUrl(AdminApi.DISCOUNT_VIEW, id));
      const selected_courses =
        res.data?.code?.discount_for_value?.length > 0
          ? res.data.code.discount_for_value.map((item: any) => item.courseId)
          : [];

      setFormData({
        name: res.data.code.name,
        description: res.data.code.description,
        start_date: res.data.code.start_date,
        end_date: res.data.code.end_date,
        type: res.data.code.type,
        use_type: res.data.code.use_type,
        discount_for: res.data.code.discount_for,
        // discount_for_value: res.data.code.discount_for_value,
        discount_for_value:
          res.data.code.discount_for_value &&
          res.data.code.discount_for_value.map(
            (item: { courseId: any }) => item.courseId
          ),
        status: res.data.code.status,
        discount_type: res.data.code.discount_type,
        discount: res.data.code.discount,
        code: res.data.code.code,
        count: res.data.code.count,
      });
      setMultiSelectedValues(selected_courses);
      const discount_for = res.data.code.discount_for;
      if (discount_for === "selected_course") {
        setshowCourses(true);
      }
    }
  }, [id]);

  // Use Effect Hook
  useEffect(() => {
    editData();
  }, [editData]);

  const handleChange = (
    event: SelectChangeEvent<string[]>,
    child: React.ReactNode
  ) => {
    const selectedItems = event.target.value as string[];

    setMultiSelectedValues(selectedItems);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: selectedItems,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [event.target.name]: undefined,
    }));
  };

  const handleDelete = (value: string) => () => {
    const newSelectedValues = multiSelectedValues.filter(
      (item) => item !== value
    );
    const newDletedValues = formData.discount_for_value.filter(
      (item: string) => item !== value
    );
    setMultiSelectedValues(newSelectedValues);
    setFormData({
      ...formData,
      discount_for_value: newDletedValues,
    });
  };

  const handleFormSuccess = async (response: any) => {
    if (response) {
      setFormSuccess((prevState: any) => !prevState);
    }
  };

  const handleStartDateChange = (date: any) => {
    setFormData({
      ...formData,
      start_date: date,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      start_date: undefined,
    }));
  };
  const handleEndDateChange = (date: any) => {
    setFormData({
      ...formData,
      end_date: date,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      end_date: undefined,
    }));
  };
  const [errors, setErrors] = useState<any>({});

  const customValidation = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});

      const newErrors = {};

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        ...newErrors,
      }));

      return Object.keys(newErrors).length === 0;
    } catch (error: any) {
      const newErrors: any = {};

      error.inner.forEach((validationError: any) => {
        newErrors[validationError.path] = validationError.message;
      });

      setErrors(newErrors);
      return false;
    }
  };

  return (
    <Box className={styles.discount} marginTop={2}>
      <Box sx={{ width: "100%" }}>
        <Grid item xs={12} className="inner-container">
          <Form
            submitUrl={AdminApi.DISCOUNT_SAVE}
            formData={formData}
            editId={id}
            successPath="/admin/setting"
            onSuccess={handleFormSuccess}
            validate={customValidation}
          >
            {/* <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Type</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  name="type"
                  sx={{ Width: "100%", textTransform: "capitalize" }}
                  onChange={handleSelectChange}
                  defaultValue="slected"
                  value={formData.type}
                  error={errors.type !== undefined}
                >
                  <MenuItem value="slected">Select Any</MenuItem>
                  {["discount", "promotion"].map((items: any, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={items}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {items}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.type && (
                  <FormHelperText error>{errors.type}</FormHelperText>
                )}
              </Grid>
            </Grid> */}
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Title*</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  placeholder="Enter Title"
                  value={formData.name}
                  name="name"
                  variant="outlined"
                  onChange={handleOnChange}
                  error={errors.name !== undefined}
                  helperText={errors.name}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ mb: 3, alignItems: "center" }}
              spacing={2}
            >
              <Grid item xl={2} lg={2} xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Date From*</InputLabel>
              </Grid>
              <Grid item xl={4.5} lg={4.5} xs={4.5}>
                <DatePicker
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  dayAriaLabel="Day"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date"
                  onChange={handleStartDateChange}
                  value={
                    formData.start_date ? new Date(formData.start_date) : null
                  }
                  yearAriaLabel="Year"
                  locale={ukLocale} // Set the locale to UK
                  minDate={new Date()} // Disable old dates
                />
                {errors.start_date && (
                  <FormHelperText error>{errors.start_date}</FormHelperText>
                )}
              </Grid>
              <Grid item xl={1} lg={1} xs={1}>
                <InputLabel sx={{ textAlign: "center" }}>Date To*</InputLabel>
              </Grid>
              <Grid item xl={4.5} lg={4.5} xs={4.5}>
                <DatePicker
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  dayAriaLabel="Day"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date"
                  onChange={handleEndDateChange}
                  value={formData.end_date ? new Date(formData.end_date) : null}
                  yearAriaLabel="Year"
                  locale={ukLocale} // Set the locale to UK
                  minDate={new Date()} // Disable old dates
                />
                {errors.end_date && (
                  <FormHelperText error>{errors.end_date}</FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Code*</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  placeholder="Enter code"
                  value={formData.code}
                  name="code"
                  variant="outlined"
                  onChange={handleOnChange}
                  error={errors.code !== undefined}
                  helperText={errors.code}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Description*</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  placeholder="Description"
                  value={formData.description}
                  name="description"
                  variant="outlined"
                  onChange={handleOnChange}
                  error={errors.description !== undefined}
                  helperText={errors.description}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Use*</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  name="use_type"
                  sx={{ width: "100%" }}
                  onChange={handleSelectChange}
                  defaultValue="slected"
                  value={formData.use_type}
                  displayEmpty
                >
                  {discountForOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.use_type && (
                  <FormHelperText error>{errors.use_type}</FormHelperText>
                )}
              </Grid>
            </Grid>
            {formData.use_type === "multiple" && (
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Limit</InputLabel>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    sx={{ maxWidth: "80px" }}
                    fullWidth
                    value={formData.count}
                    name="count"
                    variant="outlined"
                    type="number"
                    onChange={handleOnChange}
                    error={errors.count !== undefined}
                  />
                  {errors.count && (
                    <FormHelperText error>{errors.count}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Applied to*</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  name="discount_for"
                  sx={{ width: "100%" }}
                  onChange={handleOnSlectedCourse}
                  value={formData.discount_for}
                >
                  {AppliedToOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {showCourses && (
              <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
                <Grid item xs={2}>
                  <InputLabel sx={{ textAlign: "left" }}>Subjects</InputLabel>
                </Grid>

                <Grid item xs={10}>
                  <Select
                    fullWidth
                    multiple
                    value={multiSelectedValues}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "Select Course" }}
                    name="discount_for_value"
                    MenuProps={MenuProps}
                  >
                    {courses.map((course) => (
                      <MenuItem key={course.id} value={course.id}>
                        {course.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.discount_for_value && (
                    <FormHelperText error>
                      {errors.discount_for_value}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid container item xs={12} paddingTop={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <Box>
                      {multiSelectedValues.map((value: any) => (
                        <Chip
                          key={value}
                          sx={{ marginBottom: 1, marginRight: 1 }}
                          label={
                            value.courseName ||
                            courses.find(
                              (course) => course.id === Number(value)
                            )?.name
                          }
                          onDelete={handleDelete(value)}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>
                  Discount Type*
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  name="discount_type"
                  sx={{ width: "100%" }}
                  onChange={handleOnSlectedCourse}
                  value={formData.discount_type}
                >
                  {["percent", "flat"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option && toTitleCase(option)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
              <Grid item xs={2}>
                <InputLabel sx={{ textAlign: "left" }}>Offer*</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  className="input_border_remove"
                  sx={{ maxWidth: "80px" }}
                  fullWidth
                  value={formData.discount}
                  name="discount"
                  variant="outlined"
                  type="number"
                  onChange={handleOnChangeNumber}
                  error={errors.discount !== undefined}
                  InputProps={{
                    endAdornment:
                      formData.discount_type === "percent" ? "%" : "",
                  }}
                />
                {errors.discount && (
                  <FormHelperText error>{errors.discount}</FormHelperText>
                )}
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Box>
    </Box>
  );
};

export default DiscountForm;
