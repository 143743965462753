import { Backdrop, Box, Fade, Modal } from "@mui/material";

export default function CustomModal(params: any) {

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: params.width ?? 619,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto",
    overflowX: "hidden"
  };



  const handleState = () => {
    params.setState((prevState: any) => {
      return Object.keys(prevState).reduce(function (state: any, key: string) {
        state[key] = false;        
        return state;
      }, {});
    });

    if (params.clearSectionIndex) {
      params.clearSectionIndex();
    }


  }
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={params.state}
        onClose={handleState}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={params.state}>
          <Box sx={style}>
            {params.children}
            {params.handleClose &&
              <button className="closeBtn" onClick={params.handleClose}>+</button>
            }
          </Box>
        </Fade>
      </Modal>
    </>
  );
}