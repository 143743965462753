import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import mainLogo from "../../static/images/logo.png";
import { Link } from "react-router-dom";
import { api, AuthApi, resolveUrl } from "../../config/apis";
import FormControl from "@mui/material/FormControl";
import { Alert } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import BootstrapInput from "../../layout/admin/partials/BootstrapInput";

function ForgetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const errorShow: any = errors?.email?.message;
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);

  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState([]);

  const onSubmit = async (data: any) => {
    setIsError(false);
    setIsSuccess(false);

    try {
      const res = await api.post(resolveUrl(AuthApi.FORGET_PASSWORD), data);

      if (res.data) {
        setIsSuccess(true);
        setSuccessMsg(res.data.message);
      }
    } catch (err: any) {
      setIsError(true);
      let message: any = ["Server Error"];
      if (err.response) {
        message = err.response.data.message;
      }
      setErrorMsg(message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "88vh",
        justifyContent: "center",
      }}
    >
      <Box className="login-wrap">
        <Box className="logo-wrap">
          <Box
            component="img"
            sx={{
              height: 40,
              width: 232,
              mb: 2,
            }}
            alt="Logo"
            src={mainLogo}
          />
          <Typography component="h1" variant="h5">
            Forget Password
          </Typography>
        </Box>
        <Box
          className="login-form"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          {isError && (
            <Alert severity="error">
              {Array.isArray(errorMsg) ? (
                <ul style={{ padding: "0", margin: "0" }}>
                  {errorMsg.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              ) : (
                <span>{errorMsg}</span>
              )}
            </Alert>
          )}

          {isSuccess && (
            <Alert severity="success">
              <ul style={{ padding: "0", margin: "0" }}>
                <li>{successMsg}</li>
              </ul>
            </Alert>
          )}
          {errors.email && <Alert severity="error">{errorShow}</Alert>}

          <FormControl variant="standard" sx={{ width: "100%", mb: 2, mt: 1 }}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Email Address:
            </InputLabel>
            <BootstrapInput
              required
              fullWidth
              id="email"
              autoComplete="email"
              autoFocus
              {...register("email", {
                required: "Email should not be empty",
              })}
              onChange={(e) => {
                setErrorMsg([]);
                setIsError(false);
              }}
            />
          </FormControl>
          <Button
            className="darkbeige"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Forget Password
          </Button>

          <Box className="forget-link">
            <Link to="/auth/login">Back to login</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ForgetPassword;
