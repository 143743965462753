import { Avatar, Box } from "@mui/material";
import {
  GetUserId,
  HasPermission,
  toTitleCase,
  truncateTextWithEllipsis,
} from "../../../helper/helperFunction";
import Datatable from "../../../components/dataTables/Datatable";
import { AdminApi, resolveUrl } from "../../../config/apis";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DateView } from "../../../components/DateView/DateView";
import AvatarImage from "../../../components/Avatar";

export default function ViewComplaintsUsers(props: any) {
  const navigate = useNavigate();
  const UserId = GetUserId();

  const location = useLocation();

  const tableColumns = [
    {
      cell: (row: any) => (
        <>
          <AvatarImage
            imageName={row.complainantProfile}
            name={row.complaintFrom}
          />
          {toTitleCase(`${row.complaintFrom}`)}
        </>
      ),
      name: "Complaint From",
      sortable: true,
    },

    {
      selector: (row: { description: string }) =>
        truncateTextWithEllipsis(row.description, 50),
      name: "Description",
      sortable: false,
    },
    {
      cell: (row: any) => (
        <>
          <AvatarImage imageName={row.defendantProfile} name={row.against} />
          {toTitleCase(`${row.against}`)}
        </>
      ),
      name: "Against",
      sortable: false,
    },
    {
      selector: (row: { dateTime: string }) => <DateView date={row.dateTime} />,
      name: "Date / Time",
      sortable: false,
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/complaint/${id}`);
  };

  return (
    <>
      {location.pathname === "/admin/complaints" && (
        <div className="course-content">
          <h1 className="main-heading">Complaint</h1>
          <HasPermission permission="edit.manage-complaints">
            <Box className="btn-wrapper">
              <Link to={"/admin/complaint/edit"}>Add Complaint</Link>
            </Box>
          </HasPermission>
        </div>
      )}

      <div
        className={`${
          location.pathname === "/admin/complaints" ? "page-container" : ""
        } `}
      >
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(AdminApi.PARENT_STUDENTS_COMPLAINTS)}
          paramId={props.params ? props.params : UserId}
          onView={onView}
          actions={{
            edit: false,
            delete: false,
          }}
          module="manage-complaints"
        />
      </div>
    </>
  );
}
