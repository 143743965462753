import { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import styles from "./studentQuizes.module.scss";
import StudentQuizesDetailQue from "./student-quizes-question";
import { useQuizResContext } from "../../../../../context/QuizResponseContext";
import {
  formatTime,
  minutesToSeconds,
} from "../../../../../helper/helperFunction";
import { AdminApi, api, resolveUrl } from "../../../../../config/apis";
import { useParams } from "react-router-dom";

const StudentQuizesDetail = () => {
  const [quizDetail, setQuizDetail] = useState<any>();

  const params = useParams();

  useEffect(() => {
    const getQuizes = async () => {
      try {
        const res = await api.get(
          resolveUrl(AdminApi.QUIZ_DETAIL, `${params.id}`)
        );
        setQuizDetail(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    getQuizes();
  }, [params.id]);

  return (
    <Box className={styles.quizesDetailPanel}>
      <Grid container className={styles.qDPHead}>
        <Grid item xs={12} lg={8} className={styles.qDPHeadText}>
          <Typography variant="h2" className={styles.qDPHeadTitle}>
            {quizDetail && quizDetail.quizTitle}
          </Typography>
          <Typography variant="h4" className={styles.qDPHeadCourseTitle}>
            {quizDetail && quizDetail.quizCourseTitle}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} className={styles.qDPHeadTime}>
          <Typography variant="h2" className={styles.qDPHeadTimeContent}>
            <Timer />
          </Typography>
        </Grid>
      </Grid>

      <StudentQuizesDetailQue
        quizDuration={quizDetail && quizDetail.quizDuration}
        totalQuiz={quizDetail && quizDetail.totalQuizContents}
      />
    </Box>
  );
};

const Timer = () => {
  const { setCompletion_duration } = useQuizResContext();

  // minutesToSeconds(5 ?? 0);

  // const [timer, setTimer] = useState(minutesToSeconds(duration ?? 0));

  // const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // useEffect(() => {
  //   intervalRef.current = setInterval(() => {
  //     setTimer((prevDuration: number) => prevDuration - 1);
  //   }, 1000);

  //   return () => {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (timer <= 0) {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //     setCompletion_duration(0)

  //   }
  // }, [setCompletion_duration, timer]);

  // useEffect(() => {
  //   setCompletion_duration(timer)

  // }, [setCompletion_duration, timer]);

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setCompletion_duration(seconds);
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, setCompletion_duration]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div
      className="quiz_timmer"
      dangerouslySetInnerHTML={{ __html: formatTime(seconds) }}
    />
  );
};

export default StudentQuizesDetail;
