import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import InstructorDetail from "./instructor-detail";
import CourseDescription from "./course-description";
import styles from "./assin-cousre.module.scss";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Box,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import {
  GetAuthState,
  getCourseDemoVideo,
  getCourseView,
} from "../../../../../helper/helperFunction";
import Content from "../../../manage-courses/content/Content";
import DynamicTabs from "../../../../../components/tabs/Tabs";
import { CourseData } from "../../../../../Type/courseTypes";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ReactPlayer from "react-player";
import { AdminApi, api, resolveUrl } from "../../../../../config/apis";
import BucketFileViewer from "../../../../../components/buketFileViewer";
import { useAuth } from "../../../../../hooks/auth/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTracking } from "../../../../../components/TimeTracking";
import FormLoader from "../../../../../components/loader/FormLoader";

const AssignCourseDetail: React.FC = () => {
  const { tracking, setTracking } = useTracking();

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#071836" : "#308fe8",
    },
  }));
  const CURRENT_USER = GetAuthState("USER");

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (tracking.contentId !== "") {
      timer = setInterval(() => {
        setTracking((prevTracking) => ({
          ...prevTracking,
          duration: prevTracking.duration + 1,
        }));
      }, 1000); // Update duration every second
    }

    return () => clearInterval(timer);
  }, [tracking.contentId]);

  const handleVideoSelect = async (video: string, content: any) => {
    await timeTracking();
    // const localStorageKey = `selectedItem_${CURRENT_USER.id}${courseId}`;

    // localStorage.setItem(
    //   localStorageKey,
    //   JSON.stringify({ content: content, courseId: courseId })
    // );

    setSelectedVideo(video);
    setCurrentLesson(content);
    setTracking({
      contentId: content.id ?? "",
      duration: 0,
    });
  };

  // const getSelectedVideoFromLocalStorage = () => {
  //   try {
  //     const localStorageKey = `selectedItem_${CURRENT_USER.id}${courseId}`;
  //     const selectedVideoString = localStorage.getItem(localStorageKey);
  //     if (selectedVideoString) {
  //       return JSON.parse(selectedVideoString);
  //     }
  //   } catch (error) {
  //     console.error("Error parsing selected video from local storage:", error);
  //   }
  //   return null;
  // };

  // const updateSelectedVideoPropertyInLocalStorage = (
  //   propertyName: string | number,
  //   propertyValue: any
  // ) => {
  //   try {
  //     const selectedVideo = getSelectedVideoFromLocalStorage();
  //     if (selectedVideo) {
  //       // Update the specific property
  //       selectedVideo.content[propertyName] = propertyValue;
  //       const localStorageKey = `selectedItem_${CURRENT_USER.id}${courseId}`;

  //       localStorage.setItem(localStorageKey, JSON.stringify(selectedVideo));
  //     } else {
  //       console.error("No selected video found in local storage.");
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error updating selected video property in local storage:",
  //       error
  //     );
  //   }
  // };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [currentLesson, setCurrentLesson] = useState<any>(null);
  const [currentSecIndex, setCurrentSecIndex] = useState<any>();

  const [updateLessons, setUpdateLessons] = useState<boolean>(false);

  const { REACT_APP_SERVER_BASE } = process.env;
  const baseUrl = REACT_APP_SERVER_BASE;

  let videoURL = "";
  if (typeof selectedVideo === "string") {
    const videoPath = selectedVideo;
    // videoURL = `${baseUrl}${videoPath}`;
    videoURL = `${videoPath}`;
  }

  const DEFAULT_COURSE_VIEW = {
    name: "",
    have_live_quiz: false,
    price: "",
    year: "",
    description: "",
    sections: [],
    teacherId: "",
  };

  const params = useParams<{ slug: string }>();
  const [courseViewData, setCourseViewData] =
    React.useState<CourseData>(DEFAULT_COURSE_VIEW);

  const [progress, setProgress] = useState(0);
  const [courseId, setCourseId] = useState<any>(null);

  const [studentAssignmentId, setStudentAssignmentId] = useState<any>(null);

  useEffect(() => {
    const StudentAssignShedule = async () => {
      if (
        currentLesson &&
        (currentLesson.studentAssignmentId === null || undefined || "") &&
        currentLesson.type === "assignment"
      ) {
        try {
          const response = await api.post(
            resolveUrl(AdminApi.STUDENT_ASSIGNMENT_SHEDULE),
            {
              studentId: CURRENT_USER.id,
              courseId: courseId,
              contentId: currentLesson ? currentLesson.id : "",
            }
          );
          setStudentAssignmentId(response.data.studentAssignmentId);
        } catch (error) {
          console.error(error);
        }
      }
    };
    // if (!studentAssignmentId) {
    StudentAssignShedule();
    // }
    return () => {
      // Cleanup function to set studentAssignmentId to null on component unmount
      setStudentAssignmentId(null);
    };
  }, [currentLesson?.id]);

  const markRefFileAsDone = async () => {
    try {
      if (
        currentLesson &&
        currentLesson.isCompleted === "active" &&
        currentLesson.type === "addFile"
      ) {
        const queryParam: { [key: string]: any } = {
          courseSlug: params.slug || "",
          contentId: currentLesson.id || "",
        };

        const res = await api.get(resolveUrl(AdminApi.MARK_LESSON_DONE), {
          params: queryParam,
        });

        if (res.status === 200) {
          setUpdateLessons((prevState) => !prevState);
        } else {
          console.error(
            `Failed to mark lesson as done. Status code: ${res.status}`
          );
        }
      } else {
        console.error("Current lesson is not of type 'add file'.");
      }
    } catch (error) {
      console.error(
        "An error occurred while marking the lesson as done:",
        error
      );
    }
  };

  const markSummaryAsDone = async () => {
    try {
      if (
        currentLesson &&
        currentLesson.isCompleted === "active" &&
        currentLesson.type === "summary"
      ) {
        const queryParam: { [key: string]: any } = {
          courseSlug: params.slug || "",
          contentId: currentLesson.id || "",
        };

        const res = await api.get(resolveUrl(AdminApi.MARK_LESSON_DONE), {
          params: queryParam,
        });

        if (res.status === 200) {
          setIsReaded(false);
          // updateSelectedVideoPropertyInLocalStorage("isCompleted", "completed");
          setUpdateLessons((prevState) => !prevState);
        } else {
          console.error(
            `Failed to mark lesson as done. Status code: ${res.status}`
          );
        }
      } else {
        console.error("Current lesson is not of type 'summary'.");
      }
    } catch (error) {
      console.error(
        "An error occurred while marking the lesson as done:",
        error
      );
    }
  };

  const timeTracking = async () => {
    try {
      if (tracking && tracking.contentId && tracking.duration > 0) {
        const res = await api.post(resolveUrl(AdminApi.TIME_TRACKING_STUDENT), {
          contentId: tracking.contentId,
          duration: tracking.duration,
        });

        if (res.data.status == true) {
          setTracking({
            contentId: "",
            duration: 0,
          });
        }
      } else {
        console.error("something went wrong");
      }
    } catch (error) {
      console.error("something went wrong", error);
    }
  };

  useEffect(() => {
    return () => {
      timeTracking();
    };
  }, []);
  const [isReaded, setIsReaded] = useState(false);

  useEffect(() => {
    const markLessonAsDone = async () => {
      if (Math.round(progress * 100) >= 100) {
        const queryParam: { [key: string]: any } = {
          courseSlug: params.slug ? params.slug : "",
          contentId: currentLesson ? currentLesson.id : "",
        };
        const res = await api.get(resolveUrl(AdminApi.MARK_LESSON_DONE), {
          params: queryParam,
        });
        if (res.data.status) setIsReaded(false);
        // updateSelectedVideoPropertyInLocalStorage("isCompleted", "completed");
        setUpdateLessons((prevState) => !prevState);
      }
    };
    markLessonAsDone();
  }, [progress]);
  const [loading, setLoading] = useState(true); // State to track loading

  React.useEffect(() => {
    const fetchCourseViewData = async () => {
      try {
        setLoading(true);

        const res = await api.get(
          resolveUrl(AdminApi.STUDENT_COURSE, params.slug)
        );

        setCourseViewData({
          name: res.data.course.name,
          description: res.data.course.description,
          have_live_quiz: res.data.course.have_live_quiz,
          price: res.data.course.price,
          teacherId: res.data.course.teacherId,
          year: res.data.course.year,
          sections: res.data.course.section,
          about_user: res.data.aboutTeacher.about,
          profile_picture: res.data.aboutTeacher.profile,
          teachername: res.data.aboutTeacher.name,
        });

        if (res.data.course.section.length > 0) {
          let previousSection: any = null;
          let currentSectionIndex = null;

          for (
            let sectionIndex = 0;
            sectionIndex < res.data.course.section.length;
            sectionIndex++
          ) {
            const section = res.data.course.section[sectionIndex];

            if (section.sectionScheduleStatus === "lock") {
              // Update previousSection if it's null
              if (!previousSection && sectionIndex > 0) {
                previousSection = res.data.course.section[sectionIndex - 1];
              }
              continue;
            }

            // Track the previous section that is not locked
            previousSection = section;

            for (let i = section.courseContent.length - 1; i >= 0; i--) {
              const contentItem = section.courseContent[i];
              if (contentItem.isCompleted === "active") {
                if (contentItem.type === "video") {
                  setSelectedVideo(contentItem.upload_file);
                  setCurrentLesson(contentItem);
                  currentSectionIndex = sectionIndex; // set the current section index
                } else {
                  setCurrentLesson(contentItem);
                  currentSectionIndex = sectionIndex; // set the current section index
                }
                break;
              }
            }
          }

          // If there were locked sections, ensure the correct previousSection is used
          if (previousSection) {
            const lastContentItem =
              previousSection.courseContent[
                previousSection.courseContent.length - 1
              ];
            if (lastContentItem.isCompleted === "completed") {
              if (lastContentItem.type === "video") {
                setSelectedVideo(lastContentItem.upload_file);
                setCurrentLesson(lastContentItem);
                currentSectionIndex = res.data.course.section.findIndex(
                  (section: any) => section === previousSection
                );
              } else {
                setCurrentLesson(lastContentItem);
                currentSectionIndex = res.data.course.section.findIndex(
                  (section: any) => section === previousSection
                );
              }
            }
          }

          setCurrentSecIndex(currentSectionIndex);
        }

        setCourseId(res.data.course.id);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourseViewData();
  }, [params.slug, updateLessons, isReaded]);

  // React.useEffect(() => {
  //   if (
  //     courseId &&
  //     courseViewData &&
  //     courseViewData.sections &&
  //     courseViewData.sections.length > 0
  //   ) {
  //     const selectedItem = getSelectedVideoFromLocalStorage();
  //     const firstSection = courseViewData.sections[0];
  //     if (selectedItem && selectedItem.courseId === courseId) {
  //       if (selectedItem.content.type === "video") {
  //         setSelectedVideo(selectedItem.content.upload_file);
  //         setCurrentLesson(selectedItem.content);
  //       } else {
  //         setCurrentLesson(selectedItem.content);
  //       }
  //     } else {
  //       if (
  //         firstSection &&
  //         firstSection.courseContent &&
  //         firstSection.courseContent.length > 0
  //       ) {
  //         const content_Item = firstSection.courseContent[0];

  //         if (content_Item.type === "video") {
  //           setSelectedVideo(content_Item.upload_file);
  //           setCurrentLesson(content_Item);
  //         } else {
  //           setCurrentLesson(content_Item);
  //         }
  //       }
  //     }
  //   }
  // }, [courseId, courseViewData]);

  React.useEffect(() => {
    const fetchCourseDemovideo = async () => {
      try {
        const path = await getCourseDemoVideo(courseId);
        setSelectedVideo(path);
      } catch (error) {
        console.error(error);
      }
    };

    // fetchCourseDemovideo();
  }, [courseId, setSelectedVideo]);

  const handleProgress = (state: { played: number }) => {
    const { played } = state;
    setProgress(played);
  };

  const tabs = [
    {
      label: "Subject Description",
      content: (
        <CourseDescription description={courseViewData.description ?? ""} />
      ),
    },
  ];

  const auth = useAuth();
  const userId = auth.state?.user.id;
  const navigate = useNavigate();

  // const [quizBtn, setQuizBtn] = useState(false);

  const handleStartNow = async (id: number) => {
    try {
      // setQuizBtn(true);
      const currentDate = new Date();
      const startingTime = currentDate.toISOString();

      const response = await api.post(resolveUrl(AdminApi.STUDENT_START_QUIZ), {
        quizId: id,
        startingTime: startingTime,
        studentId: userId,
      });

      if (response.data.status) {
        toast.success("Quiz started successfully");
        navigate(`/admin/student-quizes/view/${id}?id=${courseId}`);
        // setQuizBtn(false);
      } else {
        const { message } = response.data;
        // setQuizBtn(false);
      }
    } catch (error) {
      console.error("An error occurred");
    }
  };

  const handleCheckResult = (id: number) => {
    navigate(`/admin/student-quizes/quiz-result/${id}`);
  };

  const handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    setIsReaded(isChecked);
    if (isChecked) {
      markSummaryAsDone();
    }
  };

  return (
    <>
      {loading ? (
        <Box
          className="loader"
          sx={{
            display: "flex",
            height: "50vh",
            position: "static",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div className="student_subject_lessons">
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <h2 className="main-heading">{courseViewData.name}</h2>
              </Grid>
              {/* <Grid item xs={2} sx={{ textAlign: "right" }}>
          <Typography component={"span"}>2 Reviews</Typography>
          <Rating
            name="simple-controlled"
            // value={value}
            onChange={(event, newValue) => {
              // setValue(newValue);
            }}
          />
        </Grid> */}
            </Grid>

            <Grid
              container
              justifyContent={"space-between"}
              className="lesson_wraper"
            >
              <Grid item xs={7} sx={{ pr: 2 }}>
                {currentLesson && currentLesson.type === "video" && (
                  <Card>
                    <Box className={styles.subject_thumbnail}>
                      <Box className={styles.subject_video}>
                        <ReactPlayer
                          url={videoURL}
                          controls
                          onProgress={handleProgress}
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <CardContent>
                      <Grid
                        className={styles.videoCourses}
                        container
                        justifyContent={"space-between"}
                      >
                        <Grid item xl={10} lg={9} md={8.5} paddingRight={1}>
                          <Grid
                            className={styles.videoCourses}
                            container
                            justifyContent={"space-between"}
                          >
                            <Grid item xs={8}>
                              <Typography variant="h5" component="h2">
                                Progress
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                variant="h5"
                                component="h2"
                                textAlign={"right"}
                              >
                                {Math.round(progress * 100)}%
                              </Typography>
                            </Grid>
                          </Grid>

                          <BorderLinearProgress
                            variant="determinate"
                            value={Math.round(progress * 100)}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={3}
                          md={3.5}
                          sx={{ textAlign: "right" }}
                        >
                          <Button
                            className="dark-btn"
                            variant="contained"
                            onClick={toggleModal}
                            disabled
                            style={{
                              backgroundColor:
                                currentLesson.hasOwnProperty("isCompleted") &&
                                currentLesson.isCompleted === "completed"
                                  ? "green"
                                  : "",
                              color:
                                currentLesson.hasOwnProperty("isCompleted") &&
                                currentLesson.isCompleted === "completed"
                                  ? "white"
                                  : "",
                            }}
                          >
                            {currentLesson.hasOwnProperty("isCompleted") &&
                            currentLesson.isCompleted === "completed"
                              ? "Completed"
                              : "Complete"}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )}
                {currentLesson &&
                  (currentLesson.type === "quiz" ||
                    currentLesson.type === "addQuiz") && (
                    <Card>
                      <Box className={styles.subject_thumbnail}>
                        <Box
                          className={styles.subject_video}
                          sx={{ padding: "20px" }}
                        >
                          <h2>Quiz</h2>
                          {/* {currentLesson.hasOwnProperty('isCompleted') && currentLesson.isCompleted === 'completed' && <Grid item>
                  Completed
                </Grid>}
                {currentLesson.hasOwnProperty('isCompleted') && currentLesson.isCompleted === 'active' && <Grid item>
                  <Link to={`/admin/student-quizes/view/${currentLesson.quiz}`} className="btn"
                  >Start Quiz</Link>
                </Grid>} */}

                          {currentLesson.studentQuizStatus === "complete" ? (
                            <Button
                              className={"btn"}
                              onClick={() =>
                                handleCheckResult(currentLesson.studentQuizId)
                              }
                            >
                              Check Result
                            </Button>
                          ) : (
                            <div>
                              {currentLesson.studentQuizStatus === "resume" ? (
                                <Button
                                  className={"btn"}
                                  onClick={() =>
                                    handleStartNow(currentLesson.quiz)
                                  }
                                >
                                  Resume
                                </Button>
                              ) : (
                                <Button
                                  // disabled={quizBtn}
                                  className={"btn"}
                                  onClick={() =>
                                    handleStartNow(currentLesson.quiz)
                                  }
                                >
                                  Start Now
                                </Button>
                              )}
                            </div>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  )}

                {currentLesson && currentLesson.type === "summary" && (
                  <Card>
                    <Box className={styles.subject_thumbnail}>
                      <Box
                        className={styles.subject_video}
                        sx={{ padding: "20px" }}
                      >
                        <h2>Summary</h2>
                        <p>{currentLesson.textData}</p>
                        {currentLesson.hasOwnProperty("isCompleted") &&
                        currentLesson.isCompleted === "completed" ? (
                          <Grid item>Completed</Grid>
                        ) : (
                          <Grid item xs={12} container>
                            <Grid item xs={12}>
                              <Box
                                className="forget-link terms-condition"
                                sx={{ width: "100%" }}
                              >
                                <Checkbox
                                  checked={isReaded}
                                  name="readed"
                                  onChange={handleCheckboxChange}
                                />
                                <span>Marked as completed.</span>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Box>
                  </Card>
                )}
                {currentLesson && currentLesson.type === "assignment" && (
                  <Card>
                    <Box className={styles.subject_thumbnail}>
                      <Box
                        className={styles.subject_video}
                        sx={{ padding: "20px" }}
                      >
                        <h2>Assignment</h2>
                        <Link
                          to={`/admin/student-assignments/view/${
                            currentLesson.studentAssignmentId ||
                            studentAssignmentId
                          }`}
                          className="btn"
                        >
                          View Assignment
                        </Link>
                        <h3>{currentLesson.textData}</h3>
                        <BucketFileViewer
                          name={"Download File"}
                          filePath={currentLesson.upload_file}
                        />
                        <p>{currentLesson.textDataContent}</p>
                      </Box>
                    </Box>
                  </Card>
                )}
                {currentLesson && currentLesson.type === "addFile" && (
                  <Card>
                    <Box className={styles.subject_thumbnail}>
                      <Box
                        className={styles.subject_video}
                        sx={{ padding: "20px" }}
                      >
                        <h2>File</h2>
                        <h3>{currentLesson.textData}</h3>
                        <span onClick={() => markRefFileAsDone()}>
                          <BucketFileViewer
                            name={"Download File"}
                            filePath={currentLesson.upload_file}
                          />
                        </span>
                        <p>{currentLesson.textDataContent}</p>
                      </Box>
                    </Box>
                  </Card>
                )}
              </Grid>
              <Grid item xs={5} className="lesson_wraper_left">
                <Box className={`${styles.subject_detail} page-container`}>
                  <h2>Lessons</h2>
                  <Box className={styles.subject_detail_single}>
                    <Content
                      courseData={courseViewData}
                      onSelectVideo={handleVideoSelect}
                      current_Lesson={currentLesson}
                      // getFromLocal={getSelectedVideoFromLocalStorage()}
                      currentSecIndex={currentSecIndex}
                      course_id={courseId}
                      userId={CURRENT_USER}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ mt: 5 }}>
              <InstructorDetail
                title="About the Teacher"
                content={courseViewData.about_user ?? ""}
                profile_image={courseViewData.profile_picture}
                name={courseViewData.teachername}
              />
            </Box>

            <DynamicTabs tabs={tabs} />
          </div>
        </>
      )}
    </>
  );
};

export default AssignCourseDetail;
