import { AdminApi } from "../../../config/apis";
import Datatable from "../../../components/dataTables/Datatable";
import { toTitleCase } from "../../../helper/helperFunction";
import ProgressCourseCell from "../../../components/ProgressBarSubject";
import AvatarImage from "../../../components/Avatar";

export default function AdminAssignSubjectGrid({ filters }: any) {
  const tableColumns = [
    {
      cell: (row: any) => (
        <>
          <ProgressCourseCell
            name={row.courseName}
            progress={row.progress ?? ""}
            image={row.featureImage}
          />
        </>
      ),
      name: "Subject Name",
      sortable: false,
      width: "350px",
    },
    {
      cell: (row: any) => (
        <>
          <AvatarImage
            imageName={row.teacherImage}
            name={row.assignedTeacher}
          />
          {toTitleCase(`${row.assignedTeacher}`)}
        </>
      ),
      name: "Assigned Teacher",
      sortable: false,
    },
    {
      cell: (row: any) => (
        <>
          <AvatarImage
            imageName={row.studentImage}
            name={row.assignedStudent}
          />
          {toTitleCase(`${row.assignedStudent}`)}
        </>
      ),
      name: "Assigned Student",
      sortable: false,
    },
    {
      selector: (row: any) => row.lessons,
      name: "Lessons",
      sortable: false,
    },
  ];

  return (
    <>
      <Datatable
        tableColumns={tableColumns}
        dataUrl={AdminApi.ADMIN_ASSIGNED_COURSES}
        filters={filters}
        actionColumns={false}
        module="manage-parent-courses"
      />
    </>
  );
}
