import { Box, Grid, TextField, Typography } from "@mui/material";
import styles from "./setting.module.scss";
import Form from "../../../components/forms/Form";
import { AdminApi } from "../../../config/apis/admin";
import { ChangeEvent, useState } from "react";
import { useAuth } from "../../../hooks/auth/auth";
import * as yup from "yup";

interface FormData {
  userId: any;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const SettingChangePassword = () => {
  const currentUser = useAuth();
  const userId = currentUser.state?.user.id;

  const [errors, setErrors] = useState<any>({});

  const [formData, setFormData] = useState<FormData>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    userId: userId,
  });

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof FormData
  ) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [fieldName]: undefined,
    }));
  };

  const schema = yup.object().shape({
    oldPassword: yup.string().required("Old Password is required."),
    newPassword: yup
      .string()
      .required("New Password is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*?])/,
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], "Passwords do not match.")
      .required("Confirm Password is required."),
  });

  const customValidation = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors: any) {
      const errors: any = {};
      validationErrors.inner.forEach((error: any) => {
        const fieldName = error.path as keyof FormData;
        const errorMessage = error.message;
        errors[fieldName] = errorMessage;
      });
      setErrors(errors);
      return false;
    }
  };

  return (
    <Box className={styles.settingChangePasswordMain}>
      <Grid container className={styles.settingCPContainer}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={7}
          className={styles.settingCPTitle}
        >
          <Typography variant="h2">Change Password</Typography>
          {/* <Typography variant="body1">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui laboriosam repudiandae fugiat. Magni ad, error ea voluptate doloribus blanditiis perspiciatis.</Typography> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={7}
          className={styles.settingCPForm}
        >
          <Form
            submitUrl={AdminApi.CHANGE_PASSWORD}
            formData={formData}
            validate={customValidation}
          >
            <Box className={styles.passField}>
              <Typography variant="body1" className={styles.passFieldLabel}>
                Old Password
              </Typography>
              <TextField
                type="password"
                placeholder="Enter Your old Password"
                className={styles.passFieldInput}
                value={formData.oldPassword}
                onChange={(e) => handleInputChange(e, "oldPassword")}
                error={!!errors.oldPassword}
                helperText={errors.oldPassword || ""}
              />
            </Box>
            <Box className={styles.passField}>
              <Typography variant="body1" className={styles.passFieldLabel}>
                New Password
              </Typography>
              <TextField
                type="password"
                placeholder="Enter your new password"
                className={styles.passFieldInput}
                value={formData.newPassword}
                onChange={(e) => handleInputChange(e, "newPassword")}
                error={!!errors.newPassword}
                helperText={errors.newPassword || ""}
              />
            </Box>
            <Box className={styles.passField}>
              <Typography variant="body1" className={styles.passFieldLabel}>
                Confirm New Password
              </Typography>
              <TextField
                type="password"
                placeholder="Confirm password"
                className={styles.passFieldInput}
                value={formData.confirmPassword}
                onChange={(e) => handleInputChange(e, "confirmPassword")}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword || ""}
              />
            </Box>
          </Form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingChangePassword;
