import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Grid,
} from "@mui/material";
import {
  GetUserRole,
  HasPermission,
  convertMinutesToTime,
  isAdminUser,
  toTitleCase,
} from "../../../../helper/helperFunction";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { Link, useNavigate } from "react-router-dom";
import { DateView } from "../../../../components/DateView/DateView";
import FilterBase from "../filter/filter-base";
import { CSVLink } from "react-csv";
import React from "react";
import { useAuth } from "../../../../hooks/auth/auth";
import SwitchCell from "../../../../components/switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormEvent, useCallback, useEffect, useState } from "react";
import AvatarImage from "../../../../components/Avatar";

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

export default function ViewStudentUsers() {
  const navigate = useNavigate();
  const [reportsData, setReportsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [filters, setFilters] = useState<FilterState>();

  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);
  };

  const tableColumns = [
    {
      selector: (row: { id: any }) => `#${row.id}`,
      name: "ID",
      sortable: false,
      width: "100px",
    },
    {
      cell: (row: { Name: string; profile_picture: string }) => (
        <>
          <AvatarImage imageName={row.profile_picture} name={row.Name} />
          {toTitleCase(`${row.Name}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      width: "200px",
    },
    {
      selector: (row: { Email: string }) => row.Email,
      name: "Email",
      sortable: false,
      width: "350px",
    },
    {
      selector: (row: { Place: any }) => row.Place,
      name: "City",
      sortable: false,
    },
    {
      selector: (row: { AddedOn: string }) => (
        <DateView date={row.AddedOn ?? ""} />
      ),
      name: "Added on",
      sortable: false,
      width: "150px",
    },
    {
      selector: (row: { parentName: string }) =>
        toTitleCase(row.parentName ?? ""),
      name: "Parent Name",
      sortable: false,
      width: "200px",
    },
    {
      selector: (row: { courses: number }) => (row.courses ? row.courses : "-"),
      name: "Subjects",
      sortable: false,
    },
    {
      selector: (row: { avgTime: number }) =>
        row.avgTime ? convertMinutesToTime(row.avgTime) : "-",
      name: "Avg Time",
      sortable: false,
      width: "200px",
    },
    {
      selector: (row: { avgScore: any }) => (row.avgScore ? row.avgScore : "-"),
      name: "Total Score",
      sortable: false,
      width: "150px",
    },

    {
      cell: (row: { id: any; is_active: any }) => (
        <>
          <HasPermission permission="edit.manage-users">
            <SwitchCell id={row.id} is_active={row.is_active} />
          </HasPermission>
        </>
        // if (GetUserRole() === "Super Admin") {
        //   return <SwitchCell id={row.id} is_active={row.is_active} />;
        // }
      ),
      sortable: false,
      width: "60px",
    },
  ];
  const tableColumnsTeacher = [
    {
      selector: (row: { id: any }) => `#${row.id}`,
      name: "ID",
      sortable: false,
      width: "100px",
    },
    {
      cell: (row: { name: string }) => (
        <>
          <Avatar
            sx={{ width: 40, height: 40, mr: 2 }}
            alt={row.name.toUpperCase()}
            src="/static/images/avatar/2.jpg"
          />
          {toTitleCase(`${row.name}`)}
        </>
      ),
      name: "Name",
      sortable: true,
      width: "200px",
    },
    {
      selector: (row: { email: string }) => row.email,
      name: "Email",
      sortable: false,
      width: "350px",
    },
    {
      selector: (row: { place: any }) => row.place,
      name: "City",
      sortable: false,
    },
    {
      selector: (row: { addedOn: string }) => <DateView date={row.addedOn} />,
      name: "Added on",
      sortable: false,
      width: "150px",
    },
    {
      selector: (row: { parentName: string }) => toTitleCase(row.parentName),
      name: "Parent Name",
      sortable: false,
      width: "200px",
    },
    // {
    //   selector: (row: { status: string }) => row.status,
    //   name: "IGSCE Year",
    //   sortable: false,
    // },
    {
      selector: (row: { courses: number }) => row.courses,
      name: "Subjects",
      sortable: false,
    },
    {
      selector: (row: { avgTime: number }) => row.avgTime + "Min",
      name: "Avg time",
      sortable: false,
    },
    {
      selector: (row: { avgScore: string }) => row.avgScore,
      name: "Total Score",
      sortable: false,
      width: "100px",
    },

    {
      cell: (row: { id: any; is_active: any }) => {
        if (GetUserRole() === "Super Admin") {
          return <SwitchCell id={row.id} is_active={row.is_active} />;
        }
      },
      sortable: false,
      width: "60px",
    },
  ];

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const onView = async (id: string) => {
    navigate(`/admin/user/student/view/${id}`);
  };
  const onEdit = async (id: string) => {
    navigate(`/admin/user/student/edit/${id}`);
  };

  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.USER_DEL_BY_ADMIN, id));
  };

  const auth = useAuth();
  const userID = auth.state?.user.id;
  const [reportData, setReportData] = useState<any[]>([]);

  const dataUrl =
    GetUserRole() === "Teacher"
      ? resolveUrl(AdminApi.USER_TEACHER_STUDENTS, userID)
      : AdminApi.USER_STUDENT_PAGINATE;
  const tableCol =
    GetUserRole() === "Teacher" ? tableColumnsTeacher : tableColumns;

  const reportDataCallback = useCallback(async () => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;

    const res = await api.get(AdminApi.ALL_STUDENT_REPORTS, {
      params: {
        month: month,
        year: year,
      },
    });
    setReportData(res.data);
  }, [selectedDate]);

  useEffect(() => {
    reportDataCallback();
  }, [reportDataCallback]);
  return (
    <>
      <Box className="user_student_listing_table">
        <Box
          className="course-content"
          sx={{ marginBottom: 2, paddingRight: 0 }}
        >
          <Box>
            <h1 className="main-heading">Students</h1>
          </Box>
          <Box>
            <HasPermission permission="create.manage-users">
              <Box className="btn-wrapper">
                <Link style={{ margin: "0" }} to={"edit"}>
                  Add Student
                </Link>
              </Box>
            </HasPermission>
          </Box>
        </Box>

        <Grid container spacing={2} className="yi_my_filter">
          <Grid item xs={11.8} lg={6} style={{ paddingTop: "0" }}>
            <FilterBase
              onFiltersChange={handleFiltersChange}
              placeholder="Name"
            />
          </Grid>
          <>
            <Grid
              lg={6}
              xs={12}
              container
              flexWrap={"nowrap"}
              justifyContent={"flex-end"}
            >
              <Grid item pr={1}>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date: any) => setSelectedDate(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className="student_data_picker"
                />
              </Grid>
              <Grid item>
                <CSVLink className="btn" filename="Students" data={reportData}>
                  Download Report
                </CSVLink>
              </Grid>
            </Grid>
          </>
        </Grid>

        <Box className="page-container">
          <Datatable
            tableColumns={tableCol}
            dataUrl={dataUrl}
            filters={filters}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            actions={{
              view: true,
              edit: true,
              delete: true,
            }}
            module={isAdminUser() ? "manage-users" : "manage-assin-students"}
          />
        </Box>
      </Box>
    </>
  );
}
