import React, { useEffect, useState } from "react";
import { getStudentAssignments } from "../../../../../helper/helperFunction";
import { useAuth } from "../../../../../hooks/auth/auth";
import styles from "./studentAssignments.module.scss";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import CustomModal from "../../../../../components/popups/Modal";

type AssignmentDetails = {
  type: string;
  is_demo: boolean;
  ContentId: number;
  AssignemntTitle: string | null;
  totalMarks: number;
  ObtainedMarks: number | null;
  studentAssignmentId: string | null;
  studentAssignmentStatus: string | null;
  studentAssignmentSchedule: any;
  courseName: any;
  AssignmentDueDate: any;
  isSubmitted: any;
  markingStatus: string | null;
};

type Assignments = {
  courseName: string;
  assignmentDetails: AssignmentDetails[];
};

const StudentAssignmentTable = ({ filterState, limit = 1000 }: any) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const userId = auth.state?.user.id;
  const [assignments, setAssignments] = useState<AssignmentDetails[]>([]);

  useEffect(() => {
    const getAssignments = async () => {
      try {
        const res = await getStudentAssignments(userId ?? "", {
          ...filterState,
          limit: limit,
        });
        setAssignments(res.items);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getAssignments();

    return () => {};
  }, [filterState, limit, userId]);

  const handleClick = (id: string | null) => {
    navigate(`/admin/student-assignments/view/${id}`);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {!isLoading ? (
        assignments && assignments.length > 0 ? (
          <Box className={styles.assignmentTableMain}>
            {assignments.map((item, index: number) => (
              <Card key={index} className={styles.assignmentTableRow}>
                <CardContent className={styles.assignmentTableTextContent}>
                  <Typography variant="h6" className={styles.titleContent}>
                    {item.AssignemntTitle}
                  </Typography>
                  <Typography variant="body1" className={styles.subjectContent}>
                    {item.courseName}
                  </Typography>
                  <Typography variant="body1" className={styles.timeContent}>
                    {item.AssignmentDueDate}
                  </Typography>
                </CardContent>
                {/* <CardContent className={styles.assignmentTableStatus}>
                            <Badge color="primary" className={styles.statusIconNew}>
                              New
                            </Badge>
                          </CardContent> */}
                <CardContent className={styles.assignmentTableScore}>
                  <Typography
                    variant="body1"
                    className={styles.assignmentScoreContent}
                  >
                    {item.ObtainedMarks ? (
                      <span
                        style={{
                          color: item.ObtainedMarks
                            ? item.ObtainedMarks / item.totalMarks > 0.5
                              ? "green"
                              : "red"
                            : "inherit",
                        }}
                      >
                        {item.ObtainedMarks}
                      </span>
                    ) : (
                      "-"
                    )}
                    /{item.totalMarks}
                  </Typography>

                  {/* {item.ObtainedMarks ? (
                              <>
                                <Typography
                                  variant="body1"
                                  className={styles.assignmentScoreStatus}
                                >
                                  {item.ObtainedMarks ? ((item.ObtainedMarks / item.totalMarks) * 100) > 70 ? 'Qualified' : 'Fail' : 'Not Attempt'}
                                </Typography>
                              </>
                            ) :
                              (
                                <>
                                  <Typography
                                    variant="body1"
                                    className={styles.assignmentScoreStatus}
                                  >
                                    {item.isSubmitted === true ? 'Submitted' : 'Not Attempt'}
                                  </Typography>
                                </>
                              )} */}

                  <Typography
                    variant="body1"
                    className={styles.assignmentScoreStatus}
                  >
                    {item.markingStatus === "marked" ? (
                      <span style={{ color: "green" }}>Marked</span>
                    ) : item.markingStatus === "unmarked" &&
                      item.isSubmitted === true ? (
                      <span style={{ color: "green" }}>Submitted</span>
                    ) : (
                      "Not Attempt"
                    )}
                  </Typography>
                </CardContent>
                <CardContent className={styles.assignmentTableAction}>
                  {item.studentAssignmentSchedule === true ? (
                    <>
                      {" "}
                      <Button
                        className={styles.assignmentBtnCheckResult}
                        onClick={() => {
                          handleClick(item.studentAssignmentId);
                        }}
                      >
                        View
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Button
                        className={styles.assignmentBtnCheckResult}
                        onClick={handleOpenModal}
                      >
                        View
                      </Button>
                    </>
                  )}
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : (
          <Typography sx={{ padding: "30px" }}>
            There are no records to display.
          </Typography>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress sx={{ color: "#d6c38f" }} />
        </div>
      )}
      <CustomModal state={modalOpen} setState={setModalOpen}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          This assignment is not schedule.
        </Typography>

        <button className={styles.closeBtn} onClick={handleCloseModal}>
          +
        </button>
      </CustomModal>
    </>
  );
};

export default StudentAssignmentTable;
