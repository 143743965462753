import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../static/css/style.scss";
import { ToastContainer } from "react-toastify";
import HeaderMain from "../../pages/frontend/header";
import FooterMain from "../../pages/frontend/footer";
import EnrollNow from "../../pages/frontend/enroll-from";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth/auth";
import { checkLogoutTime } from "../../helper/helperFunction";
import { useLocation } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';


function Layout(params: any) {
  const theme = createTheme();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const location = useLocation();

  const { dispatch } = useAuth();

  useEffect(() => {
    checkLogoutTime(dispatch);
  }, [location.pathname]);


console.log(location.pathname,"location.pathname");


  useEffect(() => {
    
    const checkCookieConsent = () => {
      const cookieConsentElement = document.querySelector(".CookieConsent");
      console.log(cookieConsentElement, "cookieConsentElement");

      if (cookieConsentElement) {
        document.body.classList.add("has-cookie-consent");
      } else {
        document.body.classList.remove("has-cookie-consent");
      }
    };
    checkCookieConsent();

    // Create a MutationObserver to watch for changes to the DOM
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" &&
          (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0)
        ) {
            checkCookieConsent();
                 
        }
      }
    });

    // Observe changes to the entire document's child elements
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function to remove the observer and the class
    return () => {
      observer.disconnect();
      document.body.classList.remove("has-cookie-consent");
    };
  }, [location.pathname]); // Adding location.pathname as a dependency

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth={false}
          sx={{ marginTop: headerHeight ? `${headerHeight}px` : "" }}
        >
          <HeaderMain setHeaderHeight={setHeaderHeight} />
          <CssBaseline />
          {params.children}
          <ToastContainer />
          <FooterMain />
           {/* Cookie Consent as Popup */}
           {location.pathname !== "/privacy-policy" && (
            <CookieConsent
                location="bottom"
                buttonText="Accept all"
                declineButtonText="Reject"
                enableDeclineButton
                buttonStyle={{
                  color: "#ffffff",
                  background: "#4CAF50",
                  fontSize: "13px",
                  border: "none",
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  marginTop: "10px",
                  backgroundColor: "rgb(176 158 106)",
                }}
                declineButtonStyle={{
                  color: "#ffffff",
                  background: "#4CAF50",
                  fontSize: "13px",
                  border: "none",
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  margin: "0",
                  backgroundColor: "rgb(176 158 106)",
                }}

                expires={30} // Cookie expiration in days
              >
                <h2>Privacy Preferences</h2>
                We use cookies and similar technologies to improve your experience and for ads personalization. Learn more in our
                <span> </span>
                <a target="_blank" href="/privacy-policy" style={{ color: "#ffffff" }}>
                  privacy policy
                </a>
              </CookieConsent>
            )}
        </Container>
      </ThemeProvider>
    </>
  );
}

export default Layout;
