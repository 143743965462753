import { AdminApi, resolveUrl } from "../../../config/apis";
import Datatable from "../../../components/dataTables/Datatable";
import { toTitleCase } from "../../../helper/helperFunction";
import { Avatar, Box } from "@mui/material";
import ProgressCourseCell from "../../../components/BucketProgressBarSubject";
import styles from "./enrolled.module.css";
import AvatarImage from "../../../components/Avatar";

type props = {
  payId?: number;
};

export default function InstallmentCoursesPopup({ payId }: props) {
  const tableColumns = [
    {
      cell: (row: any) => (
        <>
          <ProgressCourseCell
            name={row.courseName}
            progress={row.progress ?? 0}
            image={row.courseFeatureImage}
          />
        </>
      ),
      name: "Student",
      sortable: false,
      width: "350px",
    },
    {
      cell: (row: any) => (
        <>
          <AvatarImage
            imageName={row.teacherImage}
            name={row.assignedTeacher}
          />
          {toTitleCase(`${row.assignedTeacher}`)}
        </>
      ),
      name: "Assigned Teacher",
      sortable: false,
    },
    {
      cell: (row: any) => (
        <>
          <AvatarImage
            imageName={row.studentImage}
            name={row.assignedStudent}
          />
          {toTitleCase(`${row.assignedStudent}`)}
        </>
      ),
      name: "Assigned Student",
      sortable: false,
    },
    {
      selector: (row: any) => row.lessons,
      name: "Lessons",
      sortable: false,
    },
  ];

  return (
    <>
      <Box
        className={`page-container ${styles.installment_padlist_table} `}
        padding={0}
      >
        <Datatable
          tableColumns={tableColumns}
          dataUrl={resolveUrl(
            AdminApi.INSTALLMENT_PAID_COURSES_LIST,
            `${payId}`
          )}
          actionColumns={false}
          module="manage-parent-courses"
        />
      </Box>
    </>
  );
}
