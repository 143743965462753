import { Avatar, Box } from "@mui/material";
import { HasPermission, toTitleCase } from "../../../../helper/helperFunction";
import Datatable from "../../../../components/dataTables/Datatable";
import { AdminApi, api, resolveUrl } from "../../../../config/apis";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DateView } from "../../../../components/DateView/DateView";
import FilterBase from "../filter/filter-base";
import SwitchCell from "../../../../components/switch";
import AvatarImage from "../../../../components/Avatar";

type FilterState = {
  teacherId: any;
  status: string | null;
  searchQuery: string | null;
};

export default function ViewParentUsers() {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<FilterState>();

  const handleFiltersChange = (filterState: FilterState) => {
    setFilters(filterState);
  };

  const tableColumns = [
    {
      selector: (row: { id: number }) => `#${row.id}`,
      name: "ID",
      sortable: false,
      width: "100px",
    },

    {
      cell: (row: { Name: string; profile_picture: string }) => (
        <>
          <AvatarImage imageName={row.profile_picture} name={row.Name} />
          {toTitleCase(`${row.Name}`)}
        </>
      ),
      selector: "Name",
      name: "Name",
      sortable: true,
      width: "200px",
    },

    {
      selector: (row: { Email: string }) => row.Email,
      name: "Email",
      sortable: false,
      width: "350px",
    },
    {
      selector: (row: { Place: string }) => row.Place,
      name: "City",
      sortable: false,
    },

    {
      selector: (row: { AddedOn: string }) => <DateView date={row.AddedOn} />,
      name: "Added on",
      sortable: false,
    },

    {
      selector: (row: { students: string }) => row.students,
      name: "Students",
      sortable: false,
    },
    {
      cell: (row: { id: any; is_active: any }) => {
        return <SwitchCell id={row.id} is_active={row.is_active} />;
      },
      sortable: false,
      width: "60px",
    },
  ];

  const onView = async (id: string) => {
    navigate(`/admin/user/parents/view/${id}`);
  };
  const onEdit = async (id: string) => {
    navigate(`/admin/user/parents/edit/${id}`);
  };
  const onDelete = async (id: string) => {
    return await api.delete(resolveUrl(AdminApi.USER_DEL_BY_ADMIN, id));
  };

  return (
    <>
      <div className="course-content">
        <h1 className="main-heading">Parents</h1>
        <Box className="btn-wrapper">
          <HasPermission permission="create.manage-users">
            <Link to={"edit"} style={{ marginRight: 0 }}>
              Add Parent
            </Link>
          </HasPermission>
        </Box>
      </div>
      <FilterBase onFiltersChange={handleFiltersChange} placeholder="Name" />

      <div className="page-container ">
        <Datatable
          tableColumns={tableColumns}
          dataUrl={AdminApi.USER_PARENT_PAGINATE}
          filters={filters}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
          actions={{
            view: true,
            edit: true,
            delete: true,
          }}
          module="manage-users"
        />
      </div>
    </>
  );
}
